import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-yield-list',
  templateUrl: './yield-list.component.html',
  styleUrls: ['./yield-list.component.scss']
})
export class YieldListComponent {
  searchOpen = true;
  yieldres = new ResponseArticle;
  yieldlist: Articledata = {} as Articledata;
  TotalItems=0;
  constructor(
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService
  ) {
    this.yieldres.FunctionID = 7107;
  }

  // Getyield() {
  //   this.yieldres.FunctionID = 7107;
  //   this.aritcleService.GetArticleByUnit(this.yieldres).subscribe((res) => {
  //     if (res.StatusCode == EnumStatusCode.Success) {
  //       this.yieldlist = res.Entries;
  //       console.log(this.yieldlist.Data)
  //     }
  //   });
  // }
}
