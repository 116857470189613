import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-qa-info',
  templateUrl: './qa-info.component.html',
  styleUrls: ['./qa-info.component.scss']
})
export class QAInfoComponent {
  @Input() data: any[] = [];
}
