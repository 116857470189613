<div class="footerbox">
  <div class="footerline"></div>
  <div class="redbox">
    <main class="mainbox">
      <div class="meun" *ngIf="footermeunlist.Data">

        <ng-container *ngFor="let item of footermeunlist.Data" >
          <a  [href]="getLink(item)" [attr.target]="getTarget(item)" >{{item?.CName}}</a>
          <!-- <a *ngIf="item.CLinkType==2" href="{{item.CLinkFile}}" [target]="item.CAnother == 1 ? '_blank' : '_parent'" >{{item?.CName}}</a>
          <a *ngIf="item.CLinkType==3" href="unit/Type1/{{item.CFunctionId}}/{{item.CId}}" [target]="item.CAnother == 1 ? '_blank' : '_parent'" >{{item?.CName}}</a> -->
        </ng-container>


      </div>
    </main>
  </div>
  <div>
  <main class="footermapline">
    <div class="footertop">
      <!-- //電腦版harcode -->
      <div class="contentbox mb">
        <div>{{this.footerEditContent.Content01}} | 街口證券投資信託股份有限公司</div>
        <div class="contentext2"><a href="https://maps.app.goo.gl/8du4HTGYcR28VGJDA" target="_blank"> <img src="/assets/images/map-marker.svg" alt="MAP" style="margin-right: 5px;"></a>106 臺北市大安區市民大道三段206號9樓 電話：(02)2750-5555</div>
        <div class="contentext2">客服專線：0800-818-899 | {{this.footerEditContent.Content02}} </div>
      </div>
      <!-- //手機harcode -->
      <div class="contentbox web">
        <div>{{this.footerEditContent.Content01}} </div>
        <div class="contentext2">
          <div class="textmrg">街口證券投資信託股份有限公司</div>
          <div class="textmrg"><a href="https://maps.app.goo.gl/8du4HTGYcR28VGJDA" target="_blank"><img src="/assets/images/map-marker.svg" alt="MAP" style="margin-right: 5px;"></a>106 臺北市大安區市民大道三段206號9樓</div>
          <div class="textmrg" style="margin-top: 8px;">電話：(02)2750-5555</div>
          <div class="textmrg" style="margin-top: 8px;">客服專線：0800-818-899</div>
          <!-- <div class="textmrg">傳真專線：02-2234-5679</div> -->
          <div class="textmrg">{{this.footerEditContent.Content02}} </div>
        </div>
      </div>

      <!-- 社群icon -->
      <div class="social-icons">
        <a *ngFor="let data of homeService.socialIcon" [href]="data.CUrl" target="_blank">
          <img *ngIf="data.CType == 2" class="social-icon" [src]="data.CIcon" [alt]="data.CName" />
          <span class="socialText" *ngIf="data.CType == 1">{{data.CName}}</span>
        </a>
        <!-- <a href="https://www.facebook.com/people/%E8%A1%97%E5%8F%A3%E6%8A%95%E4%BF%A1/100063892302010/" target="_blank">
            <img class="social-icon" src="/assets/images/fb.svg" alt="FB-Link"/>
        </a>
        <a href="https://www.instagram.com/jkoamam/" target="_blank">
            <img class="social-icon" src="/assets/images/ig.svg" alt="IG-Link"/>
        </a>
        <a href="https://www.youtube.com/channel/UCFRNOqW3tQ-3EXhk8bEHVYg/" target="_blank">
            <img class="social-icon" src="/assets/images/youtube.svg" alt="youtube-Link"/>
        </a> -->
    </div>
    </div>
  </main>
</div>
<div class="footerbox">
  <main>
    <div>
      <div class="selecbox" [ngClass]="{'fund-close': !searchOpen}" *ngIf="contents?.length">
        <!-- <div>【{{contents[0].CTitle}}】</div> -->
        <div class="warntext" [innerHTML]="contents[0].CContent|html"></div>
      </div>
      <button class="arrow-btn" (click)="close()">
        {{searchOpen ? '完全展開' : '收合'}}
        <div class="top-arrow" [ngClass]="{'arrow-close':searchOpen}"></div>
      </button>
    </div>
  </main>
</div>
