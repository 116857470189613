import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from 'src/app/services/service-base';
import { LibHttpUrl } from '../../api/public-api';
import { IDPreview, ResponseBase, TypeID } from '../models/base';
import {
  FundOverviewList, FundCategoryReq, FundNetValueList, GetFundTypes,
  FundSelectList, FundDetailTopReq, FundDetailTopData, FundDetailPageReq,
  FundDetailPageData, FundDetailReq, FundDetailData, FundNAV, FundNAVData, FundYieldReq, FundYieldList,
  PageNAVList,
  FundRankImportList, FundCalendarReq, FundCalendarData, RateOfChangeData, FundDetail, FundComposition, FundRecordDate, TrackDiffDataunb,
  FundInvestment
} from '../models/fund';
import { Category } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class FundService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Fund}`;
  Keyword = '';
  searchOpen = true;
  selectedItem:Category[] = []
  KeywordId= 0;
  request: FundCategoryReq = {} as FundCategoryReq;
  constructor(
    http: HttpClient
  ) {
    super(http);
    this.request.PageSize = 10;
    this.request.PageIndex = 1;
  }

  GetFundOverviewList(request: FundCategoryReq): Observable<ResponseBase<FundOverviewList[]>> {
    const url = `${this.baseUrl}/GetFundOverviewList`;
    return this.http.post<ResponseBase<FundOverviewList[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundNetValueList(request: FundCategoryReq): Observable<ResponseBase<FundNetValueList[]>> {
    const url = `${this.baseUrl}/GetFundNetValueList`;
    return this.http.post<ResponseBase<FundNetValueList[]>>(
      url,
      request,
      this.httpOptions);
  }
  GetFundTypes(): Observable<ResponseBase<GetFundTypes[]>> {
    const url = `${this.baseUrl}/GetFundTypes`;
    return this.http.get<ResponseBase<GetFundTypes[]>>(
      url,
      this.httpOptions);
  }

  GetFundSelectList(request: IDPreview): Observable<ResponseBase<FundSelectList[]>> {
    const url = `${this.baseUrl}/GetFundSelectList`;
    return this.http.post<ResponseBase<FundSelectList[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundDetailTopArea(request: FundDetailTopReq): Observable<ResponseBase<FundDetailTopData>> {
    const url = `${this.baseUrl}/GetFundDetailTopArea`;
    return this.http.post<ResponseBase<FundDetailTopData>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundDetailPage(request: FundDetailPageReq): Observable<ResponseBase<FundDetailPageData>> {
    const url = `${this.baseUrl}/GetFundDetailPage`;
    return this.http.post<ResponseBase<FundDetailPageData>>(
      url,
      request,
      this.httpOptions);
  }

  //活動頁面用
  GetFundDetail(request: FundDetailTopReq): Observable<ResponseBase<FundDetail>> {
    const url = `${this.baseUrl}/GetFundDetail`;
    return this.http.post<ResponseBase<FundDetail>>(
      url,
      request,
      this.httpOptions);
  }

  //活動頁投資組合
  GetFundInvestment(request: FundDetailTopReq): Observable<ResponseBase<FundInvestment[]>> {
    const url = `${this.baseUrl}/GetFundInvestment`;
    return this.http.post<ResponseBase<FundInvestment[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundFileLink(request: FundDetailReq): Observable<ResponseBase<FundDetailData>> {
    const url = `${this.baseUrl}/GetFundFileLink`;
    return this.http.post<ResponseBase<FundDetailData>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundNAV(request: FundNAV): Observable<ResponseBase<FundNAVData>> {
    const url = `${this.baseUrl}/GetFundNAV`;
    return this.http.post<ResponseBase<FundNAVData>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundPageNAVList(request: FundNAV): Observable<ResponseBase<PageNAVList[]>> {
    const url = `${this.baseUrl}/GetFundPageNAVList`;
    return this.http.post<ResponseBase<PageNAVList[]>>(
      url,
      request,
      this.httpOptions);
  }

  //配息資訊列表
  GetNewestFundYieldList(request: FundYieldReq): Observable<ResponseBase<FundYieldList[]>> {
    const url = `${this.baseUrl}/GetNewestFundYieldList`;
    return this.http.post<ResponseBase<FundYieldList[]>>(
      url,
      request,
      this.httpOptions);
  }

  //基金績效列表
  GetFundRankImportList(request: FundCategoryReq): Observable<ResponseBase<FundRankImportList[]>> {
    const url = `${this.baseUrl}/GetFundRankImportList`;
    return this.http.post<ResponseBase<FundRankImportList[]>>(
      url,
      request,
      this.httpOptions);
  }

  //休市日期
  GetFundCalendar(request: FundCalendarReq): Observable<ResponseBase<FundCalendarData[]>> {
    const url = `${this.baseUrl}/GetFundCalendar`;
    return this.http.post<ResponseBase<FundCalendarData[]>>(
      url,
      request,
      this.httpOptions);
  }

  // 取得變動率

  GetRateOfChange(): Observable<ResponseBase<RateOfChangeData[]>> {
    const url = `${this.baseUrl}/GetRateOfChange`;
    return this.http.get<ResponseBase<RateOfChangeData[]>>(
      url,
      // request,
      this.httpOptions);
  }

  // 取得追蹤差距
  GetTrackDiff(): Observable<ResponseBase<TrackDiffDataunb[]>> {
    const url = `${this.baseUrl}/GetTrackDiff`;
    return this.http.get<ResponseBase<TrackDiffDataunb[]>>(
      url,
      // request,
      this.httpOptions);
  }

  //配息組成
  GetFundComposition(request: FundYieldReq): Observable<ResponseBase<FundComposition[]>> {
    const url = `${this.baseUrl}/GetFundComposition`;
    return this.http.post<ResponseBase<FundComposition[]>>(
      url,
      request,
      this.httpOptions);
  }

  //配息基準日
  GetFundRecordDate(request: FundYieldReq): Observable<ResponseBase<FundRecordDate[]>> {
    const url = `${this.baseUrl}/GetFundRecordDate`;
    return this.http.post<ResponseBase<FundRecordDate[]>>(
      url,
      request,
      this.httpOptions);
  }
}
