import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({ selector: '[appMenuHover]' })
export class AppMenuHoverDirective {
  @Input() color = '';
  @Input() backgroundColor = '';
  @Input() hoverColor = '';
  @Input() hoverBackgroundColor = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, 'color', this.color);
    this.renderer.setStyle(
      this.el.nativeElement,
      'background-color',
      this.backgroundColor
    );
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.renderer.setStyle(this.el.nativeElement, 'color', this.hoverColor);
    this.renderer.setStyle(
      this.el.nativeElement,
      'background-color',
      this.hoverBackgroundColor
    );
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.renderer.setStyle(this.el.nativeElement, 'color', this.color);
    this.renderer.setStyle(
      this.el.nativeElement,
      'background-color',
      this.backgroundColor
    );
  }
}
