import { Component } from '@angular/core';
import { CategoryService } from './../../../shared/services/category.service';
import { BaseService } from './../../../shared/services/base.service';
import { FundService } from './../../../shared/services/fund.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Category, FundCategory } from '../../../shared/models/category';
import { FundOverviewList, FundCategoryReq, FundNetValueList, FundRankImportList } from '../../../shared/models/fund';
import { Router, ActivatedRoute } from '@angular/router';
import { mainData } from '../../../shared/interface/main-data';
@Component({
  selector: 'app-fund-list',
  templateUrl: './fund-list.component.html',
  styleUrls: ['./fund-list.component.scss']
})
export class FundListComponent {
  categoryData: Category[] = [];
  fundCategoryList: FundCategory[] = [];
  // request: FundCategoryReq = {} as FundCategoryReq;
  fundOverviewList: FundOverviewList[] = [];
  fundNetValueList: FundNetValueList[] = [];
  fundRankImportList: FundRankImportList[] = [];
  TotalItems = 0;
  dots: number[] = [0, 1, 2, 3, 4]
  tabList = [
    {
      id: 1,
      name: '基金總覽',
    }, {
      id: 2,
      name: '基金淨值',
    }, {
      id: 3,
      name: '基金績效',
    }
  ]
  tabActive = 1;
  isSubmit = false; //是否搜尋過
  currPath = ''; //當前路由
  fundRankDate = ''
  constructor(
    public categoryService: CategoryService,
    public fundService: FundService,
    public baseService: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    public mainData$: mainData,
  ) {
    this.GetOverviewHotKeyword();
    this.GetSearchFundCategory();
  }

  ngOnInit(): void {
    // this.fundService.request.PageSize = 10;
    // this.fundService.request.PageIndex = 1;
    this.route.url.subscribe(url => {
      this.currPath = url[0].path;
      if (this.currPath == 'fund-value') this.tabActive = 2;
      else if (this.currPath == 'fund-performance') this.tabActive = 3;
      else this.tabActive = 1;
      this.submit();
    });
  }

  GetOverviewHotKeyword() {
    this.categoryService.GetOverviewHotKeyword({ Page: 1 }).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.categoryData = res.Entries;
      }
    });
  }

  GetSearchFundCategory() {
    this.categoryService.GetSearchFundCategory(1).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundCategoryList = res.Entries;
      }
    });
  }

  tabActiveChange(id: number) {
    this.tabActive = id;
    if (this.tabActive == 2) this.router.navigate(['product/fund-value'])
    else if (this.tabActive == 3) this.router.navigate(['product/fund-performance'])
    else this.router.navigate(['product/fund-list'])
    this.submit();
    // if(this.isSubmit) this.getSubmit();
  }

  more() {
    this.fundService.request.PageSize = this.fundService.request.PageSize + 10;
    this.getSubmit();
  }

  // goBuy(data:any) {
  //  if(data.FundNo){
  //   if(!data.IsETF) {
  //     this.router.navigate([`/fund-info/${data.FundNo}`], {
  //       queryParams: {
  //         tab: 1
  //       }
  //     });
  //   } else {
  //     this.router.navigate([`/etf/etf-info/${data.FundNo}`], {
  //       queryParams: {
  //         tab: this.tabActive
  //       }
  //     });
  //   }}
  //   else{
  //     if(!data.IsETF) {
  //       this.router.navigate([`/fund-info/${data.CNo}`], {
  //         queryParams: {
  //           tab: 1
  //         }
  //       });
  //     } else {
  //       this.router.navigate([`/etf/etf-info/${data.CNo}`], {
  //         queryParams: {
  //           tab: this.tabActive
  //         }
  //       });
  //     }
  //   }
  // }

  goBuy(data: any, router: number) {
    const routePrefix = data.IsETF ? '/etf/etf-info' : '/fund-info';
    const fundNoOrCNo = data.FundNo || data.CNo;
    if (fundNoOrCNo) {
      this.router.navigate([`${routePrefix}/${fundNoOrCNo}`], {
        queryParams: {
          tab: router || 1
        }
      });
    }
  }

  goRegister(data: any) {
    const fundNoOrCNo = data.FundNo || data.CNo;
    if (data.IsETF) {
      window.open(`/etf/register/${fundNoOrCNo}`, '_blank');
    } else {
      window.open('https://ec.jkoam.com/login.php', '_blank');
    }
  }

  getLength() {
    if (this.tabActive == 1) return this.fundOverviewList.length;
    else if (this.tabActive == 2) return this.fundNetValueList.length;
    else return this.fundRankImportList.length;
  }

  submit(e?: any) {
    if (e) this.fundService.request = e;
    this.fundService.request.Keyword = this.fundService.request.Keyword || '';
    this.fundService.request.TypeIDs = this.fundService.request.TypeIDs || [];
    this.fundService.request.AreaIDs = this.fundService.request.AreaIDs || [];
    this.fundService.request.RiskLvIDs = this.fundService.request.RiskLvIDs || [];
    this.fundService.request.DividendIDs = this.fundService.request.DividendIDs || [];
    this.fundService.request.CurrencyTypeIDs = this.fundService.request.CurrencyTypeIDs || [];
    e?.data.filter((val: any) => { if (val.CodeType == 1) this.fundService.request.TypeIDs.push(val.CategoryID) })
    e?.data.filter((val: any) => { if (val.CodeType == 8) this.fundService.request.AreaIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 11) this.fundService.request.RiskLvIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 7) this.fundService.request.DividendIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 9) this.fundService.request.CurrencyTypeIDs.push(val.CategoryID) });
    this.getSubmit();
  }

  getSubmit() {
    if (this.tabActive == 1) this.GetFundOverviewList();
    else if (this.tabActive == 2) this.GetFundNetValueList();
    else if (this.tabActive == 3) this.GetFundRankImportList();
    // else if(this.tabActive == 3) 基金績效api
  }

  GetFundOverviewList() {
    this.fundService.GetFundOverviewList(this.fundService.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundOverviewList = res.Entries;
        this.TotalItems = res.TotalItems;
      }
      this.isSubmit = true;
      this.mainData$.loading$.next(false);
    });
  }

  GetFundNetValueList() {
    this.fundService.GetFundNetValueList(this.fundService.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundNetValueList = res.Entries;
        this.TotalItems = res.TotalItems;
      }
      this.isSubmit = true;
      this.mainData$.loading$.next(false);
    });
  }

  GetFundRankImportList() {
    this.fundService.GetFundRankImportList(this.fundService.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundRankImportList = res.Entries;
        this.fundRankDate = this.fundRankImportList[0].CResourceDate;
        this.TotalItems = res.TotalItems;
      }
      this.isSubmit = true;
    });
    this.mainData$.loading$.next(false);
  }
}
