import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, PLATFORM_ID
} from '@angular/core';
import { BaseService } from '../../../../shared/services/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  GetFundTypes, FundSelectList, FundDetailTopReq, FundDetailData, FundDetailTopData, FundDetailPageReq,
  FundDetailPageData, FundDetail, FundInvestment, FundRankImport, FundYield, FundDetailReq, FundConstituent,
  ParticipatingDealer, FundTradeInfoReq, TradeInfoDatas, FundNAV, FundNAVvsPrice
} from '../../../../shared/models/etf';
import { FundService } from '../../../../shared/services/fund.service';
import { ETFService } from '../../../../shared/services/etf.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { enumETFDateList } from 'src/app/enum/enumDateList';
import { Popup, DialogData, PopupReq, IDPreview } from '../../../../shared/models/base';
import { mainData } from '../../../../shared/interface/main-data';
import { Meta, Title } from '@angular/platform-browser';
import { footernumber, MetaData } from 'src/app/shared/models/home';
import { HomeService } from 'src/app/shared/services/home.service';
@Component({
  selector: 'app-etf-info',
  templateUrl: './etf-info.component.html',
  styleUrls: ['./etf-info.component.scss']
})
export class EtfInfoComponent {
  isBrowser: boolean;
  domain = environment.domain;
  router1 = 'etf';
  router2 = 'etf-info';
  FundNo = '';
  currPath = ''; //當前的路由
  fundTypeList: GetFundTypes[] = [];//第一層下拉選單
  selectedType: GetFundTypes = {
    Name: '全部',
    Id: 0,
    ParentID: 0,
    Sort: 0
  } as GetFundTypes;
  fundSelectList: FundSelectList[] = []; //第二層下拉選單
  selectedFund: FundSelectList = {
    "FundNo": "100",
    "FundFullName": "1-1test_miranda",
    "FundShortName": "1-1test",
    "FundWarning": "警語test",
    "IsETF": false
  } as FundSelectList;
  fundDetailTopReq: FundDetailTopReq = {} as FundDetailTopReq;
  tabList = [
    {
      id: 1,
      name: '基本介紹',
    }, {
      id: 2,
      name: '指數介紹',
    }, {
      id: 3,
      name: '預估淨值vs即時市價',
    }, {
      id: 4,
      name: '淨值vs收盤價',
    }, {
      id: 5,
      name: '基金成分股',
    }, {
      id: 6,
      name: '申購買回清單',
    }, {
      id: 7,
      name: '流動量提供與參與券商',
    }
  ]
  tabActive = 1;
  FundLinkData: FundDetailData = {} as FundDetailData;
  FundDetailTopData: FundDetailTopData = {} as FundDetailTopData;
  fundDetailPageReq: FundDetailPageReq = {} as FundDetailPageReq;
  FundDetailPageData: FundDetailPageData = {} as FundDetailPageData;
  FundDetail: FundDetail = {} as FundDetail;
  FundFeatureList: string[] = []; //基金特色
  FundInvestment: FundInvestment[] = [];
  FundRankImport: FundRankImport = {} as FundRankImport;
  FundYield: FundYield = {} as FundYield;
  FundFileLink: FundDetailReq = {} as FundDetailReq;
  FundConstituent: FundConstituent = {} as FundConstituent;
  ParticipatingDealerReq: FundDetailTopReq = {} as FundDetailTopReq;
  ParticipatingDealerList: ParticipatingDealer[] = [];
  FundTradeInfoReq: FundTradeInfoReq = {} as FundTradeInfoReq;
  FundTradeInfo: TradeInfoDatas = {} as TradeInfoDatas;
  FundNAVReq: FundNAV = {} as FundNAV;
  FundNAVData: FundNAVvsPrice = {} as FundNAVvsPrice;
  FundNAVDataup: FundNAVvsPrice = {} as FundNAVvsPrice;
  enumDateList = enumETFDateList;
  Createdate: string = '';
  //警語彈窗
  dialogData: DialogData[] = [];
  visible = false;
  popupData: Popup[] = [];
  UpdateTime: Popup[] = [];
  ETFMsgDate = '';
  ETFMsgUpdate = '';
  agreeTimes = 0;
  agreeTimesDisclaimer = 0;
  PopupReq = new PopupReq;
  dialogDataDisclaimer: DialogData = {} as DialogData;
  popupDataDisclaimer: Popup = {} as Popup;
  UpdateTimeDisclaimer: Popup = {} as Popup;
  ETFMsgDateDisclaimer = '';
  ETFMsgUpdateDisclaimer = '';
  Disclaimer = false;
  isPreview = false;
  FundsSplitRemark = '';
  metaTagData: MetaData = {} as MetaData;
  iDPreview: IDPreview = {} as IDPreview;
  previousTab: number | null = null;
  MetaDataTitle ='';
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    public baseService: BaseService,
    public fundService: FundService,
    public ETFService: ETFService,
    public mainData$: mainData,
    private titleService: Title,
    private metaTagService: Meta,
    public homeService: HomeService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.GetFundTypes();
    this.GetFundSelectList(0);
    this.goTop();
    this.getETFMsg();
    this.getETFDisclaimer();
    // this.getMetaData();
  }

  ngOnInit(): void {
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      this.tabActive = params['tab'];
      this.FundNo = params['id'];
      this.fundDetailTopReq.FundNo = params['id'];
      this.fundDetailPageReq.FundNo = params['id'];
      this.FundFileLink.FundNo = params['id'];
      this.ParticipatingDealerReq.FundNo = params['id'];
      this.FundTradeInfoReq.FundNo = params['id'];
      this.FundNAVReq.FundNo = params['id'];
      if (this.tabActive == 5) this.GetFundConstituent();
      //  this.selectedFund.FundNo = this.FundNo;
      // console.log(this.selectedFund)
    });

    urlParams.subscribe(queryParams => {
      if (queryParams['isPreview'] !== null && queryParams['isPreview'] !== undefined) {
        this.isPreview =  Boolean(queryParams['isPreview']);
        this.iDPreview.IsPreview = this.isPreview;
         this.GetFundSelectList(0);
      }
    });

    this.route.url.subscribe(url => {
      this.GetFundFileLink();
      this.GetFundDetailTopArea();
      this.GetFundDetailPage();
      this.GetParticipatingDealer();
      this.FundTradeInfoReq.Date = null;
      this.GetFundTradeInfo();
      //預設30天
    //  this.FundNAVReq.SDate = new Date(this.enumDateList[0].SDate);
    //  this.FundNAVReq.EDate = new Date(this.enumDateList[0].EDate);
      this.GetFundNAVvsPrice();
      this.GetFundNAVvsPricedown();
    })
    // this.selectedFund.FundNo = this.fundDetailTopReq.FundNo
    // this.selectedFund.FundFullName ='街口標普高盛布蘭特原油ER單日正向2倍指數股票型期貨信託基金'
    // this.selectListChange()
    // this.selectedFund.FundFullName
    // this.GetFundSelectList(this.fundDetailTopReq.FundNo);
  }

  getMetaData() {
    this.homeService.GetMetaData().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.metaTagData = res.Entries
        if (this.metaTagData.CTitle) this.titleService.setTitle(this.metaTagData.CTitle)
        if (this.metaTagData.CDescription) this.metaTagService.updateTag({ name: 'description', content: this.metaTagData.CDescription });
        if (this.metaTagData.CKeyWord) this.metaTagService.updateTag({ name: 'keyword', content: this.metaTagData.CKeyWord });
      }
    });
  }

  getETFMsg() {
    // ETF警語
    this.ETFMsgDate = localStorage.getItem('ETFMsg') || ''
    this.GetPopup();
  }

  getETFDisclaimer() {
    //ETF免責
    this.ETFMsgDateDisclaimer = localStorage.getItem('ETFMsgDisclaimer') || ''
    this.GetTermsMaintenance();
  }

  // Call API
  GetFundTypes() {
    this.fundService.GetFundTypes().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundTypeList = [
          {
            Name: '全部',
            Id: 0,
            ParentID: 0,
            Sort: 0
          },
          ...res.Entries,
        ]
      }
    });
  }

  GetFundSelectList(id: number) {
    this.iDPreview.TypeID = id;
    this.fundService.GetFundSelectList(this.iDPreview).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundSelectList = res.Entries;
        const selectedFundNo = this.FundNo;
        const foundFund = this.fundSelectList?.find(fund => fund.FundNo === selectedFundNo);
        if (foundFund) this.selectedFund = foundFund;
        //   console.log(this.selectedFund);
        if (this.selectedFund && id == 0) {
          this.selectListChange(this.selectedFund)
        }
        if (id !== 0 && this.fundSelectList[0]) {
          this.selectListChange(this.fundSelectList[0])
        }

      }
      this.mainData$.loading$.next(false);
    });
  }

  GetFundList(id: number) {
    this.iDPreview.TypeID = id;
    this.fundService.GetFundSelectList(this.iDPreview).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundSelectList = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }


  GetFundDetailTopArea() {
    this.fundService.GetFundDetailTopArea(this.fundDetailTopReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundDetailTopData = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }


  //取得基金下方資訊
  GetFundDetailPage() {
    this.fundDetailPageReq = {
      ...this.fundDetailPageReq,
      SearchType: 1,
      Year: 0,
      SDate: new Date(),
      EDate: new Date(),
      IsPreview: this.isPreview
    }

    this.fundService.GetFundDetailPage(this.fundDetailPageReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        if (res.Entries && res.Entries.FundDetail && res.Entries.FundDetail.BasicInfo) {
          const footerNumber: footernumber = { CId: res.Entries.FundDetail.BasicInfo.FooterID };
          this.baseService.footerID$.next(footerNumber);
          this.Createdate = res.Entries.FundDetail.BasicInfo.SetUpDt
          this.FundsSplitRemark = res.Entries.FundDetail.BasicInfo.FundsSplitRemark
          const selected = res.Entries.FundDetail.BasicInfo.FundType;
          const foundFundTYPE = this.fundTypeList?.find(TYPE => TYPE.Id === selected);
          if (foundFundTYPE) {
            this.selectedType = foundFundTYPE;
            this.GetFundList(this.selectedType.Id);
          }
        }
        else {
          this.baseService.footerID$.next({ CId: 1 });
        }
        this.FundDetailPageData = res.Entries;
        this.FundDetail = this.FundDetailPageData.FundDetail;
        //基本資料斷行
       if(this.FundDetail.BasicInfo){
        this.FundDetail.BasicInfo.StockIndex = this.FundDetail.BasicInfo.StockIndex.replace(/\r\n|\n/g, '<br>');
        this.FundDetail.BasicInfo.IndexLicensingFee = this.FundDetail.BasicInfo.IndexLicensingFee.replace(/\r\n|\n/g, '<br>');
        this.FundDetail.BasicInfo.MarginTrading = this.FundDetail.BasicInfo.MarginTrading.replace(/\r\n|\n/g, '<br>');
        this.FundDetail.BasicInfo.SecMarketTranFee = this.FundDetail.BasicInfo.SecMarketTranFee.replace(/\r\n|\n/g, '<br>');
        this.FundDetail.BasicInfo.CashNAV = this.FundDetail.BasicInfo.CashNAV.replace(/\r\n|\n/g, '<br>');
      }
       //注意事項斷行
        if (this.FundDetail.CETFTranNotice) {
          this.FundDetail.CETFTranNotice = this.FundDetail?.CETFTranNotice?.map(item => {
            return {
              ...item,
              Val1: item.Val1.replace(/\r\n|\n/g, '<br>'),
              Val2: item.Val2.replace(/\r\n|\n/g, '<br>')
            };
          });
        }
        this.FundFeatureList = this.FundDetail.BasicInfo?.FundFeature.split('\r\n') || [];
        this.FundFeatureList = this.FundFeatureList.filter(data => data)
        this.FundInvestment = this.FundDetailPageData.FundInvestment;
        this.FundInvestment = this.FundInvestment.map(val => {
          return {
            ...val,
            chartOptions: {
              // ...this.chartOptions,
              series: [{
                data: val.Data,
                type: 'pie',
                dataLabels: {
                  enabled: false
                },
                // colors: this.colors,
                innerSize: '80%',
              }]
            }
          }
        })
        this.FundRankImport = this.FundDetailPageData.FundRankImport;
        this.FundYield = this.FundDetailPageData.FundYield;
        if (res.Entries.FundDetail.MetaData) {
          if(res.Entries.FundDetail.MetaData.Title){
            this.MetaDataTitle = res.Entries.FundDetail.MetaData.Title;
            this.titleService.setTitle(res.Entries.FundDetail.MetaData.Title);
            this.metaTagService.updateTag({
              name: 'title',
              content: res.Entries.FundDetail.MetaData.Title
            });
          }
       if(res.Entries.FundDetail.MetaData.Description){
        this.metaTagService.updateTag({
          name: 'description',
          content: res.Entries.FundDetail.MetaData.Description,
        });
       }
       if(res.Entries.FundDetail.MetaData.Keywords){
          this.metaTagService.updateTag({
            name: 'keyword',
            content: res.Entries.FundDetail.MetaData.Keywords,
          });
        }
      }
      }
    });
  }

  //取得下方檔案下載
  GetFundFileLink() {
    this.fundService.GetFundFileLink(this.FundFileLink).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundLinkData = res.Entries;
      }
    });
  }

  //淨值VS收盤價
  GetFundNAVvsPrice() {
    this.FundNAVReq.isDesc = false;
    this.ETFService.GetFundNAVvsPrice(this.FundNAVReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundNAVData = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetFundNAVvsPricedown() {
    this.FundNAVReq.isDesc = true;
    this.ETFService.GetFundNAVvsPrice(this.FundNAVReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundNAVDataup = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }


  //取得基金成分股
  GetFundConstituent() {
    this.ETFService.GetFundConstituent(this.FundFileLink).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundConstituent = res.Entries;
      }
    });
  }

  //取得申購買回清單
  GetFundTradeInfo() {
    this.ETFService.GetFundTradeInfo(this.FundTradeInfoReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundTradeInfo = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  //流動量提供與參與券商
  GetParticipatingDealer() {
    this.ETFService.GetParticipatingDealer(this.ParticipatingDealerReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.ParticipatingDealerList = res.Entries;
      }
    });
  }

  //搜尋淨值VS收盤價
  getNavSubmit(e: FundNAV) {
    this.FundNAVReq = e
    this.GetFundNAVvsPrice();
    this.GetFundNAVvsPricedown();
  }

  selectListChange(e: any) {
   // this.fundDetailTopReq.FundNo = this.selectedFund.FundNo;
    if (e.value) {
      this.selectedFund = e.value;
      if (e.value.IsETF) {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
      };
      this.router.navigate([`/etf/etf-info/${e.value.FundNo}`], { queryParams });
      } else {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
      };
      this.router.navigate([`/fund-info/${e.value.FundNo}`], { queryParams });
      }
    }
    else if(e) {
      this.selectedFund = e;
      if (e.IsETF) {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
      };
      this.router.navigate([`/etf/etf-info/${e.FundNo}`], { queryParams });
      } else {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
      };
      this.router.navigate([`/fund-info/${e.FundNo}`], { queryParams });
      }
    }
  }

  selectedTypeChange(e: any) {
    this.GetFundSelectList(this.selectedType.Id);
    // this.selectListChange(this.selectedFund)
  }


  tabActiveChange(id: number) {
    this.getETFMsg();
    this.tabActive = id;
    this.router.navigate(['/etf/etf-info/' + this.fundDetailTopReq.FundNo], {
      queryParams: {
        tab: id
      }
    });
  }

  dateChange(date: string) {
    this.FundTradeInfoReq.Date = date;
    this.GetFundTradeInfo();
  }


  GetPopup() {
    this.baseService.GetPopup(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupData = res.Entries;
        this.UpdateTime = this.popupData.sort(function (a, b) {
          let timeA: string = '';
          let timeB: string = '';
          timeA = a.UpdateTime;
          timeB = b.UpdateTime;

          if (timeA < timeB) {
            return 1;
          }
          if (timeA > timeB) {
            return -1;
          }
          return 0;
        })
        this.ETFMsgUpdate = localStorage.getItem('ETFMsgUpdate') || ''
        if ((!this.ETFMsgDate || this.baseService.getTimeout0hour(this.ETFMsgDate)) || (this.UpdateTime.length && this.ETFMsgUpdate !== this.UpdateTime[0].UpdateTime)) {
          this.OpenDialog();
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetTermsMaintenance() {
    // 免責在首頁僅供預覽
    this.baseService.GetTermsMaintenance(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupDataDisclaimer = res.Entries;
        this.UpdateTimeDisclaimer = this.popupDataDisclaimer;
        this.ETFMsgUpdateDisclaimer = localStorage.getItem('ETFMsgUpdateDisclaimer') || ''
        if ((!this.ETFMsgDateDisclaimer || this.baseService.getTimeout0hour(this.ETFMsgDateDisclaimer)) || (this.UpdateTimeDisclaimer && this.ETFMsgUpdateDisclaimer !== this.UpdateTimeDisclaimer.UpdateTime)) {
          this.OpenDialogDisclaimer();
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  OpenDialog() {
    if (this.isBrowser) this.visible = true;
    this.dialogData = this.popupData.map(val => {
      return {
        title: val.Title,
        message: val.Content,
        data: val
      }
    });
  }

  OpenDialogDisclaimer() {
    // 免責在首頁僅供預覽
    if (this.isBrowser) this.visible = true;
    this.Disclaimer = true;
    this.dialogDataDisclaimer = {
      title: this.popupDataDisclaimer.Title,
      message: this.popupDataDisclaimer.Content,
      data: this.popupDataDisclaimer,
    };
  }



  visibleChange(e: any) {
    if (e) this.agreeTimes = this.agreeTimes + 1;
    if (this.agreeTimes == this.popupData.length || (this.agreeTimes === 3)) {
      localStorage.setItem('ETFMsg', new Date().toString());
      if (this.UpdateTime.length) localStorage.setItem('ETFMsgUpdate', this.UpdateTime[0].UpdateTime)
    }

  }

  visibleChangeDisclaimer(e: any) {
    if (this.popupDataDisclaimer) {
      localStorage.setItem('ETFMsgDisclaimer', new Date().toString());
      if (this.UpdateTimeDisclaimer) localStorage.setItem('ETFMsgUpdateDisclaimer', this.UpdateTimeDisclaimer.UpdateTime)
    }
  }

  goTop() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  unCheck(e: any) {
   // this.router.navigate(['/']);
  }
  etfunCheck(e: any){
   // this.router.navigate(['/product/etf-list']);
  }
}
