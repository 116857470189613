import { AritcleService } from 'src/app/shared/services/article.service';
import { Component, Input, numberAttribute } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ArticledataID, ResponseArticleID } from '../../models/article';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Observable, combineLatest, map } from 'rxjs';
import { Console } from 'console';
import { Meta, Title } from '@angular/platform-browser';
import { mainData } from '../../interface/main-data';
import { DialogData } from '../confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-news-info-detail',
  templateUrl: './news-info-detail.component.html',
  styleUrls: ['./news-info-detail.component.scss']
})
export class NewsInfoDetailComponent {
  currPath = ''; //當前的路由
  request = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;
  url = '';
  video = false;
  showLoading = false;
  Article = false; //判斷是否為meta文章
  errorTest: string = '';
  dialogData: DialogData = {} as DialogData;
  visible = false;
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public aritcleService: AritcleService,
    private titleService: Title,
    private metaTagService: Meta,
    public mainData$: mainData
  ) {

  }

  ngOnInit(): void {
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'bulletin-detail') {
        //最新消息
        this.request.FunctionID = 7101;
      } else if (currentPath === 'yield-detail') {
        //配息公告
        this.request.FunctionID = 7107;
      }
      else if (currentPath === 'world-detail') {
        //全球快訊
        this.request.FunctionID = 7001;
      }
      else if (currentPath === 'weekly-report-detail') {
        //市場週報
        this.request.FunctionID = 7007;
      }
      else if (currentPath === 'quarterly-report-detail') {
        //投資季展望
        this.request.FunctionID = 7013;
      }
      else if (currentPath === 'anti-money-detail') {
        //洗錢防制
        this.request.FunctionID = 7020;
      }
      else if (currentPath === 'esg-detail') {
        //永續發展
        this.request.FunctionID = 7349;
      }
      else if (currentPath === 'classroom-detail') {
        //ETF小教室
        this.request.FunctionID = 7318;
      }
      else if (currentPath === 'video-detail') {
        this.request.FunctionID = 7312;
        //ETF影音
        this.video = true;
      }
      else if (currentPath === 'intro-detail') {
        //ETF懶人包
        this.request.FunctionID = 7325;
      }
    });

    // this.route.queryParams.subscribe(params => {
    //   this.request.ID = params['/news'];
    //  if(params['isPreview']!==null){
    //   this.request.IsPreview = Boolean(params['isPreview']);
    //  }
    //   this.GetArticleByID();
    // })
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      const idWithoutNews = id.replace('news', '');
      this.request.ID = Number(idWithoutNews);
    });

    urlParams.subscribe(queryParams => {
      if (queryParams['isPreview'] !== null) {
        this.request.IsPreview = Boolean(queryParams['isPreview']);
      }
    });
    this.GetArticleByID();
  }


  back() {
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'bulletin-detail') {
        //最新消息
        this.router.navigate(['/notice/bulletin']);
      } else if (currentPath === 'yield-detail') {
        //配息公告
        this.router.navigate(['/notice/yield-notice']);
      }
      else if (currentPath === 'world-detail') {
        //全球日訊
        this.router.navigate(['/point/news']);
      }
      else if (currentPath === 'weekly-report-detail') {
        //市場週報
        this.router.navigate(['/point/weekly-report']);
      }
      else if (currentPath === 'quarterly-report-detail') {
        //投資季展望
        this.router.navigate(['/point/quarterly-report']);
      }
      else if (currentPath === 'anti-money-detail') {
        //洗錢防制
        this.router.navigate(['/notice/anti-money']);
      }
      else if (currentPath === 'esg-detail') {
        //永續發展
        this.router.navigate(['/about-us/esg']);
      }
      else if (currentPath === 'classroom-detail') {
        //ETF小教室
        this.router.navigate(['/etf/classroom']);
      }
      else if (currentPath === 'video-detail') {
        //ETF影音
        this.router.navigate(['/etf/video']);
      }
      else if (currentPath === 'intro-detail') {
        //ETF懶人包
        this.router.navigate(['/etf/intro']);
      }
    });
  }

  GetArticleByID() {
    this.showLoading = true;
    this.aritcleService.GetArticleByID(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.list = res.Entries;
        if (this.list) {
          this.showLoading = false;
        }
        if (res.Entries.MetaDataInfo) {
          if (res.Entries.MetaDataInfo.CTitle || res.Entries.MetaDataInfo.CContent || res.Entries.MetaDataInfo.CKeyword) {
            this.Article = true;
          }
          this.titleService.setTitle(res.Entries.MetaDataInfo.CTitle);
          this.metaTagService.updateTag({
            name: 'title',
            content: res.Entries.MetaDataInfo.CTitle
          });
          this.metaTagService.updateTag({
            name: 'description',
            content: res.Entries.MetaDataInfo.CContent,
          });
          this.metaTagService.updateTag({
            name: 'keyword',
            content: res.Entries.MetaDataInfo.CKeyword,
          });
        }
      }
      else{
          this.visible = true;
          this.dialogData.title = '提醒'
          this.dialogData.message = '連結已失效。點[確認]後回到首頁。'

      }
    });
    this.showLoading = false;
  }

  visibleChange(e: any) {
    this.visible = false;
    this.router.navigate(['/']);
  }

}
