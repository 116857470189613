import { Component,Input } from '@angular/core';
import {
  ParticipatingDealer
} from '../../../../shared/models/etf';
@Component({
  selector: 'app-etf-provider',
  templateUrl: './etf-provider.component.html',
  styleUrls: ['./etf-provider.component.scss']
})
export class EtfProviderComponent {
  @Input() data: Array< ParticipatingDealer> = [];

}
