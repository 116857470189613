import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-anti-money-list',
  templateUrl: './anti-money-list.component.html',
  styleUrls: ['./anti-money-list.component.scss']
})
export class AntiMoneyListComponent {
  searchOpen = true;
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  TotalItems=0;
  constructor(
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService
  ) {
    // this.Getnews()
    this.newsres.FunctionID = 7020;
  }

  // Getnews() {
  //   this.newsres.FunctionID = 7020;
  //   this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
  //     if (res.StatusCode == EnumStatusCode.Success) {
  //       this.newslist = res.Entries;
  //       console.log(this.newslist.Data)
  //     }
  //   });
  // }
}
