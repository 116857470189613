<div class="wrapper">
  <main *ngIf="!showLoading">
    <div>
      <div class="listbox">
        <ng-container *ngFor="let item of newslist.Data">
          <a class="list" [href]="getLink(item)" [attr.target]="getTarget(item)">
            <span class="date">{{item.CShowStartDt | date:'yyyy/MM/dd' }}
              <span class="listcontent">
                {{item.CName}}
              </span>
            </span>
          </a>
        </ng-container>
      </div>
      <!-- <button class="arrow-btn" *ngIf="TotalItems > (newslist.Data && newslist.Data.length)" (click)="more()">
        查看更多
        <div class="top-arrow"></div>
      </button>
      <p class="no-data" *ngIf="(newslist.Data && newslist.Data.length) >= TotalItems">無更多資料</p> -->
      <ng-container>
        <p-paginator (onPageChange)="paginate($event)"
        [first]="first"
        [rows]="10"
        [totalRecords]="TotalItems" />
      </ng-container>
    </div>
  </main>
  <div class="loading" *ngIf="showLoading">
    <img alt="loading" src="/assets/images/loading.png" />
  </div>
</div>
