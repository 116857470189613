import { GlobalModalService } from 'src/app/shared/services/global-modal.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from 'src/app/services/service-base';
import { LibHttpUrl } from '../../api/public-api';
import { Observable, BehaviorSubject } from 'rxjs';
import { ResponseBase } from '../models/base';
import { ActivityFormData, SignActionForm } from '../models/event';

// import { CartModel } from '../models/common';
// import { FundCartService } from './fundCart.service';
@Injectable({
  providedIn: 'root'
})
export class EventsService extends ServiceBase {
  readonly localStorageName = 'purchasingFundIDs';
  BreadcrumbLast: any;
  BreadcrumbList: any;
  BreadcrumbVisible = true;
  PageTitle: string = '';
  webMode = ''; //RWD:PC版&手機板,APP:原生裝置嵌入WebView

  isShow = {
    header: true,
    footer: true,
  };
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Event}`;
  // footerID = new BehaviorSubject<number>(1);
  constructor(
    public globalModalService: GlobalModalService,
    http: HttpClient
    // public cartService: FundCartService
  ) {
    super(http);
  }

  getFundID(): string[] {
    let local: string = localStorage.getItem(this.localStorageName) ?? '';
    return local.split(',').filter((a) => a != '');
  }

  updateFundID(fundID: string) {
    let local = this.getFundID();
    if (local.some((a) => a == fundID))
      local = local.filter((a) => a != fundID);
    else local.push(fundID);
    localStorage.setItem(this.localStorageName, local.join(','));
    return this.getFundID();
  }

  clear() {
    localStorage.setItem(this.localStorageName, '');
    return this.getFundID();
  }

  isAdded(fundID: string) {
    let local = this.getFundID();
    return local.indexOf(fundID) > -1;
  }

  openBuyModal(event: any, fundNo: string, altAble: boolean, rspAble: boolean) {
    if (!altAble && !rspAble) return;
    event.preventDefault();
    // const cartInfo = this.cartService.getInfo(fundNo);
    this.globalModalService.modalTitle.next('立即申購');
    this.globalModalService.fundNo.next(fundNo);
    this.globalModalService.AltAble.next(altAble);
    this.globalModalService.RspAble.next(rspAble);
    this.globalModalService.isModalOpen.next(true);
    this.globalModalService.modalType.next(4);
    // if (cartInfo.FundID == '')
    //   if (altAble) cartInfo.checkBox_A = true;
    //   else cartInfo.checkBox_R = true;
    // this.globalModalService.cartInfo.next(cartInfo ?? new CartModel());
    this.globalModalService.modalConfirmBut.next('確認');
  }

  //取得活動表單
  GetActionForm(request: string): Observable<ResponseBase<ActivityFormData>> {
    let data = {
      Source: '',
      ActionCode: request
    }
    const url = `${this.baseUrl}/GetActionForm`;
    return this.http.post<ResponseBase<ActivityFormData>>(
      url,
      data,
      this.httpOptions);
  }

  //送出活動表單
  SignActionForm(request: SignActionForm): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/SignActionForm`;
    return this.http.post<ResponseBase<string>>(
      url,
      request,
      this.httpOptions);
  }
}
