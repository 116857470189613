<div class="top-wrap">
  <main>
    <ol vocab="https://schema.org/" typeof="BreadcrumbList">
      <li property="itemListElement" typeof="ListItem">
        <a property="item" typeof="WebPage" [href]="domain">
          <span property="name">Home</span></a>
        <meta property="position" content="1">
      </li>
      <li property="itemListElement" typeof="ListItem">
        <a property="item" typeof="WebPage" routerLink="/product/fund-list">
          <span property="name">基金總覽</span></a>
        <meta property="position" content="2">
      </li>
      <li property="itemListElement" typeof="ListItem">
        <a property="item" typeof="WebPage" (click)="tabActiveChange(1)">
          <span property="name">基金詳情</span></a>
        <meta property="position" content="3">
      </li>
    </ol>

    <div class="search-label">查詢其他基金</div>
    <div class="dropdown-wrap">
      <div class="dropdown">
        <p-dropdown [options]="fundTypeList" (onChange)="selectedTypeChange($event)" [(ngModel)]="selectedType"
          optionLabel="Name"></p-dropdown>
      </div>
      <div class="dropdown" *ngIf="fundSelectList.length">
        <p-dropdown [options]="fundSelectList" (onChange)="selectListChange($event)" [(ngModel)]="selectedFund"
          (ngModelChange)="selectedFundChanged($event)" optionLabel="FundShortName"></p-dropdown>
      </div>
    </div>
    <h1 class="fundName" *ngIf="!baseService.Web$.value">
      {{FundDetail?.BasicInfo?.FundShortName}}
      <span class="warning-text">{{FundDetail?.BasicInfo?.FundWarning}}</span>
    </h1>

    <a *ngIf="!baseService.Web$.value && FundDetailTopData.IsCanTrade" href="https://ec.jkoam.com/login.php"
      target="_blank">
      <button>線上申購</button>
    </a>
    <h1 class="fundName" *ngIf="baseService.Web$.value">{{FundDetail?.BasicInfo?.FundShortName}}
      <span class="warning-text">{{FundDetail?.BasicInfo?.FundWarning}}</span>
    </h1>

    <div class="fund-info">
      <div class="fund-item">
        <div class="name">最新淨值 ({{FundDetailTopData.LatestDataDt}})</div>
        <div style="display: flex;align-items: center;"><span class="value">{{FundDetailTopData.LatestNetValueStr}}</span><span *ngIf="FundDetail.BasicInfo?.FundCurrencyTypeStr">{{FundDetail.BasicInfo?.FundCurrencyTypeStr}}</span></div>
      </div>
      <div class="fund-item">
        <div class="name">漲跌 (漲跌幅)</div>
        <div class="value-wrap">
          <span class="value"
            [ngClass]="{'pValue': FundDetailTopData.LatestChange > 0,'nValue': 0 > FundDetailTopData.LatestChange}">
            <span class="arrow"
              [ngClass]="{'nArrow': 0 > FundDetailTopData.LatestChange,none: FundDetailTopData.LatestChange == 0}"></span>
            {{FundDetailTopData.LatestChangeStr}}
          </span>
          <span class="ratio"
            [ngClass]="{'pValue': FundDetailTopData.LatestChangeRatio > 0,'nValue': 0 > FundDetailTopData.LatestChangeRatio}">
            (<span class="arrow"
              [ngClass]="{'nArrow': 0 > FundDetailTopData.LatestChangeRatio,none: FundDetailTopData.LatestChangeRatio == 0}"></span>{{FundDetailTopData.LatestChangeRatioStr}})
          </span>
        </div>
      </div>
      <div class="fund-item">
        <div class="name">歷史淨值最低 ({{FundDetailTopData.LowestNAVDate}})</div>
        <div><span class="value">{{FundDetailTopData.LowestNAVStr}}</span></div>
      </div>
      <div class="fund-item">
        <div class="name">歷史淨值最高 ({{FundDetailTopData.HighestNAVDate}})</div>
        <div><span class="value">{{FundDetailTopData.HighestNAVStr}}</span></div>
      </div>

      <a class="btn-link" *ngIf="baseService.Web$.value && FundDetailTopData.IsCanTrade"
        href="https://ec.jkoam.com/login.php" target="_blank">
        <button>線上申購</button>
      </a>
    </div>
  </main>
</div>

<div class="detail-wrap">
  <div class="tab-menu">
    <main class="tab-inner-menu">
      <div class="tab-item" *ngFor="let item of tabList" [ngClass]="{'tab-item-active': tabActive == item.id}"
        (click)="tabActiveChange(item.id)">{{item.name}}</div>
    </main>
  </div>
  <ng-container *ngIf="tabActive == 1">
    <main>
      <h2 class="sub-title sub-title-first">基金特色</h2>
      <ol>
        <li *ngFor="let data of FundFeatureList">{{data}}</li>
      </ol>
      <h2 class="sub-title">基本資料</h2>
      <div class="detail-table-wrap" *ngIf="FundDetail.BasicInfo">
        <div class="detail-table">
          <div>
          <div class="itembox">
            <div class="items">
              <div class="label">淨值日期</div>
              <div class="desc">{{FundDetail.BasicInfo.FundNavDate}}</div>
            </div>
            <div class="items"  *ngIf="FundDetail.BasicInfo.Description">
            <div class="label">基金描述</div>
            <div class="desc">{{FundDetail.BasicInfo.Description}}</div>
          </div>

            <div class="items" *ngIf="FundDetail.BasicInfo.FundSize">
              <div class="label">基金規模</div>
              <div class="desc">{{FundDetail.BasicInfo.FundSize}}</div>
            </div>
            <div class="items" *ngIf="FundDetail.BasicInfo.Remark">
            <div class="label">基金備註</div>
            <div class="desc" [innerHTML]="FundDetail.BasicInfo.Remark | html">
              <!-- {{FundDetail.BasicInfo.Remark}} -->
            </div>
          </div>

            <div class="items">
              <div class="label">基金類型</div>
              <div class="desc">{{FundDetail.BasicInfo.FundTypeStr}}</div>
            </div>

            <div class="items">
            <div class="label">經理人</div>
            <div class="desc">{{FundDetail.BasicInfo.Manager}}</div>
          </div>
            <div class="items" *ngIf="FundDetail.BasicInfo.CoManager">
              <div class="label">協管經理人</div>
              <div class="desc">{{FundDetail.BasicInfo.CoManager}}</div>
            </div>
            <div class="items">
            <div class="label">經理費</div>
            <div class="desc" [innerHTML]="FundDetail.BasicInfo.ManagerFee | html">
              <!-- {{FundDetail.BasicInfo.ManagerFee}} -->
            </div>
          </div>
            <div class="items">
              <div class="label">保管費</div>
              <div class="desc" [innerHTML]="FundDetail.BasicInfo.CustodyFee | html">
                <!-- {{FundDetail.BasicInfo.CustodyFee}} -->
              </div>
            </div>
            <div class="items">
            <div class="label">成立日期</div>
            <div class="desc">{{FundDetail.BasicInfo.SetUpDt}}</div>
          </div>
            <div class="items" *ngIf="FundDetail.BasicInfo.ISINCode">
              <div class="label">ISIN代碼</div>
              <div class="desc">{{FundDetail.BasicInfo.ISINCode}}</div>
            </div>
            <div class="items"  *ngIf="FundDetail.BasicInfo.BloomberCode">
            <div class="label">彭博代碼</div>
            <div class="desc">{{FundDetail.BasicInfo.BloomberCode}}</div>
          </div>
            <div class="items" *ngIf="FundDetail.BasicInfo.LipperCode">
              <div class="label">Lipper代碼</div>
              <div class="desc">{{FundDetail.BasicInfo.LipperCode}}</div>
            </div>
            <div class="items">
            <div class="label">投資區域</div>
            <div class="desc">{{FundDetail.BasicInfo.FundAreaStr}}</div>
          </div>
            <div class="items">
              <div class="label">計價幣別</div>
              <div class="desc">{{FundDetail.BasicInfo.FundCurrencyTypeStr}}</div>
            </div>
            <div class="items">
            <div class="label">配息頻率</div>
            <div class="desc">{{FundDetail.BasicInfo.FundDividendStr}}</div>
          </div>
            <div class="items">
              <div class="label">風險屬性</div>
              <div class="desc">
                <div class="dots" *ngIf="FundDetail.BasicInfo.FundRiskLevelValue">
                  <span *ngFor="let dot of dots;let i = index" class="risk-level-dots"
                    [ngClass]="{'risk-level-dots-red': i < FundDetail.BasicInfo.FundRiskLevelValue}"></span>
                </div>
                {{FundDetail.BasicInfo.FundRiskLevelStr}}
              </div>
            </div>
            <div class="items">
            <div class="label">投資標的</div>
            <div class="desc">{{FundDetail.BasicInfo.InvestmentTargets}}</div>
          </div>
          </div></div>

          <!-- <div class="detail-table" *ngIf="baseService.Web$.value">
            <div class="item" *ngIf="FundDetail.BasicInfo.Description">
              <div class="label">基金描述</div>
              <div class="desc">{{FundDetail.BasicInfo.Description}}</div>
            </div>
            <div class="item" *ngIf="FundDetail.BasicInfo.Remark">
              <div class="label">基金備註</div>
              <div class="desc">{{FundDetail.BasicInfo.Remark}}</div>
            </div>
            <div class="item">
              <div class="label">經理人</div>
              <div class="desc">{{FundDetail.BasicInfo.Manager}}</div>
            </div>
            <div class="item">
              <div class="label">經理費</div>
              <div class="desc">{{FundDetail.BasicInfo.ManagerFee}}</div>
            </div>
            <div class="item">
              <div class="label">成立日期</div>
              <div class="desc">{{FundDetail.BasicInfo.SetUpDt}}</div>
            </div>
            <div class="item" *ngIf="FundDetail.BasicInfo.BloomberCode">
              <div class="label">彭博代碼</div>
              <div class="desc">{{FundDetail.BasicInfo.BloomberCode}}</div>
            </div>
            <div class="item">
              <div class="label">投資區域</div>
              <div class="desc">{{FundDetail.BasicInfo.FundAreaStr}}</div>
            </div>
            <div class="item">
              <div class="label">配息頻率</div>
              <div class="desc">{{FundDetail.BasicInfo.FundDividendStr}}</div>
            </div>
            <div class="item">
              <div class="label">投資標的</div>
              <div class="desc">{{FundDetail.BasicInfo.InvestmentTargets}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="tip">※如至銷售機構申請，交易須知依各銷售機構規範為主</div>
      <!-- 檔案下載 -->
      <h2 class="sub-title">檔案下載</h2>
    </main>
    <div class="download-wrap">
      <main class="download-main">
        <div class="download-item" *ngIf="FundLinkData.ProspectusUrl">
          <a [href]="FundLinkData.ProspectusUrl" target="_blank">
            <img class="download-icon" src="/assets/images/download_icon.svg" alt="下載公開說明書" />公開說明書
          </a>
        </div>
        <div class="download-item" *ngIf="FundLinkData.SimpleProspectusUrl">
          <a [href]="FundLinkData.SimpleProspectusUrl" target="_blank">
            <img class="download-icon" src="/assets/images/download_icon.svg" alt="下載簡式公開說明書" />簡式公開說明書
          </a>
        </div>
        <div class="download-item" *ngIf="FundLinkData.MonthUrl">
          <a [href]="FundLinkData.MonthUrl" target="_blank">
            <img class="download-icon" src="/assets/images/download_icon.svg" alt="下載月報" />月報
          </a>
        </div>
        <div class="download-item no-data"
          *ngIf="!FundLinkData.ProspectusUrl&&!FundLinkData.SimpleProspectusUrl&&!FundLinkData.MonthUrl">無相關資料</div>
      </main>
    </div>
    <main>
      <h2 class="sub-title">交易須知</h2>
      <div class="detail-table">
        <div class="item" *ngFor="let data of FundDetail.CTranNotice">
          <div class="label">{{data.name}}</div>
          <div class="desc">  <div [innerHTML]="data.val|html"></div></div>
        </div>
      </div>

      <!-- 銷售機構 -->
      <h2 class="sub-title">銷售機構</h2>
      <div class="detail-list">
        <div class="item" [ngStyle]="{width: getWidth(i)}"
          *ngFor="let data of FundDetail.CompanyNameList;let i = index">
          {{data}}
        </div>
      </div>


    </main>

  </ng-container>

  <ng-container *ngIf="tabActive == 2">
    <main>
      <div class="pie-chart-wrap">
        <div class="pie-chart" *ngFor="let data of FundInvestment">
          <h2 class="sub-title sub-title-first">{{data.CChartName}}</h2>
          <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
            [options]="data.chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
            [runOutsideAngular]="runOutsideAngular"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>

          <div class="chart-wrap">
            <div class="chart-label" *ngFor="let item of data.Data;let i = index">
              <div class="point" [ngStyle]="{'background-color': colors[i]}"></div>
              <div class="point-name">{{item.name}}</div>
              <div class="point-value">{{item.y}}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tip">資料日期: {{FundInvestment && FundInvestmentDate}}</div>

      <!-- <div class="tip">資料來源：街口投信、Lipper 資料日期: {{FundInvestment && FundInvestment.length && FundInvestment[0].CDataDate}}
      </div> -->

      <h2 *ngIf="FundInvestmentTop10.length" class="sub-title sub-title-first">前十大標的</h2>

      <table *ngIf="FundInvestmentTop10.length" class="top10table">
        <thead>
          <tr>
            <th>
              名稱
            </th>
            <th>持有比例%
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of FundInvestmentTop10">
            <td>{{data.CName }}</td>
            <td>{{data.CPercentage | number}}%</td>
          </tr>
        </tbody>
      </table>
      <div class="tip" *ngIf="FundInvestmentTop10.length">資料日期:{{FundInvestmentTop10 &&
        FundInvestmentTop10[0].CDataDate| date : 'yyyy-MM-dd'}}</div>
    </main>
  </ng-container>

  <ng-container *ngIf="tabActive == 3">
    <main class="table-allright">
      <h2 class="sub-title sub-title-first">基金績效</h2>
      <table>
        <thead>
          <tr>
            <th class="tableTh">三個月
              <span>{{FundRankImport && FundRankImport.CRoi3MonthRStr}}</span>
            </th>
            <th>六個月
              <span>{{FundRankImport && FundRankImport.CRoi6MonthRStr}}</span>
            </th>
            <th>一年
              <span>{{FundRankImport && FundRankImport.CRoi1YearRStr}}</span>
            </th>
            <th>二年
              <span>{{FundRankImport && FundRankImport.CRoi2YearRStr}}</span>
            </th>
            <th>三年
              <span>{{FundRankImport && FundRankImport.CRoi3YearRStr}}</span>
            </th>
            <th>五年
              <span>{{FundRankImport && FundRankImport.CRoi5YearRStr}}</span>
            </th>
            <th>今年以來
              <span>{{FundRankImport && FundRankImport.CRoiYearStartRStr}}</span>
            </th>
            <th>自成立日
              <span>{{FundRankImport && FundRankImport.CRoiHistoryRStr}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{FundRankImport && FundRankImport.CRoi3MonthRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoi6MonthRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoi1YearRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoi2YearRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoi3YearRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoi5YearRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoiYearStartRStr}}</td>
            <td>{{FundRankImport && FundRankImport.CRoiHistoryRStr}}</td>
          </tr>
        </tbody>
      </table>
      <div class="tip">資料來源：街口投信、Lipper 資料日期: {{FundRankImport && FundRankImport.CResourceDate |
        date:'yyyy/MM/dd'}}</div>

      <h2 class="sub-title"><span *ngIf="DateName">{{this.DateName}}</span><span *ngIf="this.FundNAVReq.SDate && FundNAVReq.EDate&&!DateName">{{this.FundNAVReq.SDate| date:'yyyy/MM/dd'}} ~ {{this.FundNAVReq.EDate| date:'yyyy/MM/dd'}}</span> 淨值走勢圖</h2>

      <div class="search-wrap">
        <div class="calendar-item">
          <label>開始時間</label>
          <div class="calendar-wrap">
            <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
            <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="FundNAVReq.SDate"
              placeholder="年/月/日"></p-calendar>
          </div>
        </div>
        <div class="calendar-item">
          <label>結束時間</label>
          <div class="calendar-wrap">
            <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
            <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="FundNAVReq.EDate"
              placeholder="年/月/日"></p-calendar>
          </div>
        </div>
        <button (click)="submitCheck()">查詢</button>
      </div>
      <div class="button-wrap">
        <button *ngFor="let data of enumDateList" [ngClass]="{'btn-active': DateName == data.Name}"
          (click)="getDateName(data)">{{data.Name}}</button>
      </div>

      <!-- hightChart -->
      <div class="highcharts">
        <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
          [options]="chartLineOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>

      <div class="value-wrap">
        <div class="value-box">
          <label>查詢期間淨值</label>
          <div class="items">
            <div class="item">
              最高
              <span class="net"
                [ngClass]="{'pValue': getNumber(FundNAVData.QHighestNAV) > 0,'nValue': 0 > getNumber(FundNAVData.QHighestNAV)}">{{FundNAVData.QHighestNAV}}</span>
              <span style="color: #555555;">({{FundNAVData.QHighestNAVDate}})</span>
            </div>
            <div class="item">
              最低
              <span class="net"
                [ngClass]="{'pValue': getNumber(FundNAVData.QLowestNAV) > 0,'nValue': 0 > getNumber(FundNAVData.QLowestNAV)}">{{FundNAVData.QLowestNAV}}</span>
              <span style="color: #555555;">({{FundNAVData.QLowestNAVDate}})</span>
            </div>
          </div>
        </div>

        <div class="value-box">
          <label>成立至今淨值</label>
          <div class="items">
            <div class="item">
              最高
              <span class="net"
                [ngClass]="{'pValue': getNumber(FundNAVData.HighestNAV) > 0,'nValue': 0 > getNumber(FundNAVData.HighestNAV)}">{{FundNAVData.HighestNAV}}</span>
              <span style="color: #555555;">({{FundNAVData.HighestNAVDate}})</span>
            </div>
            <div class="item">
              最低
              <span class="net"
                [ngClass]="{'pValue': getNumber(FundNAVData.LowestNAV) > 0,'nValue': 0 > getNumber(FundNAVData.LowestNAV)}">{{FundNAVData.LowestNAV}}</span>
              <span style="color: #555555;">({{FundNAVData.LowestNAVDate}})</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tip">
        <span *ngIf="FundNAVData.LatestNAVDate">資料日期: {{FundNAVData.LatestNAVDate}} </span> <br>
      </div>


      <!-- 近30筆淨值 -->
      <h2 class="sub-title"><span *ngIf="NAVDateName">{{this.NAVDateName}}</span><span *ngIf="this.FundNAVReq.SDate && FundNAVReq.EDate&&!NAVDateName">{{this.FundPageNAVReq.SDate| date:'yyyy/MM/dd'}} ~ {{this.FundPageNAVReq.EDate| date:'yyyy/MM/dd'}}</span> 基金淨值</h2>
      <div class="search-wrap">
        <div class="calendar-item">
          <label>開始時間</label>
          <div class="calendar-wrap">
            <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
            <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="FundPageNAVReq.SDate"
              placeholder="年/月/日"></p-calendar>
          </div>
        </div>
        <div class="calendar-item">
          <label>結束時間</label>
          <div class="calendar-wrap">
            <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
            <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="FundPageNAVReq.EDate"
              placeholder="年/月/日"></p-calendar>
          </div>
        </div>
        <button (click)="GetFundPageNAVList(true)">查詢</button>
      </div>
      <div class="detail-table-wrap detail-table-wrap2">
        <div class="detail-table">
          <div class="item">
            <div class="label">基準日</div>
            <div class="desc">淨值</div>
          </div>
          <div class="item" *ngFor="let data of FundPageNAVList | slice: section1Start : section1End">
            <div class="label">{{data.DataDT}}</div>
            <div class="desc">{{data.Nav}}</div>
          </div>
        </div>
        <div class="detail-table" *ngIf="FundPageNAVList.length > section1End">
          <div class="item">
            <div class="label">基準日</div>
            <div class="desc">淨值</div>
          </div>
          <div class="item" *ngFor="let data of FundPageNAVList | slice: section2Start : section2End">
            <div class="label">{{data.DataDT}}</div>
            <div class="desc">{{data.Nav}}</div>
          </div>
        </div>
        <div class="detail-table" *ngIf="FundPageNAVList.length > section2End">
          <div class="item">
            <div class="label">基準日</div>
            <div class="desc">淨值</div>
          </div>
          <div class="item" *ngFor="let data of FundPageNAVList | slice: section3Start : section3End">
            <div class="label">{{data.DataDT}}</div>
            <div class="desc">{{data.Nav}}</div>
          </div>
        </div>
      </div>
      <!-- <div class="tip">所有基金績效，均為過去績效，不代表未來之績效表現，亦不保證基金之最低投資收益。<br>
        基金淨值僅供參考，實際以基金公司公告之淨值為準。
      </div> -->
    </main>
  </ng-container>

  <ng-container *ngIf="tabActive == 4">
    <main class="dividend-record">
      <table class="table-web">
        <thead>
          <tr>
            <th>基準日</th>
            <th>每單位配息金額</th>
            <!-- <th>年化配息率(%)</th> -->
            <th>當期配息率(%)</th>
            <th>當期報酬率(%， 含息)</th>
            <!-- <th>評價日</th> -->
            <th>除息日</th>
            <th>發放日</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of FundYield.Data">
            <td>{{data.CBaseDate}}</td>
            <td>{{data.CPerShare}}</td>
            <!-- <td>{{data.CAnnualizedDy | number}}</td> -->
            <td>{{data.CCurrentDyStr}}</td>
            <td>{{data.CCurrentRoiStr}}</td>
            <!-- <td>{{data.CValuationDate}}</td> -->
            <td>{{data.CExDate}}</td>
            <td>{{data.CPayableDate}}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-mob">
        <thead>
          <tr *ngFor="let data of FundYield.Data">
            <th>基準日
              <span>{{data.CBaseDate}}</span>
            </th>
            <th>每單位配息金額
              <span>{{data.CPerShare}}</span>
            </th>
            <!-- <th>年化配息率(%)
              <span>{{data.CAnnualizedDy | number}}</span>
            </th> -->
            <th>當期配息率(%)
              <span>{{data.CCurrentDyStr}}</span>
            </th>
            <th>當期報酬率(%， 含息)
              <span>{{data.CCurrentRoiStr}}</span>
            </th>
            <!-- <th>評價日
              <span>{{data.CValuationDate}}</span>
            </th> -->
            <th>除息日
              <span>{{data.CExDate}}</span>
            </th>
            <th>發放日
              <span>{{data.CPayableDate}}</span>
            </th>
          </tr>
        </thead>
      </table>

      <div class="note-wrap" *ngIf="!remark">
        註<br>
        <ul>
          <li>配息率計算公式：每單位配息金額÷除息日前一日之淨值Ｘ100%</li>
          <!-- <li>年化配息率為估算值，計算公式：每單位配息金額÷除息日之淨值Ｘ一年配息次數Ｘ100%</li> -->
          <li>當期(含息)報酬率計算公式為：[(當期除昔日淨值＋當期每單位配息金額)÷當期除昔日淨值-1]X100%;然首次配息之基金，前述計算公式中之「前期除息日淨值」，改以「當期除昔日淨值」計算之。</li>
        </ul>
      </div>
      <div class="note-wrap"  *ngIf="remark">
        <span *ngIf="!empty">註</span><br>
        <ul *ngIf="!empty">
           <li *ngFor="let item of remark">{{ item }}</li>
        </ul>
      </div>
    </main>
  </ng-container>

</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" (visibleChange)="visibleChange($event)" [privateType]="0"></app-message-dialog>
