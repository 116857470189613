import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { SeminarList } from 'src/app/shared/models/activity';
import { ActivityService } from 'src/app/shared/services/activity.service';

@Component({
  selector: 'app-lecture',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.scss']
})
export class LectureComponent {
  TotalItems = 0;
  SeminarListres: SeminarList[] = [];
  moreSeminarListres: SeminarList[] = [];
  itemsPerLoad = 2;
  url='/service/fund-lecture/detail';
  constructor(
    private route: ActivatedRoute,
    public activityService: ActivityService,
  ) {
    this.lecturelist()
  }

  lecturelist() {
    this.activityService.GetSeminarList().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.moreSeminarListres = res.Entries;
        this.SeminarListres = res.Entries.slice(0, this.itemsPerLoad);
        this.TotalItems = res.Entries.length;
      }
    });
  }

  more() {
    this.itemsPerLoad = this.itemsPerLoad + 2;
    this.all()
  }
  all() {
    this.SeminarListres = this.moreSeminarListres.slice(0, this.itemsPerLoad);
  }
}
