<app-bread-crumb></app-bread-crumb>
<!-- <div class="wrapper-top">
    <main>
      <div class="title-box">
        <span>{{list.CCreateDt| date:'yyyy/MM/dd'}}</span>
        <h2>{{list.CName}}</h2>
      </div>
    </main>
  </div> -->
<div class="wrapper">
  <main>
    <div class="editable" [innerHtml]="list.CContent|html"></div>
  </main>
</div>
<!-- <div class="wrapper-botm">
  <main>
    <div class="comeback" (click)="back()">回列表頁</div>
  </main>
</div> -->

