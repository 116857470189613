import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartLocalStorageModel, CartModel } from '../models/event';
@Injectable({
  providedIn: 'root'
})
export class GlobalModalService {
  defaultTitle = '預設標題';
  defaultTextList = ['預設內文'];
  defaultConfirmBut = '確認';

  reponseErrorTitle = '系統通知';
  reponseErrorTextList = ['系統目前忙碌中，請稍後再試！'];

  modalTitle = new BehaviorSubject<string>(this.defaultTitle);
  modalTextList = new BehaviorSubject<string[]>([...this.defaultTextList]);
  isModalOpen = new BehaviorSubject<boolean>(false);
  isCloseBtnShow = new BehaviorSubject<boolean>(true);
  isOverlayClickable = new BehaviorSubject<boolean>(true);
  closeCallbackFunction = new BehaviorSubject<Function>(
    this.defaultCloseCallbackFunction
  );
  modalType = new BehaviorSubject<number>(0);
  modalConfirmBut = new BehaviorSubject<string>(this.defaultConfirmBut);
  AltAble = new BehaviorSubject<boolean>(true);
  RspAble = new BehaviorSubject<boolean>(true);
  fundNo = new BehaviorSubject<string>('');
  LinkUrl = new BehaviorSubject<string>('');

  cartInfo = new BehaviorSubject<CartModel>(new CartModel());
  constructor() {}

  setDefaultConfig() {
    this.modalTitle.next(this.defaultTitle);
    this.modalTextList.next([...this.defaultTextList]);
    this.isCloseBtnShow.next(true);
    this.isOverlayClickable.next(true);
    this.closeCallbackFunction.next(this.defaultCloseCallbackFunction);
    this.modalType.next(0);
    this.cartInfo.next(new CartModel());
    this.modalConfirmBut.next(this.defaultConfirmBut);
  }

  setReponseErrorConfig() {
    this.setDefaultConfig();
    this.modalTitle.next(this.reponseErrorTitle);
    this.modalTextList.next([...this.reponseErrorTextList]);
  }

  //彈跳視窗
  popupRemind(
    errorList: string[],
    title: string = '提醒',
    confirmWord: string = '確認'
  ) {
    this.modalTitle.next(title);
    this.modalTextList.next(errorList);
    this.modalConfirmBut.next(confirmWord);
    this.isModalOpen.next(true);
    this.modalType.next(1);
  }

  private defaultCloseCallbackFunction(): void {}
}
