<app-bread-crumb></app-bread-crumb>
<div class="qa etf">
  <p-tabView [(activeIndex)]="activeIndex" (onChange)="selectChange($event)">
    <p-tabPanel [header]="'即時預估'">
      <main class="etf-list-trad">
        <div class="classbox">
          <div class="butn" *ngFor="let data of TypeData" [ngClass]="{'selected': isActiveTypeId === data}"
            (click)="selectTypeData(data)">{{data}}</div>
        </div>
        <div class="fund-list">
          <div class="web" *ngIf="baseService.Web$.value">
            <p-table [value]="Data" [scrollable]="true" styleClass="mt-3">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:90px" pFrozenColumn>股票代號</th>
                  <th style="width:270px" pFrozenColumn>基金簡稱</th>
                  <!-- <th style="width:100px">基金類別</th> -->
                  <th style="width:70px">幣別</th>
                  <th style="width:100px">淨值日期</th>
                  <th style="width:80px">淨值</th>
                  <th style="width:120px;">預估淨值<br>漲跌幅％</th>
                  <!-- <th style="width:150px">淨值<br>日漲 跌幅％</th> -->
                  <th style="width:110px">前一營業日<br>收盤價</th>
                  <th style="width:120px">最新市價<br>漲跌幅％</th>
                  <!-- <th style="width:150px">市價<br>日漲 跌幅％</th> -->
                  <!-- <th style="width:100px">折溢價<br>幅度％</th> -->
                  <th style="width:120px">折溢價<br>幅度％</th>
                  <th style="width:120px">資料時間</th>
                  <!-- <th style="width:100px" alignFrozen="right" pFrozenColumn>
                    <img class="scroll-arrow" [ngClass]="{'scroll-arrow-left':isLeft}" (click)="getScroll()"
                      src="/assets/images/black-arrow.svg" />
                    空白
                  </th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data>
                <tr
                  *ngIf="isActiveTypeId ==='全部'||isActiveTypeId === '商品型' && data.ETF_TYPE==='商品ETF' || isActiveTypeId === '槓桿/反向型' && data.ETF_TYPE==='槓桿/反向型ETF'">
                  <td style="width:90px" pFrozenColumn>{{data.Code}}</td>
                  <td style="width:270px" pFrozenColumn><a class="name" [routerLink]="'/etf/etf-info/' + data.Fund_ID"
                      [queryParams]="{tab: 1}">{{data.CSName}}<span
                        class="warning-text">{{data.Fund_Warning}}</span></a></td>
                  <!-- <td style="width:100px">{{data.ETF_TYPE}}</td> -->
                  <td style="width:70px">{{data.cur}}</td>
                  <td style="width:100px">{{data.Nav_Date}}</td>
                  <td style="width:80px">{{data.Fund_Nav}}</td>
                  <td style="width:120px;">
                    <div style="display: block;">{{data.Fund_Nav_New}}<br>
                      <div [ngClass]="{'pValue': data.Fund_Up_Down > 0,'nValue': 0 >data.Fund_Up_Down}" *ngIf="data.Fund_Up_Down !=='-'">
                        {{data.Fund_Up_Down}}<span *ngIf="data.FundsSplit!=='1'">%</span></div>
                    </div>
                  </td>
                  <!-- <td style="width:150px" [ngClass]="{'pValue': data.Fund_Up_Down > 0,'nValue': 0 >data.Fund_Up_Down}">
                    {{data.Fund_Up_Down}}%</td> -->
                  <td style="width:110px">{{data.Stock_Nav}}</td>
                  <td style="width:120px;">
                    <div style="display: block;">{{data.Stock_Nav_New}}<br>
                      <div [ngClass]="{'pValue': data.Stock_Up_Down > 0,'nValue': 0 > data.Stock_Up_Down}" *ngIf="data.Stock_Up_Down !=='-'">
                        {{data.Stock_Up_Down}}<span *ngIf="data.FundsSplit!=='1'">%</span></div>
                    </div>
                  </td>
                  <!-- <td style="width:150px" [ngClass]="{'pValue': 0.16 > 0,'nValue': 0 > 0.16}">0.16 1.30%</td> -->
                  <!-- <td style="width:150px"
                    >
                    {{data.Stock_Up_Down}}%</td> -->
                  <!-- <td style="width:100px">{{data.Discount_Premium}}</td> -->
                  <td style="width:120px;">
                    <div style="display: block;">{{data.Discount_Premium}}<br>
                      <div [ngClass]="{'pValue': data.Discount_Premium_UD > 0,'nValue': 0 > data.Discount_Premium_UD}" *ngIf="data.Discount_Premium_UD !=='-'">
                        {{data.Discount_Premium_UD}}<span *ngIf="data.FundsSplit!=='1'">%</span></div>
                    </div>
                  </td>
                  <td style="width:120px">{{data.Nav_Time | date:'yyyy/MM/dd'}}<br>{{data.Nav_Time | date:'HH:mm:ss'}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <ng-container *ngFor="let data of Data;let i = index">
            <p-panel class="p-panel" [collapsed]="baseService.Web$.value || (!baseService.Web$.value) ? false : true"
              [toggleable]="baseService.Web$.value ? false : true"
              *ngIf="isActiveTypeId ==='全部'||isActiveTypeId === '商品型' && data.ETF_TYPE==='商品ETF' || isActiveTypeId === '槓桿/反向型' && data.ETF_TYPE==='槓桿/反向型ETF'">
              <ng-template pTemplate="header">
                <!-- <a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a> -->
                <div [routerLink]="'/etf/etf-info/' + data.Fund_ID" [queryParams]="{tab: 1}">
                  <a class="type">股票代號 {{data.Code}}</a>
                  <a class="name">{{data.CSName}}<span class="warning-text">{{data.Fund_Warning}}</span></a>
                </div>
              </ng-template>
              <ng-template pTemplate="headericons">
                <img class="black-arrow" src="/assets/images/black-arrow.svg" />
              </ng-template>
              <!-- <div class="panel-item">
                <div class="name">基金類型</div>
                <div class="desc">{{data.ETF_TYPE}}</div>
              </div> -->
              <div class="panel-item">
                <div class="name">幣別</div>
                <div class="desc">{{data.cur}}</div>
              </div>
              <div class="panel-item">
                <div class="name">淨值日期</div>
                <div class="desc">{{data.Nav_Date}}</div>
              </div>
              <div class="panel-item">
                <div class="name">淨值</div>
                <div class="desc">{{data.Fund_Nav}}</div>
              </div>
              <div class="panel-item">
                <div class="name">預估淨值</div>
                <div class="desc">{{data.Fund_Nav_New}}</div>
              </div>
              <div class="panel-item">
                <div class="name">預估淨值漲跌幅％</div>
                <div class="desc" [ngClass]="{'pValue': data.Fund_Up_Down > 0,'nValue': 0 > data.Fund_Up_Down}">
                  {{data.Fund_Up_Down}}<span *ngIf="data.FundsSplit!=='1'">%</span>
                </div>
              </div>
              <div class="panel-item">
                <div class="name">前一營業日收盤價</div>
                <div class="desc">{{data.Stock_Nav}}</div>
              </div>
              <div class="panel-item">
                <div class="name">最新市價</div>
                <div class="desc">{{data.Stock_Nav_New}}</div>
              </div>
              <div class="panel-item">
                <div class="name">最新市價漲跌幅％</div>
                <div class="desc" [ngClass]="{'pValue': data.Stock_Up_Down > 0,'nValue': 0 > data.Stock_Up_Down}">
                  {{data.Stock_Up_Down}}<span *ngIf="data.FundsSplit!=='1'">%</span></div>
              </div>
              <div class="panel-item">
                <div class="name">折溢價</div>
                <div class="desc">{{data.Discount_Premium}}</div>
              </div>
              <div class="panel-item">
                <div class="name">折溢價幅度％</div>
                <div class="desc"
                  [ngClass]="{'pValue': data.Discount_Premium_UD > 0,'nValue': 0 > data.Discount_Premium_UD}">
                  {{data.Discount_Premium_UD}}<span *ngIf="data.FundsSplit!=='1'">%</span></div>
              </div>
              <div class="panel-item">
                <div class="name">資料時間</div>
                <div class="desc">{{data.Nav_Time | date:'yyyy/MM/dd'}}<br>{{data.Nav_Time | date:'HH:mm:ss'}}</div>
              </div>
            </p-panel>
          </ng-container>
        </div>

        <!-- <button *ngIf="TotalItems > 0" class="arrow-btn" (click)="more()">
          顯示更多
          <div class="top-arrow"></div>
        </button> -->
        <p class="no-data" *ngIf="0 >= TotalItems && isSubmit">無更多資料</p>
        <!-- <p class="no-data" *ngIf="0 >= TotalItems && isSubmit">無更多資料</p> -->
        <ng-container *ngFor="let data of Data;let i = index">
          <ng-container *ngIf="data.FundsSplit=='1'">
            <div *ngIf="data.Alert" [innerHtml]="data.Alert|html">
            </div>
          </ng-container>
        </ng-container>
        <div class="note-wrap">
          說明：<br> (1)預估淨值：<br>
          A.以街口標普高盛黃豆ER指數股票型期貨信託基金昨日投資部位庫存，導入美國芝加哥期貨交易所(CBOT)黃豆期貨電子盤即時價格，套用街口標普高盛黃豆ER指數股票型期貨信託基金預估昨日在外流通單位數(前日在外流通單位數+昨日申購單位數-昨日贖回單位數)推導而成，即時估計淨值僅做為市場行情趨勢變動之參考，投資人切勿將此作為交易依據。
          <br>B.以街口標普高盛布蘭特原油ER單日正向2倍指數股票型期貨信託基金昨日投資部位庫存，導入英國洲際歐洲期貨交易所(ICE
          EU)布蘭特原油期貨即時價格，套用街口標普高盛布蘭特原油ER單日正向2倍指數股票型期貨信託基金預估昨日在外流通單位數(前日在外流通單位數+昨日申購單位數-昨日贖回單位數)推導而成，即時估計淨值僅做為市場行情趨勢變動之參考，投資人切勿將此作為交易依據。
          <br> C.以街口道瓊銅ER指數股票型期貨信託基金昨日投資部位庫存，導入美國紐約商品交易所
          (COMEX)高級銅期貨即時價格，套用街口道瓊銅ER指數股票型期貨信託基金預估昨日在外流通單位數(前日在外流通單位數+昨日申購單位數-昨日贖回單位數)推導而成，即時估計淨值僅做為市場行情趨勢變動之參考，投資人切勿將此作為交易依據。
          (2)前一營業日淨值為基金淨資產除以在外發行單位數所得之數據，在基金淨值結出前，該欄位將以「未結出」文字呈現。
          (3)折溢價為最新市價與預估淨值比較之結果，折溢價計算公式為為【最新市價-預估淨值】之結果，折溢價幅度之計算公式為【(最新市價/即時估計淨值-1)×100%】之結果，該數據僅用以比較最新市價與即時估計淨值之差異，不宜作為交易之依據。
          (4)最新市價：投資人於次級市場進行基金買賣，即時撮合的交易價格。 (5)評價匯率：即時匯率資訊僅供參考，實際評價匯率依基金信託契約相關條款所訂。<br>
          參考匯率： 1 {{Curr[0]?.Curr}} = {{Curr[0]?.nClose}} TWD<br>
          (更新時間：{{Curr[0]?.CreateTime}})<br> 註：<br>
          (1)以上資料僅供參考，盤中之最新市價、漲跌幅需以臺灣證券交易所公佈為準；基金淨值則以街口投信（以下簡稱本公司）最終公佈之每日淨值為準。
          <br> (2)中華民國證券市場交易日休市時，將停止提供及更新淨值及其漲跌幅。交易所休市時、開盤前或開盤後尚未成交前，相對應的「資料時間」欄位將以空白呈現。
          <br>
          (3)基金所投資或交易之標的可能涉及不同國家之貨幣，因此各幣別匯率波動會影響基金淨值之計算。而盤中即時估計淨值的計算所使用的盤中即時匯率，因評價時點及資訊來源不同，與實際基金淨值計算之匯率或有差異，故上列即時估計淨值與參考匯率僅供參考，實際淨值應以本公司最終公告之每日淨值為準。
          <br> (4)資料更新時間為每營業日8：30～17：00，台灣地區若為非營業日，當日停止提供及更新資料。惟因使用者網路頻寬、連線速度、傳輸品質各異，將可能產生更新頻率上之差異。
          <br>
          (5)本公司為服務廣大投資人而製作之任何報價資料，僅供投資人參考使用。本公司不保證資料來源之完整正確性，如有任何遺漏或疏忽，請即通知本公司修正，本公司有權隨時修改或刪除，如遇通訊斷線、斷電、網路壅塞、股價報價延遲、系統延宕、或發生天然災害等不可抗力事由，或許將造成您使用上的不便、資料喪失、錯誤、電腦系統受損或其他經濟上損失等情形。您於使用本服務時宜自行採取防護措施。本公司、台灣證券交易所、台灣期貨交易所對於您因使用本服務所衍生之損害或損失，均不負任何賠償及法律責任。
          <br> (6)期貨交易所開收盤時間：
          <br>
          A.美國芝加哥期貨交易所(CBOT)黃豆期貨交易時間為美國時間T-1日19:00(台灣時間T日08:00);收盤時間為美國時間T日13:20(台灣時間T+1日凌晨02:20);每日結算時間為台灣時間T+1日凌晨02:14-02:15。美國開盤時間與上述期貨結算價產生時間，對應至台灣時間會因應日光節約時間而有所調整。
          <br> B.英國洲際歐洲期貨交易所(ICE
          EU)布蘭特原油期貨開盤時間為倫敦時間T日1:00(夏令時間台灣時間T日08:00);收盤時間為倫敦時間T日23:00(夏令時間台灣時間T+1日06:00)惟星期一開盤提早兩小時;每日結算時間為台灣時間T+1日凌晨02:28-02:30。倫敦開盤時間與上述期貨結算價產生時間，對應至台灣時間會因應日光節約時間而有所調整。
          <br>
          C.美國紐約商品交易所(COMEX)高級銅期貨開盤時間為紐約時間T-1日17:00(台灣時間T日06:00);收盤時間為紐約時間T日16:00(台灣時間T+1日05:00);每日結算時間近月為台灣時間T+1日凌晨00:59-01:00;遠月為台灣時間T+1日凌晨00:30-01:00。紐約開盤時間與上述期貨結算價產生時間，對應至台灣時間會因應日光節約時間而有所調整。
        </div>
      </main>

    </p-tabPanel>
    <p-tabPanel [header]="'追蹤差距'">
      <!-- <app-announcement-info [></app-announcement-info> -->
      <main>
        <div class="classbox">
          <div class="text">計算幣別</div>
          <div class="butn" *ngFor="let data of TWD" [ngClass]="{'selected': isActiveSubId === data}"
            (click)="selectData(data)">{{data}}</div>
        </div>
        <div class="dividend-list">
          <table *ngIf="baseService.Web$.value">
            <thead>
              <tr>
                <th>基準日</th>
                <!-- <th>股票代碼</th> -->
                <th>基金簡稱<br>基金標的指數名稱</th>
                <th>基準日淨值<br>指數(註1)</th>
                <th>當日<br>漲跌幅</th>
                <th>當日<br>追蹤差距<br> (A)-(B)</th>
                <th>基期淨值<br> 指數(註2)</th>
                <th>今年以來<br>漲跌幅</th>
                <th>今年以來<br>追蹤差距<br> (C)-(D)</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of TrackDiff">
                <tr *ngIf="isActiveSubId === '新台幣' && item.IsTWD || isActiveSubId === '原幣' && !item.IsTWD">
                  <!-- <th><a [routerLink]="'/fund-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a></th> -->
                  <td>
                    {{item.NavDate}}
                  </td>
                  <!-- <td>{{item.CStockNo}}</td> -->
                  <td class="block">
                    <div>{{item.CShortName}}<span class="warning-text" *ngIf="item.CWarning">{{item.CWarning}}</span>
                    </div>{{item.StockName}}
                  </td>
                  <td>{{item.FundNavStr}}<br>{{item.StockNavStr}}</td>
                  <td>
                    <div class="block">
                      <div class="updown">
                        <div [ngClass]="{'pValue':item.FundUpDown > 0,'nValue': 0 > item.FundUpDown}">
                          {{item.FundUpDownStr}}</div>(A)
                      </div>
                      <div class="updown">
                        <div [ngClass]="{'pValue':item.StockUpDown > 0,'nValue': 0 > item.StockUpDown}">
                          {{item.StockUpDownStr}}</div>(B)
                      </div>
                    </div>
                  </td>

                  <td [ngClass]="{'pValue':item.AMinusB > 0,'nValue': 0 > item.AMinusB}">
                    {{item.AMinusBStr}}</td>
                  <td>{{item.FundNavYStr}}<br>{{item.StockNavYStr}}</td>
                  <td>
                    <div class="block">
                      <div class="updown">
                        <div [ngClass]="{'pValue':item.FundUpDownIncrease > 0,'nValue': 0 > item.FundUpDownIncrease}">
                          {{item.FundUpDownIncreaseStr}}</div>(C)
                      </div>
                      <div class="updown">
                        <div [ngClass]="{'pValue':item.StockUpDownIncrease > 0,'nValue': 0 > item.StockUpDownIncrease}">
                          {{item.StockUpDownIncreaseStr}}</div>(D)
                      </div>
                    </div>
                  </td>
                  <td [ngClass]="{'pValue':item.CMinusD > 0,'nValue': 0 > item.CMinusD}">
                    {{item.CMinusDStr}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <ng-container *ngFor="let item of TrackDiff">
            <p-panel class="p-panel" [collapsed]="baseService.Web$.value || (!baseService.Web$.value) ? false : true"
              [toggleable]="baseService.Web$.value ? false : true"
              *ngIf="isActiveSubId === '新台幣' && item.IsTWD || isActiveSubId === '原幣' && !item.IsTWD">
              <ng-template pTemplate="header">
                <!-- <a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a> -->
                <div>
                  <!-- <a class="type">股票代碼 {{item.CStockNo}}</a> -->
                  <a class="name">
                    <div>{{item.CShortName}}<span class="warning-text" *ngIf="item.CWarning">({{item.CWarning}})</span>
                    </div>
                    <span class="type">指數名稱：{{item.StockName}}</span>
                  </a>
                </div>


              </ng-template>
              <ng-template pTemplate="headericons">
                <img class="black-arrow" src="/assets/images/black-arrow.svg" />
              </ng-template>
              <div class="panel-item">
                <div class="name">基準日</div>
                <div class="desc">{{item.NavDate}}</div>

              </div>
              <div class="panel-item">
                <div class="name">基準日淨值<br>指數(註1)</div>
                <div class="desc">{{item.FundNavStr}}<br>{{item.StockNavStr}}</div>

              </div>
              <div class="panel-item">
                <div class="name">當日漲跌幅</div>
                <div class="desc">
                  <div class="block">
                    <div class="updown">
                      <div [ngClass]="{'pValue':item.FundUpDown > 0,'nValue': 0 > item.FundUpDown}">
                        {{item.FundUpDownStr}}</div>(A)
                    </div>
                    <div class="updown">
                      <div [ngClass]="{'pValue':item.StockUpDown > 0,'nValue': 0 > item.StockUpDown}">
                        {{item.StockUpDownStr}}</div>(B)
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-item">
                <div class="name">當日追蹤差距(A)-(B)</div>
                <div class="desc" [ngClass]="{'pValue':item.AMinusB > 0,'nValue': 0 > item.AMinusB}">
                  {{item.AMinusBStr}}</div>
              </div>
              <div class="panel-item">
                <div class="name">基期淨值<br> 指數(註2)</div>
                <div class="desc">{{item.FundNavYStr}}<br>{{item.StockNavYStr}}</div>
              </div>
              <div class="panel-item">
                <div class="name">今年以來漲跌幅</div>
                <div class="desc">
                  <div class="block">
                    <div class="updown">
                      <div [ngClass]="{'pValue':item.FundUpDownIncrease > 0,'nValue': 0 > item.FundUpDownIncrease}">
                        {{item.FundUpDownIncreaseStr}}</div>(C)
                    </div>
                    <div class="updown">
                      <div [ngClass]="{'pValue':item.StockUpDownIncrease > 0,'nValue': 0 > item.StockUpDownIncrease}">
                        {{item.StockUpDownIncreaseStr}}</div>(D)
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-item">
                <div class="name">今年以來追蹤差距(C)-(D)</div>
                <div class="desc" [ngClass]="{'pValue':item.CMinusD > 0,'nValue': 0 > item.CMinusD}">
                  {{item.CMinusDStr}}
                </div>
              </div>
            </p-panel>
          </ng-container>

          <button *ngIf="TotalItems > 0" class="arrow-btn" (click)="more()">
            顯示更多
            <div class="top-arrow"></div>
          </button>
          <p class="no-data" *ngIf="0 >= TotalItems && isSubmit">無更多資料</p>
        </div>
        <div class="note-wrap">
          註：<br>
          (1)係指基準日之淨值或收盤指數。<br>
          (2)係指前一年度最後1個營業日之淨值或收盤指數；若當年度掛牌上市者，則以上市日起計算今年以來追蹤差距。<br>
          (3)新臺幣淨值指數僅供參考，實際評價匯率依基金信託契約相關條款所訂。
        </div>
      </main>
    </p-tabPanel>
    <p-tabPanel [header]="'變動率'">
      <ng-container>
        <main>
          <div>
            <h2 class="sub-title sub-title-first">6日變動率</h2>
            <div>資料時間：{{CDate}}</div>
            <div class="trans-list">
              <table *ngIf="baseService.Web$.value" class="day6">
                <thead>
                  <tr>
                    <th>股票代號</th>
                    <th>基金簡稱</th>
                    <th>淨值</th>
                    <th>6日變動率</th>
                    <th>2.33倍標準差</th>
                    <th>負2.33倍<br>標準差臨界值</th>
                    <th>正2.33倍<br>標準差臨界值</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Ratedata">
                    <!-- <th><a [routerLink]="'/fund-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a></th> -->
                    <td>
                      {{item.CCode}}
                    </td>
                    <td><a>{{item.CCbrief}}<span class="warning-text">{{item.CWarning}}</span></a></td>
                    <td>{{item.CFundNavStr}}</td>
                    <td>{{item.CDay6BoStr}}</td>
                    <td>{{item.CDay6StdevStr}}</td>
                    <td>{{item.CDay6SStr}}</td>
                    <td>{{item.CDay6SpStr}}</td>
                  </tr>
                </tbody>
              </table>

              <p-panel class="p-panel" [collapsed]="baseService.Web$.value || (!baseService.Web$.value) ? false : true"
                [toggleable]="baseService.Web$.value ? false : true" *ngFor="let item of Ratedata">
                <ng-template pTemplate="header">
                  <!-- <a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a> -->
                  <div>
                    <a class="type">股票代號 {{item.CCode}}</a>
                    <a class="name">{{item.CCbrief}}<span class="warning-text">{{item.CWarning}}</span></a>
                  </div>
                </ng-template>
                <ng-template pTemplate="headericons">
                  <img class="black-arrow" src="/assets/images/black-arrow.svg" />
                </ng-template>
                <div class="panel-item">
                  <div class="name">淨值</div>
                  <div class="desc">{{item.CFundNavStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">6日變動率</div>
                  <div class="desc">{{item.CDay6BoStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">2.33倍標準差</div>
                  <div class="desc">{{item.CDay6StdevStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">負2.33倍標準差臨界值</div>
                  <div class="desc">{{item.CDay6SStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">正2.33倍標準差臨界值</div>
                  <div class="desc">{{item.CDay6SpStr}}</div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="day30">
            <h2 class="sub-title sub-title-first">30日變動率</h2>
            <div class="trans-list">
              <table *ngIf="baseService.Web$.value">
                <thead>
                  <tr>
                    <th>股票代號</th>
                    <th>基金簡稱</th>
                    <th>淨值</th>
                    <th>30日變動率</th>
                    <th>2.33倍標準差</th>
                    <th>負2.33倍<br>標準差臨界值</th>
                    <th>正2.33倍<br>標準差臨界值</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Ratedata">
                    <!-- <th><a [routerLink]="'/fund-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a></th> -->
                    <td>
                      {{item.CCode}}
                    </td>
                    <td><a>{{item.CCbrief}}<span class="warning-text">{{item.CWarning}}</span></a></td>
                    <td>{{item.CFundNavStr}}</td>
                    <td>{{item.CDay30BoStr}}</td>
                    <td>{{item.CDay30StdevStr}}</td>
                    <td>{{item.CDay30SStr}}</td>
                    <td>{{item.CDay30SpStr}}</td>
                  </tr>
                </tbody>
              </table>

              <p-panel class="p-panel" [collapsed]="baseService.Web$.value || (!baseService.Web$.value) ? false : true"
                [toggleable]="baseService.Web$.value ? false : true" *ngFor="let item of Ratedata">
                <ng-template pTemplate="header">
                  <!-- <a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.FullName}}</a> -->
                  <div>
                    <a class="type">股票代號 {{item.CCode}}</a>
                    <a class="name">{{item.CCbrief}}<span class="warning-text">{{item.CWarning}}</span></a>
                  </div>
                </ng-template>
                <ng-template pTemplate="headericons">
                  <img class="black-arrow" src="/assets/images/black-arrow.svg" />
                </ng-template>
                <div class="panel-item">
                  <div class="name">淨值</div>
                  <div class="desc">{{item.CFundNavStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">30日變動率</div>
                  <div class="desc">{{item.CDay30BoStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">2.33倍標準差</div>
                  <div class="desc">{{item.CDay30StdevStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">負2.33倍標準差臨界值</div>
                  <div class="desc">{{item.CDay30SStr}}</div>
                </div>
                <div class="panel-item">
                  <div class="name">正2.33倍標準差臨界值</div>
                  <div class="desc">{{item.CDay30SpStr}}</div>
                </div>
              </p-panel>
            </div>
          </div>
        </main>
      </ng-container>
    </p-tabPanel>
    <p-tabPanel [header]="'交易費率'">
      <main>
        <div *ngIf="list.CContent" [innerHtml]="list.CContent | html"></div>
        <div *ngIf="!list.CContent"> 無資料</div>
      </main>
      <!-- <div>
          <main>
          <div class="Rate-list">
            <table class="web">
              <thead>
                <tr>
                  <th>產品名稱</th>
                  <th>手續費上限</th>
                  <th>網路交易優惠手續費率</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>期街口S&P黃豆</td>
                  <td>前收型：3.00%<br> 後收型：<br> 自申購之日起持有1年以下：3% <br>持有1年（含）～2年以下：2% <br>持有2年（含）～3年以下：1%<br> 持有3年以上：0%</td>
                  <td>前收型：3.00%<br> 後收型：<br> 自申購之日起持有1年以下：3% <br>持有1年（含）～2年以下：2% <br>持有2年（含）～3年以下：1%<br> 持有3年以上：0%</td>
                </tr>
                <tr>
                  <td>期街口布蘭特正2</td>
                  <td>3.00%</td>
                  <td>單筆申購0.60%、定期定額0.60%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mbbox mb">
            <h2>期街口S&P黃豆</h2>
            <div class="limit">手續費率上限</div>
            <div class="box1">前收型：3.00%<br> 後收型：<br> 自申購之日起持有1年以下：3%<br> 持有1年（含）～2年以下：2% <br> 持有2年（含）～3年以下：1% <br>持有3年以上：0%</div>
            <div class="limit box2">網路交易優惠手續費率</div>
            <div>後收型：3.00%<br> 後收型：<br> 自申購之日起持有1年以下：3%<br> 持有1年（含）～2年以下：2% <br> 持有2年（含）～3年以下：1% <br>持有3年以上：0%</div>
          </div>
          </main>
        </div> -->
    </p-tabPanel>
  </p-tabView>
</div>


<ng-container *ngFor="let data of dialogData | slice:0:3">
  <app-confirm-dialog *ngIf="visible" [visible]="visible" [data]="data"
    (visibleChange)="visibleChange($event)"></app-confirm-dialog>
</ng-container>
