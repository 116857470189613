import { Component } from '@angular/core';

@Component({
  selector: 'app-accessible-home',
  templateUrl: './accessible-home.component.html',
  styleUrls: ['./accessible-home.component.scss']
})
export class AccessibleHomeComponent {

}
