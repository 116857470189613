<app-bread-crumb></app-bread-crumb>
<div class="wrapper">
  <main>
    <div>
      <div class="listbox">
        <!-- 進去報名內頁 -->
        <ng-container *ngFor="let item of SeminarListres">
            <div class="list" *ngIf="item.CType == 1 || item.CType == 2">
              <div class="imgbox">
                <img src="{{item.CPhoto}}">
              </div>
              <h2 class="title">{{ item.CTitle }}</h2>
              <div class="date">
                <div class="date-text">活動時間 | {{ item.CActivityStartDt | date:'yyyy/MM/dd HH:mm' }}</div>
                <div class="date-text" *ngIf="item.CEvent">活動場次 | {{ item.CEvent }}</div>
              </div>
              <div class="list-text">
                <div class="listcontent">
                  <div class="list-title">{{ item.CSubTitle }}</div>
                 <span class="content-text"> {{ item.CDescription }}</span>
                </div>
                <div class="btn-box">
                  <!-- 根據CType選擇不同的href -->
                  <a class="confirm-btn"
                     [href]="item.CType === 1 ? (url + '/' + item.CId) : item.CUrl"
                     [target]="item.CIsOpenNewWindows ? '_blank' : '_self'">前往活動</a>
                </div>
              </div>
            </div>
        </ng-container>
      </div>
      <ng-container>
        <button *ngIf="TotalItems > (SeminarListres && SeminarListres.length)" class="arrow-btn" (click)="more()">
          顯示更多
          <div class="top-arrow"></div>
        </button>
        <p class="no-data" *ngIf="(SeminarListres && SeminarListres.length) >= TotalItems">無更多資料</p>
      </ng-container>
    </div>
  </main>
</div>
