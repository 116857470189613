import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { footercontent, FooterEditContent, footernumber } from 'src/app/shared/models/home';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-accessible-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterAccessibleComponent {
  contents: footercontent[] = [];
  CId = 1;
  searchOpen = true;
  open = false; //手機板漢堡選單開合狀態
  footermenu = new ResponseArticle;
  footermeunlist :Articledata={} as Articledata ;
  footernumber:footernumber = {} as footernumber ;
  footerEditContent: FooterEditContent = {} as FooterEditContent;
  constructor(
    public homeService: HomeService,
    public baseService: BaseService,
    public aritcleService:AritcleService
  ) {
    this.GetFooterContent()
    this.Getfootermeun()
    this.GetFooterEditContent()
  }

  close() {
    this.searchOpen = !this.searchOpen;
  }


  GetFooterContent() {
    this.footernumber.CId = 99;
    this.homeService.GetFooterContent(this.footernumber).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.contents = res.Entries;
      }
    });
  }


  Getfootermeun() {
    this.footermenu.FunctionID = 638;
    this.footermenu.PageSize = 100;
    this.aritcleService.GetArticleByUnit(this.footermenu).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.footermeunlist = res.Entries;
        this.footermeunlist.Data.forEach(item => {
          const filePath = item.CLinkFile;
          const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);  // 先抓取最後一個斜線後的檔案名稱部分
          const fileExtension = fileName.substring(fileName.lastIndexOf('.')); // 從檔案名稱抓取副檔名
          item.CName = item.CName + fileExtension;  // 將副檔名加回 CName
      });
      }
    });
  }

   GetFooterEditContent() {
    this.homeService.GetFooterEditContent().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.footerEditContent = res.Entries;
      }
    });
  }

  getLink(data: any): string {
    switch (data.CLinkType) {
      case 1:
        return data.CLinkUrl;
      case 2:
        return data.CLinkFile;
      case 3:
        return `unit/Type1/${data.CFunctionId}/${data.CId}`;
      case null:
        return `unit/Type1/${data.CFunctionId}/${data.CId}`;
      default:
      return `unit/Type1/${data.CFunctionId}/${data.CId}`;
    }
  }

  getTarget(data: any): string {
    switch (data.CLinkType) {
      case 1:
      case 3:
      case null:
        return data.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }
}
