import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ArticledataID, ResponseArticleID } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
  currPath = ''; //當前的路由
  request = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;
  url = '';
  constructor(
    private route: ActivatedRoute,
    // private router: Router,
    public aritcleService: AritcleService

  ) {

  }

  ngOnInit(): void {
    this.request.FunctionID = 7045
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      // const idWithoutNews = id.replace('esg', '');
      this.request.ID = Number(id);
    });

    urlParams.subscribe(queryParams => {
      if (queryParams['isPreview'] !== null) {
        this.request.IsPreview = Boolean(queryParams['isPreview']);
      }
      else{
        this.request.ID = 2
      }
    });
    this.GetArticleByID();
  }


  GetArticleByID() {
    this.aritcleService.GetArticleByID(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.list = res.Entries;
      }
    });
  }
}
