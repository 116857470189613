import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Articledata, ResponseArticle } from '../../models/article';
import { HomeService } from '../../services/home.service';
import { BaseService } from '../../services/base.service';
import { AritcleService } from '../../services/article.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { mainData } from '../../../shared/interface/main-data';

@Component({
  selector: 'app-news-info',
  templateUrl: './news-info.component.html',
  styleUrls: ['./news-info.component.scss']
})
export class NewsInfoComponent {
  @Input() data: any[] = [];
  // @Input() TotalItems: number = 0;
  @Input() FunctionID: number = 0;
  @Output() clicked = new EventEmitter<any>();
  searchOpen= false;
  TotalItems=0;
  url='';
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  showLoading = false;
  first: number = 1;
  rows: number = 10;
  // items = [
  //   {
  //     date: "2011/11/04",
  //     listcontent: "OO投信投醒您，慎 防冒名之金融投資詐騙"
  //   },
  //   {
  //     date: "2012/02/15",
  //     listcontent: "新的投資機會，請注意"
  //   },
  //   {
  //     date: "2012/05/20",
  //     listcontent: "市場更新：最新趨勢分析"
  //   },
  //   {
  //     date: "2012/08/10",
  //     listcontent: "投資者提示：風險管理重要性"
  //   },
  //   {
  //     date: "2012/12/01",
  //     listcontent: "投資組合多元化的好處"
  //   }
  // ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public homeService: HomeService,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ){

  }

  ngOnInit(): void {
    this.mainData$.loading$.next(true);
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'bulletin') {
        this.url='/notice/bulletin-detail'
      } else if (currentPath === 'yield-notice') {
        this.url='/notice/yield-detail'
      }
      else if (currentPath === 'anti-money') {
        this.url='/notice/anti-money-detail'
      }
    });
    this.Getnews();
  }


  close() {
    this.searchOpen = !this.searchOpen;
  }

  // more() {
  //   this.clicked.emit();
  // }


  Getnews() {
    this.showLoading = true;
    // this.newsres.FunctionID = 7101;
    // this.FunctionID
    this.newsres.FunctionID= this.FunctionID
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.newslist = res.Entries;
        this.TotalItems= res.TotalItems;
        if(this.newslist){
          this.showLoading = false;
        }
      }
     this.mainData$.loading$.next(false);
    });
  }

  more() {
    this.newsres.PageSize=this.newsres.PageSize + 10;
    this.Getnews()
  }

  getLink(item: any): string {
    switch (item.CLinkType) {
      case 1:
        return item.CLinkUrl;
      case 2:
        return item.CLinkFile;
      case 3:
        return `${this.url}/news${item.CId}`;
      case null:
        return `${this.url}/news${item.CId}`;
      default:
        return `${this.url}/news${item.CId}`;
    }
  }

  getTarget(data: any): string {
    switch (data.CLinkType) {
      case 1:
      case 3:
      case null:
        return data.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }

  paginate(event: any) {
    this.newsres.PageIndex = event.page + 1;
     this.first = event.first;
     this.Getnews();
  }
}
