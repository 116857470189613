import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.scss']
})
export class WeeklyReportComponent {
  searchOpen = true;
  worldres = new ResponseArticle;
  weeklylist: Articledata = {} as Articledata;
  TotalItems=0;
  constructor(
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService
  ) {
    // this.Getweekly()
    this.worldres.FunctionID = 7007;
  }

  // Getweekly() {
  //   this.worldres.FunctionID = 7007;
  //   this.aritcleService.GetArticleByUnit(this.worldres).subscribe((res) => {
  //     if (res.StatusCode == EnumStatusCode.Success) {
  //       this.weeklylist = res.Entries;
  //       console.log(this.weeklylist.Data)
  //     }
  //   });
  // }
}
