export class Page {
  PageIndex = 1;
  PageSize = 10;
}

export class PageSort extends Page {
  SortColName = '';
  IsDesc = true;
  IsPagination = true;
}
