import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { Observable, combineLatest, map } from 'rxjs';
import { enumETFDateList } from 'src/app/enum/enumDateList';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { mainData } from 'src/app/shared/interface/main-data';
import { FundDetailPageData, FundDetailPageReq, FundIndex, FundIndexDetail, FundNAVvsPrice } from 'src/app/shared/models/etf';
import { ETFService } from 'src/app/shared/services/etf.service';
import { FundService } from 'src/app/shared/services/fund.service';

@Component({
  selector: 'app-index-market',
  templateUrl: './index-market.component.html',
  styleUrls: ['./index-market.component.scss']
})
export class IndexMarketComponent {
  data: FundNAVvsPrice = {} as FundNAVvsPrice;
  enumDateList = enumETFDateList;
  DateName = this.enumDateList[0].Name;
  Date30day = this.enumDateList[0];
  isHighcharts = typeof Highcharts === 'object';
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  series1Data: any[] = []; //預估淨值
  series2Data: any[] = []; //市價
  fundId=0;
  request:FundIndex ={} as FundIndex;
  Creatdate = '';
  isBrowser: boolean;
  chartOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      name: '指數行情',
      data: this.series1Data,
      type: 'line',
      dataLabels: {
        enabled: false,
      },
    }],
    legend: {
      labelFormatter: function () {
        return `<span>${this.name}</span>`
      },
      enabled: true,
    },
    lang: {
      noData: '無資料可顯示' // 設置無資料時的文本訊息
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    },
    colors: ['#C9191D', '#29A1D8'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        turboThreshold: 0
      },
      areaspline: {
        fillOpacity: 0.3
      }
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return '數據：' + this.y + '<br>' + '日期：' + this.x;
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  };
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  fundIndexDetail:FundIndexDetail = {} as FundIndexDetail
  fundIndexDetailchart:FundIndexDetail = {} as FundIndexDetail
  section1Start = 0;
  section1End: any;
  section2Start: any;
  section2End: any;
  section3Start: any;
  section3End: any;
  fundDetailPageReq: FundDetailPageReq = {} as FundDetailPageReq;
  FundDetailPageData: FundDetailPageData = {} as FundDetailPageData;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    public eTFService: ETFService,
    public mainData$: mainData,
    public fundService: FundService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      this.request.FundNo = id;
      this.fundDetailPageReq.FundNo = id;
    });
    this.Getdetail();
    this.goTop();
  }

  ngOnInit(): void {
    this.request.IsTWD = false;
    this.getDateName(this.Date30day);
  }

  getDateName(data: any) {
    this.DateName = data.Name;
    if (data.Name !== '成立至今') {
      this.request.StartDate = new Date(data.SDate);
      this.request.EndDate = new Date(data.EDate);
    }
    else {
      this.request.StartDate = new Date(this.Creatdate);
      this.request.StartDate.setUTCHours(16, 0, 0, 0);
      this.request.EndDate = new Date(data.EDate);
    }
    this.GetTrackDiffHistory();
    this.GetTrackDiffHistorychart();
  }

  search(){
    this.DateName='';
    this.GetTrackDiffHistory();
    this.GetTrackDiffHistorychart();
  }

  Getdetail(){
    this.fundDetailPageReq = {
      ...this.fundDetailPageReq,
      SearchType: 1,
      Year: 0,
      SDate: new Date(),
      EDate: new Date(),
      IsPreview:false
    }
    this.fundService.GetFundDetailPage(this.fundDetailPageReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        if (res.Entries && res.Entries.FundDetail && res.Entries.FundDetail.BasicInfo) {
          this.Creatdate = res.Entries.FundDetail.BasicInfo.SetUpDt
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetTrackDiffHistory() {
    this.request.IsDesc = true;
    this.eTFService.GetTrackDiffHistory(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundIndexDetail = res.Entries;
        const total = this.fundIndexDetail.StockNav.length;
        const baseSectionCount = Math.floor(total / 3);
        const remainder = total % 3;
        this.section1Start = 0;
        this.section1End = baseSectionCount + (remainder > 0 ? 1 : 0);
        this.section2Start = this.section1End;
        this.section2End = this.section2Start + baseSectionCount + (remainder > 1 ? 1 : 0);
        this.section3Start = this.section2End;
        this.section3End = total;
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetTrackDiffHistorychart(){
    this.request.IsDesc = false;
    this.eTFService.GetTrackDiffHistory(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundIndexDetailchart = res.Entries;
        let _xAxis: any[] = [];
        let seriesData: any[] = [];
        let seriesDataStr: any[] = [];
        let totalDataPoints = this.fundIndexDetailchart.StockNav.length;
        let desiredTicks = window.innerWidth < 768 ? 20 : 40;
        this.fundIndexDetailchart.StockNav.map(x => {
          _xAxis.push(x.DateStr);
          seriesData.push(x.Nav)
          seriesDataStr.push(x.NavStr)
        });

        this.chartOptions = {
          ...this.chartOptions,
          series: [{
            name: '指數行情',
            data: this.fundIndexDetailchart.StockNav.map(x => {
              return {
                y: x.Nav,
                _xYxis: x.NavStr,
              }
            }),
            type: 'line',
            dataLabels: {
              enabled: false
            },
          }],
          xAxis: {
            type: 'datetime',
            categories: _xAxis,
            labels: {
              format: "{value}",
              style: {
                fontSize: '0.8em',
                fontWeight: 'bolder',
              },
              rotation: -60
            },
            lineWidth: 2,
            tickPositioner: function () {
              var positions = [],
                step = Math.ceil(totalDataPoints / desiredTicks);
              for (let i = 0; i < totalDataPoints; i += step) {
                positions.push(i);
              }
              return positions.filter(pos => typeof pos === 'number');  // 確保只返回 number 類型
            }
          },
          tooltip: {
            // formatter: function () {
            //   return this.x + ' ' + '<br>' + seriesDataStr + ' ';

            // }
            formatter: function (): string { // 這裡指定返回類型為 string
              return this.x + ' ' + '<br>' + (this as any).point._xYxis + ' ';
            }
          },
          plotOptions: {
            series: {
              turboThreshold: 0
            }
          },
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  goTop() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
