<main class="indices-wrap">
  <h2 class="sub-title sub-title-first">指數介紹</h2>
  <div class="detail-table-wrap">
    <div class="detail-table">
      <div class="item" *ngFor="let item of data">
        <div class="label">{{item.Name}}</div>
        <div class="desc">
          <!-- {{item.Val}} -->
           <div [innerHTML]="item.Val|html"></div>
        </div>
      </div>
    </div>
  </div>
  <h2 class="sub-title">指數行情</h2>
  <table class="etf-indices">
    <thead>
      <tr>
        <th>指數名稱</th>
        <th>日期</th>
        <th>收盤價</th>
        <th>日漲跌</th>
        <th>日漲跌幅(%)</th>
        <th>歷史行情</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="navData">
        <th>{{navData.IndexName}}</th>
        <td>{{navData.Date}}</td>
        <td>{{navData.PriceStr}}</td>
        <td [ngClass]="{'pValue': navData.PriceChange > 0,'nValue': 0 > navData.PriceChange}">{{navData.PriceChangeStr}}</td>
        <td [ngClass]="{'pValue': navData.PriceChangeRatio > 0,'nValue': 0 > navData.PriceChangeRatio}">{{navData.PriceChangeRatioStr}}</td>
        <td> <a [routerLink]="'/etf/index-market/' + FundNo">
          <img class="download-icon" src="/assets/images/net-value.svg" alt="歷史淨值" />
        </a></td>
      </tr>
    </tbody>
  </table>

  <p-panel class="p-panel"
    [collapsed]="false "
    [toggleable]="false"
    *ngIf="navData">
    <ng-template pTemplate="header">
      {{navData.IndexName}}
    </ng-template>
    <ng-template pTemplate="headericons">
      <img class="black-arrow" src="/assets/images/black-arrow.svg" />
    </ng-template>
    <!-- <div class="panel-item">
      <div class="name">指數名稱</div>
      <div class="desc">
        {{navData.IndexName}}
      </div>
    </div> -->
    <div class="panel-item">
      <div class="name">日期</div>
      <div class="desc">
        {{navData.Date}}
      </div>
    </div>

    <div class="panel-item">
      <div class="name">收盤價</div>
      <div class="desc">
        {{navData.PriceStr}}
      </div>
    </div>

    <div class="panel-item">
      <div class="name">日漲跌</div>
      <div class="desc" [ngClass]="{'pValue': navData.PriceChange > 0,'nValue': 0 > navData.PriceChange}">
        {{navData.PriceChangeStr}}
      </div>
    </div>

    <div class="panel-item">
      <div class="name">日漲跌幅(%)</div>
      <div class="desc" [ngClass]="{'pValue': navData.PriceChangeRatio > 0,'nValue': 0 > navData.PriceChangeRatio}">
        {{navData.PriceChangeRatioStr}}
      </div>
    </div>

    <div class="panel-item">
      <div class="name">歷史行情</div>
      <div class="desc">
        <a [routerLink]="'/etf/index-market/' + FundNo">
          <img class="download-icon" src="/assets/images/net-value.svg" alt="歷史淨值" />
        </a>
      </div>
    </div>
  </p-panel>
</main>
