import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, PLATFORM_ID
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseService } from '../../../../shared/services/base.service';
import { Popup, DialogData, PopupReq } from '../../../../shared/models/base';
import { UserService } from '../../../../shared/services/user.service';
import { PurchaseService } from '../../../../shared/services/purchase.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { FundDetailReq, Comment, PDBranch, registerReq, ETFOrderFormInfo } from '../../../../shared/models/purchase';
import { AritcleService } from '../../../../shared/services/article.service';
import { ResponseArticleID, ArticledataID } from '../../../../shared/models/article';
import { mainData } from '../../../../shared/interface/main-data';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  isBrowser: boolean;
  captchaUrl = '';
  agreeChecked = false;
  disableagreeChecked = true;
  FundDetail = ['凱基商業銀行股份有限公司', '凱基商業銀行股份有限公司', '合作金庫銀行', '合作金庫銀行', '合作金庫銀行', '合作金庫銀行', '合作金庫銀行', '合作金庫銀行']
  scrWidth = 0;
  dialogData: DialogData = {} as DialogData;
  visible = false;
  visibleType = ''
  //警語彈窗
  dialogMsgData: DialogData[] = []; //警語彈窗
  visiblegMsg = false;
  popupData: Popup[] = [];
  UpdateTime: Popup[] = [];
  ETFMsgDate = '';
  ETFMsgUpdate = '';
  agreeTimes = 0;

  errorTest: string[] = [];
  errorFormat: string[] = [];
  CodeToken = '';
  FundNo = '';
  PDreq: FundDetailReq = {} as FundDetailReq;
  brokerageList: Comment[] = [];
  PDBranchList: PDBranch[] = [];
  request: registerReq = {} as registerReq;
  ETFOrderFormInfo: ETFOrderFormInfo = {} as ETFOrderFormInfo;
  OrderTime: boolean = false;
  TheOrderTime: boolean = true;
  //個資文章
  requestNew = new ResponseArticleID;
  aritcle: ArticledataID = {} as ArticledataID;

  //非登記時段時間
  EndDT = '';
  StartDT = '';
  PopupReq = new PopupReq;
  Alert = '';
  FundsSplit: boolean = false;
  privateType = 0;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private sanitizer: DomSanitizer,
    public baseService: BaseService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public purchaseService: PurchaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.goTop();
    this.GetCaptchaImg();
    this.GetArticleByID();
    this.getETFMsg();
  }

  ngOnInit(): void {
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      this.FundNo = params['id'];
      this.request.FundNo = this.FundNo;
    });

    this.route.url.subscribe(url => {
      this.GetPD();
      this.GetETFOrderFormInfo();
    })
  }

  selectListChange(e: any) {
    this.request.PDealerID = e.value.ID;
    this.GetPDBranch(e.value.ID)
  }

  selectBranchListChange(e: any) {
    this.request.PDBranchCode = e.value.Code;
  }

  //驗證碼API
  GetCaptchaImg() {
    this.userService.GetCaptchaImg().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.captchaUrl = res.Entries.Base64;
        this.request.CodeToken = res.Entries.Token;
      }
    });
    this.mainData$.loading$.next(false);
  }
  //驗證碼
  getSanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  //取得參與券商
  GetPD() {
    this.PDreq.FundNo = this.FundNo;
    this.purchaseService.GetPD(this.PDreq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.brokerageList = res.Entries;
        if (this.brokerageList.length) {
          //預設第一筆取得子選單
          this.selectListChange({
            value: {
              ID: this.brokerageList[0].ID,
              Name: this.brokerageList[0].Name
            }
          })
        }
      }
    });
  }

  //券商分點/分公司代碼
  GetPDBranch(pId: number) {
    this.purchaseService.GetPDBranch(pId).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.PDBranchList = res.Entries;
        if (this.PDBranchList.length && !this.PDBranchList[0].NoChild) {
          this.request.PDBranchCode = this.PDBranchList[0].Code;
        } else {
          this.request.PDBranchCode = ''
        }
      }
      this.mainData$.loading$.next(false);
    })
  }

  //申購登記需求單表單資訊
  GetETFOrderFormInfo() {
    this.purchaseService.GetETFOrderFormInfo(this.PDreq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.OrderTime = true;
        this.TheOrderTime = true;
        this.ETFOrderFormInfo = res.Entries;
        // if (this.PDBranchList.length) {
        //   this.request.PDBranchCode = this.PDBranchList[0].Code;
        // }
      } else {
        this.OrderTime = false;
        this.TheOrderTime = false;
        this.EndDT = res.Entries.EndDT;
        this.StartDT = res.Entries.StartDT;
        this.FundsSplit = res.Entries.FundsSplit;
        this.Alert = res.Entries.Alert;
      }
      this.mainData$.loading$.next(false);
    })

  }

  getWidth(i: number) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 820) {
      if (this.brokerageList && i === this.brokerageList?.length - 1) {
        let num = this.brokerageList && this.brokerageList?.length % 3
        if (num == 1) return '100%';
        else if (num) return 'calc(100% / 3 * 2)';
        else return 'calc(100% / 3)'
      } else return 'calc(100% / 3)'
    } else if (this.scrWidth > 640) {
      return '50%'
    } else return '100%'
  }

  visibleChange(e: any) {
    this.visible = false;
    if (e && this.visibleType === 'agreeChecked')
      this.agreeChecked = true;
      this.disableagreeChecked = false;
    this.GetETFOrderFormInfo();
  }

  getETFMsg() {
    // ETF警語
    this.ETFMsgDate = localStorage.getItem('ETFregisterMsg') || ''
    this.GetPopup();
  }

  GetPopup() {
    this.baseService.GetPopup(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupData = res.Entries;
        this.UpdateTime = this.popupData.sort(function (a, b) {
          let timeA: string = '';
          let timeB: string = '';
          timeA = a.UpdateTime;
          timeB = b.UpdateTime;

          if (timeA < timeB) {
            return 1;
          }
          if (timeA > timeB) {
            return -1;
          }
          return 0;
        })
        this.ETFMsgUpdate = localStorage.getItem('ETFMsgregisterUpdate') || ''
        if ((!this.ETFMsgDate || this.baseService.getTimeout0hour(this.ETFMsgDate)) || (this.UpdateTime.length && this.ETFMsgUpdate !== this.UpdateTime[0].UpdateTime)) {
          this.OpenDialog();
        }
      }
    });
  }

  OpenDialog() {
    if (this.isBrowser) this.visiblegMsg = true;
    this.dialogMsgData = this.popupData.map(val => {
      return {
        title: val.Title,
        message: val.Content,
        data: val
      }
    });
  }

  //警語彈窗
  visibleMsgChange(e: any) {
    if (e) this.agreeTimes = this.agreeTimes + 1;
    if (this.agreeTimes == this.popupData.length || (this.agreeTimes === 3)) {
      localStorage.setItem('ETFregisterMsg', new Date().toString());
      if (this.UpdateTime.length) localStorage.setItem('ETFMsgregisterUpdate', this.UpdateTime[0].UpdateTime)
    }
  }

  submitCheck() {
    this.errorTest = [];
    this.errorFormat = [];
    this.dialogData.message = ''
    if (!this.request.Name || !/^\s*\S/.test(this.request.Name)) {
      this.errorTest.push('姓名');
    }

    if (!this.request.IDNumber || !/^\s*\S/.test(this.request.IDNumber)) {
      this.errorTest.push('身份證字號/統編/外資法人');
    }

    if (this.request.IDNumber && !/^[A-Z][12]\d{8}$/.test(this.request.IDNumber) && !/^[0-9]{8}$/.test(this.request.IDNumber) && !/^F\d{8}$/.test(this.request.IDNumber)) {
      this.errorFormat.push('身份證字號/統編/外資法人');
    }

    if (!this.request.Email || !/^\s*\S/.test(this.request.Email)) {
      this.errorTest.push('E-mail');
    }

    if (this.request.Email && !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.request.Email)) {
      this.errorFormat.push('E-mail');
    }
    if (!this.request.Phone || !/^\s*\S/.test(this.request.Phone)) {
      this.errorTest.push('聯絡電話');
    }
    if (this.request.Phone && !/^(09\d{8}|(?!09|00|01)(\d{2,4})\d{7,8})$/.test(this.request.Phone)) {
      this.errorFormat.push('聯絡電話');
    }

    if (!this.request.PDealerID) {
      this.errorTest.push('參與券商');
    }

    if (!this.request.PDBranchCode) {
      this.errorTest.push('券商分點/分公司代碼');
    }

    if (!this.request.PurchaseQuantity) {
      this.errorTest.push('擬申購組數');
    }

    if (!this.request.Code) {
      this.errorTest.push('驗證碼');
    }

    if (!this.agreeChecked) {
      this.errorTest.push('個人資料蒐集、處理及利用告知事項');
    }

    if (this.errorTest.length || this.errorFormat.length) {
      this.dialogData.title = '提醒'
      this.privateType =0;
      this.visible = true;
      this.visibleType = '';
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '為必填欄位。<br>'
      if (this.errorFormat.length) this.dialogData.message = this.dialogData.message + this.errorFormat.toString() + '欄位格式錯誤。'
    } else this.submit();
  }

  // 卡控申購組數
  validateInput(event: any) {
    const inputElement: HTMLInputElement = event.target;
    let inputValue: string = inputElement.value;
    // 整數正則式
    const regex = /^[1-9]\d*$/;
    if (!regex.test(inputValue)) {
      // 去除符號
      inputValue = inputValue.replace(/[^\d]/g, '');
      // 輸入0，重置默認1
      if (inputValue === "0") {
        inputValue = "1";
      }
      inputElement.value = inputValue;
    }
    // 更新 ngModel 綁訂
    this.request.PurchaseQuantity = parseInt(inputValue, 10);
  }

  refresh() {
    this.GetCaptchaImg();
  }

  submit() {
    if (!this.OrderTime) return;
    this.request.HandlingFee = this.ETFOrderFormInfo.HandlingFee;
    this.request.TransactionFee = this.ETFOrderFormInfo.TransactionFee;
    this.request.BrokerFee = this.ETFOrderFormInfo.BrokerFee;
    this.request.ETFRegisterID = this.ETFOrderFormInfo.ETFRegisterID;
    this.purchaseService.CreateOrder(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        if (this.isBrowser) document.getElementById('reset')?.click();
      }
      this.dialogOpen(res.Message);
      this.GetCaptchaImg();
      this.mainData$.loading$.next(false);
    })
  }

  dialogOpen(message: string, type?: string) {
    this.visibleType = '';
    this.visible = true;
    this.privateType = 0;
    this.dialogData.message = message;
     this.dialogData.title = '提醒'
    if (type=='agreeChecked') {
      this.visibleType = type;
      this.dialogData.title = '個人資料蒐集、處理及利用告知事項'
      this.privateType = 1;
    }
  }

  goTop() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  //個資單篇文章
  GetArticleByID() {
    this.requestNew.FunctionID = 7343;
    this.requestNew.ID = 4;
    this.aritcleService.GetArticleByID(this.requestNew).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.aritcle = res.Entries;
      }
    });
  }

  unCheck(e: any) {
   // this.router.navigate(['/']);
  }
}
