import { Component, ElementRef } from '@angular/core';
import { ImgToken } from '../../shared/models/user';
import { UserService } from '../../shared/services/user.service';
import { Popup, DialogData, PopupReq } from '../../shared/models/base';
import { mainData } from '../../shared/interface/main-data';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ArticledataID, ResponseArticleID } from '../../shared/models/article';
import { EventsService } from 'src/app/shared/services/events.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { ActivityForm, SignActionForm, SignActionFormList, ActivityFormData } from '../../shared/models/event';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {
  request = {} as SignActionForm;
  resimg: ImgToken = {} as ImgToken;
  visible = false;
  dialogData: DialogData = {} as DialogData;
  Token = '';
  checkbox = false;
  list: ArticledataID = {} as ArticledataID;
  ActionCode = '';
  ActivityFormInfo:ActivityFormData = {} as  ActivityFormData;
  FormData: ActivityForm[] = [];

  ActivityFormData: FormGroup;
  checkboxs: string = '';
  errorTest: string[] = [];
  constructor(
    public userService: UserService,
    public mainData$: mainData,
    public eventsService: EventsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private elementRef: ElementRef
  ) {
    this.ActionCode = this.route.snapshot.params['FormType'];
    this.request.ActionCode = this.ActionCode;
    this.GetActionForm();
    this.GetCaptchaImg();
    this.ActivityFormData = this.fb.group({});
  }

  ngOnInit(): void {
    this.ActivityFormData.valueChanges.subscribe({
      next: value => {
        // console.log("formGroup", value);
      }
    })
  }

  ngAfterViewChecked() {
    // console.log("checkboxs", this.checkboxs);
  }

  GetActionForm() {
    this.eventsService.GetActionForm(this.ActionCode).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.ActivityFormInfo = res.Entries;
        this.FormData = res.Entries.Question;
        this.FormData.map(val => this.addControl(`Control${val.ID}`, val.Type, val.Must))
      }
      this.mainData$.loading$.next(false);
    })
  }

  addControl(name: string, Type: number, request: boolean) {
    if (Type == 3) {
      if (request) this.ActivityFormData.addControl(name, this.fb.array([], Validators.required));
      else this.ActivityFormData.addControl(name, this.fb.array([]));
    }
    else {
      if (request) this.ActivityFormData.addControl(name, new FormControl('', Validators.required));
      else this.ActivityFormData.addControl(name, new FormControl(''));
    }
  }

  //多選
  toggleSelection(option: string, name: string) {
    const selectedOptionsFormArray = this.ActivityFormData.get(name) as FormArray;
    if (selectedOptionsFormArray.value.includes(option)) {
      const index = selectedOptionsFormArray.value.indexOf(option);
      selectedOptionsFormArray.removeAt(index);
    } else {
      selectedOptionsFormArray.push(this.fb.control(option));
    }
  }

  refresh() {
    this.GetCaptchaImg();
  }

  GetCaptchaImg() {
    this.userService.GetCaptchaImg().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.resimg = res.Entries;
        this.request.CodeToken = this.resimg.Token;
      }
      this.mainData$.loading$.next(false);
    });
  }

  policyDialog() {
    this.visible = true;
    this.dialogData.title = '個人資料蒐集、處理及利用告知事項'
    this.dialogData.message = this.list.CContent
  }

  ContantUs() {
    this.errorTest = [];
    this.FormData.map(val => {
      let control = this.ActivityFormData.get(`Control${val.ID}`);
      if (control && control.hasError('required')) this.errorTest.push(val.Name)
    })
    if (!this.request.Code) this.errorTest.push('驗證碼')
    if (this.errorTest.length) {
      this.visible = true;
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '為必填欄位。<br>'
    } else this.submit();
  }

  submit() {
    let list: SignActionFormList[] = []
    this.FormData.map(val => {
      list.push({
        Name: val.Name,
        Answer: this.ActivityFormData.get(`Control${val.ID}`)?.value.toString()
      })
    })
    this.request.List = list;
    this.eventsService.SignActionForm(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.ActivityFormData.reset();
        this.setAllCheckboxesToFalse();
      }
      this.visible = true;
      this.dialogData.message = res.Message;
      this.request.Code = '';
      this.refresh();
      this.mainData$.loading$.next(false);
    })
  }
  setAllCheckboxesToFalse() {
    this.FormData.map(val => {
      if(typeof(this.ActivityFormData.get('Control' + val.ID)?.value) == 'object') {
        const formArrays = this.ActivityFormData.get('Control' + val.ID) as FormArray;
        while (formArrays.length) {
          formArrays.removeAt(0);
        }
      }
    })

    var elementsToRemove = document.getElementsByTagName("checkicon");
    for (var i = elementsToRemove.length - 1; i >= 0; i--) {
      elementsToRemove[i].parentNode?.removeChild(elementsToRemove[i]);
    }
  }

  visibleChange(e: any) {
    this.visible = false;
  }
}
