import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogData, Popup } from '../../../../shared/models/base';
import { BaseService } from '../../../../shared/services/base.service';
import { UserService } from '../../../../shared/services/user.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { InquireReq, ETFProduct, InquireRes, CancelReq } from '../../../../shared/models/purchase';
import { AritcleService } from '../../../../shared/services/article.service';
import { ResponseArticleID, ArticledataID } from '../../../../shared/models/article';
import { PurchaseService } from '../../../../shared/services/purchase.service';
import { mainData } from '../../../../shared/interface/main-data';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inquire',
  templateUrl: './inquire.component.html',
  styleUrls: ['./inquire.component.scss']
})
export class InquireComponent {
  captchaUrl = '';
  agreeChecked = false;
  disableagreeChecked = true;
  isSubmit = false;
  visible = false;
  visibleType = '';
  confirmVisible = false;
  dialogData: DialogData = {} as DialogData; //訊息彈窗
  dialogConfirmData: DialogData = {} as DialogData; //確認彈窗
  request: InquireReq = {} as InquireReq;
  ETFProduct: ETFProduct[] = [];
  Time:any;
  errorTest: string[] = [];
  errorFormat: string[] = [];
  inquireRes: InquireRes = {} as InquireRes;
  privateType = 0;
  //取消申購
  cancelReq: CancelReq = {} as CancelReq;
  Timeup = true; //預設不可申購

  //個資文章
  requestNew = new ResponseArticleID;
  aritcle: ArticledataID = {} as ArticledataID;
  isBrowser: boolean;
  todayDatatime:any;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private sanitizer: DomSanitizer,
    public baseService: BaseService,
    public userService: UserService,
    public aritcleService: AritcleService,
    public purchaseService: PurchaseService,
    public mainData$: mainData,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.goTop();
    this.GetCaptchaImg();
    this.GetArticleByID();
    this.GetTime();
    this.GetProduct();
    this.todayDatatime = this.getFormattedCurrentTime();
  }
  //驗證碼API
  GetCaptchaImg() {
    this.userService.GetCaptchaImg().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.captchaUrl = res.Entries.Base64;
        this.request.CodeToken = res.Entries.Token;
      }
      this.mainData$.loading$.next(false);
    });
  }


  refresh() {
    this.GetCaptchaImg()
  }

  //申購產品
  GetProduct() {
    this.purchaseService.GetProduct().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.ETFProduct = res.Entries;
        if (this.ETFProduct.length) this.request.FundNo = this.ETFProduct[0].FundNo
      }
    })
  }

  checkTime() {
  }

  getFormattedCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  //取得申購時間
   GetTime() {
    this.purchaseService.GetTime().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.Time = res.Entries;
        this.todayDatatime = this.getFormattedCurrentTime();
        if(this.todayDatatime > this.Time.StartDT && this.todayDatatime < this.Time.EndDT){
          this.Timeup = false; //在申購時間內 可申購+取消 開放按鈕
        }
        else {
          this.Timeup = true; // 不在申購時間內，不可申購
        }
      }
      this.mainData$.loading$.next(false);
    })
  }

  //個資單篇文章
  GetArticleByID() {
    this.requestNew.FunctionID = 7343;
    this.requestNew.ID = 4;
    this.aritcleService.GetArticleByID(this.requestNew).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.aritcle = res.Entries;
      }
    });
  }

  //驗證碼
  getSanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  selectListChange(e: any) {
    this.request.FundNo = e.value.FundNo;
  }

  submitCheck() {
    this.errorTest = [];
    this.errorFormat = [];
    this.dialogData.message = '';

    if (!this.request.IDNumber || !/^\s*\S/.test(this.request.IDNumber)) {
      this.errorTest.push('身份證字號/統編/外資法人');
    }

    if (this.request.IDNumber && !/^[A-Z][12]\d{8}$/.test(this.request.IDNumber) && !/^[0-9]{8}$/.test(this.request.IDNumber) && !/^F\d{8}$/.test(this.request.IDNumber)) {
      this.errorFormat.push('身份證字號/統編/外資法人');
    }

    if (!this.request.PurchaseCode || !/^\s*\S/.test(this.request.IDNumber)) {
      this.errorTest.push('申購驗證碼');
    }

    if (!this.request.Code || !/^\s*\S/.test(this.request.IDNumber)) {
      this.errorTest.push('驗證碼');
    }

    if (!this.agreeChecked) {
      this.errorTest.push('個人資料蒐集、處理及利用告知事項');
    }

    if (this.errorTest.length || this.errorFormat.length) {
      this.visible = true;
      this.visibleType = '';
      this.dialogData.title = '提醒'
      this.privateType = 0;
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '為必填欄位。<br>'
      if (this.errorFormat.length) this.dialogData.message = this.dialogData.message + this.errorFormat.toString() + '欄位格式錯誤。'
    } else this.submit();
  }

  submit() {
    this.purchaseService.PurchaseSearch(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        if (this.isBrowser) document.getElementById('reset')?.click();
        this.isSubmit = true;
        this.inquireRes = res.Entries;
      } else {
        this.dialogOpen(res.Message);
      }
      this.GetCaptchaImg();
    })
  }


  goBack() {
    this.isSubmit = false;
    this.GetTime();
  }

  submitCancel() {
    this.purchaseService.PurchaseCancle(this.cancelReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.isSubmit = false;
        this.dialogOpen(res.Entries);
      } else this.dialogOpen(res.Message);
      this.mainData$.loading$.next(false);
    })
  }

  visibleChange(e: any, type: number) {
    this.visible = false;
    this.confirmVisible = false;
    if (type == 1) {
      if (e && this.visibleType === 'agreeChecked')
        this.agreeChecked = true;
      this.disableagreeChecked = false;
    } else {
      if (e) {
        this.cancelReq = {
          // FundNo: this.request.FundNo,
          // IDNumber: this.inquireRes.IDNumber,
          OrderNumber: this.inquireRes.OrderNumber,
        }
        this.submitCancel();
      }
    }
  }
  confirmDialogOpen() {
    this.confirmVisible = true;
    this.privateType = 0;
    this.dialogConfirmData.title = '提醒'
    this.dialogConfirmData.data = {
      ButtonStatus: 3
    }
    this.dialogConfirmData.message = '是否確認取消？'
  }

  dialogOpen(message: string, type?: string) {
    this.visibleType = '';
    this.visible = true;
    this.privateType = 0;
    this.dialogData.message = message;
    this.dialogData.title = '提醒'
    if (type == 'agreeChecked') {
      this.visibleType = type;
      this.dialogData.title = '個人資料蒐集、處理及利用告知事項'
      this.privateType = 1;
    }
  }

  maskIDNumber(value: string): string {
    if (!value || value.length < 8) {
      return value; // 如果字串太短，不進行隱碼
    }
    const visibleStart = value.slice(0, 4);
    const visibleEnd = value.slice(-3);
    const maskedSection = '*'.repeat(value.length - 7);
    return `${visibleStart}${maskedSection}${visibleEnd}`;
  }

  goTop() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

  }
  unCheck(e: any) {
    // this.router.navigate(['/']);
  }
}
