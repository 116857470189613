import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, PLATFORM_ID
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { banner, product, news } from '../../shared/models/home';
import { HomeService } from '../../shared/services/home.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Popup, DialogData, PopupReq } from '../../shared/models/base';
import { BaseService } from '../../shared/services/base.service';
import * as Highcharts from 'highcharts';
import { mainData } from '../../shared/interface/main-data';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  isBrowser: boolean;
  banners: banner[] = [];
  products: product[] = [];
  news: news[] = [];
  dialogData: DialogData[] = [];
  visible = false;
  noagree = false;
  popupData: Popup[] = [];
  dialogDataDisclaimer: DialogData = {} as DialogData;
  popupDataDisclaimer: Popup = {} as Popup;
  UpdateTimeDisclaimer: Popup = {} as Popup;
  Disclaimer = false;
  homeMsgDate = '';
  agreeTimes = 0;
  agreepupCId: number[] = [];
  homeMsgUpdate = '';
  UpdateTime: Popup[] = [];
  url = ''
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  PopupReq = new PopupReq;
  IsPreview = false;
  chartOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      data: [1, 2, 3],
      type: 'line',
      dataLabels: {
        enabled: false
      },
    }],
    legend: {
      enabled: false
    },
    colors: ['#C9191D'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          display: 'none',
          fontSize: '0.4em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 0,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.4em',
        },
      },
      lineColor: '#fff',
      lineWidth: 0,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        turboThreshold:0
      }
    },
    // tooltip: {
    //   formatter: function () {
    //     return this.y + ' ';
    //   }
    // },
    credits: {
      enabled: false
    }
  }; // required

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public homeService: HomeService,
    private router: Router,
    public baseService: BaseService,
    public mainData$: mainData,
    public aritcleService: AritcleService,
    private route: ActivatedRoute,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.GetBanner();
    this.GetHomeProductNews();
    this.GetHomeNews();
    this.Getnews();
    this.url = '/notice/bulletin-detail'
    this.homeMsgDate = localStorage.getItem('homeMsg') || ''
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((value) => {
      this.PopupReq.IsPreview = this.route.snapshot.queryParams["isPreview"] ? true : false;
      this.Disclaimer = this.route.snapshot.queryParams["Disclaimer"] ? true : false;
      if (this.route.snapshot.queryParams["isPreview"]) this.IsPreview = true;
      this.PopupReq.CId = Number(this.route.snapshot.queryParams["CId"]) || 0;
      // 首頁警語
      if (this.Disclaimer) {
        this.GetTermsMaintenance();
      }
      else {
        this.GetPopup();
      }
    })
  }

  OpenDialog() {
    if (this.isBrowser) this.visible = true;
    // 過濾掉this.agreepupCId中的undefined元素
    this.dialogData = this.popupData.map(val => {
      return {
        title: val.Title,
        message: val.Content,
        data: val,
      }
    });
  }

  GetPopup() {
    this.baseService.GetPopup(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupData = res.Entries;
        this.UpdateTime = this.popupData.sort(function (a, b) {
          let timeA: string = '';
          let timeB: string = '';
          timeA = a.UpdateTime;
          timeB = b.UpdateTime;

          if (timeA < timeB) {
            return 1;
          }
          if (timeA > timeB) {
            return -1;
          }
          return 0;
        })
        this.homeMsgUpdate = localStorage.getItem('homeMsgUpdate') || ''
        if (!this.homeMsgDate|| (!this.homeMsgDate || this.baseService.getTimeout0hour(this.homeMsgDate)) || (this.UpdateTime.length && this.homeMsgUpdate !== this.UpdateTime[0].UpdateTime)) {
          this.OpenDialog();
        }
      }
    });
  }

  OpenDialogDisclaimer() {
    // 免責在首頁僅供預覽
    if (this.isBrowser) this.visible = true;
    this.dialogDataDisclaimer = {
      title: this.popupDataDisclaimer.Title,
      message: this.popupDataDisclaimer.Content,
      data: this.popupDataDisclaimer,
    };
  }

  GetTermsMaintenance() {
    // 免責在首頁僅供預覽
    this.baseService.GetTermsMaintenance(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupDataDisclaimer = res.Entries;
        this.OpenDialogDisclaimer();
      }
    });
  }

  visibleChange(e: any) {
    if (e) this.agreeTimes = this.agreeTimes + 1;
    if (this.agreeTimes == this.popupData.length || (this.agreeTimes === 3)) {
      localStorage.setItem('homeMsg', new Date().toString());
      if (this.UpdateTime.length) localStorage.setItem('homeMsgUpdate', this.UpdateTime[0].UpdateTime)
    }
  }



  GetBanner() {
    this.homeService.GetBanner().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.banners = res.Entries;
      }
    });
  }

  GetHomeProductNews() {
    this.homeService.GetHomeProductNews().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.products = res.Entries;
        this.products = this.products.map(val => {
          let _xAxis: string[] = []
          let _xYxis: string
          val.FundNetValueList.map(x => {
            _xAxis.push(x.DataDt)
          })
          return {
            ...val,
            chartOptions: {
              ...this.chartOptions,
              xAxis: {
                categories: _xAxis,
                align: 'center',
                labels: {
                  style: {
                    fontSize: '0.4em',
                    transform: 'rotate(0deg)',
                    fontWeight: 'bolder',
                    padding: '10px'
                  },
                },
                lineWidth: 0
              },
              series: [{
                enabled: false,
                data: val.FundNetValueList.map(x => {
                  return {
                    y: x.NetValue,
                    _xYxis: x.NetValueStr
                  }
                }),
                type: 'line',
                dataLabels: {
                  enabled: true
                },
              }],
              tooltip: {
                formatter: function (): string { // 這裡指定返回類型為 string
                  return (this as any).point._xYxis + ' ';
                }
              },
            }
          }
        })
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetHomeNews() {
    this.homeService.GetHomeNews().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.news = res.Entries;
      }
    });
  }

  Getnews() {
    this.newsres.FunctionID = 7101;
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.newslist.Data = res.Entries.Data.slice(0, 5);
        // this.newslist.Data = res.Entries.slice(0,5);
      //  console.log(this.newslist)
      }
      this.mainData$.loading$.next(false);
    });
  }

  goPage(url: string) {
    this.router.navigateByUrl(url);
  }

  unCheck(e: any) {
   // this.noagree = true;
  }

  getLink(data: any): string {
    switch (data.CLinkType) {
      case 1:
        return data.CLinkUrl;
      case 2:
        return data.CLinkFile;
      case 3:
        return `${this.url}/news${data.CId}`;
      case null:
        return `${this.url}/news${data.CId}`;
      default:
        return `${this.url}/news${data.CId}`;
    }
  }

  getTarget(data: any): string {
    switch (data.CLinkType) {
      case 1:
      case 3:
      case null:
        return data.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }
}
