import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from 'src/app/services/service-base';
import { LibHttpUrl } from '../../api/public-api';
import { CIdData, PageReq, ResponseBase } from '../models/base';
import { banner, product, news, footercontent, HeaderCommunity,MetaData, footernumber, FooterEditContent } from '../../shared/models/home';

@Injectable({
    providedIn: 'root'
})
export class HomeService extends ServiceBase {
    protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Menu}`;
    socialIcon:HeaderCommunity[]=[]
    constructor(
        http: HttpClient
    ) {
        super(http);
    }

    GetBanner(): Observable<ResponseBase<banner[]>> {
        const url = `${this.baseUrl}/GetBanner`;
        return this.http.get<ResponseBase<banner[]>>(
            url,
            this.httpOptions);
    }

    GetHomeProductNews(): Observable<ResponseBase<product[]>> {
        const url = `${this.baseUrl}/GetHomeProductNews`;
        return this.http.get<ResponseBase<product[]>>(
            url,
            this.httpOptions);
    }

    GetHomeNews(): Observable<ResponseBase<news[]>> {
        const url = `${this.baseUrl}/GetHomeNews`;
        return this.http.get<ResponseBase<news[]>>(
            url,
            this.httpOptions);
    }
    //footer警語
    GetFooterContent(request: footernumber): Observable<ResponseBase<footercontent[]>> {
        const url = `${this.baseUrl}/GetFooterContent`;
        return this.http.post<ResponseBase<footercontent[]>>(
            url,
            request,
            this.httpOptions);
    }

    //取得社群icon
    GetHeaderCommunity(): Observable<ResponseBase<HeaderCommunity[]>> {
        const url = `${this.baseUrl}/GetHeaderCommunity`;
        return this.http.get<ResponseBase<HeaderCommunity[]>>(
            url,
            this.httpOptions);
    }

    //banner儲存點擊次數
    SaveClickCount(request: number): Observable<ResponseBase<string>> {
        const url = `${this.baseUrl}/SaveClickCount`;
        let data = {
            Id:request
        }
        return this.http.post<ResponseBase<string>>(
            url,
            data,
            this.httpOptions);
    }

    //全站SEO
    GetMetaData(): Observable<ResponseBase<MetaData>> {
        const url = `${this.baseUrl}/GetMetaData`;
        return this.http.get<ResponseBase<MetaData>>(
            url,
            this.httpOptions);
    }

    //取得Footer可編輯內容
    GetFooterEditContent(): Observable<ResponseBase<FooterEditContent>> {
      const url = `${this.baseUrl}/GetFooterEditContent`;
      return this.http.get<ResponseBase<FooterEditContent>>(
          url,
          this.httpOptions);
    }
}
