import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, Input, OnInit, PLATFORM_ID
} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { mainData } from '../../interface/main-data';
import * as Highcharts from 'highcharts';
import { Router} from '@angular/router';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
})
export class ProductCarouselComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Input() chartOptions: Highcharts.Options = {} as Highcharts.Options;
  @Input() name: string = '';
  web: boolean = true;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 6000,
    navText: ['', ''],
    merge: true,
    margin: 20,
    autoHeight: false,
    responsive: {
      //在特定寬度下顯示幾張圖
      1200: {
        items: 3,
      },
      820: {
        items: 2,
      },
      0: {
        items: 1,
      },
    },
  };
  slidesStore: Array<any> = [];
  isBrowser: boolean;

  isHighcharts = typeof Highcharts === 'object';
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  // chartOptions: Highcharts.Options = {
  //   title: {
  //     text:  ``,
  //     style: {
  //       fontSize: '18px',
  //       fontWeight: 'bold',
  //       color: '#414141',
  //     },
  //     verticalAlign: 'middle'
  //   },
  //   series: [{
  //     data: [1, 2, 3],
  //     type: 'line',
  //     dataLabels: {
  //       enabled: false
  //     },
  //   }],
  //   tooltip: {
  //     formatter: function () {
  //       return '<b>' + this.point.name + '佔比</b>: ' + this.y + ' %';
  //     }
  //   },
  // }; // required
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = true;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
  }

  // 圖片輪播時觸發的事件
  getData(e: any) {
    // console.log('$event',e);
  }

  //是否為負數
  getNegativeValue(value:number) {
    return value < 0
  }

  //是否為正數
  getPositiveValue(value:number) {
    return value > 0
  }


  gobuy(e: any) {
    if(!e.IsETF) {
      this.router.navigate([`/fund-info/${e.FundNo}`], {
        queryParams: {
          tab: 1
        }
      });
    } else {
      this.router.navigate([`/etf/etf-info/${e.FundNo}`], {
        queryParams: {
          tab: 1
        }
      });
    }
  }

}
