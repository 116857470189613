import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { Router, ActivatedRoute } from '@angular/router';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss']
})
export class UnitListComponent {
  Type = ''; //Type1列表;Type2圖文;Type3為影音
  FId = 0;
  isPreview = false; //是否為預覽
  aritcleReq = new ResponseArticle;
  aritcleList: Articledata = {} as Articledata;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public aritcleService: AritcleService
  ) {
  }
  ngOnInit(): void {
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      this.Type = params['Type'];
      this.FId = Number(params['FId']);
      if(params['isPreview']) this.isPreview = params['isPreview']
      this.aritcleReq.FunctionID = this.FId;
    });
  }
}
