import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Category, FundCategory } from 'src/app/shared/models/category';
import { FundCategoryReq, FundComposition, FundNetValueList, FundOverviewList, FundRecordDate, FundYieldList, FundYieldReq } from 'src/app/shared/models/fund';
import { BaseService } from 'src/app/shared/services/base.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { FundService } from 'src/app/shared/services/fund.service';
import { mainData } from '../../../shared/interface/main-data';

@Component({
  selector: 'app-dividend-list',
  templateUrl: './dividend-list.component.html',
  styleUrls: ['./dividend-list.component.scss']
})
export class DividendListComponent {
  categoryData: Category[] = [];
  fundCategoryList: FundCategory[] = [];
  requests: FundYieldReq = {} as FundYieldReq;
  fundOverviewList: FundOverviewList[] = [];
  fundNetValueList: FundNetValueList[] = [];
  fundYieldList: FundYieldList[] = [];
  FundComposition: FundComposition[] = [];
  FundRecordDate:FundRecordDate[]=[];
  TotalItems = 0;
  tabActive = 1;
  isSubmit = false; //是否搜尋過
  tabList = [
    {
      id:1,
      name:'基金配息',
    },{
      id:2,
      name:'配息組成',
    },{
      id:3,
      name:'配息基準日',
    }
  ]
  currPath = '';
  constructor(
    public categoryService: CategoryService,
    public fundService: FundService,
    public baseService: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    public mainData$: mainData,
  ) {
    this.requests.PageSize = 10;
    this.requests.PageIndex = 1;
    this.GetOverviewHotKeyword();
    if(!this.categoryService.fundYieldCategoryList.length)this.GetSearchFundCategory();
    this.route.url.subscribe(url => {
      this.currPath = url[0].path;
      if(this.currPath == 'dividend-element') this.tabActive = 2;
      else if(this.currPath == 'reference-date') this.tabActive = 3;
      else this.tabActive = 1;
      this.submit();
    });
    // this.GetNewesGetFundRecordDaten();
  }

  tabActiveChange(id:number) {
    this.tabActive = id;
    if(this.tabActive == 2) this.router.navigate(['product/dividend-element'])
    else if(this.tabActive == 3) this.router.navigate(['product/reference-date'])
    else this.router.navigate(['product/yield-info'])
  }

  submit(e?: any) {
    this.requests.Keyword = e?.Keyword || '';
    this.requests.TypeIDs = [];
    this.requests.AreaIDs = [];
    this.requests.RiskLvIDs = [];
    this.requests.DividendIDs = [];
    this.requests.CurrencyTypeIDs = [];
    this.requests.YearList = [];
    e?.data.filter((val: any) => { if (val.CodeType == 1) this.requests.TypeIDs.push(val.CategoryID) })
    e?.data.filter((val: any) => { if (val.CodeType == 8) this.requests.AreaIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 11) this.requests.RiskLvIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 7) this.requests.DividendIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 9) this.requests.CurrencyTypeIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 19) this.requests.YearList.push(val.CategoryID) });
    this.getSubmit();
  }

  getSubmit() {
    if(this.tabActive == 1)this.GetNewestFundYieldList();
    else if(this.tabActive == 2)this.GetNewestGetFundComposition();
    else if(this.tabActive == 3)this.GetNewesGetFundRecordDaten();
  }

  more() {
    this.requests.PageSize = this.requests.PageSize + 10;
    this.getSubmit();
  }

  GetOverviewHotKeyword() {
    this.categoryService.GetOverviewHotKeyword({Page: 1}).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.categoryData = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetSearchFundCategory() {
    this.categoryService.GetSearchFundCategory(2).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.categoryService.fundYieldCategoryList = res.Entries;
      }
    });
  }

  GetNewestFundYieldList() {
    this.fundService.GetNewestFundYieldList(this.requests).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundYieldList = res.Entries;
        this.TotalItems = res.TotalItems;
      }
      this.mainData$.loading$.next(false);
      this.isSubmit = true;
    });
  }
  GetNewestGetFundComposition() {
    this.fundService.GetFundComposition(this.requests).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundComposition = res.Entries;
        if(this.FundComposition){
          this.FundComposition = this.FundComposition.map(item => {
            const numericYear = Number(item.Year);  // 首先將年份從字串轉換成數字
            const republicYear = numericYear - 1911; // 將西元年份轉換為民國年份
            return {
              ...item,
              Year: republicYear.toString()  // 最後將民國年份轉換回字串
            };
          });
        }
        this.TotalItems = res.TotalItems;
      }
      this.isSubmit = true;
      this.mainData$.loading$.next(false);
    });
  }

  GetNewesGetFundRecordDaten() {
    this.fundService.GetFundRecordDate(this.requests).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundRecordDate = res.Entries;
        this.TotalItems = res.TotalItems;
      }
      this.isSubmit = true;
      this.mainData$.loading$.next(false);
    });
  }

  goBuy(no: string) {
    this.router.navigate(['fund-info/' + no], {
      queryParams: {
        tab: 4
      }
    })
  }

  getLength() {
    if(this.tabActive == 1) return this.fundYieldList.length;
    else if(this.tabActive == 2) return this.FundComposition.length;
    else return this.FundRecordDate.length;
  }

}
