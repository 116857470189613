import { Component, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import {
  TradeInfoData
} from '../../../../shared/models/etf';
@Component({
  selector: 'app-etf-pcf',
  templateUrl: './etf-pcf.component.html',
  styleUrls: ['./etf-pcf.component.scss']
})
export class EtfPcfComponent {
  SDate = ''
  @Output() dateChange = new EventEmitter<any>();
  @Input() data:TradeInfoData = {} as TradeInfoData;

  GetSearch() {
    if(this.SDate) this.dateChange.emit(moment(this.SDate).format("YYYY-MM-DD"));
    else this.dateChange.emit(null)
  }

  addThousandsSeparator(numStr: string): string {
    // 先將字串轉換成數字
    const num = parseFloat(numStr);

    // 如果不是有效的數字，直接返回原字串
    if (isNaN(num)) {
        return numStr;
    }

     // 將數字轉換成千分位格式的字串，並保留原始的小數位數
    return num.toLocaleString(undefined, { minimumFractionDigits: numStr.split('.')[1]?.length });
   }
}
