import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { DocReq, DocTypelist, DoclistReq, DoclistRes } from 'src/app/shared/models/docDownload';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { DocDownloadService } from 'src/app/shared/services/docDownload.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { mainData } from '../../../shared/interface/main-data';

@Component({
  selector: 'app-stewardship',
  templateUrl: './stewardship.component.html',
  styleUrls: ['./stewardship.component.scss']
})
export class StewardshipComponent {
  Doclistreq: DoclistReq = {} as DoclistReq;
  doclist: DoclistRes[] = [];
  Docreq: DocReq = {} as DocReq;
  doctype: DocTypelist[] = [];
  selectedCategoryId = 0;
  activeIndex: number = 0;
  isActiveMainId = -1;
  isActiveSubId = -1;
  constructor(
    public homeService: HomeService,
    public baseService: BaseService,
    public docDownloadService: DocDownloadService,
    public mainData$: mainData
  ) {
    this.Doclistreq = {
      CCategoryMainID: -1,
      CCategorySubID: -1,
      CodeType: 20,
    }
    this.Docreq = {
      CodeType :20,
    }

    // this.GetDoclist()
    this.GetDoctype()
  }

  GetDoclist() {
    this.Doclistreq.CCategoryMainID = this.isActiveMainId;
    this.Doclistreq.CCategorySubID = this.isActiveSubId;
    this.docDownloadService.GetDocList(this.Doclistreq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.doclist = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  selectChange(e:any){
    this.isActiveMainId = e.value.CId;
    this.GetDoclist();
  }

  GetDoctype() {
    this.docDownloadService.GetDocTypes(this.Docreq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.doctype = res.Entries;
        this.doctype.forEach(item => {
          if (item.ChildTypes && item.ChildTypes.length > 0) {
            // 添加全部
            item.ChildTypes.unshift({
              ChildTypes:[],
              CId: -1,
              CTcTypeId: -1,
              CIsExtraAdd: false,
              CName: '全部',
              CSort: 0,
              CIsNeedSecondTcType: false,
              CSecondTcTypeId: '',
              CIsDelete: '',
              CCreateDt: '',
              CCreator: '',
              CUpdateDt: '',
              CUpdator: '',
              CUrl: '',
              CContent: '',
              CNote: '',
              CIsEnable: false,
              CIdnumber: ''
            });
          }
        });
        this.activeIndexChange(0)
      }
    });
  }

  activeIndexChange(e:any) {
    this.isActiveSubId = -1;
    this.isActiveMainId = this.doctype[e].CId;
    this.GetDoclist();
  }

  getCategorySubID(data:any) {
    this.isActiveSubId = data.CId;
    this.GetDoclist();
  }
}
