<main>
    <h2 class="sub-title sub-title-first"><span *ngIf="DateName">{{DateName}}</span><span *ngIf="this.FundNAVReq.SDate && FundNAVReq.EDate&&!DateName">{{this.FundNAVReq.SDate| date:'yyyy/MM/dd'}} ~ {{this.FundNAVReq.EDate| date:'yyyy/MM/dd'}}</span> 淨值vs收盤價</h2>
    <div class="search-wrap">
        <div class="calendar-item">
            <label>開始時間</label>
            <div class="calendar-wrap">
                <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
                <p-calendar class="calendar" dateFormat="yy/mm/dd"  [(ngModel)]="FundNAVReq.SDate" placeholder="年/月/日"></p-calendar>
            </div>
        </div>
        <div class="calendar-item">
            <label>結束時間</label>
            <div class="calendar-wrap">
                <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
                <p-calendar class="calendar" dateFormat="yy/mm/dd"  [(ngModel)]="FundNAVReq.EDate" placeholder="年/月/日"></p-calendar>
            </div>
        </div>
        <button (click)="submitCheck()">查詢</button>
    </div>
    <div class="button-wrap">
        <button *ngFor="let data of enumDateList" [ngClass]="{'btn-active': DateName == data.Name}"
            (click)="getDateName(data)">{{data.Name}}</button>
    </div>

    <!-- hightChart -->
    <div class="highcharts" *ngIf="data">
        <div>
            <div class="nav-item">
                <span>期間淨值最高：{{data.QHighestNAV }}</span>
                <span>期間淨值最低：{{data.QLowestNAV }}</span>
            </div>
            <div class="nav-item">
                <span>期間市價最高：{{data.QHighestPrice }}</span>
                <span>期間市價最低：{{data.QLowestPrice }}</span>
            </div>
        </div>
        <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
            [options]="chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
          </div>
          <div class="nodata" *ngIf="!this.data?.DataList?.length">該區間查無資料！</div>
            <div style="padding-top: 20px;" [innerHTML]="FundsSplitRemark|html" *ngIf="FundsSplitRemark"></div>
    <h2 class="sub-title"><span *ngIf="DateName">{{DateName}}</span><span *ngIf="this.FundNAVReq.SDate && FundNAVReq.EDate&&!DateName">{{this.FundNAVReq.SDate| date:'yyyy/MM/dd'}} ~ {{this.FundNAVReq.EDate| date:'yyyy/MM/dd'}}</span> 折溢價</h2>

    <div class="highcharts">
        <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
            [options]="chart2Options" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
          </div>
          <div class="nodata" *ngIf="!this.data?.DataList?.length">該區間查無資料！</div>
    <h2 class="sub-title"><span *ngIf="DateName">{{DateName}}</span><span *ngIf="this.FundNAVReq.SDate && FundNAVReq.EDate&&!DateName">{{this.FundNAVReq.SDate| date:'yyyy/MM/dd'}} ~ {{this.FundNAVReq.EDate| date:'yyyy/MM/dd'}}</span> 淨值與收盤價</h2>
    <table class="etf-net">
        <thead>
          <tr>
            <th>日期</th>
            <th>淨值</th>
            <th>收盤價</th>
            <th>折溢價</th>
            <th>折溢價幅度%</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataup.DataList">
            <th>{{item.CDataDt}}</th>
            <td>{{item.CNetValueStr}}</td>
            <td>{{item.CPriceStr}}</td>
            <td [ngClass]="{'pValue': item.CPremDiscount > 0,'nValue': 0 > item.CPremDiscount}">{{item.CPremDiscountStr}}</td>
            <td [ngClass]="{'pValue': item.CPremDiscount > 0,'nValue': 0 > item.CPremDiscount}">{{item.CPremDiscountChangeRatio}}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-mob">
        <thead>
          <tr *ngFor="let item of dataup.DataList">
            <th>日期
                <span>{{item.CDataDt}}</span>
            </th>
            <th>淨值
                <span>{{item.CNetValueStr}}</span>
            </th>
            <th>收盤價
                <span>{{item.CPriceStr}}</span>
            </th>
            <th>折溢價
                <span [ngClass]="{'pValue': item.CPremDiscount > 0,'nValue': 0 > item.CPremDiscount}">{{item.CPremDiscountStr}}</span>
            </th>
            <th>折溢價幅度%
                <span [ngClass]="{'pValue': item.CPremDiscount > 0,'nValue': 0 > item.CPremDiscount}">{{item.CPremDiscountChangeRatio}}</span>
            </th>
          </tr>
        </thead>
    </table>

    <div class="note-wrap">
        ※所有基金績效，均為過去績效，不代表未來之績效表現，亦不保證基金之最低投資收益。 基金淨值僅供參考，實際以基金公司公告之淨值為準。
    </div>
</main>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" (visibleChange)="visibleChange($event)" [privateType]="0"></app-message-dialog>
