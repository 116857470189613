<div class="search">
    <div class="keyword-wrap">
        <label *ngIf="baseService.Web$.value">搜尋基金</label>
        <input [(ngModel)]="fundService.Keyword" placeholder="請輸入基金名稱">
        <button class="search-btn" (click)="submit(1)">
            <img class="search-icon" alt="搜尋" src="/assets/images/white_search_icon.svg" />
            搜尋
        </button>
    </div>
    <div class="hotKeyword" *ngIf="baseService.Web$.value">
        <label>熱門搜尋</label>
        <div class="category-btns">
            <button *ngFor="let category of hotKeyword" class="fund-btn" [ngClass]="{'fund-btn-active': fundService.KeywordId === category.CategoryID}" (click)="getHotKeyword(category.Name,category.CategoryID)">{{category.Name}}</button>
        </div>
    </div>
    <button class="filter-btn" (click)="close()">
        <img class="filter-icon" src="/assets/images/filter-icon.svg" alt="篩選" />
        <span>篩選</span>
    </button>
</div>
<div class="fund-type" id="fund-type" [ngClass]="{'fund-close': !fundService.searchOpen}">
    <div class="fund-title">
        <span>基金篩選</span>
        <img class="close-icon" alt="基金篩選" (click)="close()" src="/assets/images/close.svg"/>
    </div>
    <div class="fund-list-wrap">
      <div class="fund-list" *ngIf="hotKeyword.length&&!baseService.Web$.value">
        <label>熱門搜尋</label>
        <button *ngFor="let category of hotKeyword"
        class="fund-btn"
        [ngClass]="{'fund-btn-active':isHotKeywordSelected(category.CategoryID)}"
         (click)="getHotKeyword(category.Name,category.CategoryID)">
         {{category.Name}}</button>
      </div>
        <div class="fund-list" *ngFor="let categorys of fundCategory">
            <label>{{categorys.CodeName}}</label>
            <div class="fund-btns">
                <button
                    class="fund-btn"
                    [ngClass]="{'fund-btn-active': isSelectedAllCategory(categorys)}"
                    (click)="getAllCategory(categorys)">
                    全部
                </button>
                <button
                    *ngFor="let category of categorys.CategoryList"
                    class="fund-btn"
                    [ngClass]="{'fund-btn-active': isSelectedCategory(category)}"
                    (click)="getCategory(category)">
                    {{category.Name}}
                </button>
            </div>
        </div>
    </div>
    <div class="clear-wrap">
        <button class="clear-btn" (click)="clearAll()">
            <img class="clear-icon" alt="全部清除篩選" src="/assets/images/clear-all.svg" />
            <span>全部清除</span>
        </button>
        <button class="search-btn" (click)="submit(1)">
            搜尋 (共{{TotalItems | number}})
        </button>
    </div>
</div>

<button *ngIf="baseService.Web$.value" class="arrow-btn" (click)="close()">
    {{fundService.searchOpen ? '收合' : '展開'}}基金搜尋
    <div class="top-arrow" [ngClass]="{'arrow-close':fundService.searchOpen}"></div>
</button>

<div class="selected-list">
    <span *ngIf="baseService.Web$.value" class="selected-test">已選條件({{fundService.selectedItem.length}})</span>
    <span *ngIf="baseService.Web$.value" class="selected-test selected-result">相關結果共有<span class="pValue">{{TotalItems | number}}</span>檔</span>
    <div class="selecteds">
        <button class="selected-btn" *ngFor="let category of fundService.selectedItem">
            <img (click)="removeCategory(category)" class="close-icon" alt="清除篩選" src="/assets/images/close.svg" />
            <span>{{category.Name}}</span>
        </button>
    </div>
    <button *ngIf="baseService.Web$.value" class="clear-btn" (click)="clearAll()">
        <img class="clear-icon" alt="全部清除篩選" src="/assets/images/clear-all.svg" />
        <span>全部清除</span>
    </button>
</div>
