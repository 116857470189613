import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { DocReq, DocTypelist, DoclistReq, DoclistRes } from 'src/app/shared/models/docDownload';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { DocDownloadService } from 'src/app/shared/services/docDownload.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { Popup, DialogData, PopupReq } from '../../../../shared/models/base';
import { mainData } from '../../../../shared/interface/main-data';

@Component({
  selector: 'app-etf-classroom',
  templateUrl: './etf-classroom.component.html',
  styleUrls: ['./etf-classroom.component.scss']
})
export class EtfClassroomComponent {
  isBrowser: boolean;
  searchOpen = true;
  newsres = new ResponseArticle;
  vediosres = new ResponseArticle;
  docDownloads = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  vediolist: Articledata = {} as Articledata;
  TotalItems = 0;
  Doclistreq: DoclistReq = {} as DoclistReq;
  doclist: DoclistRes[] = [];
  Docreq: DocReq = {} as DocReq;
  doctype: DocTypelist[] = [];
  selectedCategoryId = 0;
  activeIndex: number = 0;
  url = ''
  isActiveSubId = -1;

  //警語彈窗
  dialogData: DialogData[] = [];
  visible = false;
  popupData: Popup[] = [];
  UpdateTime: Popup[] = [];
  ETFMsgDate = '';
  ETFMsgUpdate = '';
  agreeTimes = 0;
  PopupReq = new PopupReq;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public docDownloadService: DocDownloadService,
    private route: ActivatedRoute,
    private router: Router,
    public mainData$: mainData
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   // this.Getnews()
   // this.Getvedio()
    this.Doclistreq = {
      CCategoryMainID: -1,
      CCategorySubID: -1,
      CodeType: 49,
    }
    this.Docreq = {
      CodeType: 49
    }

    this.GetDoclist();
    this.GetDoctype();
    this.newsres.FunctionID = 7318;
    this.vediosres.FunctionID = 7312;
    this.docDownloads.FunctionID = 7325;
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'classroomt') {
        this.activeIndex = 0;
      } else if (currentPath === 'video') {
        this.activeIndex = 1;
      }
      else if (currentPath === 'intro') {
        this.activeIndex = 2;
      }
    });

    // ETF警語
    this.ETFMsgDate = localStorage.getItem('ETFMsg') || ''
    this.GetPopup();
  }



  Getnews() {
    //ETF小教室
    this.newsres.FunctionID = 7318;
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.newslist = res.Entries;
      }
    });
  }

  Getvedio() {
    //ETF影音
    this.newsres.FunctionID = 7312;
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.vediolist = res.Entries;
      }
    });
  }

  GetDoclist() {
    this.docDownloadService.GetDocList(this.Doclistreq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.doclist = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  selectChange(e: any) {
    if (this.activeIndex == 0) {
      this.router.navigate(['etf/classroom'])
    }
    else if (this.activeIndex == 1) {
      this.router.navigate(['etf/video'])
    }
    else if (this.activeIndex == 2) {
      this.router.navigate(['etf/intro'])
    }
  }

  GetDoctype() {
    this.docDownloadService.GetDocTypes(this.Docreq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.doctype = res.Entries;
      }
    });
  }

  navigateToRoute(route: string) {
    this.router.navigate([route]);
  }

  getCategorySubID(data:any) {
    this.isActiveSubId = data.CId;
    this.GetDoclist();
  }

  GetPopup() {
    this.baseService.GetPopup(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupData = res.Entries;
        this.UpdateTime = this.popupData.sort(function (a, b) {
          let timeA: string = '';
          let timeB: string = '';
          timeA = a.UpdateTime;
          timeB = b.UpdateTime;

          if (timeA < timeB) {
            return 1;
          }
          if (timeA > timeB) {
            return -1;
          }
          return 0;
        })
        this.ETFMsgUpdate = localStorage.getItem('ETFMsgUpdate') || ''
        if ((!this.ETFMsgDate || this.baseService.getTimeout0hour(this.ETFMsgDate))|| (this.UpdateTime.length && this.ETFMsgUpdate !== this.UpdateTime[0].UpdateTime)) {
          this.OpenDialog();
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  OpenDialog() {
    if(this.isBrowser)this.visible = true;
    this.dialogData = this.popupData.map(val => {
      return {
        title: val.Title,
        message: val.Content,
        data: val
      }
    });
  }

  visibleChange(e:any) {
    if(e) this.agreeTimes = this.agreeTimes + 1;
    if(this.agreeTimes == this.popupData.length || (this.agreeTimes === 3)) {
      localStorage.setItem('ETFMsg', new Date().toString());
      if (this.UpdateTime.length) localStorage.setItem('ETFMsgUpdate', this.UpdateTime[0].UpdateTime)
    }
  }
}
