import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { mainData } from '../../shared/interface/main-data';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { MenuService } from '../../shared/services/Menu.service';
import { SearchReq, HotKeywords, SearchRes, Tab } from '../../shared/models/menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-search',
  templateUrl: './all-search.component.html',
  styleUrls: ['./all-search.component.scss']
})
export class AllSearchComponent {
  activeIndex: number = 0;
  request:SearchReq = {} as SearchReq;
  selectedItem:HotKeywords | string = {} as HotKeywords;
  allHotKeywords:HotKeywords[] = []; //所有關鍵字
  hotKeywords:HotKeywords[] = []; //篩選後關鍵字
  SearchData:SearchRes = {} as SearchRes;
  TotalItems = 0;
  SearchTabs:Tab[]=[]
  selectedTab:Tab = {} as Tab;
  domain = environment.domain;
  constructor(
    private route: ActivatedRoute,
    public mainData$: mainData,
    public menuService: MenuService,
    private router: Router,
  ) {
    this.request.IsPagination = true;
    this.GetHotKeywords();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((value) => {
      this.request.Keyword = this.route.snapshot.queryParams["key"] || '';
      this.request.PageIndex = 1;
      this.request.PageSize = 10;
      this.request.FunctionID = -1;
      this.selectedItem = {
        CId: 0,
        CName: this.request.Keyword,
        CSort: 0,
      };
      if(this.selectedItem.CName =='[object Object]'){
        this.selectedItem.CName ='';
      }
      this.GetHomeSearch();
    })
  }

  GetHomeSearch(more?:boolean) {
    this.menuService.GetHomeSearch(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        if(more) {
          this.SearchData = {
            ...this.SearchData,
            Data: [
              ...this.SearchData.Data,
              ...res.Entries.Data
            ]
          }
        } else {
          if(!this.SearchTabs.length) this.SearchTabs = res.Entries.Tab;
          this.SearchData = res.Entries;
        }
        this.TotalItems = res.Entries.DataTotalItems;
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetHotKeywords() {
    this.menuService.GetHotKeywords().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.hotKeywords = res.Entries;
        this.allHotKeywords = res.Entries;
      }
    });
  }

  getKeywords(e:any) {
    this.request.Keyword = e.CName
    if(this.route.snapshot.queryParams["key"] !== this.request.Keyword)this.SearchTabs = [];
    this.getUrl();
  }

  getSearchKeywords() {
    if(typeof(this.selectedItem) === 'string') this.request.Keyword = this.selectedItem;
    else if(typeof(this.selectedItem) === 'object') this.request.Keyword = this.selectedItem.CName;
    else this.request.Keyword = '';
    if(this.route.snapshot.queryParams["key"] !== this.request.Keyword)this.SearchTabs = [];
    this.getUrl();
  }

  getUrl() {
    this.router.navigate(['/search'], {
      queryParams: {
        key: this.request.Keyword
      }
    });
  }

  selectTabChange(e:any) {
    this.request.FunctionID = this.SearchData.Tab[e.index].CFunctionID;
    this.request.PageIndex = 1;
    this.GetHomeSearch();
  }

  search(event:any) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.allHotKeywords.length; i++) {
      let country = this.allHotKeywords[i];
      if (country.CName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.hotKeywords = filtered;
  }

  more() {
    this.request.PageIndex = this.request.PageIndex + 1;
    this.GetHomeSearch(true);
  }

  selectChange(e:any){
    if(this.request.FunctionID !== e.CFunctionID) {
      this.request.FunctionID = e.CFunctionID;
      this.request.PageIndex = 1;
      this.GetHomeSearch();
    }
  }
}
