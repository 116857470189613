import { footernumber } from './../models/home';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase, Popup, PopupReq } from '../models/base';
import { ServiceBase } from 'src/app/services/service-base';

@Injectable({
  providedIn: 'root'
})
export class BaseService extends ServiceBase {
  Web$ = new BehaviorSubject<boolean>(true);
  Accessible$ = new BehaviorSubject<boolean>(false);
  footerID$ = new BehaviorSubject<footernumber>({CId: 1 });
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Menu}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  GetPopup(data:PopupReq): Observable<ResponseBase<Popup[]>> {
    const url = `${this.baseUrl}/GetPopup`;
    return this.http.post<ResponseBase<Popup[]>>(
      url,
      data,
      this.httpOptions);
  }

  getTimeout(date:string) {
    let currentTime = new Date(date);
    currentTime.setHours(currentTime.getHours() + 24);
    return  new Date().getTime() > new Date(currentTime).getTime();
  }

  getTimeout0hour(date:string) {
    let currentTime = new Date(date);
    // 將時間設置為當天的午夜（0點）
    currentTime.setDate(currentTime.getDate() + 1);
    currentTime.setHours(0, 0, 0, 0);
   // console.log(currentTime)
    return new Date().getTime() > new Date(currentTime).getTime();
  }


  GetTermsMaintenance(data:PopupReq): Observable<ResponseBase<Popup>> {
    const url = `${this.baseUrl}/GetTermsMaintenance`;
    return this.http.post<ResponseBase<Popup>>(
      url,
      data,
      this.httpOptions);
  }
}
