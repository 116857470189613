<app-bread-crumb></app-bread-crumb>
<main>
  <app-fund-search  id="searchResults" [hotKeyword]="categoryData" [fundCategory]="fundCategoryList" (result)="submit($event)"
    [TotalItems]="TotalItems"></app-fund-search>
  <div class="tab-menu">
    <div class="tab-item" *ngFor="let item of tabList" [ngClass]="{'tab-item-active': tabActive == item.id}"
      (click)="tabActiveChange(item.id)">{{item.name}}</div>
  </div>

  <div class="fund-list" *ngIf="tabActive === 1">
    <table>
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>風險等級</th>
          <th>基金類型</th>
          <th>幣別</th>
          <th>配息頻率</th>
          <th>投資地區</th>
          <th>月報</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fundOverviewList">
          <th><a (click)="goBuy(data,1)">
            {{data.ShortName}}
            <span class="warning-text">{{data.Warning}}</span>
          </a></th>
          <td>
            <div class="dots-wrap">
              {{data.FundRiskLevelStr}}
              <div *ngIf="data.FundRiskLevelValue">
                <span *ngFor="let dot of dots;let i = index" class="risk-level-dots"
                  [ngClass]="{'risk-level-dots-red': i < data.FundRiskLevelValue}"></span>
              </div>
            </div>
          </td>
          <td>{{data.FundTypeStr}}</td>
          <td>{{data.FundCurrencyStr}}</td>
          <td>{{data.FundDividendStr}}</td>
          <td>{{data.FundAreaStr}}</td>
          <td>
            <a [href]="data.MonthlyReport" target="_blank">
              <img class="download-icon" src="/assets/images/report-download.svg" alt="月報下載" />
            </a>
          </td>
          <td>
            <a (click)="goRegister(data)" target="_blank" *ngIf="data.IsCanTrade"><button class="fund-btn">我要申購</button></a>
          </td>
        </tr>
      </tbody>
    </table>
    <p-panel class="p-panel"
      [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
      [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of fundOverviewList;let i = index">
      <ng-template pTemplate="header">
        <div (click)="goBuy(data,1)">
          {{data.ShortName}}
          <span class="warning-text">{{data.Warning}}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg" />
      </ng-template>
      <div class="panel-item">
        <div class="name">風險等級</div>
        <div class="desc">
          <div class="dots" *ngIf="data.FundRiskLevelValue">
            <span *ngFor="let dot of dots;let i = index" class="risk-level-dots"
              [ngClass]="{'risk-level-dots-red': i < data.FundRiskLevelValue}"></span>
          </div>
          {{data.FundRiskLevelStr}}
        </div>
      </div>
      <div class="panel-item">
        <div class="name">基金類型</div>
        <div class="desc">{{data.FundTypeStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">幣別</div>
        <div class="desc">{{data.FundCurrencyStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">配息頻率</div>
        <div class="desc">{{data.FundDividendStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">投資地區</div>
        <div class="desc">{{data.FundAreaStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">月報</div>
        <div class="desc">
          <a [href]="data.MonthlyReport" target="_blank">
            <img class="download-icon" src="/assets/images/report-download.svg" alt="月報下載" />
          </a>
        </div>
      </div>
      <a (click)="goRegister(data)" target="_blank" *ngIf="data.IsCanTrade">
        <button class="fund-btn">我要申購</button>
      </a>
    </p-panel>
  </div>

  <div class="fund-list" *ngIf="tabActive === 2">
    <table class="net-value">
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>基金類型</th>
          <th>幣別</th>
          <th>配息頻率</th>
          <th>日期</th>
          <th>淨值</th>
          <th>漲跌</th>
          <th>漲跌幅</th>
          <th>歷史淨值</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fundNetValueList">
          <th><a (click)="goBuy(data, data.IsETF ? 4 : 3)">
            {{data.ShortName}}
            <span class="warning-text">{{data.Warning}}</span>
          </a></th>
          <td>{{data.FundTypeStr}}</td>
          <td>{{data.FundCurrencyStr}}</td>
          <td>{{data.FundDividendStr}}</td>
          <td>{{data.FundNavLatestDate}}</td>
          <td>{{data.NetValueStr}}</td>
          <td [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}">{{data.ChangeStr}}</td>
          <td [ngClass]="{'pValue': data.ChangeRatio > 0,'nValue': 0 > data.ChangeRatio}">
            {{data.ChangeRatioStr}}</td>
          <td>
            <a [routerLink]="data.IsETF ? ('/etf/etf-info/' + data.FundNo) : ('/fund-info/' + data.FundNo)"
            [queryParams]="{tab: data.IsETF ? 4 : 3}">
              <img class="download-icon" src="/assets/images/net-value.svg" alt="歷史淨值" />
            </a>
          </td>
          <td>
            <a (click)="goRegister(data)" target="_blank" *ngIf="data.IsCanTrade">
              <button class="fund-btn">我要申購</button>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <p-panel class="p-panel"
      [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
      [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of fundNetValueList;let i = index">
      <ng-template pTemplate="header">
        <div (click)="goBuy(data.FundNo, data.IsETF ? 4 : 3)">
          {{data.ShortName}}
          <span class="warning-text">{{data.Warning}}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg" />
      </ng-template>
      <div class="panel-item">
        <div class="name">基金類型</div>
        <div class="desc">
          {{data.FundTypeStr}}
        </div>
      </div>

      <div class="panel-item">
        <div class="name">幣別</div>
        <div class="desc">{{data.FundCurrencyStr}}</div>
      </div>

      <div class="panel-item">
        <div class="name">配息頻率</div>
        <div class="desc">{{data.FundDividendStr}}</div>
      </div>

      <div class="panel-item">
        <div class="name">日期</div>
        <div class="desc">{{data.FundNavLatestDate}}</div>
      </div>

      <div class="panel-item">
        <div class="name">淨值</div>
        <div class="desc">{{data.NetValueStr}}</div>
      </div>

      <div class="panel-item">
        <div class="name">漲跌</div>
        <div class="desc" [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}">{{data.ChangeStr}}
        </div>
      </div>

      <div class="panel-item">
        <div class="name">漲跌幅</div>
        <div class="desc" [ngClass]="{'pValue': data.ChangeRatio > 0,'nValue': 0 > data.ChangeRatio}">
          {{data.ChangeRatioStr}}</div>
      </div>

      <div class="panel-item">
        <div class="name">歷史淨值</div>
        <div class="desc">
          <a [routerLink]="data.IsETF ? ('/etf/etf-info/' + data.FundNo) : ('/fund-info/' + data.FundNo)"
          [queryParams]="{tab: data.IsETF ? 4 : 3}">
            <img class="download-icon" src="/assets/images/net-value.svg" alt="歷史淨值" />
          </a>
        </div>
      </div>
      <a (click)="goRegister(data)" target="_blank" *ngIf="data.IsCanTrade">
        <button class="fund-btn">我要申購</button>
      </a>
    </p-panel>
  </div>
<!-- 基金績效 -->
  <div class="fund-list" *ngIf="tabActive === 3">
    <table class="performance">
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>基金類型</th>
          <th>幣別</th>
          <th>配息頻率</th>
          <th>三個月</th>
          <th>六個月</th>
          <th>一年</th>
          <th>二年</th>
          <th>三年</th>
          <th>今年以來</th>
          <th>自成立日</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fundRankImportList">
          <th><a (click)="goBuy(data,3)">
            {{data.CShortName}}
            <span class="warning-text">{{data.CFundWarning}}</span>
          </a></th>
          <td>{{data.CCgFundTypeStr}}</td>
          <td>{{data.CCgCurrencyTypeStr}}</td>
          <td>{{data.CCgDividendTypeStr}}</td>
          <td [ngClass]="{'pValue': data.CRoi3MonthR > 0,'nValue': 0 > data.CRoi3MonthR}">{{data.CRoi3MonthRStr}}</td>
          <td [ngClass]="{'pValue': data.CRoi6MonthR > 0,'nValue': 0 > data.CRoi6MonthR}">{{data.CRoi6MonthRStr}}</td>
          <td [ngClass]="{'pValue': data.CRoi1YearR > 0,'nValue': 0 > data.CRoi1YearR}">{{data.CRoi1YearRStr}}</td>
          <td [ngClass]="{'pValue': data.CRoi2YearR > 0,'nValue': 0 > data.CRoi2YearR}">{{data.CRoi2YearRStr}}</td>
          <td [ngClass]="{'pValue': data.CRoi3YearR > 0,'nValue': 0 > data.CRoi3YearR}">{{data.CRoi3YearRStr}}</td>
          <td [ngClass]="{'pValue': data.CRoiYearStartR > 0,'nValue': 0 > data.CRoiYearStartR}">{{data.CRoiYearStartRStr}}</td>
          <td [ngClass]="{'pValue': data.CRoiHistoryR > 0,'nValue': 0 > data.CRoiHistoryR}">{{data.CRoiHistoryRStr}}</td>
          <td>
            <a (click)="goRegister(data)" *ngIf="data.CCanTrade">
              <button class="fund-btn">我要申購</button>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p-panel class="p-panel"
      [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
      [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of fundRankImportList;let i = index">
      <ng-template pTemplate="header">
        <div (click)="goBuy(data,3)">
          {{data.CShortName}}
          <span class="warning-text">{{data.CFundWarning}}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg" />
      </ng-template>
      <div class="panel-item">
        <div class="name">基金類型</div>
        <div class="desc">{{data.CCgFundTypeStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">幣別</div>
        <div class="desc">{{data.CCgCurrencyTypeStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">配息頻率</div>
        <div class="desc">{{data.CCgDividendTypeStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">三個月</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoi3MonthR > 0,'nValue': 0 > data.CRoi3MonthR}">{{data.CRoi3MonthRStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">六個月</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoi6MonthR > 0,'nValue': 0 > data.CRoi6MonthR}">{{data.CRoi6MonthRStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">一年</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoi1YearR > 0,'nValue': 0 > data.CRoi1YearR}">{{data.CRoi1YearRStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">兩年</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoi2YearR > 0,'nValue': 0 > data.CRoi2YearR}">{{data.CRoi2YearRStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">三年</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoi3YearR > 0,'nValue': 0 > data.CRoi3YearR}">{{data.CRoi3YearRStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">今年以來</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoiYearStartR > 0,'nValue': 0 > data.CRoiYearStartR}">{{data.CRoiYearStartRStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">自成立日</div>
        <div class="desc" [ngClass]="{'pValue': data.CRoiHistoryR > 0,'nValue': 0 > data.CRoiHistoryR}">{{data.CRoiHistoryRStr}}</div>
      </div>
      <a (click)="goRegister(data)" target="_blank" *ngIf="data.CCanTrade">
        <button class="fund-btn">我要申購</button>
      </a>
    </p-panel>
  </div>

  <button *ngIf="TotalItems > getLength()" class="arrow-btn" (click)="more()">
    顯示更多
    <div class="top-arrow"></div>
  </button>
  <p class="no-data" *ngIf="getLength() >= TotalItems && isSubmit">無更多資料</p>

  <!-- <div class="note-wrap" *ngIf="tabActive === 1">
    資料來源：各基金公司<br>
    資料日期：各基金淨值之最新公佈日期<br>
    註：投信類貨幣型基金為年化報酬率<br>
    標注*之基金已下架並終止在臺灣銷售，此基金之相關資訊僅供原持有該基金單位數之投資人參考之用，非推介此基金
  </div> -->
  <div class="note-wrap" *ngIf="tabActive === 3">
    資料來源：街口投信、Lipper  資料日期：{{this.fundRankDate|date:'yyyy/MM/dd'}}
  </div>
</main>
