import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HtmlPipe } from '../../shared/pipes/html.pipes';
import { AppMenuHoverDirective } from '../directive/appMenuHover.directive';

@NgModule({
  declarations: [
    HtmlPipe,
    AppMenuHoverDirective,
    // 如果 CalendarComponent 需要在其他模組中使用，則需要在這裡聲明
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule,
    CalendarModule,
    HtmlPipe,
    AppMenuHoverDirective,
    // 確保導出所有需要重用的模組和組件
  ]
})
export class SharedModule {}
