import { AritcleService } from './../../../shared/services/article.service';
import { Component } from '@angular/core';
import { Router } from 'express';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { footercontent, FooterEditContent, footernumber } from 'src/app/shared/models/home';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { mainData } from '../../../shared/interface/main-data';
import { Console } from 'node:console';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  contents: footercontent[] = [];
  // breadCrumb: BreadCrumbInfo = {} as BreadCrumbInfo;
  CId = 1;
  searchOpen = true;
  footermenu = new ResponseArticle;
  footermeunlist: Articledata = {} as Articledata;
  footernumber: footernumber = {} as footernumber;
  footerEditContent: FooterEditContent = {} as FooterEditContent;
  constructor(
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ) {
    this.baseService.footerID$.subscribe(val => {
      if (val) {
        this.footernumber.CId = val.CId;
        this.GetFooterContent();
      } else {
        this.footernumber.CId =1;
           this.GetFooterContent();
      }
    })
    this.Getfootermeun()
    this.GetFooterEditContent()
  }

  close() {
    this.searchOpen = !this.searchOpen;
  }


  GetFooterContent() {
    this.homeService.GetFooterContent(this.footernumber).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.contents = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  Getfootermeun() {
    this.footermenu.FunctionID = 638;
    this.footermenu.PageSize = 100;
    this.aritcleService.GetArticleByUnit(this.footermenu).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.footermeunlist = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  getLink(data: any): string {
    switch (data.CLinkType) {
      case 1:
        return data.CLinkUrl;
      case 2:
        return data.CLinkFile;
      case 3:
        return `unit/Type1/${data.CFunctionId}/${data.CId}`;
      case null:
        return `unit/Type1/${data.CFunctionId}/${data.CId}`;
      default:
      return `unit/Type1/${data.CFunctionId}/${data.CId}`;
    }
  }

  getTarget(data: any): string {
    switch (data.CLinkType) {
      case 1:
      case 3:
      case null:
        return data.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }

  GetFooterEditContent() {
    this.homeService.GetFooterEditContent().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.footerEditContent = res.Entries;
      }
    });
  }
}
