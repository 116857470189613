import { Component } from '@angular/core';

@Component({
  selector: 'app-fund-performance',
  templateUrl: './fund-performance.component.html',
  styleUrls: ['./fund-performance.component.scss']
})
export class FundPerformanceComponent {

}
