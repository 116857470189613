import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface DialogData {
  title:string;
  message:string;
 }

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
 @Input() data:DialogData = {} as DialogData;
  @Input() visible:boolean = false;
  @Output() visibleChange = new EventEmitter<any>();
  @Output() visiblelecture = new EventEmitter<any>();
  @Input() lecture:boolean = false;
  @Input() privateType:number = 0; //1是隱私權 //0是預設
  contentElement:any;
  scrollListener: any;
  nobottom:boolean=true;
  constructor(
  ) {

    // this.visible = this.data.visible;
  }

  _visibleChange() {
    this.visibleChange.emit(this.visible)
  }

  _visiblelecture() {
    this.visiblelecture.emit(this.visible)
  }

  ngAfterViewInit() {
    // 確保初次渲染後獲取元素
    this.contentElement = document.querySelector('.p-dialog-content');
    if (this.contentElement) {
      this.scrollListener = this.onScroll.bind(this);
      this.contentElement.addEventListener('scroll', this.scrollListener);
    }
  }

  onScroll(event: Event) {
    if (this.contentElement) {
      const scrollTop = this.contentElement.scrollTop; //滾動範圍
      const scrollHeight = this.contentElement.scrollHeight; //總高度
      const clientHeight = this.contentElement.clientHeight; //彈窗中間範圍高度
      const scrollRange = scrollHeight - clientHeight;
      if (scrollTop + 5 >= scrollRange) {
        this.nobottom = false;
      } else {
        this.nobottom = true;
      }
    }
  }
}
