import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { mainData } from '../../../shared/interface/main-data';

@Component({
  selector: 'app-quarterly',
  templateUrl: './quarterly.component.html',
  styleUrls: ['./quarterly.component.scss']
})
export class QuarterlyComponent {
  searchOpen = true;
  quarterlyres = new ResponseArticle;
  quarterly: Articledata = {} as Articledata;
  TotalItems=0;
  constructor(
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ) {
    // this.Getquarterly()
    this.quarterlyres.FunctionID = 7013;
  }

  Getquarterly() {
    this.quarterlyres.FunctionID = 7013;
    this.aritcleService.GetArticleByUnit(this.quarterlyres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.quarterly = res.Entries;
      }
    });
    this.mainData$.loading$.next(false);
  }
}
