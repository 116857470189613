import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase, PopupReq } from '../models/base';
import { ServiceBase } from 'src/app/services/service-base';
import { Articledata, ArticledataID, QAList, QAReq, ResponseArticle, ResponseArticleID } from '../models/article';
import { CreateSeminar, ResponseContact, Responsefeeback, SeminarDetail, SeminarForm, SeminarList, lectureID } from '../models/activity';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends ServiceBase {
  Web$ = new BehaviorSubject<boolean>(true);
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Activity}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  // 聯絡
  CreateContactUs(data: ResponseContact): Observable<ResponseBase<Responsefeeback>> {
    const url = `${this.baseUrl}/CreateContactUs`;
    // const data = {UnitAfterID:request}
    return this.http.post<ResponseBase<Responsefeeback>>(
      url,
      data,
      this.httpOptions);
  }
  // 檢舉
  CreateReport(data: ResponseContact): Observable<ResponseBase<Responsefeeback>> {
    const url = `${this.baseUrl}/CreateReport`;
    // const data = {UnitAfterID:request}
    return this.http.post<ResponseBase<Responsefeeback>>(
      url,
      data,
      this.httpOptions);
  }

  // 理財講座列表
  GetSeminarList(): Observable<ResponseBase<SeminarList[]>> {
    const url = `${this.baseUrl}/GetSeminarList`;
    return this.http.post<ResponseBase<SeminarList[]>>(
      url,
      this.httpOptions);
  }

  //理財講座列表(第二頁內容頁)
  GetSeminarDetail(data:PopupReq): Observable<ResponseBase<SeminarDetail>> {
    const url = `${this.baseUrl}/GetSeminarDetail`;
    return this.http.post<ResponseBase<SeminarDetail>>(
      url,
      data,
      this.httpOptions);
  }

  //理財講座表單內容(報名頁左方內容)
  GetSeminarForm(data:lectureID): Observable<ResponseBase<SeminarForm>> {
    const url = `${this.baseUrl}/GetSeminarForm`;
    return this.http.post<ResponseBase<SeminarForm>>(
      url,
      data,
      this.httpOptions);
  }

   //理財講座報名頁
   CreateSeminarSignupData(data:CreateSeminar): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/CreateSeminarSignupData`;
    return this.http.post<ResponseBase<string>>(
      url,
      data,
      this.httpOptions);
  }
}
