import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, map, pipe } from 'rxjs';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { mainData } from 'src/app/shared/interface/main-data';
import { ResponseArticle, Articledata } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-unit-announcement',
  templateUrl: './unit-announcement.component.html',
  styleUrls: ['./unit-announcement.component.scss']
})
export class UnitAnnouncementComponent {
  @Input() data: any[] = [];
  TotalItems = 0;
  url = '';
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  @Input() FunctionID: number = 0;
  Type = '';
  vedio = false;
  first: number = 1;
  rows: number = 6;
  constructor(
    private route: ActivatedRoute,
    public homeService: HomeService,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ) {
  }

  ngOnInit(): void {
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      this.Type = params['Type'];
      if (this.Type == 'type3' || this.Type == 'Type3') {
        this.vedio = true;
      }
    });
    this.newsres.PageSize = 6;
    // this.route.url.subscribe(urlSegments => {
    //   const currentPath = urlSegments[0].path;
    //   console.log(currentPath)
    //   if (currentPath === 'news') {
    //     this.url = '/point/world-detail'
    //   } else if (currentPath === 'weekly-report') {
    //     this.url = '/point/weekly-report-detail'
    //   }
    //   else if (currentPath === 'quarterly-report') {
    //     this.url = '/point/quarterly-report-detail'
    //   }
    //   else if (currentPath === 'esg') {
    //     this.url='/about-us/esg-detail'
    //   }
    //   else if (currentPath === 'classroom') {
    //     this.url='/etf/classroom-detail'
    //   }
    //   else if (currentPath === 'video') {
    //     this.url='/etf/video-detail'
    //   }
    // });
    this.Getnews()
  }


  Getnews() {

    this.newsres.FunctionID = this.FunctionID;
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.newslist = res.Entries;
        this.TotalItems = res.TotalItems;
        // console.log(this.newslist.Data)
      }
      this.mainData$.loading$.next(false);
    });
  }

  more() {
    this.newsres.PageSize = this.newsres.PageSize + 6;
    this.Getnews()
  }

  getLink(item: any): string {
    switch (item?.CLinkType) {
      case 1:
        return item?.CLinkUrl;
      case 2:
        return item?.CLinkFile;
      case 3:
        return `unit/Type2/${this.newsres.FunctionID}/${item.CId}`;
      case null:
        return `unit/Type2/${this.newsres.FunctionID}/${item.CId}`;
      default:
        return `unit/Type2/${this.newsres.FunctionID}/${item.CId}`;
    }
  }

  getVedioLink(item: any): string {
    switch (item?.CLinkType) {
      case 1:
        return item?.CLinkUrl;
      case 2:
        return item?.CLinkFile;
      case 3:
        return `unit/Type3/${this.newsres.FunctionID}/${item.CId}`;
      case null:
        return `unit/Type3/${this.newsres.FunctionID}/${item.CId}`;
      default:
        return `unit/Type3/${this.newsres.FunctionID}/${item.CId}`;
    }
  }

  getTarget(item: any): string {
    switch (item?.CLinkType) {
      case 1:
      case 3:
      case null:
        return item?.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }

  paginate(event: any) {
    this.newsres.PageIndex = event.page + 1;
     this.first = event.first;
     this.Getnews();
  }

}
