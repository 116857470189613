<main>
    <h2 class="sub-title sub-title-first">流動量提供者與參與券商</h2>

    <table class="table-web">
        <thead>
          <tr>
            <th>券商名稱</th>
            <th>地址</th>
            <th>電話</th>
            <th>參與券商</th>
            <th>流動量提供者</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <th>{{item.Name}}</th>
            <td>{{item.Address}}</td>
            <td>{{item.Phone}}</td>
            <td>
                <img class="tick-icon" *ngIf="item.IsParticipatingDealer" src="/assets/images/tick.svg" />
            </td>
            <td>
                <img class="tick-icon" *ngIf="item.IsliquidityProvider" src="/assets/images/tick.svg" />
            </td>
          </tr>
        </tbody>
    </table>
    <table class="table-mob">
        <thead>
          <tr *ngFor="let item of data">
            <th>
              <span>{{item.Name}} </span>
            </th>
            <th>
               <span>{{item.Address}} </span>
            </th>
            <th>電話
                <span>{{item.Phone}}</span>
            </th>
            <th>參與券商
                <span>
                    <img class="tick-icon" src="/assets/images/tick.svg" *ngIf="item.IsParticipatingDealer"/>
                </span>
            </th>
            <th>流動量提供者
                <span>
                    <img class="tick-icon" src="/assets/images/tick.svg"  *ngIf="item.IsliquidityProvider"/>
                </span>
            </th>
          </tr>
          <!-- <tr>
            <th>中國信託綜合證券股份有限公司</th>
            <th>
                臺北市南港區經貿二路168號3樓
            </th>
            <th>電話
                <span>02-6639-2000</span>
            </th>
            <th>參與券商
                <span>
                    <img class="tick-icon" src="/assets/images/tick.svg" />
                </span>
            </th>
            <th>流動量提供者
                <span>
                    <img class="tick-icon" src="/assets/images/tick.svg" />
                </span>
            </th>
          </tr> -->
        </thead>
    </table>
</main>
