<app-bread-crumb [Article]="Article"></app-bread-crumb>
  <div class="wrapper-top">
    <main>
      <div class="title-box">
        <span>{{list.CShowStartDt| date:'yyyy/MM/dd'}}</span>
        <h2>{{list.CName}}</h2>
      </div>
    </main>
  </div>
  <div class="wrapper">
    <main *ngIf="!showLoading">
      <div class="editable" [innerHtml]="list.CContent|html" *ngIf="!video&& list.CContent"></div>
      <div class="editable" [innerHtml]="list.CYoutubeEmbedCode|html" *ngIf="video && list.CYoutubeEmbedCode"></div>
    </main>
      <div class="loading"  *ngIf="showLoading">
        <img alt="loading" src="/assets/images/loading.png" />
      </div>
  </div>
  <div class="wrapper-botm">
    <main>
      <div class="comeback" (click)="back()">回列表頁</div>
    </main>
  </div>

  <app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" (visibleChange)="visibleChange($event)"></app-message-dialog>
