import { Page } from "./page";
import { ETPNAVPrice
 } from '../models/etf';
export class ResponseBase<T> {
  TotalItems: number = 0;
  Entries = {} as T;
  Message: string = '';
  StatusCode: number = 0;
}

export class ResponseETPNAVPrice<T> {
  Entries:ETPNAVPrice[] = []
}

export class ResponseIntradayNAVBase<T> {
  returnCode: string = '';
  returnMsg: string = '';
  returnData = [{} as T];
  Alert: string = '';
  FundsSplit: string = '';
}

export class ResponseObjBase<T> {
  TotalItems: number = 0;
  Entries: any;
  Message: string = '';
  StatusCode: string = '';
}


export class PageReq extends Page {
}

export class PopupReq {
  IsPreview: boolean = false;
  CId: number = 0;
}

export interface Popup {
  Title: string;
  Content: string;
  FileName?: string;
  ButtonStatus: number;
  CId?: number;
  UpdateTime: string;
}

export interface DialogData {
  title:string;
  message:string;
  data:any;
 }

 export class CIdData<T> {
  Cid: number = 0;
  StatusCode: number = 0;
  Entries = {} as T;
}

export interface TypeID {
  TypeID:number;
}

export interface IDPreview {
  TypeID:number;
  IsPreview:Boolean;
}
