<div class="wrapper">
  <main>
    <div>
      <div class="listbox">
        <ng-container *ngFor="let item of newslist.Data">
          <a  *ngIf="!vedio; else nonVideoLink" class="list" [href]="getLink(item)" [attr.target]="getTarget(item)">
            <img *ngIf="item.CPhotoFileName" src="{{item.CPhotoFileName}}" alt="{{item.CPhotoName}}">
            <div class="list-text">
              <div class="date">{{item.CShowStartDt | date: 'yyyy/MM/dd'}}</div>
              <div class="title">{{item.CName}}</div>
              <div class="listcontent">{{item.CFileDescription}}</div>
            </div>
          </a>
          <ng-template #nonVideoLink>
            <a class="list" [href]="getVedioLink(item)" [attr.target]="getTarget(item)">
              <img *ngIf="item.CPhotoFileName" src="{{item.CPhotoFileName}}" alt="{{item.CPhotoName}}">
              <div class="list-text">
                <div class="date">{{item.CShowStartDt | date: 'yyyy/MM/dd'}}</div>
                <div class="title">{{item.CName}}</div>
                <div class="listcontent">{{item.CFileDescription}}</div>
              </div>
            </a>
          </ng-template>
        </ng-container>
        <!-- <ng-container *ngFor="let item of newslist.Data" >
        <a class="list"  [href]="item.CLinkUrl"   [target]="item.CAnother == 1 ? '_blank' : '_parent'"  *ngIf="item.CLinkType==1">
          <img src="{{item.CPhotoFileName}}" alt="{{item.CPhotoName}}" *ngIf="item.CPhotoFileName">
          <div class="list-text">
            <div class="date">{{item.CShowStartDt| date:'yyyy/MM/dd'}}</div>
            <div class="title">{{item.CName}}</div>
            <div class="listcontent">
              {{item.CFileDescription}}
            </div>
          </div>
        </a>
      </ng-container>
      <ng-container *ngFor="let item of newslist.Data" >
        <a class="list"  [href]="item.CLinkFile"  target="_blank" *ngIf="item.CLinkType==2">
          <img src="{{item.CPhotoFileName}}"  alt="{{item.CPhotoName}}" *ngIf="item.CPhotoFileName">
          <div class="list-text">
            <div class="date">{{item.CShowStartDt| date:'yyyy/MM/dd'}}</div>
            <div class="title">{{item.CName}}</div>
            <div class="listcontent">
              {{item.CFileDescription}}
            </div>
          </div>
        </a>
      </ng-container>
      <ng-container *ngFor="let item of newslist.Data" >
        <a class="list"  href="unit/Type2/{{this.newsres.FunctionID}}/{{item.CId}}" [target]="item.CAnother == 1 ? '_blank' : '_parent'" *ngIf="item.CLinkType==3">
          <img src="{{item.CPhotoFileName}}"  alt="{{item.CPhotoName}}" *ngIf="item.CPhotoFileName">
          <div class="list-text">
            <div class="date">{{item.CShowStartDt| date:'yyyy/MM/dd'}}</div>
            <div class="title">{{item.CName}}</div>
            <div class="listcontent">
              {{item.CFileDescription}}
            </div>
          </div>
        </a>
      </ng-container> -->
      <!-- //影音 -->
      <!-- <ng-container *ngFor="let item of newslist.Data" >
        <a class="list"  href="unit/Type2/{{this.newsres.FunctionID}}/{{item.CId}}" [target]="item.CAnother == 1 ? '_blank' : '_parent'"  *ngIf="item.CLinkType==null">
          <img src="{{item.CPhotoFileName}}"  alt="{{item.CPhotoName}}" *ngIf="item.CPhotoFileName">
          <div class="list-text">
            <div class="date">{{item.CShowStartDt| date:'yyyy/MM/dd'}}</div>
            <div class="title">{{item.CName}}</div>
            <div class="listcontent">
              {{item.CFileDescription}}
            </div>
          </div>
        </a>
      </ng-container> -->

      </div>
      <!-- <ng-container>
        <button *ngIf="TotalItems > (newslist.Data && newslist.Data.length)" class="arrow-btn" (click)="more()">
          顯示更多
          <div class="top-arrow"></div>
        </button>
        <p class="no-data" *ngIf="(newslist.Data && newslist.Data.length) >= TotalItems">無更多資料</p>
      </ng-container> -->
      <ng-container>
        <p-paginator (onPageChange)="paginate($event)"
        [first]="first"
        [rows]="6"
        [totalRecords]="TotalItems" />
      </ng-container>
    </div>
  </main>
</div>
