<div class="wrapper">
  <main>
    <div class="form-box">
      <div class="form-left">
        <h2>{{data.Title}} 報名表</h2>
        <div class="text">
          <h3>時間</h3>
          <span>{{data.Date}} {{data.Time}}</span>
        </div>
        <div class="text" *ngIf="data.Event">
          <h3>活動場次</h3>
          <span>{{data.Event}}</span>
        </div>
        <div class="phone">
          <h3>地點</h3>
          <span>{{data.Address}}</span>
        </div>
      </div>
      <div class="form-right">
        <div class="input-box">
          <div class="title">姓名<span class="require">＊必填</span></div>
          <input placeholder="請輸入姓名" [(ngModel)]="request.CName">
        </div>
        <div class="input-box">
          <div class="title">性別<span class="require">＊必填</span></div>
          <div class="radio">
            <p-radioButton name="pizza" value="M" [(ngModel)]="request.CGender" inputId="ingredient1"></p-radioButton>
            <label for="ingredient1" class="ml-2">男</label>
            <p-radioButton name="pizza" value="F" [(ngModel)]="request.CGender" inputId="ingredient1"></p-radioButton>
            <label for="ingredient1" class="ml-2">女</label>
          </div>
        </div>
        <div class="input-box">
          <div class="title">E-mail<span class="require">＊必填</span></div>
          <input placeholder="請輸入E-mail" [(ngModel)]="request.CEmail">
        </div>
        <div class="input-box">
          <div class="title">聯絡電話<span class="require">＊必填</span></div>
          <input placeholder="行動電話(範例：0912345678)/市話(範例：021234567)，手機市話請擇一填寫" [(ngModel)]="request.CPhone">
        </div>
        <div class="input-box">
          <div class="title">如何得知此次活動<span class="require">＊必填</span></div>
          <div class="check">
            <div class="flex">
              <p-checkbox name="group1" value="街口投信官網" [(ngModel)]="request.CSource"
                inputId="街口投信官網"></p-checkbox>
              <label for="街口投信官網">街口投信官網</label>
            </div>
            <div class="flex">
              <p-checkbox name="group1" value="社群(FB、Youtube、Line)" [(ngModel)]="request.CSource" inputId="社群(FB、Youtube、Line)"></p-checkbox>
              <label for="社群(FB、Youtube、Line)">社群(FB、Youtube、Line)</label>
            </div>
            <div class="flex">
              <p-checkbox name="group1" value="廣告" [(ngModel)]="request.CSource" inputId="廣告"></p-checkbox>
              <label for="廣告">廣告</label>
            </div>
            <div class="flex">
              <p-checkbox name="group1" value="EDM" [(ngModel)]="request.CSource" inputId="EDM"></p-checkbox>
              <label for="EDM">EDM</label>
            </div>
          </div>
          <div class="checkother">
            <div class="flex">
              <p-checkbox value="1" [binary]="true"  id="其他"  [(ngModel)]="incheckother"></p-checkbox>
              <label for="其他">其他</label>
            </div>
            <input class="refreshinput" placeholder="其他："  (ngModelChange)="otherthingchange($event)" [(ngModel)]="otherthing">
          </div>
        </div>
        <div class="input-box">
          <div class="title">是否為投信客戶<span class="require">＊必填</span></div>
          <div class="radio">
            <div>
              <p-radioButton name="pizza" [value]="1" inputId="ingredient1"
                [(ngModel)]="request.CIsMember"></p-radioButton>
              <label for="ingredient1" class="ml-2">是</label>
            </div>
            <div class="radio-text">
              <p-radioButton name="pizza" [value]="2" inputId="ingredient1"
                [(ngModel)]="request.CIsMember"></p-radioButton>
              <label for="ingredient1" class="ml-2">否，我還不是街口投信客戶</label>
            </div>
            <div class="radio-text">
              <div>
                <p-radioButton name="pizza" [value]="3" inputId="ingredient1"
                  [(ngModel)]="request.CIsMember"></p-radioButton>
              </div>
              <div for="ingredient1" class="ml-2 divlabel">否，請協助我預約開戶，享手續費優惠服務</div>
            </div>
            <div class="remind">相關活動優惠以現場提供之內容為準，現場開戶請準備身分證、帳戶存摺影本與原留印鑑、及欲留於街口投信之印鑑章。</div>
          </div>
        </div>
        <!-- <div class="input-box">
          <div class="title">{{selectedCities}}<span class="require">＊必填</span></div>
          <textarea rows="10" cols="50" placeholder="請輸入您的問題或建議" [(ngModel)]="request.CMsgContent" ></textarea>
        </div> -->
        <div class="input-box">
          <div class="title">驗證碼<span class="require">＊必填</span></div>
          <div class="refreshbox">
            <input class="refreshinput" placeholder="請輸入圖形驗證碼" [(ngModel)]="request.Code">
            <!-- <img src="/assets/images/code.svg"> -->
            <img [src]="resimg.Base64" *ngIf="resimg.Base64">
            <img class="refresh" src="/assets/images/refresh.svg" alt="refresh" (click)="refresh()">
          </div>
        </div>
        <!-- <div class="input-box">
          <div class="check web">
            <p-checkbox name="group2" value="agree" inputId="agree" [(ngModel)]="checkbox" [binary]="true"></p-checkbox>
            <label for="agree">本人已同意、知悉街口投信「<a (click)="policyDialog(1)">個人資料蒐集、處理及利用告知事項</a>」</label>
          </div>
          <div class="check check2 mb">
            <div>
              <p-checkbox name="group2" value="agree" inputId="agree" [(ngModel)]="checkbox"
                [binary]="true"></p-checkbox>
            </div>
            <label for="agree">本人已同意、知悉街口投信<br>「<a (click)="policyDialog(1)">個人資料蒐集、處理及利用告知事項</a>」</label>
          </div>
        </div> -->
        <div class="form-item checkbox-wrap" (click)="policyDialog(1)">
          <p-checkbox class="checkbox" name="agreeChecked" [(ngModel)]="checkbox" [binary]="true" inputId="binary" [disabled]="disableagreeChecked"></p-checkbox>
          <span>本人已知悉同意街口投信<span class="agrees">「<span class="agree-info">個人資料蒐集、處理及利用告知事項</span>」</span></span>
      </div>
        <div class="confirm-btn-box">
          <button class="confirm-btn" (click)="ContantUs()">確認送出</button>
        </div>
      </div>
    </div>
  </main>
</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [lecture]="lecture" [data]="dialogData" [privateType]="visibleType"
  (visibleChange)="visibleChange($event)"  (visiblelecture)="visiblelecture($event)"></app-message-dialog>
