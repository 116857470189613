import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Articledata, ResponseArticle } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-esg-list',
  templateUrl: './esg-list.component.html',
  styleUrls: ['./esg-list.component.scss']
})
export class EsgListComponent {
    searchOpen = true;
    newsres = new ResponseArticle;
    newslist: Articledata = {} as Articledata;
    TotalItems=0;
    constructor(
      public homeService: HomeService,
      // private router: Router,
      public baseService: BaseService,
      public aritcleService: AritcleService
    ) {
      this.newsres.FunctionID = 7349;
    }

    // Getnews() {
    //   this.newsres.FunctionID = 7349;
    //   this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
    //     if (res.StatusCode == EnumStatusCode.Success) {
    //       this.newslist = res.Entries;
    //       console.log(this.newslist.Data)
    //     }
    //   });
    // }

}
