<app-bread-crumb></app-bread-crumb>
<div class="wrapper">
    <main>
        <form *ngIf="OrderTime&&ETFOrderFormInfo">
            <h2>【{{ETFOrderFormInfo.Name}}】({{ETFOrderFormInfo.No}})初級市場預約申購需求單</h2>
            <div class="form-item">
                <label>姓名 <span class="request">＊必填</span></label>
                <input placeholder="請輸入您的姓名" [(ngModel)]="request.Name" name="name" [maxlength]="200">
            </div>

            <div class="form-item">
                <label>身份證字號/統編/外資法人 <span class="request">＊必填</span></label>
                <input placeholder="請輸入您的身份證字號/統編/外資法人" [(ngModel)]="request.IDNumber" name="id">
            </div>

            <div class="form-item">
                <label>E-mail <span class="request">＊必填</span></label>
                <input placeholder="請輸入您的E-mail" [(ngModel)]="request.Email" name="mail">
            </div>

            <div class="form-item">
                <label>聯絡電話<span class="request">＊必填</span></label>
                    <input placeholder="行動電話(範例：0912345678)/市話(範例：021234567)，手機市話請擇一填寫" name="cell-phone" [(ngModel)]="request.Phone">
                    <!-- <input placeholder="日間電話(範例：02-22345678)" name="phone"> -->
            </div>

            <div class="form-item">
                <label>參與券商 <span class="request">＊必填</span></label>
                <p-dropdown [options]="brokerageList" name="PDealerID" (onChange)="selectListChange($event)"
                    optionLabel="Name"></p-dropdown>
            </div>

            <div class="form-item">
                <label>券商分點/分公司代碼 <span class="request">＊必填</span></label>
                <p-dropdown [options]="PDBranchList" name="PDBranchCode" (onChange)="selectBranchListChange($event)"
                    optionLabel="Name"></p-dropdown>
            </div>

            <div class="form-item">
                <label>擬申購組數 <span class="request">＊必填</span></label>
                <input type="number" placeholder="請輸入申購組數" [(ngModel)]="request.PurchaseQuantity" name="amount" (input)="validateInput($event)">
            </div>

            <div class="pay-info">
                <div class="pay-item">
                    <div class="name">基金交易費(內含)</div>
                    <div>{{ETFOrderFormInfo.TransactionFee}}</div>
                </div>

                <div class="pay-item">
                    <div class="name">手續費(內含)</div>
                    <div>{{ETFOrderFormInfo.HandlingFee}}</div>
                </div>

                <div class="pay-item">
                    <div class="name">券商處理費(內含/外加)</div>
                    <div>{{ETFOrderFormInfo.BrokerFee}}</div>
                </div>

                <div class="pay-item">
                    <div class="name">預計每組應付款項(每日更新)</div>
                    <div>{{ETFOrderFormInfo.AccountPayable}}</div>
                </div>
            </div>

            <div class="form-item">
                <label>驗證碼</label>
                <div class="verification-wrap">
                    <input placeholder="請輸入圓形驗證碼" [(ngModel)]="request.Code" name="VerificationCode">
                    <figure>
                        <img [src]="getSanitizedUrl(captchaUrl)" alt="VerificationCode" />
                    </figure>
                    <img class="refresh-icon" src="/assets/images/refresh.svg" (click)="refresh()"/>
                </div>
            </div>
            <div class="form-item checkbox-wrap" (click)="dialogOpen(aritcle.CContent,'agreeChecked')">
                <p-checkbox class="checkbox" name="agreeChecked" [(ngModel)]="agreeChecked" [binary]="true" inputId="binary" [disabled]="disableagreeChecked"></p-checkbox>
                <span>本人已知悉同意街口投信<span class="agrees">「<span class="agree-info">個人資料蒐集、處理及利用告知事項</span>」</span></span>
            </div>
            <div class="btn-wrap">
                <button id="reset" type="reset" class="reset">清除重填</button>
                <button type="submit" (click)="submitCheck()">送出</button>
            </div>
        </form>

        <p class="time-txst" *ngIf="!OrderTime&&!TheOrderTime&&!FundsSplit">
          現在不是開放時間。開放登記時間為【每基金營業日 {{StartDT || '08:00'}}~{{EndDT || '10:30'}}】，詳見官網公告，謝謝。
        </p>
        <ng-container *ngIf="FundsSplit">
          <div [innerHTML]="Alert|html"></div>
        </ng-container>

        <div class="detail-wrap">
            <div class="title">查詢各券商分點/公司</div>
            <div class="detail-list">
                <div class="item" [ngStyle]="{width: getWidth(i)}"
                    *ngFor="let data of brokerageList;let i = index">
                    {{data.Name}}
                </div>
            </div>
        </div>
    </main>

</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" (visibleChange)="visibleChange($event)" [privateType]="privateType"></app-message-dialog>
<ng-container *ngFor="let data of dialogMsgData | slice:0:3">
    <app-confirm-dialog *ngIf="visiblegMsg" [visible]="visiblegMsg" [data]="data" (visibleChange)="visibleMsgChange($event)" (unCheck)="unCheck($event)"></app-confirm-dialog>
</ng-container>
