import { FormDownloadComponent } from './../components/form-download/form-download.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase, Popup } from '../models/base';
import { ServiceBase } from 'src/app/services/service-base';
import { DocReq, DocTypelist, DoclistReq, DoclistRes } from '../models/docDownload';

@Injectable({
  providedIn: 'root'
})
export class DocDownloadService  extends ServiceBase {
  Web$ = new BehaviorSubject<boolean>(true);
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.DocDownload}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }
  //取得表單分類
  GetDocTypes(request:DocReq): Observable<ResponseBase<DocTypelist[]>> {
    const url = `${this.baseUrl}/GetDocTypes`;
    return this.http.post<ResponseBase<DocTypelist[]>>(
      url,
      request,
      this.httpOptions);
  }

//取得表單列表
  GetDocList(request:DoclistReq): Observable<ResponseBase<DoclistRes[]>> {
    const url = `${this.baseUrl}/GetDocList`;
    return this.http.post<ResponseBase<DoclistRes[]>>(
      url,
      request,
      this.httpOptions);
  }

  // GetFaqList(data:QAReq): Observable<ResponseBase<QAList[]>> {
  //   const url = `${this.baseUrl}/GetFaqList`;
  //   return this.http.post<ResponseBase<QAList[]>>(
  //     url,
  //     data,
  //     this.httpOptions);
  // }

}
