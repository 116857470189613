import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from 'src/app/services/service-base';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase } from '../models/base';
import { Category, FundCategory, HotKeyword } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Category}`;
  fundYieldCategoryList: FundCategory[] = []; //一般基金
  etfCategoryList: FundCategory[] = []; //etf基金
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  GetOverviewHotKeyword(request:HotKeyword): Observable<ResponseBase<Category[]>> {
    const url = `${this.baseUrl}/GetOverviewHotKeyword`;
    return this.http.post<ResponseBase<Category[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetSearchFundCategory(request:number): Observable<ResponseBase<FundCategory[]>> {
    const _request = {Page:request}
    const url = `${this.baseUrl}/GetSearchFundCategory`;
    return this.http.post<ResponseBase<FundCategory[]>>(
      url,
      _request,
      this.httpOptions);
  }
}
