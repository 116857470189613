import { AritcleService } from 'src/app/shared/services/article.service';
import { FundService } from 'src/app/shared/services/fund.service';
import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, PLATFORM_ID
} from '@angular/core';
import { FundEtfOverviewList, FundIntradayNAVCurr, FundIntradayNAVData } from 'src/app/shared/models/etf';
import { BaseService } from 'src/app/shared/services/base.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { FundCategoryReq, RateOfChangeData, TrackDiffData, TrackDiffDataunb } from 'src/app/shared/models/fund';
import { Page } from 'src/app/shared/models/page';
import { Articledata, ArticledataID, ResponseArticle, ResponseArticleID } from 'src/app/shared/models/article';
import { Popup, DialogData, PopupReq } from '../../../../shared/models/base';
import { Observable, combineLatest, finalize, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { mainData } from '../../../../shared/interface/main-data';
import { ETFService } from 'src/app/shared/services/etf.service';

@Component({
  selector: 'app-trad-info',
  templateUrl: './trad-info.component.html',
  styleUrls: ['./trad-info.component.scss']
})
export class TradInfoComponent {
  isBrowser: boolean;
  activeIndex: number = -1;
  customers: any[] = [{}, {}];
  isLeft = false;
  request: Page = {} as Page;
  fundEtfList: FundEtfOverviewList[] = [];
  TypeData: string[] = ['全部', '商品型', '槓桿/反向型'];
  isActiveTypeId: string = '全部';
  TWD: string[] = ['原幣', '新台幣'];
  isActiveSubId: string = '原幣';
  Ratedata: RateOfChangeData[] = [];
  TotalItems = 0;
  CDate: string = ''
  TrackDiff: TrackDiffDataunb[] = [];
  isSubmit = false;
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  requestNew = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;
  Data: FundIntradayNAVData[] = []
  Curr:FundIntradayNAVCurr[]=[]
  //警語彈窗
  dialogData: DialogData[] = [];
  visible = false;
  popupData: Popup[] = [];
  UpdateTime: Popup[] = [];
  ETFMsgDate = '';
  ETFMsgUpdate = '';
  agreeTimes = 0;
  PopupReq = new PopupReq;
  timeoutID:any;
  todaylasttime = new Date();
  now = new Date()
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public categoryService: CategoryService,
    // public ETFService: ETFService,
    public baseService: BaseService,
    public fundService: FundService,
    public etfService: ETFService,
    public aritcleService: AritcleService,
    private route: ActivatedRoute,
    private router: Router,
    public mainData$: mainData,

  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    // this.GetSearchFundCategory();
    // this.request.PageSize = 10;
    // this.request.PageIndex = 1;
    // this.submit();
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'predict') {
        this.activeIndex = 0;
        this.timeoutID = setInterval(() => {
          this.GetPredict();
        }, 15000);
      } else if (currentPath === 'trace') {
        this.activeIndex = 1;
      }
      else if (currentPath === 'bodong') {
        this.activeIndex = 2;
      }
      else if (currentPath === 'rate') {
        this.activeIndex = 3;
      }
    });
    this.requestNew.FunctionID = 7332;
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      // const idWithoutNews = id.replace('esg', '');
      this.requestNew.ID = Number(id);
    });

    urlParams.subscribe(queryParams => {
      if (queryParams['isPreview'] !== null) {
        this.requestNew.IsPreview = Boolean(queryParams['isPreview']);
      }
      else {
        this.requestNew.ID = 3
      }
    });
    if (this.activeIndex == 3) {
      this.GetArticleByID()
    }
    this.GetRateOfChange()
    this.GetTrackDiff()
    this.GetPredict()

    // ETF警語
    this.ETFMsgDate = localStorage.getItem('ETFMsg') || ''
    this.GetPopup();
  }
  getScroll() {
    let element = document.getElementsByClassName('p-datatable-wrapper');
    let maxScrollLeft = element[0].scrollWidth - element[0].clientWidth;
    if (!this.isLeft) {
      element[0].scrollTo({
        left: maxScrollLeft,
        behavior: 'smooth'
      });
      this.isLeft = true;
    } else {
      element[0].scrollTo({
        left: 0,
        behavior: 'smooth'
      });
      this.isLeft = false;
    }

  }

  clearPredictInterval() {
    if (this.timeoutID) {
      window.clearInterval(this.timeoutID);
      this.timeoutID = null;
    }
  }

  clearPredictIntervalTimeOut() {
    if (this.timeoutID && this.now > this.todaylasttime) {
      window.clearInterval(this.timeoutID);
      this.timeoutID = null;
    }
  }

  ngOnDestroy() {
    window.clearInterval(this.timeoutID);
  }

  selectChange(e: any) {
    if (this.activeIndex == 0) {
      this.router.navigate(['etf/predict'])
    }
    else if (this.activeIndex == 1) {
      this.router.navigate(['etf/trace'])
      this.clearPredictInterval()
    }
    else if (this.activeIndex == 2) {
      this.router.navigate(['etf/bodong'])
      this.clearPredictInterval()
    }
    else if (this.activeIndex == 3) {
      this.router.navigate(['etf/rate/3'])
      this.clearPredictInterval()
    }
  }
  selectData(data: any) {
    this.isActiveSubId = data;
    // this.onChange.emit(data)
  }

  selectTypeData(data: any) {
    this.isActiveTypeId = data;
  }

  //取得變動率
  GetRateOfChange() {
    this.fundService.GetRateOfChange().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.Ratedata = res.Entries;
        this.CDate = this.Ratedata[0].CDate;
        // this.TotalItems = res.TotalItems;
      }
    });
  }

  GetPredict() {
    this.etfService.GetFundIntradayNAV().pipe(
      finalize(() => {
        this.mainData$.loading$.next(false);
        this.clearPredictIntervalTimeOut();
      })
    ).subscribe((res) =>  {
      if (res.returnCode == '200') {
        // this.now = new Date();
        this.Data = res.returnData[0].Data;
        this.Curr =res.returnData[0].Curr;
        let previousTime = new Date().setHours(17, 0, 0, 0);
        this.todaylasttime.setHours(17, 0, 0, 0);
        this.mainData$.loading$.next(false);
        this.clearPredictIntervalTimeOut();
      }
    });
  }

  GetTrackDiff() {
    this.fundService.GetTrackDiff().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.TrackDiff = res.Entries;
        // this.TotalItems = res.TotalItems;

      }
      this.isSubmit = true;
    });
  }

  more() {
    this.request.PageSize = this.request.PageSize + 10;
    this.getSubmit();
  }

  //追蹤差距more
  getSubmit() {
    this.GetTrackDiff();
  }

  //交易費率單篇文章
  GetArticleByID() {
    this.aritcleService.GetArticleByID(this.requestNew).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.list = res.Entries;
      }
    });
  }

  getNumber(value: string) {
    return Number(value)
  }

  GetPopup() {
    this.baseService.GetPopup(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupData = res.Entries;
        this.UpdateTime = this.popupData.sort(function (a, b) {
          let timeA: string = '';
          let timeB: string = '';
          timeA = a.UpdateTime;
          timeB = b.UpdateTime;

          if (timeA < timeB) {
            return 1;
          }
          if (timeA > timeB) {
            return -1;
          }
          return 0;
        })
        this.ETFMsgUpdate = localStorage.getItem('ETFMsgUpdate') || ''
        if ((!this.ETFMsgDate || this.baseService.getTimeout0hour(this.ETFMsgDate)) || (this.UpdateTime.length && this.ETFMsgUpdate !== this.UpdateTime[0].UpdateTime)) {
          this.OpenDialog();
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  OpenDialog() {
    if (this.isBrowser) this.visible = true;
    this.dialogData = this.popupData.map(val => {
      return {
        title: val.Title,
        message: val.Content,
        data: val
      }
    });
  }

  visibleChange(e: any) {
    if (e) this.agreeTimes = this.agreeTimes + 1;
    if (this.agreeTimes == this.popupData.length || (this.agreeTimes === 3)) {
      localStorage.setItem('ETFMsg', new Date().toString());
      if (this.UpdateTime.length) localStorage.setItem('ETFMsgUpdate', this.UpdateTime[0].UpdateTime)
    }
  }
}
