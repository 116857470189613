<app-bread-crumb></app-bread-crumb>
<main class="etf-list">
  <app-fund-search id="searchResults" [hotKeyword]="categoryData" [fundCategory]="fundCategoryList" (result)="submit($event)" [etf]="true"
    [TotalItems]="TotalItems"></app-fund-search>

  <div class="etf-list">
    <p-table *ngIf="baseService.Web$.value" [value]="fundEtfList" [scrollable]="true" styleClass="mt-3">
      <ng-template pTemplate="header">
        <tr>
          <th style="width:120px" pFrozenColumn>股票代碼</th>
          <th style="width:250px">基金簡稱</th>
          <th style="width:100px">風險等級</th>
          <th style="width:100px">基金類型</th>
          <th style="width:100px">幣別</th>
          <th style="width:100px">配息頻率</th>
          <th style="width:100px">淨值日期</th>
          <th style="width:130px">淨值</th>
          <th style="width:200px">淨值日漲跌幅％</th>
          <th style="width:130px">收盤價</th>
          <th style="width:200px">市價日漲跌幅％</th>
          <th style="width:120px">折溢價</th>
          <th style="width:200px" alignFrozen="right" pFrozenColumn>
            <img class="scroll-arrow" [ngClass]="{'scroll-arrow-left':isLeft}" (click)="getScroll()"
              src="/assets/images/black-arrow.svg" />
            空白
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td style="width:120px" pFrozenColumn>
            <a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo" [queryParams]="{tab: 1}">
              {{data.StockNo}}
            </a>
          </td>
          <td style="width:250px"><a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo"
              [queryParams]="{tab: 1}">{{data.ShortName}} <span class="warning-text">{{data.Warning}}</span></a></td>
          <td style="width:100px">{{data.FundRiskLevelStr}}</td>
          <td style="width:100px">{{data.FundTypeStr}}</td>
          <td style="width:100px">{{data.FundCurrencyStr}}</td>
          <td style="width:100px">{{data.FundDividendStr}}</td>
          <td style="width:100px">{{data.NavDate}}</td>
          <td style="width:130px"><div style="text-align: right;">{{data.NetValueStr}}<br><div [innerHTML]="data.TableAlert|html"></div></div></td>
          <td style="width:200px">
          <div [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}">{{data.ChangeStr}}</div>&nbsp;<div class="prcent" [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}" *ngIf="data.ChangeRatioStr !=='-'">{{data.ChangeRatioStr}}</div></td>
          <td style="width:130px">{{data.PriceStr}}</td>
          <td style="width:200px">
            <div  [ngClass]="{'pValue': data.PriceChange > 0,'nValue': 0 > data.PriceChange}">{{data.PriceChangeStr}}</div>&nbsp;<div class="prcent" [ngClass]="{'pValue': data.PriceChange > 0,'nValue': 0 > data.PriceChange}" *ngIf="data.PriceChangeRatioStr !=='-'">{{data.PriceChangeRatioStr}}</div>
           </td>
          <td style="width:120px">{{data.PremDiscountStr}}</td>
          <td class="rightFrozen" style="width:200px" alignFrozen="right" pFrozenColumn>
            <a [routerLink]="'/etf/register/' + data.FundNo" *ngIf="data.IsCanTrade">
              <button class="fund-btn">我要申購</button>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-panel class="p-panel"
      [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
      [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of fundEtfList;let i = index">
      <ng-template pTemplate="header">
        <a class="name" [routerLink]="'/etf/etf-info/' + data.FundNo" [queryParams]="{tab: 1}">{{data.StockNo}} {{data.ShortName}} <span class="warning-text">{{data.Warning}}</span></a>
      </ng-template>
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg" />
      </ng-template>
      <div class="panel-item">
        <div class="name">風險等級</div>
        <div class="desc">
          {{data.FundRiskLevelStr}}
        </div>
      </div>
      <div class="panel-item">
        <div class="name">基金類型</div>
        <div class="desc">{{data.FundTypeStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">幣別</div>
        <div class="desc">{{data.FundCurrencyStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">配息頻率</div>
        <div class="desc">{{data.FundDividendStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">淨值日期</div>
        <div class="desc">{{data.NavDate}}</div>
      </div>
      <div class="panel-item">
        <div class="name">淨值</div>
        <div class="desc"><div style="text-align: right;">{{data.NetValueStr}}<br><div [innerHTML]="data.TableAlert|html"></div></div></div>
      </div>
      <div class="panel-item">
        <div class="name">淨值日漲跌幅％</div>
        <!-- <div class="desc" [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}">
          {{data.ChangeStr}}&nbsp;&nbsp;&nbsp;&nbsp;{{data.ChangeRatioStr}}
        </div> -->
        <div class="desc"><div [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}">{{data.ChangeStr}}</div><div class="prcent" [ngClass]="{'pValue': data.Change > 0,'nValue': 0 > data.Change}" *ngIf="data.ChangeRatioStr !=='-'">&nbsp;{{data.ChangeRatioStr}}</div></div>
      </div>
      <div class="panel-item">
        <div class="name">收盤價</div>
        <div class="desc">{{data.PriceStr}}</div>
      </div>
      <div class="panel-item">
        <div class="name">市價日漲跌幅％</div>
        <!-- <div class="desc" [ngClass]="{'pValue': data.PriceChange > 0,'nValue': 0 > data.PriceChange}">
        {{data.PriceChangeStr}}&nbsp;&nbsp;&nbsp;&nbsp;{{data.PriceChangeRatioStr}}</div> -->
         <div class="desc"><div  [ngClass]="{'pValue': data.PriceChange > 0,'nValue': 0 > data.PriceChange}">{{data.PriceChangeStr}}</div><div class="prcent" [ngClass]="{'pValue': data.PriceChange > 0,'nValue': 0 > data.PriceChange}" *ngIf="data.PriceChangeRatioStr !=='-'">&nbsp;{{data.PriceChangeRatioStr}}</div></div>
      </div>
      <div class="panel-item">
        <div class="name">折溢價</div>
        <div class="desc">{{data.PremDiscountStr}}</div>
      </div>
      <a [routerLink]="'/etf/register/' + data.FundNo" *ngIf="data.IsCanTrade">
        <button class="fund-btn">我要申購</button>
      </a>
    </p-panel>
    <ng-container *ngFor="let data of fundEtfList;let i = index">
      <ng-container *ngIf="data.FundsSplit">
       <div *ngIf="data.Alert" [innerHtml]="data.Alert|html">
       </div>
      </ng-container>
    </ng-container>
  </div>

  <button *ngIf="TotalItems > fundEtfList.length" class="arrow-btn" (click)="more()">
    顯示更多
    <div class="top-arrow"></div>
  </button>
  <p class="no-data" *ngIf="0 >= TotalItems && isSubmit">無更多資料</p>

  <!-- <div class="note-wrap">
    資料來源：各基金公司<br>
    資料日期：各基金淨值之最新公佈日期<br>
    註：投信類貨幣型基金為年化報酬率<br>
    標注*之基金已下架並終止在臺灣銷售，此基金之相關資訊僅供原持有該基金單位數之投資人參考之用，非推介此基金
  </div> -->
</main>

<ng-container *ngFor="let data of dialogData | slice:0:3">
  <app-confirm-dialog *ngIf="visible" [visible]="visible" [data]="data" (visibleChange)="visibleChange($event)" (unCheck)="unCheck($event)"></app-confirm-dialog>
</ng-container>
