import { Component, OnInit } from '@angular/core';
import { mainData } from '../../interface/main-data';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  showLoading = false;
  constructor(
    public mainData$: mainData,
  ) { }

  ngOnInit(): void {
    this.mainData$.loading$.subscribe(val => {
      this.showLoading = val;
    })
  }
}
