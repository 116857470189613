import { Component, Input } from '@angular/core';
import {
  FundConstituentData
} from '../../../../shared/models/etf';
@Component({
  selector: 'app-etf-constituent',
  templateUrl: './etf-constituent.component.html',
  styleUrls: ['./etf-constituent.component.scss']
})
export class EtfConstituentComponent {
    addThousandsSeparator(numStr: string): string {
     // 先將字串轉換成數字
     const num = parseFloat(numStr);

     // 如果不是有效的數字，直接返回原字串
     if (isNaN(num)) {
         return numStr;
     }

      // 將數字轉換成千分位格式的字串，並保留原始的小數位數
     return num.toLocaleString(undefined, { minimumFractionDigits: numStr.split('.')[1]?.length });
    }

  @Input() data: FundConstituentData = {} as FundConstituentData;
}
