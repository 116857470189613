<p-dialog [closeOnEscape]="false" closeIcon="none" header="Header" [(visible)]="visible"
  [style]="{'width': lecture ? '400px' : '800px'}" [breakpoints]="{ '960px': '85vw','460px': '90vw' }"
  [ngClass]="{'lecture': lecture}">
  <ng-template pTemplate="header" >
    <span class="title" *ngIf="!lecture">{{data.title || '提醒'}}</span>
    <img src="/assets/images/close-icon.svg" alt="close-icon" class="closeimg" *ngIf="lecture" (click)="_visiblelecture()">
  </ng-template>
  <div class="dialog-content" [innerHTML]="data.message | html" *ngIf="!lecture">
  </div>
  <div class="lecturebox"  *ngIf="lecture">
    <img src="/assets/images/mail-icon.svg" alt="mail-icon" class="mailimg">
    <div class="title">感謝您的報名</div>
    <div class="text">我們已收到您的報名<br>後續將以電子郵件寄送活動相關通知<br>請留意您的電子郵件信箱</div>
  </div>

  <ng-template pTemplate="footer" *ngIf="privateType == 0">
    <button (click)="_visibleChange()"  *ngIf="!lecture">確定</button>
  </ng-template>

  <ng-template pTemplate="footer" *ngIf="privateType == 1">
    <button (click)="_visibleChange()" [disabled]="nobottom"  *ngIf="!lecture">確定</button>
  </ng-template>
</p-dialog>
