import { Component, Input } from '@angular/core';
import { BaseService } from '../../../../shared/services/base.service';
import {
  CIndexDescription, IndexMarketData
} from '../../../../shared/models/etf';
@Component({
  selector: 'app-etf-indices',
  templateUrl: './etf-indices.component.html',
  styleUrls: ['./etf-indices.component.scss']
})
export class EtfIndicesComponent {
  @Input() FundShortName: string= '';
  @Input() FundWarning: string= '';
  @Input() data: Array<CIndexDescription> = [];
  @Input() navData: IndexMarketData = {} as IndexMarketData;
  @Input() FundNo:string='';
  constructor(
    public baseService: BaseService,
  ) {
  }

  ngOnChanges(): void {
      this.data = this.data.map(item => {
        return {
          ...item,
          Val: item.Val.replace(/\r\n|\n/g, '<br>')
        };
      });
  }
}
