<app-bread-crumb></app-bread-crumb>
<div class="wrapper">
  <main class="top-main">
    <div class="calendar-left">
      <div class="calendar">
        <div class="row text-center">
          <div class="col-md-4">
            <section class="fund-dividend-schedule-header">
              <button class="pre" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="changeMonthByButton()">
                <img src="/assets/images/black-arrow.svg" alt="上一個月份" />
              </button>
              <div class="date-box">
                <!-- <input type="month" [(ngModel)]="dayPickerDate" (change)="changeMonthByDayPicker()"  /> -->
                <div class="calendar-wrap">
                  <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
                  <p-calendar view="month" class="calendar" dateFormat="yy/mm" [(ngModel)]="dayPickerDate" [defaultDate]="dayPickerDate"
                    (onSelect)="changeMonthByDayPicker()"></p-calendar>
                </div>

              </div>

              <!-- <span class="text" mwlCalendarToday [(viewDate)]="viewDate"
                                    (viewDateChange)="changeMonthByButton()">Today</span> -->
              <button class="next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="changeMonthByButton()">
                <img src="/assets/images/black-arrow.svg" alt="下一個月份" />
              </button>
            </section>
          </div>
          <div id="mwl-calendar-month-view" [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (dayClicked)="onDayClicked($event)" (eventClicked)="onEventClicked($event)">
            </mwl-calendar-month-view>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-right">
      <div class="date">
        {{EventDate}}({{EventDay}})
      </div>
      <div class="fund-data"
        *ngIf="(selectFundData?.CFullNames && selectFundData.CFullNames.length) || (selectFTFData?.CFullNames && selectFTFData.CFullNames.length)">
        暫停計價基金
        <div class="title" *ngIf="selectFundData?.CFullNames">街口投信系列</div>
        <div class="item" *ngFor="let data of selectFundData?.CFullNames">
          {{data}}
        </div>

        <div class="title title2" *ngIf="selectFTFData?.CFullNames">ETF系列</div>
        <div class="item" *ngFor="let data of selectFTFData?.CFullNames">
          {{data}}
        </div>
      </div>
    </div>
  </main>
  <main>
    <div class="note-wrap">
      各基金相關配息資訊請至【基金配息資訊】單元查詢。<br>
      暫停計價原則為基金投資比重達該基金淨資產價值一定比例之主要投資所在國或地區之證券交易市場，得遇例假日休市停止交易。各基金公告之暫停計價日請參考各基金公開說明書之基金營業日規定，如有異動時，將另行公告通知。
      <br>※週六若遇台灣補行上班日，然海外市場並非交易日，故相關註冊或投資比重達公開說明書規定比例之海外市場或交易所若休市，則仍為基金之非營業日，詳細說明請參閱各基金公開說明書。
    </div>
  </main>
</div>
