import { Component } from '@angular/core';

@Component({
  selector: 'app-fund-compose',
  templateUrl: './fund-compose.component.html',
  styleUrls: ['./fund-compose.component.scss']
})
export class FundComposeComponent {

}
