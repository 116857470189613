<!-- //電腦版 -->
<main>
  <ol vocab="https://schema.org/" typeof="BreadcrumbList">
    <li property="itemListElement" typeof="ListItem">
      <a property="item" typeof="WebPage" [href]="domain">
        <span property="name">Home</span></a>
      <meta property="position" content="1">
    </li>
    <li property="itemListElement" typeof="ListItem">
      <a property="item" typeof="WebPage" routerLink="/search">
        <span property="name">搜尋結果</span></a>
      <meta property="position" content="2">
    </li>
  </ol>
  <!-- <div class="searchheader">
    Home <span class="gray">/ 搜尋結果</span>
  </div> -->

</main>
<main>
  <form>
    <div class="searchbox">
      <div class="text">關鍵字</div>
      <p-autoComplete
        class="autoComplete"
        placeholder="請輸入關鍵字"
        field="CName"
        name="CName"
        emptyMessage="無資料"
        [(ngModel)]="selectedItem"
        [suggestions]="hotKeywords"
        (onSelect)="getKeywords($event)"
        [completeOnFocus]="true"
        (completeMethod)="search($event)">
      </p-autoComplete>
      <button type="submit" class="butn" (click)="getSearchKeywords()">
        <img src="/assets/images/white_search_icon.svg">
        搜尋
      </button>
    </div>
  </form>
</main>
<div class="tab">
    <p-tabView class="web" [(activeIndex)]="activeIndex" (onChange)="selectTabChange($event)">
      <p-tabPanel *ngFor="let item of SearchData.Tab">
        <ng-template pTemplate="header">
          <span class="tabPanel-title">{{item.CFunctionName}}</span>
          <span class="tabPanel-Count">({{item.Count}})</span>
        </ng-template>
        <div class="wrapper">
          <main>
            <div class="searchcontent">
              共 {{SearchData.Tab[0].Count}} 筆搜尋結果
            </div>
            <div class="searchtable">
              <table class="web">
                <tbody>
                  <tr *ngFor="let item of SearchData.Data">
                    <td>
                      <span class="mark">
                        {{item.CFunctionName}}
                      </span>
                      <a *ngIf="item.CUnitType == 0" [href]="item.CLinkType == 2 ? item.CLinkFile : item.CUrl" [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                        {{item.CName}}<span class="warning-text">{{item.CWarning}}</span>
                      </a>
                      <a *ngIf="item.CUnitType == 1 || item.CUnitType == 2" [href]="item.CLinkType == 2 ? item.CLinkFile : item.CUrl"   [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                        {{item.CName}}
                      </a>
                      <a *ngIf="item.CUnitType == 3" [href]="item.CLinkType == 2 ? item.CLinkFile : item.CUrl"  [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                        {{item.CName}}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
          <button *ngIf="(SearchData.Data && SearchData.Data.length) < TotalItems" class="arrow-btn" (click)="more()">
            查看更多
            <div class="top-arrow"></div>
          </button>
          <p class="no-data" *ngIf="(SearchData.Data && SearchData.Data.length) >= TotalItems" >無更多資料</p>
        </div>
      </p-tabPanel>
    </p-tabView>

</div>

<div class="drop mb">
  <main *ngIf="SearchTabs.length">
    <p-dropdown [options]="SearchTabs" [(ngModel)]="selectedTab" optionLabel="CFunctionName" (ngModelChange)="selectChange($event)">
      <ng-template pTemplate="selectedItem">
        <div>{{selectedTab && selectedTab.CFunctionName}}({{selectedTab && selectedTab.Count}})
        </div>
      </ng-template>
      <ng-template let-tab pTemplate="item">
        <div class="flex align-items-center gap-2">
            <div>{{ tab.CFunctionName }}({{tab.Count}})</div>
      </div>
      </ng-template>
    </p-dropdown>
    <div class="searchcontent">
      共 {{SearchData.Tab[0].Count}} 筆搜尋結果
    </div>
    <div class="searchtable">
      <table class="mb">
        <tbody>
          <tr *ngFor="let item of SearchData.Data">
            <td>
              <span class="mark">
                {{item.CFunctionName}}
              </span>
              <!-- <a *ngIf="item.CUnitType == 0" [href]="item.CUrl" [target]="item.CAnother == 1 ? '_blank': '_self'">
                {{item.CName}}<span class="warning-text">{{item.CWarning}}</span>
              </a>
              <a *ngIf="item.CUnitType == 1 || item.CUnitType == 2" [href]="item.CUrl"  [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                {{item.CName}}
              </a> -->
              <a *ngIf="item.CUnitType == 0" [href]="item.CLinkType == 2 ? item.CLinkFile : item.CUrl" [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                {{item.CName}}<span class="warning-text">{{item.CWarning}}</span>
              </a>
              <a *ngIf="item.CUnitType == 1 || item.CUnitType == 2" [href]="item.CLinkType == 2 ? item.CLinkFile : item.CUrl"   [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                {{item.CName}}
              </a>
              <a *ngIf="item.CUnitType == 3" [href]="item.CLinkType == 2 ? item.CLinkFile : item.CUrl"  [target]="item.CLinkType == 2 ? '_blank' : (item.CAnother == 1 ? '_blank' : '_self')">
                {{item.CName}}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button *ngIf="(SearchData.Data && SearchData.Data.length) < TotalItems" class="arrow-btn" (click)="more()">
      查看更多
      <div class="top-arrow"></div>
    </button>
    <p class="no-data" *ngIf="(SearchData.Data && SearchData.Data.length) >= TotalItems" >無更多資料</p>
  </main>
</div>
