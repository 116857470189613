import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase, ResponseIntradayNAVBase, ResponseETPNAVPrice } from '../models/base';
import { FundCategoryReq, FundEtfOverviewList, FundDetailReq,
  FundConstituent, FundDetailTopReq, ParticipatingDealer,FundTradeInfoReq,
  TradeInfoDatas, FundNAV, FundNAVvsPrice, FundIntradayNAV,ETPNAVPrices,
  FundIndex,
  FundIndexDetail
 } from '../models/etf';
import { ServiceBase } from 'src/app/services/service-base';

@Injectable({
  providedIn: 'root'
})
export class ETFService extends ServiceBase {
  Web$ = new BehaviorSubject<boolean>(true);
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Fund}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  GetETFOverviewList(request: FundCategoryReq): Observable<ResponseBase<FundEtfOverviewList[]>> {
    const url = `${this.baseUrl}/GetETFOverviewList`;
    return this.http.post<ResponseBase<FundEtfOverviewList[]>>(
      url,
      request,
      this.httpOptions);
  }

  //取得基金成分股
  GetFundConstituent(request: FundDetailReq): Observable<ResponseBase<FundConstituent>> {
    const url = `${this.baseUrl}/GetFundConstituent`;
    return this.http.post<ResponseBase<FundConstituent>>(
      url,
      request,
      this.httpOptions);
  }

  //流動量提供與參與券商
  GetParticipatingDealer(request: FundDetailTopReq): Observable<ResponseBase<ParticipatingDealer[]>> {
    const url = `${this.baseUrl}/GetParticipatingDealer`;
    return this.http.post<ResponseBase<ParticipatingDealer[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetFundTradeInfo(request: FundTradeInfoReq): Observable<ResponseBase<TradeInfoDatas>> {
    const url = `${this.baseUrl}/GetFundTradeInfo`;
    return this.http.post<ResponseBase<TradeInfoDatas>>(
      url,
      request,
      this.httpOptions);
  }

  //淨值VS收盤價
  GetFundNAVvsPrice(request: FundNAV): Observable<ResponseBase<FundNAVvsPrice>> {
    const url = `${this.baseUrl}/GetFundNAVvsPrice`;
    return this.http.post<ResponseBase<FundNAVvsPrice>>(
      url,
      request,
      this.httpOptions);
  }

  //盤中預估淨值與市價
  GetFundIntradayNAV(): Observable<ResponseIntradayNAVBase<FundIntradayNAV>> {
    const url = `${this.baseUrl}/GetFundIntradayNAV`;
    return this.http.get<ResponseIntradayNAVBase<FundIntradayNAV>>(
      url,
      this.httpOptions);
  }

  //盤中及時預估淨值
  GetFundIntradayNAVPrice(request: string): Observable<ResponseIntradayNAVBase<ETPNAVPrices>> {
    let data = {
      FundNo: request
    }
    const url = `${this.baseUrl}/GetFundIntradayNAVPrice`;
    return this.http.post<ResponseIntradayNAVBase<ETPNAVPrices>>(
      url,
      data,
      this.httpOptions);
  }

  //取得指數行情
  GetTrackDiffHistory(request:FundIndex): Observable<ResponseBase<FundIndexDetail>> {
    const url = `${this.baseUrl}/GetTrackDiffHistory`;
    return this.http.post<ResponseBase<FundIndexDetail>>(
      url,
      request,
      this.httpOptions);
  }
}
