import { Component, HostListener } from '@angular/core';
import { MenuService } from './../../../shared/services/Menu.service';
import { GetMenu } from '../../../shared/models/menu';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Router, ActivatedRoute } from '@angular/router';
import { mainData } from '../../../shared/interface/main-data';
import { SearchReq, HotKeywords, SearchRes, Tab } from '../../../shared/models/menu';
import { HomeService } from '../../../shared/services/home.service';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  menus:GetMenu[]=[];
  open = false; //手機板漢堡選單開合狀態
  menuId = 0; //目前預設menu選單id
  bgShow = false;
  dropMenuShow = false;
  searchopen = false;
  //關鍵字
  selectedItem:HotKeywords | string = {} as HotKeywords;
  allHotKeywords:HotKeywords[] = []; //所有關鍵字
  hotKeywords:HotKeywords[] = []; //篩選後關鍵字
  SearchData:SearchRes = {} as SearchRes;
  constructor(
    public menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    public mainData$: mainData,
    public homeService: HomeService,
    public baseService: BaseService,
  ) {
    this.getMenu();
    this.GetHotKeywords();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let scrWidth = window.innerWidth;
    if (scrWidth >= 1024) {
      this.open = false;
    }
  }
  getMenu() {
    this.menuService.Menus().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.menus = res.Entries;
        this.menuService.Headers$.next(this.menus)
      }
    });
  }

  getMenuId(id:number) {
    this.menuId = id;
    this.bgShow = true;
    this.dropMenuShow = true;
  }

  goMenuUrl(purl:string,url:string,id:number) {
    this.bgShow = false;
    this.dropMenuShow = false;
    this.open = false;
  }

  bgClose() {
    this.bgShow = false;
    this.menuId = 0;
  }

  footerID(){
    this.baseService.footerID$.next({CId:1});
  }

  search(){
    this.searchopen = !this.searchopen;
  }

  mbclose(){
    this.selectedItem ='';
  }

  getSearch() {
    this.open=false;
    this.router.navigate(['/search'], {
      queryParams: {
        key: this.selectedItem
      }
    });
    this.searchopen = false;
  }

  //取得熱門關鍵字
  GetHotKeywords() {
    this.menuService.GetHotKeywords().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.hotKeywords = res.Entries;
        this.allHotKeywords = res.Entries;
      }
    });
  }

  getKeywords(e:any) {
    this.selectedItem = e.CName
    this.getSearch();
  }

  getSearchKey(event:any) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.allHotKeywords.length; i++) {
      let country = this.allHotKeywords[i];
      if (country.CName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.hotKeywords = filtered;
  }
}
