import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase, Popup } from '../models/base';
import { ServiceBase } from 'src/app/services/service-base';
import { Articledata, ArticledataID, QAList, QAReq, ResponseArticle, ResponseArticleID } from '../models/article';
import { ResponseContact, Responsefeeback } from '../models/activity';
import { ImgToken } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {
  Web$ = new BehaviorSubject<boolean>(true);
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.User}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  // 取得驗證碼
  GetCaptchaImg(): Observable<ResponseBase<ImgToken>> {
    const url = `${this.baseUrl}/GetCaptchaImg`;
    // const data = {UnitAfterID:request}
    return this.http.post<ResponseBase<ImgToken>>(
      url,
      // data,
      this.httpOptions);
  }


}
