import { Component} from '@angular/core';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { Subject } from 'rxjs';
import { FundService } from './../../shared/services/fund.service';
import { FundCalendarReq, FundCalendarData, InBorderCalendarActivitys } from '../../shared/models/fund';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import * as moment from 'moment';
import { mainData } from '../../shared/interface/main-data';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#C9191D',
    secondary: 'ETF系列',
  },
  orange: {
    primary: '#FFDD64',
    secondary: '街口投信系列',
  },
  black: {
    primary: '#3e454d',
    secondary: '暫停交易日',
  },
  black2: {
    primary: '#C9191D',
    secondary: '暫停計價日',
  },
};

// declare var $: any;
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  colors: any = [];
  viewDate: Date = new Date();
  dayPickerDate: Date = new Date();
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  refresh = new Subject<void>();
  activeDayIsOpen: boolean = true;
  view: CalendarView = CalendarView.Month;
  request: FundCalendarReq = {} as FundCalendarReq;
  FundCalendarData: FundCalendarData[] = [];
  selectFTFData:InBorderCalendarActivitys = {} as InBorderCalendarActivitys;
  selectFundData:InBorderCalendarActivitys = {} as InBorderCalendarActivitys;
  EventDate:string = moment(this.viewDate).format("YYYY年MM月DD日");; //被點選的日期
  EventDay:string = ''; //被點選的星期
  constructor(
    public fundService: FundService,
    public mainData$: mainData
  ) {
    this.request.Types = [3];
    this.getData(new Date());
    this.getDay(new Date());
  }

  //取得日期
  getData(date:Date) {
    let currentDate = date;
    // 當月份的第一天
    let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // 取得下个月的第一天，然候减去一天得到当前月份的最后一天
    let nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    let lastDay = new Date(nextMonth.getTime() - 1);
    this.request.SDate = new Date(firstDay.setHours(firstDay.getHours() + 8));
    this.request.EDate = new Date(lastDay.setHours(lastDay.getHours() + 8));
    this.GetFundCalendar();
  }

  //api
  GetFundCalendar() {
    this.fundService.GetFundCalendar(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundCalendarData = res.Entries;
        this.events = [];
        if(this.FundCalendarData.length)this.getEvents();
      }
      this.getSelectedDate(new Date());
      this.mainData$.loading$.next(false);
    });
  }

  //新增事件到行事曆
  getEvents() {
    this.FundCalendarData.map(val => {
      if(val.GeneralFund.length) {
        this.events.push({
          title:'街口投信系列',
          start:new Date(val.CDataDt),
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          color: { ...colors['orange'] },
          meta: val
        });
      }
      if(val.ETF.length) {
        this.events.push({
          title:'ETF系列',
          start:new Date(val.CDataDt),
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          color: { ...colors['red'] },
          meta: val
        });
      }
    })
    setTimeout(() => {
      this.changeTitle();
    }, 100);
  }

  //取得點擊日期
  onDayClicked(e:any) {
    this.EventDate = moment(e.day.date).format("YYYY年MM月DD日");
    this.getSelectedDate(e.day.date) //取得選取到的日期
    this.getDay(e.day.date)
    if(e.day.events.length){
      const ftfData = e.day.events.map((event: any) => event.meta.ETF.find((val: any) => val.CType == 3));
      this.selectFTFData = ftfData[0];
      const fundData = e.day.events.map((event: any) => event.meta.GeneralFund.find((val: any) => val.CType == 3));
      this.selectFundData = fundData[0];
    }
    else{
      this.selectFTFData = {} as InBorderCalendarActivitys;
      this.selectFundData = {} as InBorderCalendarActivitys;
    }
  }

    //取得點擊事件
    onEventClicked(e:any) {
      this.EventDate =  moment(e.event.start).format("YYYY年MM月DD日");
      this.selectFTFData = e.event.meta.ETF.find((val:any) => val.CType == 3)
      this.selectFundData = e.event.meta.GeneralFund.find((val:any) => val.CType == 3)
      this.getSelectedDate(e.event.start) //取得選取到的日期
      this.getDay(e.event.start)
    }

  //取得選取到的日期
  getSelectedDate(date:Date) {
    const calEvent = document.getElementsByClassName('cal-day-number');
    let getDay = new Date(date).getDate(); //取得日期
    let getMonth = new Date(date).getMonth() + 1; //取得日期
    if (calEvent.length) {
      [].slice.call(calEvent).forEach((element: any) => {
        let Month = 0;
        let stringM = element?.parentElement?.parentElement?.getAttribute('aria-label')
        if(stringM.includes('December')) Month = 12;
        else if(stringM.includes('November')) Month = 11;
        else if(stringM.includes('October')) Month = 10;
        else if(stringM.includes('September')) Month = 9;
        else if(stringM.includes('August')) Month = 8;
        else if(stringM.includes('July')) Month = 7;
        else if(stringM.includes('June')) Month = 6;
        else if(stringM.includes('May')) Month = 5;
        else if(stringM.includes('April')) Month = 4;
        else if(stringM.includes('March')) Month = 3;
        else if(stringM.includes('February')) Month = 2;
        else if(stringM.includes('January')) Month = 1;

        if(element.innerHTML == `${getDay}`) {
        }

        if(element.innerHTML == `${getDay}` && (Month == getMonth)) {
          element.classList.add('calSelected');
        }
        else element.classList.remove('calSelected')
      });
    }
  }


  getDay(date:Date) {
    let weekDays = ["日", "一", "二", "三", "四", "五", "六"];
    let dayOfWeek = date.getDay();
    this.EventDay = weekDays[dayOfWeek];
  }

  changeMonthByDayPicker() {
    this.viewDate = new Date(this.dayPickerDate);
    this.getData(new Date(this.dayPickerDate));
  }

  changeMonthByButton() {
    this.dayPickerDate = this.viewDate;
    this.getData(new Date(this.dayPickerDate));
  }

  changeTitle() {
    const calEvent = document.getElementsByClassName('cal-event');
    const pageWidth = document.documentElement.scrollWidth;
    if (calEvent.length) {
      [].slice.call(calEvent).forEach((element: any) => {
        switch (element.style.backgroundColor) {
          case 'rgb(201, 25, 29)':
            if (pageWidth > 768) {
              element.innerHTML='<span class="calEvent">ETF系列<span>';
            }
          break;

          case 'rgb(255, 221, 100)':
            if (pageWidth > 768) {
              element.innerHTML='<span class="calEvent calEvent2">街口投信系列<span>';
            }
          break;
        }
      });
    }
  }

}
