<header id="header">
  <div class="headerline">
    <div class="headerline1"></div>
    <div class="headerline2"></div>
  </div>
  <main>
    <div class="navToggle" (click)="open = !open" *ngIf="!searchopen">
      <div class="line" [ngClass]="{'line1': open}"></div>
      <div class="line" [ngClass]="{'line2': open}"></div>
      <div class="line" [ngClass]="{'line3': open}"></div>
    </div>
    <a routerLink="/" (click)="bgClose();footerID()" class="web"><img class="logo" src="/assets/images/Logo.png"
        alt="街口投信LOGO" /></a>
    <a routerLink="/" (click)="bgClose();footerID()" class="mb mbmargin" *ngIf="!searchopen"><img class="logo"
        src="/assets/images/Logo.png" alt="街口投信LOGO" /></a>
    <!-- <div class="searchbox mb" *ngIf="searchopen">
      <div class="mbsearch">
        <img src="/assets/images/web_search_icon.svg" alt="search-icon">
        <input [(ngModel)]="searchKey" placeholder="請輸入關鍵字">
        <p-autoComplete
          class="autoComplete"
          placeholder="請輸入關鍵字"
          field="CName"
          name="CName"
          emptyMessage="無資料"
          [(ngModel)]="selectedItem"
          [suggestions]="hotKeywords"
          [completeOnFocus]="true"
          (onSelect)="getKeywords($event)"
          (completeMethod)="getSearchKey($event)">
        </p-autoComplete>
        <div class="mbsearchbutn" (click)="getSearch()">搜尋</div>
      </div>
      <img class="mbclose" src="/assets/images/clear-all.svg" *ngIf="searchopen" alt="close-icon" (click)="search()">
    </div> -->
    <div class="nav-wrap">
      <nav class="menu">
        <ul>
          <li [ngClass]="{'li-active': menuId == menu.CId }" *ngFor="let menu of menus">
            <div class="drop-title" (mouseenter)="getMenuId(menu.CId)">{{menu.CName}}</div>
            <div class="drop-menu" (mouseleave)="bgClose()"
              [ngClass]="{'drop-menu-active': menuId == menu.CId && dropMenuShow}">
              <a class="drop-item" href="{{item.CPageUrl}}"
                [target]="item.CIsOpenNewWindow == true ? '_blank' : '_parent'" *ngFor="let item of menu.ChildUnits"
                (click)="goMenuUrl(menu.CPageUrl,item.CPageUrl,item.CId)">{{item.CName}}</a>
            </div>
          </li>
        </ul>
      </nav>
      <div class="social-icons" *ngIf="!searchopen">
        <a *ngFor="let data of homeService.socialIcon" [href]="data.CUrl" target="_blank">
          <img *ngIf="data.CType == 2" class="social-icon" [src]="data.CIcon" [alt]="data.CName" />
          <span class="socialText" *ngIf="data.CType == 1">{{data.CName}}</span>
        </a>
        <!-- <a href="https://www.facebook.com/people/%E8%A1%97%E5%8F%A3%E6%8A%95%E4%BF%A1/100063892302010/" target="_blank">
          <img class="social-icon" src="/assets/images/fb.svg" alt="FB-Link" />
        </a>
        <a href="https://www.instagram.com/jkoamam/" target="_blank">
          <img class="social-icon" src="/assets/images/ig.svg" alt="IG-Link" />
        </a>
        <a href="https://www.youtube.com/channel/UCFRNOqW3tQ-3EXhk8bEHVYg/" target="_blank">
          <img class="social-icon" src="/assets/images/youtube.svg" alt="youtube-Link" />
        </a> -->
      </div>
      <a href="https://ec.jkoam.com/login.php" target="_blank" *ngIf="!searchopen"><button>線上交易</button></a>
      <a href="https://ec.jkoam.com/open_account/entrance.php" *ngIf="!searchopen" target="_blank"><button
          class="open-account">線上開戶</button></a>
      <div class="searchbox web allserach" *ngIf="searchopen">
        <div class="mbsearch">
          <img src="/assets/images/web_search_icon.svg" alt="search-icon">
          <!-- <input [(ngModel)]="searchKey" placeholder="請輸入關鍵字"> -->
          <p-autoComplete class="autoComplete" placeholder="請輸入關鍵字" field="CName" name="CName" emptyMessage="無資料"
            [(ngModel)]="selectedItem" [suggestions]="hotKeywords" [completeOnFocus]="true"
            (onSelect)="getKeywords($event)" (completeMethod)="getSearchKey($event)">
          </p-autoComplete>
          <div class="mbsearchbutn" (click)="getSearch()">搜尋</div>
        </div>
        <img class="mbclose" src="/assets/images/clear-all.svg" *ngIf="searchopen" alt="close-icon" (click)="search()">
      </div>
      <div class="search" *ngIf="!searchopen" (click)="search()">
        <div class="search_icon web"></div>
        <span>搜尋</span>
      </div>

    </div>
  </main>
  <div [ngClass]="{'bk-bg':bgShow}" (click)="bgClose()"></div>

  <!-- RWD -->
  <!-- <div class="top_m">
        <div class="wrapper">
            <ng-container>
                <button class="navToggle" (click)="open = !open">
                    <div class="line" [ngClass]="{'line1': open}"></div>
                    <div class="line" [ngClass]="{'line2': open}"></div>
                    <div class="line" [ngClass]="{'line3': open}"></div>
                </button>
            </ng-container>

            <div class="logoWrap">
                <a href="https://www.esunbank.com.tw/bank/personal" class="logo" target="_blank">
                    <img class="logo" src="/assets/images/Logo.png" />
                </a>
            </div>

        </div>
    </div> -->
</header>
<div class="top_m" [ngClass]="{'open':open}">
  <div *ngIf="open">
    <div class="serchbox_mb">
      <div class="searchbox mb allserach">
        <div class="mbsearch">
          <img src="/assets/images/web_search_icon.svg" alt="search-icon">
          <!-- <input [(ngModel)]="searchKey" placeholder="請輸入關鍵字"> -->
          <p-autoComplete class="autoComplete" placeholder="請輸入關鍵字" field="CName" name="CName" emptyMessage="無資料"
            [(ngModel)]="selectedItem" [suggestions]="hotKeywords" [completeOnFocus]="true"
            (onSelect)="getKeywords($event)" (completeMethod)="getSearchKey($event)">
          </p-autoComplete>
          <div class="mbsearchbutn" (click)="getSearch()">搜尋</div>
        </div>
        <img class="mbclose" src="/assets/images/clear-all.svg" alt="close-icon" (click)="mbclose()">
      </div>
    </div>
    <p-panel class="p-panel" toggler="header" [header]="menu.CName" [toggleable]="true" [collapsed]="true"
      *ngFor="let menu of menus">
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg" />
        <img class="red-arrow" src="/assets/images/top-arrow.svg" />
      </ng-template>

      <a class="drop-item" href="{{item.CPageUrl}}" [target]="item.CIsOpenNewWindow == true ? '_blank' : '_parent'"
        *ngFor="let item of menu.ChildUnits"
        (click)="goMenuUrl(menu.CPageUrl,item.CPageUrl,item.CId)">{{item.CName}}</a>
    </p-panel>
    <!-- <div class="btn-wrap">
      <a href="https://ec.jkoam.com/login.php" target="_blank"><button>線上交易</button></a>
      <a href="https://ec.jkoam.com/open_account/entrance.php" target="_blank"><button
          class="open-account">線上開戶</button></a>
    </div> -->

    <div class="social-icons-mb">
      <a *ngFor="let data of homeService.socialIcon" [href]="data.CUrl" target="_blank">
        <img *ngIf="data.CType == 2" class="social-icon" [src]="data.CIcon" [alt]="data.CName" />
        <span class="socialText" *ngIf="data.CType == 1">{{data.CName}}</span>
      </a>
      <!-- <a href="https://www.instagram.com/jkoamam/" target="_blank">
        <img class="social-icon" src="/assets/images/ig.svg" alt="IG-Link" />
      </a>
      <a href="https://www.youtube.com/channel/UCFRNOqW3tQ-3EXhk8bEHVYg/" target="_blank">
        <img class="social-icon" src="/assets/images/youtube.svg" alt="youtube-Link" />
      </a> -->
    </div>
  </div>
</div>
