import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ServiceBase } from 'src/app/services/service-base';
import { LibHttpUrl } from '../../api/public-api';
import { PageReq, ResponseBase } from '../models/base';
import { GetMenu, BreadCrumbInfo, BreadCrumbReq, SearchReq, HotKeywords, SearchRes } from '../models/menu';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Menu}`;
  Headers$ = new BehaviorSubject<GetMenu[]>([]);
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  Menus(): Observable<ResponseBase<GetMenu[]>> {
    const url = `${this.baseUrl}/GetUnit`;
    return this.http.get<ResponseBase<GetMenu[]>>(
      url,
      this.httpOptions);
  }

  GetBreadCrumbInfo(request:BreadCrumbReq): Observable<ResponseBase<BreadCrumbInfo>> {
    const url = `${this.baseUrl}/GetBreadCrumbInfo`;
    return this.http.post<ResponseBase<BreadCrumbInfo>>(
      url,
      request,
      this.httpOptions);
  }

  generateCanonicalUrl(url:string) {
    const canonicalUrl = environment.domain + url;
    const linkElement = document.querySelector('link[rel="canonical"]');
    if (linkElement) {
      linkElement.setAttribute('href', canonicalUrl);
    }
  }
  
  GetHotKeywords(): Observable<ResponseBase<HotKeywords[]>> {
    const url = `${this.baseUrl}/GetHotKeywords`;
    const request = ''
    return this.http.post<ResponseBase<HotKeywords[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetHomeSearch(request:SearchReq): Observable<ResponseBase<SearchRes>> {
    const url = `${this.baseUrl}/GetHomeSearch`;
    return this.http.post<ResponseBase<SearchRes>>(
      url,
      request,
      this.httpOptions);
  }
}
