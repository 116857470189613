import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ServiceBase } from 'src/app/services/service-base';
import { LibHttpUrl } from '../../api/public-api';
import { PageReq, ResponseBase } from '../models/base';
import { FundDetailReq, Comment, PDBranch, ETFOrderFormInfo, registerReq, ETFProduct, InquireReq, InquireRes, CancelReq } from '../models/purchase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Purchase}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  GetPD(request:FundDetailReq): Observable<ResponseBase<Comment[]>> {
    const url = `${this.baseUrl}/GetPD`;
    return this.http.post<ResponseBase<Comment[]>>(
      url,
      request,
      this.httpOptions);
  }

  GetPDBranch(request:number): Observable<ResponseBase<PDBranch[]>> {
    const url = `${this.baseUrl}/GetPDBranch`;
    const data = {ParentID:request}
    return this.http.post<ResponseBase<PDBranch[]>>(
      url,
      data,
      this.httpOptions);
  }

  GetETFOrderFormInfo(request:FundDetailReq): Observable<ResponseBase<ETFOrderFormInfo>> {
    const url = `${this.baseUrl}/GetETFOrderFormInfo`;
    return this.http.post<ResponseBase<ETFOrderFormInfo>>(
      url,
      request,
      this.httpOptions);
  }

  CreateOrder(request:registerReq): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/CreateOrder`;
    return this.http.post<ResponseBase<string>>(
      url,
      request,
      this.httpOptions);
  }

  GetProduct(): Observable<ResponseBase<ETFProduct[]>> {
    const url = `${this.baseUrl}/GetProduct`;
    return this.http.get<ResponseBase<ETFProduct[]>>(
      url,
      this.httpOptions);
  }

  PurchaseSearch(request:InquireReq): Observable<ResponseBase<InquireRes>> {
    const url = `${this.baseUrl}/PurchaseSearch`;
    return this.http.post<ResponseBase<InquireRes>>(
      url,
      request,
      this.httpOptions);
  }

  PurchaseCancle(request:CancelReq): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/PurchaseCancle`;
    return this.http.post<ResponseBase<string>>(
      url,
      request,
      this.httpOptions);
  }

  GetTime(): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/GetTime`;
    return this.http.get<ResponseBase<string>>(
      url,
      this.httpOptions);
  }

}
