import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, PLATFORM_ID
} from '@angular/core';
import { Category, FundCategory } from '../../../../shared/models/category';
import { FundCategoryReq, FundEtfOverviewList } from '../../../../shared/models/etf';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { CategoryService } from '../../../../shared/services/category.service';
import { ETFService } from '../../../../shared/services/etf.service';
import { BaseService } from '../../../../shared/services/base.service';
import { Popup, DialogData, PopupReq } from '../../../../shared/models/base';
import { mainData } from '../../../../shared/interface/main-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-etf-list',
  templateUrl: './etf-list.component.html',
  styleUrls: ['./etf-list.component.scss']
})
export class EtfListComponent {
  isBrowser: boolean;
  categoryData: Category[] = [];
  fundCategoryList: FundCategory[] = [];
  TotalItems = 0;
  request: FundCategoryReq = {} as FundCategoryReq;
  fundEtfList:FundEtfOverviewList[] = [];
  isSubmit = false; //是否搜尋過
  customers:any[]=[{},{}];
  isLeft = false;

  //警語彈窗
  dialogData: DialogData[] = [];
  visible = false;
  popupData: Popup[] = [];
  UpdateTime: Popup[] = [];
  ETFMsgDate = '';
  ETFMsgUpdate = '';
  agreeTimes = 0;
  PopupReq = new PopupReq;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public categoryService: CategoryService,
    public ETFService: ETFService,
    public baseService: BaseService,
    public mainData$: mainData,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.GetOverviewHotKeyword();
    this.GetSearchFundCategory();
    this.request.PageSize = 10;
    this.request.PageIndex = 1;
    this.submit();

    // ETF警語
    this.ETFMsgDate = localStorage.getItem('ETFMsg') || ''
    this.GetPopup();
  }

  //API
  GetSearchFundCategory() {
    this.categoryService.GetSearchFundCategory(3).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundCategoryList = res.Entries;
      }
    });
  }

  GetOverviewHotKeyword() {
    this.categoryService.GetOverviewHotKeyword({Page: 3}).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.categoryData = res.Entries;
      }
    });
  }

  GetETFOverviewList() {
    this.ETFService.GetETFOverviewList(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundEtfList = res.Entries;
        this.TotalItems = res.TotalItems;
      }
      this.mainData$.loading$.next(false);
    });
  }

  getScroll() {
    let element = document.getElementsByClassName('p-datatable-wrapper');
    let maxScrollLeft = element[0].scrollWidth - element[0].clientWidth;
    if(!this.isLeft) {
      element[0].scrollTo({
        left: maxScrollLeft,
        behavior: 'smooth'
      });
      this.isLeft = true;
    } else {
      element[0].scrollTo({
        left: 0,
        behavior: 'smooth'
      });
      this.isLeft = false;
    }

  }

  submit(e?: any) {
    this.request.Keyword = e?.Keyword || '';
    this.request.TypeIDs = [];
    this.request.AreaIDs = [];
    this.request.RiskLvIDs = [];
    this.request.DividendIDs = [];
    this.request.CurrencyTypeIDs = [];
    e?.data.filter((val: any) => { if (val.CodeType == 1) this.request.TypeIDs.push(val.CategoryID) })
    e?.data.filter((val: any) => { if (val.CodeType == 8) this.request.AreaIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 11) this.request.RiskLvIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 7) this.request.DividendIDs.push(val.CategoryID) });
    e?.data.filter((val: any) => { if (val.CodeType == 9) this.request.CurrencyTypeIDs.push(val.CategoryID) });
    this.getSubmit();
  }

  more() {
    this.request.PageSize = this.request.PageSize + 10;
    this.getSubmit();
  }

  getSubmit() {
    this.GetETFOverviewList();
  }

  GetPopup() {
    this.baseService.GetPopup(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.popupData = res.Entries;
        this.UpdateTime = this.popupData.sort(function (a, b) {
          let timeA: string = '';
          let timeB: string = '';
          timeA = a.UpdateTime;
          timeB = b.UpdateTime;

          if (timeA < timeB) {
            return 1;
          }
          if (timeA > timeB) {
            return -1;
          }
          return 0;
        })
        this.ETFMsgUpdate = localStorage.getItem('ETFMsgUpdate') || ''
        if ((!this.ETFMsgDate || this.baseService.getTimeout0hour(this.ETFMsgDate))|| (this.UpdateTime.length && this.ETFMsgUpdate !== this.UpdateTime[0].UpdateTime)) {
          this.OpenDialog();
        }
      }
    });
  }

  OpenDialog() {
    if(this.isBrowser)this.visible = true;
    this.dialogData = this.popupData.map(val => {
      return {
        title: val.Title,
        message: val.Content,
        data: val
      }
    });
  }

  visibleChange(e:any) {
    if(e) this.agreeTimes = this.agreeTimes + 1;
    if(this.agreeTimes == this.popupData.length || (this.agreeTimes === 3)) {
      localStorage.setItem('ETFMsg', new Date().toString());
      if (this.UpdateTime.length) localStorage.setItem('ETFMsgUpdate', this.UpdateTime[0].UpdateTime)
    }
  }

  unCheck(e: any) {
   // this.router.navigate(['/']);
  }

}
