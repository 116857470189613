import { SearchDetailReq } from '../../shared/models/event';
import { EventsService } from 'src/app/shared/services/events.service';
import { Component, OnInit, OnDestroy, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import {
  DomSanitizer,
  Meta,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
// highcharts
import * as Highcharts from 'highcharts';
import { ResponseBase } from '../../shared/models/base';
import { FundNAV, FundInvestment } from '../../shared/models/fund';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';

import { ILibHttpApi, LibHttpUrl } from 'src/app/api/public-api';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FundService } from 'src/app/shared/services/fund.service';
import { mainData } from '../../shared/interface/main-data';
import { enumDateList } from 'src/app/enum/enumDateList';
import { QAReq, FaqList } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { isPlatformBrowser } from '@angular/common';


declare var $: any;
declare var require: any;

// let sunburst = require('highcharts/modules/sunburst');
// const swiper = new Swiper('.swiper', {
//   modules: [Navigation, Pagination],
// });

// sunburst(Highcharts);

@Component({
  selector: 'app-event-site',
  templateUrl: './event-site.component.html',
  styleUrls: ['./event-site.component.scss'],
})
export class EventSiteComponent implements OnInit, OnDestroy {
  open = false; //手機板漢堡選單開合狀態

  isNav = false;
  isMobile = false;

  //常見問題
  getFaqListReq = new GetFaqListReq();
  getFaqListRes = new GetFaqListRes();
  activeMainCategory = 0;
  qaList: FaqList [] = [];

  //基金配息
  searchDetailReq: SearchDetailReq = new SearchDetailReq();
  searchDetail: any;
  expandIndex = 0;

  //淨值
  timeRange: string = '';
  fundDetailPriceReq: FundNAV = {} as FundNAV;
  lastFundDetailPriceReqStrDate: string = '';
  lastFundDetailPriceReqEndDate: string = '';

  //#region Highcharts
  Highcharts = Highcharts;
  updateFlag: boolean = true;
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  oneToOneFlag: boolean = true; // optional boolean, defaults to false

  // chartInstance: Highcharts.Chart;
  chartInstance: typeof Highcharts = Highcharts;
  isHighcharts = typeof Highcharts === 'object';
  // chartOptions: Highcharts.Options = {
  //   chart: {
  //     width:
  //       window.innerWidth > 1200
  //         ? 1200 // 1230 - 30 :
  //         : window.innerWidth > 992
  //           ? 900 // 960 - 30 :
  //           : window.innerWidth > 768
  //             ? 690 // 720 - 30 :
  //             : window.innerWidth > 576
  //               ? 510 // 540 - 30 :
  //               : window.innerWidth - 30,
  //   },
  //   title: {
  //     text: '淨值走勢',
  //     style: {
  //       fontSize: '22px',
  //       fontWeight: 'bold',
  //       color: '#3e454d',
  //     },
  //   },
  //   credits: undefined,
  //   subtitle: {
  //     text: '',
  //   },
  //   tooltip: {
  //     shared: true,
  //     formatter: function () {
  //       let _dateTime: number = this.x as number;
  //       const _dateStr = new Date(_dateTime).toLocaleDateString();
  //       return this.points?.reduce(function (s, point) {
  //         return (
  //           s +
  //           '<br/>' +
  //           point.series.name +
  //           ' ' +
  //           ':<br/><span style="color:' +
  //           point.color +
  //           '">\u25CF</span>績效:' +
  //           point.y +
  //           '%'
  //         );
  //       }, `<strong style="margin-bottom:5px">${_dateStr}</strong></br><strong>`);
  //     },
  //   },
  //   yAxis: {
  //     title: { text: '' },
  //     opposite: true,
  //     labels: {
  //       x: window.innerWidth > 1200 ? 5 : 0,
  //       formatter: function () {
  //         return (this.value as number).toFixed(2);
  //       },
  //       style: {
  //         fontSize: window.innerWidth > 1200 ? '16px' : '12px',
  //       },
  //     },
  //   },
  //   xAxis: {
  //     opposite: true,
  //     type: 'datetime',
  //     labels: {
  //       format: '{value:%Y/%m}',
  //       style: {
  //         fontSize: '16px',
  //       },
  //       step: 2,
  //     },
  //     crosshair: {
  //       width: 1,
  //       color: '#e1e1e1',
  //     },
  //   },
  //   plotOptions: {
  //     series: {
  //       marker: {
  //         enabled: false,
  //         states: {
  //           hover: {
  //             // enabled: false,
  //           },
  //         },
  //       },
  //     },
  //   },
  //   legend: {
  //     verticalAlign: 'top',
  //     align: 'left',
  //     itemStyle: {
  //       fontSize: '16px',
  //     },
  //   },
  //   series: [],
  // };
  //#endregion
  chartOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      data: [1, 2, 3],
      type: 'line',
      dataLabels: {
        enabled: false
      },
    }],
    legend: {
      enabled: false
    },
    colors: ['#C9191D', '#E3961C', '#29A1D8'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
         turboThreshold:0
      }
    },
    tooltip: {
      formatter: function () {
        return this.y + ' ';
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  }; // required

  //#region Highcharts2
  updateFlag2 = false;
  Highcharts2 = Highcharts;
  chartscolors2 = [
    '#F04D29',
    '#FAA634',
    '#FECD66',
    '#3AC2CF',
    '#48A7E0',
    '#004276',
    '#C6BD73',
    '#DCD8AD',
    '#89929D',
    '#3E454D',
  ];
  chartOptions2: Highcharts.Options = {
    chart: {
      width: window.innerWidth / 4,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    series: [
      {
        type: 'sunburst',
        // data: data20,
        // allowDrillToNode: false,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 8,
          },
        },
        levels: [
          {
            level: 1,
            // levelIsConstant: false,
            dataLabels: {
              // align: 'center',
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 60,
              },
              style: {
                fontSize: '22px',
                textOverflow: '',
                fontFamily: 'Microsoft JhengHei',
              },
              y: 8,
            },
          },
          {
            level: 2,
            // colorByPoint: true,
            dataLabels: {
              enabled: false,
            },
          },
        ],
      },
    ],
    tooltip: {
      valueDecimals: 2,
      headerFormat: '',
      pointFormat: '<b>{point.name}</b> : <b>{point.value:.2f}</b>',
    },
    credits: {
      enabled: false,
    },
  };
  //#endregion

  event: any = {};

  //
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    autoplay: false,
    autoplayTimeout: 6000,
    navText: ['', ''],
    merge: true,
    margin: 20,
    autoHeight: false,
    responsive: {
      //在特定寬度下顯示幾張圖
      0: {
        items: 1,
      },
      992: {
        items: 3,
      },
    },
  };

  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    autoplay: false,
    autoplayTimeout: 6000,
    navText: ['', ''],
    merge: true,
    margin: 20,
    autoHeight: true,
    responsive: {
      //在特定寬度下顯示幾張圖
      0: {
        items: 1,
      },
      992: {
        items: 4,
      },
    },
  };

  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    autoplay: false,
    autoplayTimeout: 6000,
    navText: ['', ''],
    merge: true,
    margin: 20,
    autoHeight: true,
    responsive: {
      //在特定寬度下顯示幾張圖
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
    },
  };
  IsPreview = false;
  dots: number[] = [0, 1, 2, 3, 4];
  enumDateList = enumDateList;
  DateName = '';
  QA : QAReq= {} as QAReq;

  // 投資組合
  FundInvestment: FundInvestment[] = [];
  runOutsideAngular: boolean = true;
  colors = ['#C3211D', '#E79F38', '#22CCC6', '#60A3B9', '#D6D628', '#71B5EF', '#1C5AE6', '#6EE0FC', '#C31D9D', '#FADE45','#E79F38', '#22CCC6', '#60A3B9', '#D6D628', '#71B5EF', '#1C5AE6', '#6EE0FC', '#C31D9D', '#FADE45'];
  isBrowser: boolean;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public eventsService: EventsService,
    private sanitizer: DomSanitizer,
    // private httpService: HttpService,
    private mhs: ILibHttpApi,
    private route: ActivatedRoute,
    // private datepipe: DatePipe,
    private metaService: Meta,
    private titleService: Title,
    public fundService: FundService,
    public mainData$: mainData,
    public aritcleService: AritcleService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  isActive = true;

  ngOnInit(): void {
    let eventNo = this.route.snapshot.params['EventNo'];
    this.IsPreview = Boolean(this.route.snapshot.queryParams['isPreview']);
    this.isMobile = window.innerWidth <= 992;

    this.eventsService.isShow.header = false;
    this.eventsService.isShow.footer = true;
    this.getEventById(eventNo, this.IsPreview);
    this.onResize();
  }

  ngAfterViewInit() {
    // 在視圖初始化後設置幻燈片高度
    // this.setSlideHeight();
  }

  ngAfterViewChecked() {
    if(this.isBrowser)this.setSlideHeight();
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    let scrWidth = window.innerWidth;
    if (scrWidth <= 992) {
      this.customOptions.dots = true;
      this.customOptions2.dots = true;
      this.customOptions3.dots = true;
    } else {
      this.customOptions.dots = false;
      this.customOptions2.dots = false;
      this.customOptions3.dots = false;
    }
  }

  setSlideHeight() {
    const carouselSlides = document.querySelectorAll('.slide-content') as NodeListOf<HTMLElement>;
    const carouselSlides2 = document.querySelectorAll('.slide-content2') as NodeListOf<HTMLElement>;
    const carouselSlides3 = document.querySelectorAll('.slide-content3') as NodeListOf<HTMLElement>;
    let maxHeight = 0;
    let maxHeight2 = 0;
    let maxHeight3 = 0;
    //3張輪播
    carouselSlides.forEach((slide) => {
      const slideHeight = slide.offsetHeight;
      if (slideHeight > maxHeight) {
        maxHeight = slideHeight;
      }
    });
    carouselSlides.forEach((slide) => {
      slide.style.height = maxHeight + 'px';
    });

    //4張輪播
    carouselSlides2.forEach((slide) => {
      const slideHeight = slide.offsetHeight;
      if (slideHeight > maxHeight2) {
        maxHeight2 = slideHeight;
      }
    });
    carouselSlides2.forEach((slide) => {
      slide.style.height = maxHeight2 + 'px';
    });

    //影片輪播
    carouselSlides3.forEach((slide) => {
      const slideHeight = slide.offsetHeight;
      if (slideHeight > maxHeight3) {
        maxHeight3 = slideHeight;
      }
    });
    carouselSlides3.forEach((slide) => {
      slide.style.height = maxHeight3 + 'px';
    });
  }

  ngOnDestroy() {
    this.eventsService.isShow.header = true;
    this.eventsService.isShow.footer = true;
  }

  getEventById(eventNo: string, IsPreview: boolean) {
    this.mhs.Api('/' + LibHttpUrl.Event + '/GetEventById')
      .post<ResponseBase<any>>({
        EventNo: eventNo,
        IsPreview: IsPreview || false
      })
      .subscribe(x => {
        if (x.StatusCode == EnumStatusCode.Success) {
          this.event = x.Entries;
          if (!this.eventsService.BreadcrumbLast) {
            this.eventsService.BreadcrumbLast = { label: '' };
          }
          this.eventsService.BreadcrumbLast.label = x.Entries.CFunctionName;
          if (x.Entries.MetaDataInfo) {
            this.titleService.setTitle(x.Entries.MetaDataInfo.CTitle);
            this.metaService.updateTag({
              name: 'title',
              content: x.Entries.MetaDataInfo.CTitle
            });
            this.metaService.updateTag({
              name: 'description',
              content: x.Entries.MetaDataInfo.CContent,
            });
            this.metaService.updateTag({
              name: 'keywords',
              content: x.Entries.MetaDataInfo.CKeyword,
            });
          }

          this.event.SubItems.forEach((item: any) => {
            switch (item.CType) {
              case '8':
              case '9':
              case '10':
                item.ShowData = this.convertType8(item.JsonColumns);
                break;
              case '12':
                item.ShowData = this.convertType12(item.JsonColumns);
                break;
              case '11':
              case '13':
              case '14':
              case '15':
                item.YoutubeUrl = this.getU2Video(
                  item.JsonColumns?.IframeCode1
                );
                break;
              case '16':
                //基金配息
                item.fundPerformance = new Array();
                let dividendFundIDs: string[] = [];
                item.JsonColumns?.FundList.forEach((a: any) => {
                  dividendFundIDs.push(a.FundNo);
                });

                let data1: any = {
                  Keyword: '',
                  TypeIDs: [],
                  AreaIDs: [],
                  RiskLvIDs: [],
                  DividendIDs: [],
                  CurrencyTypeIDs: [],
                  YearList: [],
                  FundNoList: dividendFundIDs,
                }

                this.fundService.GetNewestFundYieldList(data1).subscribe((res) => {
                  if (res.StatusCode == EnumStatusCode.Success) {
                    item.fundPerformance = res.Entries;
                  }
                  this.mainData$.loading$.next(false);
                });
                // this.postSearchDetail(dividendFundIDsStr).subscribe((x:any) => {
                //   item.fundPerformance = x?.data?.fundDatas;

                // });
                break;
              case '17':
                //基金績效
                item.fundPerformance = new Array();
                let perFundIDs: string[] = [];
                item.JsonColumns?.FundList.forEach((a: any) => {
                  perFundIDs.push(a.FundNo);
                });

                let data: any = {
                  Keyword: '',
                  TypeIDs: [],
                  AreaIDs: [],
                  RiskLvIDs: [],
                  DividendIDs: [],
                  CurrencyTypeIDs: [],
                  FundNoList: perFundIDs,
                };

                this.fundService.GetFundRankImportList(data).subscribe((res) => {
                  if (res.StatusCode == EnumStatusCode.Success) {
                    item.fundPerformance = res.Entries;
                  }
                  this.mainData$.loading$.next(false);
                });
                break;
              case '18':
                // 基金小檔案
                this.fundService.GetFundDetail({
                  FundNo: item.JsonColumns?.FundList[0]?.FundNo,
                  IsPreview: this.IsPreview
                }).subscribe((res) => {
                  if (res.StatusCode == EnumStatusCode.Success) {
                    item.fundInfo = res.Entries;
                  }
                  this.mainData$.loading$.next(false);
                });
                break;
              case '19':
                item.JsonColumns?.FundList.map((val:any) => {
                  this.fundService.GetFundInvestment({
                    FundNo: val.FundNo,
                  }).subscribe((res) => {
                    if (res.StatusCode == EnumStatusCode.Success) {
                      this.FundInvestment = this.FundInvestment.concat(res.Entries);
                      this.FundInvestment = this.FundInvestment.map(val => {
                        return {
                          ...val,
                          chartOptions: {
                            ...this.chartOptions,
                            series: [{
                              data: val.Data,
                              type: 'pie',
                              dataLabels: {
                                enabled: false
                              },
                              colors: this.colors,
                              innerSize: '80%',
                            }]
                          }
                        }
                      })
                    }
                    this.mainData$.loading$.next(false);
                  });
                })
                break;
              case '20':
                this.selectTimeRange(
                  item.JsonColumns?.NavRangeStr,
                  item.JsonColumns?.FundList
                );
                break;
              case '21':
                this.GetQAlist(item.JsonColumns?.QACategoryID);
                break;
              case '22':
                item.ShowData = this.convertType22(item.JsonColumns, item.JsonColumns.FundBrandList);
                break;
              default:
                item.ShowData = [];
                break;
            }
          });
          // this.setChartData();
          // this.setChartData2();

          /* this.postSearchDetail(); */

          //header版型
          if (this.event.CLayoutType == 1 && window.innerWidth >= 992) {
            this.eventsService.isShow.header = true;
          } else {
            this.eventsService.isShow.header = false;
          }
          //footer版型
          // this.eventsService.footerID.next(this.event.CFooterID);
          this.setSlideHeight();

        }
      });
  }

  getU2Video(url: string): SafeResourceUrl {
    if (url) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = url.trim().match(regExp);

      if (match && match[7].length === 11) {
        // 'https://img.youtube.com/vi/' + match[7] + '/0.jpg';

        const videoLink = 'https://www.youtube.com/embed/' + match[7];

        // 嵌入iframe前先將網址替換為安全的
        return this.sanitizer.bypassSecurityTrustResourceUrl(videoLink + '');
      }
    }

    return '';
  }
  convertType8(obj: any) {
    let result: any[] = [];
    if (obj) {
      if (obj.PhotoFile1) {
        result.push({
          PhotoFile: obj.PhotoFile1,
          Description: obj.Description1,
          ImgSummary: obj.ImgSummary1,
        });
      }
      if (obj.PhotoFile2) {
        result.push({
          PhotoFile: obj.PhotoFile2,
          Description: obj.Description2,
          ImgSummary: obj.ImgSummary2,
        });
      }
      if (obj.PhotoFile3) {
        result.push({
          PhotoFile: obj.PhotoFile3,
          Description: obj.Description3,
          ImgSummary: obj.ImgSummary3,
        });
      }
      if (obj.PhotoFile4) {
        result.push({
          PhotoFile: obj.PhotoFile4,
          Description: obj.Description4,
          ImgSummary: obj.ImgSummary4,
        });
      }
    }
    return result;
  }
  convertType12(obj: any) {
    let result: any[] = [];
    if (obj) {
      if (obj.IframeCode1) {
        result.push({
          YoutubeUrl: this.getU2Video(obj.IframeCode1),
          Description: obj.Description1,
        });
      }
      if (obj.IframeCode2) {
        result.push({
          YoutubeUrl: this.getU2Video(obj.IframeCode2),
          Description: obj.Description2,
        });
      }
    }
    return result;
  }
  parseFloat(num: any) {
    if (num) {
      return parseFloat(num);
    } else {
      return 0;
    }
  }

  //績效表現:Performance/歷史淨值:NavHIS/持股明細:Holding/基金評等:Rating/歷史配息:DividendHIS/基金檔案:FundINFO/相關報告:Report
  getFundInfo(type: string, fundID: string) {
    // return this.mhs.get<any>(
    //   'FundDetail/FundInfo?fundDetailEnum=' + type + '&FundID=' + fundID,
    //   2
    // );
  }
  selectTimeRange(type: string, fundInfos: any) {
    this.DateName = type;
    let now = new Date();
    this.timeRange = type;
    switch (type) {
      case '今年以來':
        this.fundDetailPriceReq.SDate = new Date(now.getFullYear() + '-01-01');
        break;
      case '成立至今':
        this.fundDetailPriceReq.SDate = new Date('1900-01-01');
        break;
      default:
        let month = 0;
        switch (type) {
          case '3個月':
            month = 3;
            break;
          case '6個月':
            month = 6;
            break;
          case '1年':
            month = 12;
            break;
          case '2年':
            month = 24;
            break;
          case '3年':
            month = 36;
            break;
          case '5年':
            month = 60;
            break;
        }
        const startDate = new Date(
          new Date().setMonth(new Date().getMonth() - month)
        );
        this.fundDetailPriceReq.EDate = new Date();
        this.fundDetailPriceReq.SDate = startDate;
        this.fundDetailPriceReq.IsPreview = this.IsPreview
        break;
    }
    this.setChartData(fundInfos);
  }

  setChartData(fundInfos: any) {
    const _this = this;

    this.updateFlag = false;
    let color = ['#A71E00', '#FAA634', '#23a415', ''];
    this.chartOptions.series = [];
    // this.fundDetailPriceReq.priceENUM = 'NAVHIS';
    var promiseList: any = [];

    fundInfos.forEach((fundInfo: any, index: number) => {
      this.fundDetailPriceReq.FundNo = fundInfo.FundNo;
      this.fundService.GetFundNAV(this.fundDetailPriceReq).subscribe((res) => {
        if (res.StatusCode == EnumStatusCode.Success) {
          let seriesData: any[] = [];
          let _xAxis: any[] = [];
          let _dateType = 0;
          if (!this.isMobile) _dateType = res.Entries.NAVs.length / 10;
          else _dateType = res.Entries.NAVs.length / 5;
          res.Entries.NAVs.map(x => {
            _xAxis.push(x.CDataDt);
            seriesData.push(x.CNetValue)
          });
          this.chartOptions = {
            ...this.chartOptions,
            series: [{
              data: seriesData,
              type: 'line',
              dataLabels: {
                enabled: false,
              },

            }],
            xAxis: {
              type: 'datetime',
              tickInterval: _dateType,
              categories: _xAxis,
              labels: {
                format: "{value}",
                style: {
                  fontSize: '0.8em',
                  fontWeight: 'bolder',
                },
                rotation: 0
              },
              lineWidth: 2,
            },
          }
          //  this.chartOptions.series?.push({
          //    type: 'line',
          //    name: fundInfo.FundName,
          //    data: seriesData,
          //    color: color[index],
          //  });
          //  this.chartOptions.xAxis = {
          //   type: 'datetime',
          //     categories: _xAxis,
          //     labels: {
          //       format: "{value}",
          //       style: {
          //         fontSize: '0.8em',
          //         fontWeight: 'bolder',
          //       },
          //       rotation: 0
          //     },
          //     lineWidth: 2,
          //  }

        }
        this.mainData$.loading$.next(false);

      })

      this.updateFlag = true;
      // promiseList.push(
      //   new Promise((resolve, reject) => {
      //     try {
      //       this.fundDetailPriceReq.FundNo = fundInfo.FundNo;
      //       this.mhs
      //         .post<any>('FundDetail/Price', this.fundDetailPriceReq, 2)
      //         .toPromise()
      //         .then((x) => {
      //           //整理highchart資料
      //           this.chartOptions.series?.push({
      //             type: 'line',
      //             name: fundInfo.FundName,
      //             data: JSON.parse(x.data),
      //             color: color[index],
      //           });

      //           resolve(true);
      //         });
      //     } catch (ex) {
      //       reject(null);
      //     }
      //   })
      // );
    });

    // Promise.all(promiseList).then(function (values) {
    //   _this.updateFlag = true;
    // });
  }

  tabClick(index: number) {
    const top = document.getElementById('anchor-' + index)?.offsetTop || 0;
    if(this.isBrowser) {
      window.scrollTo({
      top: top - 86,
      behavior: 'smooth'
    });
  }

    this.isNav = false;
  }

  //常見問題
  GetQAlist(CId:number) {
    this.QA.CCategoryMainId = CId;
    this.QA.Keyword = '';
    this.QA.IsAccessible = false;

    this.aritcleService.GetFaqList(this.QA).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.qaList = res.Entries[0].FaqList;
      }
    });
  }
  // async getFaqList(CId = 9, CSubId = 0) {
  //   this.getFaqListReq.CCategoryMainId = CId;
  //   this.activeMainCategory = CId;
  //   this.getFaqListReq.CCategorySubId = CSubId;
  //   this.getFaqListReq.IsAccessible = false;

  //   const res = await this.mhs
  //     .post<ResponseBase<GetFaqListRes>>(
  //       'Article/GetFaqList',
  //       this.getFaqListReq
  //     )
  //     .toPromise();
  //   if (res.StatusCode == EnumStatusCode.Success) {
  //     let subCategoryName = '';
  //     this.getFaqListRes = res.Entries;

  //     this.qaList[0].qa = [];

  //     for (let i = 0; i < this.getFaqListRes.FaqList.length; i++) {
  //       this.qaList[0].qa.push({
  //         q: this.getFaqListRes.FaqList[i].CQuestion,
  //         a: this.getFaqListRes.FaqList[i].CAnswer,
  //         isActive: false,
  //       });
  //     }
  //   }
  // }

  //搜尋API
  postSearchDetail(fundNos: any) {
    let tempReq = new SearchDetailReq();
    tempReq.fundIDs = fundNos;
    tempReq.condition = this.searchDetailReq.condition.filter(
      (a: { groupID: string }) => a.groupID != 'Keyword'
    );

    // return this.mhs.post<any>('Search/Detail', tempReq, 2);
  }

  // 基金績效
  async GetFundRankImportList(data: string[]) {
    let _data: any = {
      Keyword: '',
      TypeIDs: [],
      AreaIDs: [],
      RiskLvIDs: [],
      DividendIDs: [],
      CurrencyTypeIDs: [],
      FundNoList: data,
    };

    let aaa: any[] = []
    this.fundService.GetFundRankImportList(_data).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        aaa = res.Entries;
      }
    });
    this.mainData$.loading$.next(false);
    return aaa;
  }

  hbClick() {
    this.isNav = !this.isNav;
  }

  copy() {
    navigator.clipboard
      .writeText(location.href)
      .then(() => alert('網址已複製，歡迎分享!'));
  }

  convertType22(obj: any, FundBrandList: FundBrandList[]) {
    let result: any[] = [];
    result.push({
    });
    const myFund = new Fund();
    if (FundBrandList.length > 0) {
      for (let i = 0; i < FundBrandList.length; i++) {
        var a = this.isMobile ? Math.floor(i / 4) : Math.floor(i / 10);
        if (myFund.itemFund[a]) {
          myFund.itemFund[a].Fundlist.push(
            FundBrandList[i]
          );
        }
        else {
          myFund.itemFund[a] = new Fundlist();
          myFund.itemFund[a].Fundlist.push(
            FundBrandList[i]
          );
        }
      }
      return myFund
    }
    else
      return [];
  }
}


class GetFaqListReq {
  Keyword: string = '';
  CCategoryMainId: number | null = null;
  CCategorySubId: number | null = null;
  IsAccessible: boolean = false;
}

class GetFaqListRes {
  CCategoryMainId: number | null = null;
  CCategoryName: string = '';
  FaqList: Faq[] = [];
  HotFaqList: Faq[] = [];
}

class Faq {
  CId: number = 0;
  CQuestion: string = '';
  CAnswer: string = '';
  IsActive: boolean = false;
  defaultOpen: Boolean = false;
}

class Fund {

  itemFund: Fundlist[] = [];
}

class Fundlist {
  Fundlist: FundBrandList[] = [];
}
class FundBrandList {
  CID: number = 0;
  CompanyName: string = '';
  Img: string = '';
  Sort: number | null = null;
  Url: string = '';
}

// EventSiteType	3	文編輯器 V
// EventSiteType	4	圖(單一全幅) V
// EventSiteType	5	圖上+文編輯器下 V
// EventSiteType	6	圖(左邊+文編輯器左下)+文編輯器右 X
// EventSiteType	7	圖(右邊+文編輯器右下)+文編輯器左 X
// EventSiteType	8	圖(左邊+文編輯器左下)+圖(中間+文編輯器中下)+圖(右邊+文編輯器右下) (預設顯示3塊) V
// EventSiteType	9	圖(左二+文編輯器左二下)+圖(左一+文編輯器左一下)+圖(右二+文編輯器右二下) +圖(右一+文編輯器右一下)(預設顯示3塊，其他左右移) V
// EventSiteType	10	圖(左邊+文編輯器左下)+圖(右邊+文編輯器右下) V
// EventSiteType	11	影片(單一全幅) V
// EventSiteType	12	影片(左邊+文編輯器左下)+影片(右邊+文編輯器右下) V
// EventSiteType	13	影片(左邊+文編輯器左下)+文編輯器右 V
// EventSiteType	14	影片(右邊+文編輯器右下)+文編輯器左 V
// EventSiteType	15	影片上+文編輯器下 V
// EventSiteType	16	基金配息(單一/該基金全級別) X
// EventSiteType	17	基金績效(單一/該基金全級別) X
// EventSiteType	18	基金小檔案 X
// EventSiteType	19	投資組合 X
// EventSiteType	20	基金淨值(單一/該基金全級別) X
