<app-bread-crumb></app-bread-crumb>
<div class="wrapper">
  <main>
    <h2 class="title">
      {{SeminarDetail.CTitle}}
    </h2>
    <!-- <div>
    退休前，講退休覺得還早，退休後，以為少花就好。小心！長壽時代+低利率趨勢… 代表你我購買力在消失！
    <br>
     12/23(四) 晚上7點，  <br>基金達人 帶你突破退休盲點， 找出適合的投資工具，為人生下半場財上生財！ <br> 機會難得，快報名！開播當天手機簡訊，提醒您準時觀看！
  </div> -->
    <div [innerHtml]="SeminarDetail.CCkcontent|html"></div>
  </main>
</div>

<main>
  <div class="list-box">
    <div [innerHtml]="SeminarDetail.CCkcontent2|html"></div>
    <!-- <div class="lecture-list">
      <table class="web">
        <thead>
          <tr>
            <th>時間</th>
            <th>議程</th>
            <th>主講人</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>14:00-14:30</td>
            <td>貴賓入席&精緻午茶</td>
            <td></td>
          </tr>
          <tr>
            <td>14:00-14:30</td>
            <td>開幕致詞</td>
            <td>OO投信直銷主管</td>
          </tr>
          <tr>
            <td>14:40-15:00</td>
            <td>台股作夢行情啟動了嗎？</td>
            <td>OO投信市場策略產品團隊主管 葉家榮</td>
          </tr>
          <tr>
            <td>15:00-15:20</td>
            <td>升息趨勢底定，債市投資攻略</td>
            <td>OO投信市場策略產品團隊副理 林芷麒</td>
          </tr>
          <tr>
            <td>15:20-16:10</td>
            <td>稅務講座</td>
            <td>資誠聯合會計師事務所合夥人 陳筱娟會計師</td>
          </tr>
          <tr>
            <td>16:10~</td>
            <td>QA</td>
            <td>OO投信市場策略產品團隊副理 林芷麒</td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <!-- <div class="lecture mb">
      <p-table [value]="customers" [scrollable]="true" class="p-element" styleClass="mt-3">
        <ng-template pTemplate="body" let-customer>
          <div class="mt-3 p-datatable p-component p-datatable-scrollable">
            <div class="p-datatable-wrapper">
              <table class="p-datatable-table p-datatable-scrollable-table">
                <tr>
                  <td class="p-element p-frozen-column" style="min-width:100px" pFrozenColumn>時間</td>
                  <td style="min-width:200px">14:00-14:30</td>
                  <td style="min-width:200px">14:00-14:30</td>
                  <td style="min-width:200px">14:40-15:00</td>
                  <td style="min-width:200px">15:00-15:20</td>
                  <td style="min-width:200px">15:20-16:10</td>
                  <td style="min-width:200px">16:10~</td>
                </tr>
                <tr>
                  <td class="p-element p-frozen-column" style="min-width:100px" pFrozenColumn>議程</td>
                  <td style="min-width:200px">貴賓入席&精緻午茶</td>
                  <td style="min-width:200px">開幕致詞</td>
                  <td style="min-width:200px">台股作夢行情啟動了嗎？</td>
                  <td style="min-width:200px">升息趨勢底定，債市投資攻略</td>
                  <td style="min-width:200px">稅務講座</td>
                  <td style="min-width:200px">QA</td>
                </tr>
                <tr>
                  <td class="p-element p-frozen-column" style="min-width:100px" pFrozenColumn>主講人</td>
                  <td style="min-width:200px"></td>
                  <td style="min-width:200px">OO信投信直銷主管</td>
                  <td style="min-width:200px">OO投信市場策略產品團隊主管 葉家榮</td>
                  <td style="min-width:200px">OO投信市場策略產品團隊副理 林芷麒</td>
                  <td style="min-width:200px">資誠聯合會計師事務所合夥人 陳筱娟會計師</td>
                  <td style="min-width:200px">OO投信市場策略產品團隊副理 林芷麒</td>
                </tr>
              </table>
            </div>
          </div>
        </ng-template>
      </p-table>
    </div> -->
  </div>

  <div class="lecture-apply">
    <table class="web">
      <thead>
        <tr>
          <th>時間</th>
          <th *ngIf="SeminarDetail.CEvent">活動場次</th>
          <th style="flex: 1 1 40%;">地點</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{SeminarDetail.CActivityDt}}</td>
          <td *ngIf="SeminarDetail.CEvent">{{SeminarDetail.CEvent}}</td>
          <td style="flex: 1 1 40%;">{{SeminarDetail.CActivityPlace}}</td>
          <td>
            <div class="btn-box">
              <button class="confirm-btn" routerLink="{{url}}/{{SeminarDetail.CId}}"
                *ngIf="SeminarDetail.IsCanSignUp">前往報名</button>
              <button class="close-btn" *ngIf="!SeminarDetail.IsCanSignUp" disabled>報名額滿</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mb mbapply-box">
      <div class="mbapply">
        <div class="mbborder" *ngIf="SeminarDetail.CEvent">
          活動場次<br>{{SeminarDetail.CEvent}}
        </div>
        <div> {{SeminarDetail.CActivityDt}}</div>
      </div>
      <div class="site">地點：{{SeminarDetail.CActivityPlace}}</div>
      <button class="confirm-btn" routerLink="{{url}}/{{SeminarDetail.CId}}"
        *ngIf="SeminarDetail.IsCanSignUp">前往報名</button>
      <button class="close-btn" *ngIf="!SeminarDetail.IsCanSignUp" disabled>報名額滿</button>
    </div>
  </div>
</main>
