import { footernumber, MetaData } from './../../../shared/models/home';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FundService } from './../../../shared/services/fund.service';
import {
  GetFundTypes, FundSelectList,
  FundDetailTopReq, FundDetailTopData,
  FundDetailPageReq, FundDetailPageData,
  FundDetail, FundInvestment, FundRankImport, FundYield,
  FundDetailReq, FundDetailData, FundNAV, FundNAVData,
  PageNAVList, FundInvestmentTop10,
} from '../../../shared/models/fund';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { enumDateList } from 'src/app/enum/enumDateList';
import * as Highcharts from 'highcharts';
import { BaseService } from '../../../shared/services/base.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { mainData } from '../../../shared/interface/main-data';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { DialogData, IDPreview } from 'src/app/shared/models/base';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-fund-info',
  templateUrl: './fund-info.component.html',
  styleUrls: ['./fund-info.component.scss']
})
export class FundInfoComponent {
  domain = environment.domain;
  router1 = 'product';
  router2 = 'fund-info';
  currPath = ''; //當前的路由
  fundTypeList: GetFundTypes[] = [];//第一層下拉選單
  selectedType: GetFundTypes = {
    Name: '全部',
    Id: 0,
    ParentID: 0,
    Sort: 0
  } as GetFundTypes;
  fundSelectList: FundSelectList[] = []; //第二層下拉選單
  selectedFund: FundSelectList = {} as FundSelectList;
  fundDetailTopReq: FundDetailTopReq = {} as FundDetailTopReq;
  FundDetailTopData: FundDetailTopData = {} as FundDetailTopData;
  fundDetailPageReq: FundDetailPageReq = {} as FundDetailPageReq;
  FundDetailPageData: FundDetailPageData = {} as FundDetailPageData;
  FundDetail: FundDetail = {} as FundDetail;
  FundInvestment: FundInvestment[] = [];
  FundInvestmentDate: any;
  FundInvestmentTop10: FundInvestmentTop10[] = [];
  FundRankImport: FundRankImport = {} as FundRankImport;
  FundYield: FundYield = {} as FundYield;
  FundFileLink: FundDetailReq = {} as FundDetailReq;
  FundLinkData: FundDetailData = {} as FundDetailData;
  FundNAVReq: FundNAV = {} as FundNAV;
  FundNAVData: FundNAVData = {} as FundNAVData;
  FundPageNAVReq: FundNAV = {} as FundNAV;
  FundPageNAVList: PageNAVList[] = [];
  footernumber: footernumber = {} as footernumber;
  section1Start = 0;
  section1End: any;
  section2Start: any;
  section2End: any;
  section3Start: any;
  section3End: any;
  Createdate: string = '';
  limit = 10; //近30筆淨值
  tabList = [
    {
      id: 1,
      name: '基本資料',
    }, {
      id: 2,
      name: '投資組合',
    }, {
      id: 3,
      name: '績效淨值',
    }, {
      id: 4,
      name: '配息紀錄',
    }
  ]
  tabActive = 1;
  currentTab = 1;
  dots: number[] = [0, 1, 2, 3, 4];
  FundFeatureList: string[] = []; //基金特色
  enumDateList = enumDateList;
  DateName = '';
  NAVDateName = '';
  remark: any;
  empty = false;
  isPreview = false;
  isHighcharts = typeof Highcharts === 'object';
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  colors = ['#C3211D', '#E79F38', '#22CCC6', '#60A3B9', '#D6D628', '#71B5EF', '#1C5AE6', '#6EE0FC', '#C31D9D', '#FADE45', '#E79F38', '#22CCC6', '#60A3B9', '#D6D628', '#71B5EF', '#1C5AE6', '#6EE0FC', '#C31D9D', '#FADE45'];
  chartOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      name: '',
      data: ['', 1],
      type: 'pie',
      dataLabels: {
        enabled: false
      },
      colors: this.colors,
      innerSize: '80%',

    }],
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '佔比</b>: ' + this.y + ' %';
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          connectorColor: 'silver'
        },
        showInLegend: false,
      },
      series: {
        boostThreshold: 0,
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'bottom',
      itemMarginBottom: 10,
      maxHeight: !this.baseService.Web$.value ? 100 : 300,
      labelFormatter: function () {
        return `${this.name}`
        // return `${this.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;%`
      },
    },
    credits: {
      enabled: false
    }
  }; // required
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = true;

  dateType: number = 300;
  chartLineOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      data: [1, 2, 3],
      type: 'line',
      dataLabels: {
        enabled: false
      },
    }],
    legend: {
      enabled: false
    },
    colors: ['#C9191D'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        turboThreshold: 0
      }
    },
    tooltip: {
      formatter: function () {
        return this.x + ' ' + '<br>' + this.y + ' ';

      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  }; // required
  isBrowser: boolean;
  visible = false;
  dialogData: DialogData = {} as DialogData; //訊息彈窗
  dialogConfirmData: DialogData = {} as DialogData; //確認彈窗
  errorTest: string[] = [];
  metaTagData: MetaData = {} as MetaData;
  iDPreview: IDPreview = {} as IDPreview;
  previousTab: number | null = null;
  MetaDataTitle = '';
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public fundService: FundService,
    private route: ActivatedRoute,
    private router: Router,
    public baseService: BaseService,
    public mainData$: mainData,
    private titleService: Title,
    private metaTagService: Meta,
    public homeService: HomeService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    // this.getMetaData();
    this.GetFundTypes();
    this.GetFundSelectList(0);
    this.goTop();
  }

  ngOnInit(): void {
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );

    urlParams.subscribe(params => {
      this.tabActive = params['tab'];
      this.fundDetailTopReq.FundNo = params['fund'];
      this.fundDetailPageReq.FundNo = params['fund'];
      this.FundFileLink.FundNo = params['fund'];
    });

    urlParams.subscribe(queryParams => {
      if (queryParams['isPreview'] !== null && queryParams['isPreview'] !== undefined) {
        this.isPreview = Boolean(queryParams['isPreview']);
      }
      this.iDPreview.IsPreview = this.isPreview;
      this.GetFundSelectList(0);
    });

    this.route.url.subscribe(params => {
      this.GetFundDetailTopArea();
      this.GetFundDetailPage();
      this.GetFundFileLink()
      this.FundNAVReq.FundNo = this.fundDetailTopReq.FundNo;
      this.FundNAVReq.SDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
      this.FundNAVReq.SDate.setUTCHours(16, 0, 0, 0); // 設定UTC時間為16:00:00.000
      this.FundNAVReq.EDate = new Date();
      this.DateName = '近30天';
      this.NAVDateName = '近30天';
      this.FundPageNAVReq.FundNo = this.fundDetailTopReq.FundNo;
      this.FundPageNAVReq.SDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
      this.FundPageNAVReq.EDate = new Date();
      this.GetFundNAV(false);
      this.GetFundPageNAVList(false);
    })

    this.baseService.Web$.subscribe(val => {
      if (val) {
        this.limit = 10;
        this.dateType = this.FundNAVData.NAVs && (this.FundNAVData.NAVs.length / 10);
      } else {
        this.limit = 30;
        this.dateType = this.FundNAVData.NAVs && (this.FundNAVData.NAVs.length / 5);
      }
    })
  }


  getMetaData() {
    this.homeService.GetMetaData().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.metaTagData = res.Entries;
        if (this.metaTagData.CTitle) this.titleService.setTitle(this.metaTagData.CTitle)
        if (this.metaTagData.CDescription) this.metaTagService.updateTag({ name: 'description', content: this.metaTagData.CDescription });
        if (this.metaTagData.CKeyWord) this.metaTagService.updateTag({ name: 'keyword', content: this.metaTagData.CKeyWord });
      }
    });
  }

  GetFundTypes() {
    this.fundService.GetFundTypes().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundTypeList = [
          {
            Name: '全部',
            Id: 0,
            ParentID: 0,
            Sort: 0
          },
          ...res.Entries,
        ]
      }
    });
  }

  GetFundSelectList(id: number) {
    this.iDPreview.TypeID = id;
    this.fundService.GetFundSelectList(this.iDPreview).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundSelectList = res.Entries;
        const selectedFundNo = this.fundDetailTopReq.FundNo;
        const foundFund = this.fundSelectList?.find(fund => fund.FundNo === selectedFundNo);
        if (foundFund) this.selectedFund = foundFund;
        if (this.selectedFund && id == 0) {
          this.selectListChange(this.selectedFund)
        }
        if (id !== 0 && this.fundSelectList[0]) {
          this.selectListChange(this.fundSelectList[0])
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  GetFundList(id: number) {
    this.iDPreview.TypeID = id;
    this.fundService.GetFundSelectList(this.iDPreview).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.fundSelectList = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  selectedFundChanged(newValue: any) {
    // 在這裡處理 selectedFund 的改變
  }

  GetFundDetailTopArea() {
    this.fundService.GetFundDetailTopArea(this.fundDetailTopReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundDetailTopData = res.Entries;
      }
      this.mainData$.loading$.next(false);
    });
  }

  //取得基金下方資訊
  GetFundDetailPage() {
    this.fundDetailPageReq = {
      ...this.fundDetailPageReq,
      SearchType: 1,
      Year: 0,
      SDate: new Date('2000-09-28'),
      EDate: new Date(),
      IsPreview: this.isPreview
    }

    this.fundService.GetFundDetailPage(this.fundDetailPageReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        if (res.Entries && res.Entries.FundDetail && res.Entries.FundDetail.BasicInfo) {
          const footerNumber: footernumber = { CId: res.Entries.FundDetail.BasicInfo.FooterID };
          this.baseService.footerID$.next(footerNumber);
          this.Createdate = res.Entries.FundDetail.BasicInfo.SetUpDt;
          // this.fundTypeList = res.Entries.FundDetail.BasicInfo.FundType;
          const selected = res.Entries.FundDetail.BasicInfo.FundType;
          const foundFundTYPE = this.fundTypeList?.find(TYPE => TYPE.Id === selected);
          //  console.log(foundFundTYPE)
          if (foundFundTYPE) {
            this.selectedType = foundFundTYPE;
            this.GetFundList(this.selectedType.Id);
          }
        }
        else {
          this.baseService.footerID$.next({ CId: 1 });
        }
        this.FundDetailPageData = res.Entries;
        this.FundDetail = this.FundDetailPageData.FundDetail;
        let remarks = this.FundDetail.BasicInfo?.DividendRemark || '';
        if (/^\s*$/.test(remarks) && remarks) {
          this.empty = true;
          this.remark = ' ';
        }
        else if (!/^\s*$/.test(remarks) && remarks) {
          this.empty = false;
          let processedRemark = remarks.replace(/\t/g, '').replace(/\r\n/g, '\n');
          let remarkArray: string[] = processedRemark.split('\n');
          this.remark = remarkArray;
        }
        else if (!remarks) {
          this.empty = false;
          this.remark = null;
        }
        //基本資料斷行
        if (this.FundDetail.BasicInfo) {
          this.FundDetail.BasicInfo.Remark = this.FundDetail.BasicInfo.Remark.replace(/\r\n|\n/g, '<br>');
          this.FundDetail.BasicInfo.ManagerFee = this.FundDetail.BasicInfo.ManagerFee.replace(/\r\n|\n/g, '<br>');
          this.FundDetail.BasicInfo.CustodyFee = this.FundDetail.BasicInfo.CustodyFee.replace(/\r\n|\n/g, '<br>');
        }
        //交易資訊斷行
        if (this.FundDetail.CTranNotice) {
          this.FundDetail.CTranNotice = this.FundDetail?.CTranNotice?.map(item => {
            return {
              ...item,
              val: item.val.replace(/\r\n|\n/g, '<br>')
            };
          });
        }
        this.FundFeatureList = this.FundDetail.BasicInfo?.FundFeature.split('\r\n') || [];
        this.FundFeatureList = this.FundFeatureList.filter(data => data)
        this.FundInvestment = this.FundDetailPageData.FundInvestment;
        let maxData: any = null;
        //找出投資組合中最新的時間
        this.FundInvestment.forEach((data: any) => {
          if (!maxData || data.CDataDate > maxData.CDataDate) {
            maxData = data;
            this.FundInvestmentDate = maxData.CDataDate;
          }
        });
        this.FundInvestmentTop10 = this.FundDetailPageData.FundInvestmentTop10;
        this.FundInvestment = this.FundInvestment.map(val => {
          return {
            ...val,
            chartOptions: {
              ...this.chartOptions,
              series: [{
                data: val.Data,
                type: 'pie',
                dataLabels: {
                  enabled: false
                },
                colors: this.colors,
                innerSize: '80%',
              }]
            }
          }
        })
        this.FundRankImport = this.FundDetailPageData.FundRankImport;
        this.FundYield = this.FundDetailPageData.FundYield;
        if (res.Entries.FundDetail.MetaData) {
          if (res.Entries.FundDetail.MetaData.Title) {
            this.MetaDataTitle = res.Entries.FundDetail.MetaData.Title
            this.titleService.setTitle(res.Entries.FundDetail.MetaData.Title);
            this.metaTagService.updateTag({
              name: 'title',
              content: res.Entries.FundDetail.MetaData.Title
            });
          }
          if (res.Entries.FundDetail.MetaData.Description) {
            this.metaTagService.updateTag({
              name: 'description',
              content: res.Entries.FundDetail.MetaData.Description,
            });
          }
          if (res.Entries.FundDetail.MetaData.Keywords) {
            this.metaTagService.updateTag({
              name: 'keyword',
              content: res.Entries.FundDetail.MetaData.Keywords,
            });
          }
        }
      }
    });
  }

  //取得下方檔案下載
  GetFundFileLink() {
    this.fundService.GetFundFileLink(this.FundFileLink).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundLinkData = res.Entries;
      }
    });
  }


  visibleChange(e: any) {
    this.visible = false;
  }

  submitCheck() {
    this.errorTest = [];
    this.dialogData.message = '';

    if (this.FundNAVReq.EDate < this.FundNAVReq.SDate) {
      this.errorTest.push('結束時間不可小於開始時間');
    }
    if (this.errorTest.length) {
      this.visible = true;
      this.dialogData.title = '提醒'
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '。<br>'
    } else this.GetFundNAV(true);
  }

  //取得淨值走勢圖
  GetFundNAV(active: boolean) {
    if (active) {
      this.DateName = '';
      this.FundPageNAVReq.SDate = this.FundNAVReq.SDate;
      this.FundPageNAVReq.EDate = this.FundNAVReq.EDate;
      this.GetFundPageNAVList(true);
    }
    this.FundNAVReq = {
      ...this.FundNAVReq,
      IsPreview: false,
    }

    this.fundService.GetFundNAV(this.FundNAVReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundNAVData = res.Entries;
        let _xAxis: any[] = [];
        let seriesData: any[] = [];
        let seriesDataStr: any[] = [];
        let totalDataPoints = this.FundNAVData.NAVs.length;
        let desiredTicks = window.innerWidth < 768 ? 20 : 40;

        // if (this.baseService.Web$.value) this.dateType = this.FundNAVData.NAVs.length / 10;
        // else this.dateType = this.FundNAVData.NAVs.length / 5;
        this.FundNAVData.NAVs.map(x => {
          _xAxis.push(x.CDataDt);
          seriesData.push(x.CNetValue)
          seriesDataStr.push(x.CNetValueStr)
        });

        // const dataLength = this.FundNAVData.NAVs.length;
        // const interval = Math.ceil(dataLength / 40); // 計算間隔，向上取整，確保顯示40筆數據
        // for (let i = 0; i < dataLength; i += interval) {
        //   const x = this.FundNAVData.NAVs[Math.min(i, dataLength - 1)]; // 避免超出範圍
        //   _xAxis.push(x.CDataDt);
        //   seriesData.push(x.CNetValue);
        // }
        this.chartLineOptions = {
          ...this.chartLineOptions,
          series: [{
            data: this.FundNAVData.NAVs.map(x => {
              return {
                y: x.CNetValue,
                _xYxis: x.CNetValueStr,
              }
            }),
            type: 'line',
            dataLabels: {
              enabled: false
            },
          }],
          xAxis: {
            type: 'datetime',
            categories: _xAxis,
            labels: {
              format: "{value}",
              style: {
                fontSize: '0.8em',
                fontWeight: 'bolder',
              },
              rotation: -60
            },
            lineWidth: 2,
            tickPositioner: function () {
              var positions = [],
                step = Math.ceil(totalDataPoints / desiredTicks);
              for (let i = 0; i < totalDataPoints; i += step) {
                positions.push(i);
              }
              return positions.filter(pos => typeof pos === 'number');  // 確保只返回 number 類型
            }
          },
          tooltip: {
            // formatter: function () {
            //   return this.x + ' ' + '<br>' + seriesDataStr + ' ';

            // }
            formatter: function (): string { // 這裡指定返回類型為 string
              return this.x + ' ' + '<br>' + (this as any).point._xYxis + ' ';
            }
          },
          plotOptions: {
            series: {
              turboThreshold: 0
            }
          },
        }
      }
      this.mainData$.loading$.next(false);
    });
  }

  //取得近30比淨值(改成30天)
  GetFundPageNAVList(active: boolean) {
    if (active) {
      this.NAVDateName = '';
    }
    this.FundPageNAVReq = {
      ...this.FundPageNAVReq,
      IsPreview: false,
    }
    this.fundService.GetFundPageNAVList(this.FundPageNAVReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.FundPageNAVList = res.Entries;
        const total = this.FundPageNAVList.length;
        const baseSectionCount = Math.floor(total / 3);
        const remainder = total % 3;
        this.section1Start = 0;
        this.section1End = baseSectionCount + (remainder > 0 ? 1 : 0);
        // console.log(this.section1End)
        this.section2Start = this.section1End;
        this.section2End = this.section2Start + baseSectionCount + (remainder > 1 ? 1 : 0);
        // console.log(this.section2End)
        this.section3Start = this.section2End;
        this.section3End = total;
      }
      this.mainData$.loading$.next(false);
    });
  }

  selectedTypeChange(e: any) {
    this.GetFundSelectList(this.selectedType.Id);
    // this.selectListChange(this.selectedFund)
  }

  selectListChange(e: any) {
    // this.fundDetailTopReq.FundNo = this.selectedFund.FundNo;
    if (e.value) {
      this.selectedFund = e.value;
      if (e.value.IsETF) {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
        };
        this.router.navigate([`/etf/etf-info/${e.value.FundNo}`], { queryParams });
      } else {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
        };
        this.router.navigate([`/fund-info/${e.value.FundNo}`], { queryParams });
      }
    }
    else if (e) {
      this.selectedFund = e;
      if (e.IsETF) {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
        };
        this.router.navigate([`/etf/etf-info/${e.FundNo}`], { queryParams });
      } else {
        const queryParams = {
          tab: this.tabActive,
          ...(this.isPreview ? { isPreview: this.isPreview } : {})
        };
        this.router.navigate([`/fund-info/${e.FundNo}`], { queryParams });
      }
    }

  }

  tabActiveChange(id: number) {
    this.tabActive = id;
    if (id == 1) {
      this.router.navigate(['/fund-info/' + this.fundDetailTopReq.FundNo], {
        queryParams: {
          tab: 1
        }
      });
    } else if (id == 2) {
      this.router.navigate(['/fund-info/' + this.fundDetailTopReq.FundNo], {
        queryParams: {
          tab: 2
        }
      });
    } else if (id == 3) {
      this.router.navigate(['/fund-info/' + this.fundDetailTopReq.FundNo], {
        queryParams: {
          tab: 3
        }
      });
    } else {
      this.router.navigate(['/fund-info/' + this.fundDetailTopReq.FundNo], {
        queryParams: {
          tab: 4
        }
      });
    }
  }

  getWidth(i: number) {
    if (this.baseService.Web$.value) {
      if (this.FundDetail.CompanyNameList && i === this.FundDetail.CompanyNameList?.length - 1) {
        let num = this.FundDetail.CompanyNameList && this.FundDetail.CompanyNameList?.length % 3
        if (num == 1) return '100%';
        else if (num) return 'calc(100% / 3 * 2)';
        else return 'calc(100% / 3)'
      } else return 'calc(100% / 3)'
    } else return '50%'
  }

  getDateName(data: any) {
    this.DateName = data.Name;
    this.NAVDateName = data.Name;
    if (data.Name !== '成立至今') {
      this.FundNAVReq.SDate = new Date(data.SDate);
      this.FundNAVReq.EDate = new Date(data.EDate);
      this.FundPageNAVReq.SDate = new Date(data.SDate);
      this.FundPageNAVReq.EDate = new Date(data.EDate);
      // if (this.baseService.Web$.value) this.dateType = data.tickInterval;
      // else this.dateType = data.tickIntervalM;
    }
    else {
      this.FundNAVReq.SDate = new Date(this.Createdate);
      this.FundNAVReq.SDate.setUTCHours(16, 0, 0, 0);
      this.FundPageNAVReq.SDate = new Date(this.Createdate);
      this.FundPageNAVReq.SDate.setUTCHours(16, 0, 0, 0);
    }
    this.GetFundNAV(false);
    this.GetFundPageNAVList(false);
  }

  getNumber(val: string) {
    return Number(val)
  }

  goTop() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

}
