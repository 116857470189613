export const environment = {
    production: true,
    //apiUrl: 'http://60.251.54.95:8021/webapi/api',
    apiUrl: '/webapi/api',
    //apiUrl: 'http://localhost:9453/api',
    domain: 'https://www.jkoam.com',
    // apiUrl: 'http://web/webapi/api',
   //  domain: 'http://web',
    company:'街口'
};
