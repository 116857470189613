<app-bread-crumb></app-bread-crumb>
<div class="wrapper">
    <main>
        <form>
            <h2>初級市場預約申購查詢</h2>
            <ng-container *ngIf="!isSubmit">
                <div class="form-item">
                    <label>申購產品 <span class="request">＊必填</span></label>
                    <p-dropdown [options]="ETFProduct" (onChange)="selectListChange($event)"
                        optionLabel="FundName"></p-dropdown>
                </div>

                <div class="form-item">
                    <label>身份證字號/統編/外資法人 <span class="request">＊必填</span></label>
                    <input placeholder="請輸入您的身份證字號/統編/外資法人" [(ngModel)]="request.IDNumber" name="id">
                </div>

                <div class="form-item">
                    <label>申購驗證碼 <span class="request">＊必填</span></label>
                    <input placeholder="請輸入您的申購驗證碼" [(ngModel)]="request.PurchaseCode" name="PurchaseCode">
                </div>

                <div class="form-item">
                    <label>驗證碼</label>
                    <div class="verification-wrap">
                        <input placeholder="請輸入圓形驗證碼" [(ngModel)]="request.Code" name="VerificationCode">
                        <figure>
                            <img [src]="getSanitizedUrl(captchaUrl)" alt="VerificationCode" />
                        </figure>
                        <img class="refresh-icon" src="/assets/images/refresh.svg" (click)="refresh()"/>
                    </div>
                </div>

                <div class="form-item checkbox-wrap" style="cursor: pointer;" (click)="dialogOpen(aritcle.CContent,'agreeChecked')">
                  <p-checkbox class="checkbox" name="agreeChecked" [(ngModel)]="agreeChecked" [binary]="true"
                        inputId="binary" [disabled]="disableagreeChecked"></p-checkbox>
                    <span>本人已知悉同意街口投信<span class="agrees">「<span class="agree-info">個人資料蒐集、處理及利用告知事項</span>」</span></span>
                </div>

                <div class="btn-wrap">
                    <button id="reset" type="reset" class="reset">清除重填</button>
                    <button type="submit" (click)="submitCheck()">查詢</button>
                </div>
            </ng-container>
            <ng-container *ngIf="isSubmit">
                <div class="pay-info">
                    <div class="pay-item">
                        <div class="name">申購產品</div>
                        <div>{{inquireRes.Name}}</div>
                    </div>

                    <div class="pay-item">
                        <div class="name">身份證字號</div>
                        <div>{{maskIDNumber(inquireRes.IDNumber)}}</div>
                    </div>

                    <div class="pay-item">
                        <div class="name">參與券商</div>
                        <div>{{inquireRes.PD}}</div>
                    </div>

                    <div class="pay-item">
                        <div class="name">擬申購組數</div>
                        <div>{{inquireRes.PurchaseQuantity}}</div>
                    </div>

                    <div class="pay-item">
                        <div class="name">申購序號</div>
                        <div>{{inquireRes.OrderNumber}}</div>
                    </div>
                </div>
                <div class="btn-wrap">
                    <button type="reset" class="reset" (click)="goBack()">返回上頁</button>
                    <button type="submit" class="cancel" [disabled]="Timeup" (click)="confirmDialogOpen()">取消申購登記</button>
                </div>
            </ng-container>
        </form>
    </main>
</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" (visibleChange)="visibleChange($event,1)" [privateType]="privateType"></app-message-dialog>
<app-confirm-dialog *ngIf="confirmVisible" [visible]="confirmVisible" [data]="dialogConfirmData" (visibleChange)="visibleChange($event,2)" (unCheck)="unCheck($event)" [privateType]="privateType"></app-confirm-dialog>
