import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LibHttpUrl } from '../../api/public-api';
import { ResponseBase, Popup } from '../models/base';
import { ServiceBase } from 'src/app/services/service-base';
import { Articledata, ArticledataID, QAList, QAReq, ResponseArticle, ResponseArticleID } from '../models/article';

@Injectable({
  providedIn: 'root'
})
export class AritcleService extends ServiceBase {
  Web$ = new BehaviorSubject<boolean>(true);
  protected baseUrl = `${this.apiBaseUrl}/${LibHttpUrl.Aritle}`;
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  // GetFooter(): Observable<ResponseBase<footer[]>> {
  //   const url = `${this.baseUrl}/GetArticleByUnit`;
  //   return this.http.get<ResponseBase<footer[]>>(
  //     url,
  //     this.httpOptions);
  // }

  GetArticleByUnit(data:ResponseArticle): Observable<ResponseBase<Articledata>> {
    const url = `${this.baseUrl}/GetArticleByUnit`;
    // const data = {UnitAfterID:request}
    return this.http.post<ResponseBase<Articledata>>(
      url,
      data,
      this.httpOptions);
  }
  //取得單篇文章>列表內頁
  GetArticleByID(data:ResponseArticleID): Observable<ResponseBase<ArticledataID>> {
    const url = `${this.baseUrl}/GetArticleByID`;
    return this.http.post<ResponseBase<ArticledataID>>(
      url,
      data,
      this.httpOptions);
  }

  //取得QA列表
  GetFaqList(data:QAReq): Observable<ResponseBase<QAList[]>> {
    const url = `${this.baseUrl}/GetFaqList`;
    return this.http.post<ResponseBase<QAList[]>>(
      url,
      data,
      this.httpOptions);
  }
}
