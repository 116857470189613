<div class="wrapper"
  [ngStyle]="breadCrumb.ImageUrl ? {'background-image': 'url(' + breadCrumb.ImageUrl + ')'}: {'background-image': 'url(' + '/assets/images/banner-page.png' + ')'}">
  <main>
    <ol *ngIf="breadCrumbShow" vocab="https://schema.org/" typeof="BreadcrumbList">
      <ng-container *ngIf="!breadCrumb.SpecialTitle">
        <li property="itemListElement" typeof="ListItem">
          <a property="item" typeof="WebPage" [href]="domain">
            <span property="name">Home</span></a>
          <meta property="position" content="1">
        </li>
        <li property="itemListElement" typeof="ListItem" *ngIf="!footer">
          <a property="item" typeof="WebPage" [routerLink]="breadCrumb.SecondPageUrl">
            <span property="name">{{breadCrumb.SubTitle || SubTitle}}</span></a>
          <meta property="position" content="2">
        </li>
        <li property="itemListElement" typeof="ListItem" *ngIf="!footer">
          <a property="item" typeof="WebPage" [routerLink]="breadCrumb.PageUrl">
            <span property="name">{{breadCrumb.Title || Title}}</span></a>
          <meta property="position" content="3">
        </li>
        <li property="itemListElement" typeof="ListItem" *ngIf="footer">
          <a property="item" typeof="WebPage" [routerLink]="breadCrumb.PageUrl">
            <span property="name">{{footertitle}}</span></a>
          <meta property="position" content="3">
        </li>
      </ng-container>
      <ng-container *ngIf="breadCrumb.SpecialTitle">
        <li property="itemListElement" typeof="ListItem">
          <a property="item" typeof="WebPage" [href]="domain">
            <span property="name">Home</span></a>
          <meta property="position" content="1">
        </li>
        <!-- //客製化第三層(懶人包) -->
        <li property="itemListElement" typeof="ListItem" *ngIf="breadCrumb.SpecialTitle">
          <a property="item" typeof="WebPage" [routerLink]="breadCrumb.SecondPageUrl">
            <span property="name">{{breadCrumb.SpecialTitle || SubTitle}}</span></a>
          <meta property="position" content="2">
        </li>
        <li property="itemListElement" typeof="ListItem">
          <a property="item" typeof="WebPage" [routerLink]="'/etf/classroom'">
            <span property="name">{{breadCrumb.SubTitle || Title}}</span></a>
          <meta property="position" content="3">
        </li>
        <li property="itemListElement" typeof="ListItem" *ngIf="!footer">
          <a property="item" typeof="WebPage" [routerLink]="breadCrumb.PageUrl">
            <span property="name">{{breadCrumb.Title || Title}}</span></a>
          <meta property="position" content="4">
        </li>
      </ng-container>
    </ol>
    <h1 class="title" *ngIf="!footer">
      {{breadCrumb.Title || Title}}
    </h1>
    <h1 class="title" *ngIf="footer">
      {{footertitle}}
    </h1>
  </main>
</div>
