<app-bread-crumb [footertitle]="footertitle" [footer]="footer" [Article]="Article"></app-bread-crumb>
<div class="wrapper-top">
    <main>
      <div class="title-box">
        <span>{{list.CShowStartDt| date:'yyyy/MM/dd'}}</span>
        <h2>{{list.CName}}</h2>
      </div>
    </main>
  </div>
<div class="wrapper">
  <main>
    <div *ngIf="list.CContent" class="editable" [innerHtml]="list.CContent|html"></div>
    <div *ngIf="list.CYoutubeEmbedCode" class="editable" [innerHtml]="list.CYoutubeEmbedCode|html"></div>
    <div *ngIf="!list.CContent && showError||!list.CYoutubeEmbedCode && showError" class="editable">查無該筆資料！</div>
  </main>
</div>
<div class="wrapper-botm">
  <main>
    <div class="comeback" (click)="back()" *ngIf="!noComeback">回列表頁</div>
  </main>
</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" (visibleChange)="visibleChange($event)"></app-message-dialog>
