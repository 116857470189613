import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Category, FundCategory } from '../../../shared/models/category';
import { BaseService } from './../../../shared/services/base.service';
import { FundService } from './../../../shared/services/fund.service';

@Component({
  selector: 'app-fund-search',
  templateUrl: './fund-search.component.html',
  styleUrls: ['./fund-search.component.scss']
})
export class FundSearchComponent {
  @Input() hotKeyword: Category[] = [];
  @Input() fundCategory: FundCategory[] = [];
  @Input() data: any[] = [];
  @Input() TotalItems: number = 0;
  @Input() etf: boolean = false;
  @Output() result = new EventEmitter<any>();
  // searchOpen = true;
  // selectedItem:Category[] = []
  // Keyword = '';
  // KeywordId= 0;
  constructor(
    public baseService: BaseService,
    public fundService: FundService,
  ) {
  }

  ngOnChanges(): void {
    if (this.fundCategory.length) {
      var element = document.getElementById("fund-type")
      var element2 = element?.offsetHeight || 0;
      if (element && element2 > 12 && this.baseService.Web$.value) {
        element.style.height = element2 + 'px';
      }
    }
  }

  close() {
    this.fundService.searchOpen = !this.fundService.searchOpen;
  }

  getAllCategory(data: FundCategory) {
    if (this.isSelectedAllCategory(data)) {
      this.fundService.selectedItem = this.fundService.selectedItem.filter(val => val.CodeType !== data.CodeType);
    } else {
      data.CategoryList.map(x => {
        if (!this.isSelectedCategory(x)) this.fundService.selectedItem.push(x);
      })
    }
  }

  isSelectedAllCategory(data: FundCategory) {
    let length = this.fundService.selectedItem.filter(val => val.CodeType == data.CodeType).length;
    return length >= data.CategoryList.length
  }

  isSelectedCategory(data: Category) {
    return this.fundService.selectedItem.find(val => val.CategoryID == data.CategoryID)
  }

  getCategory(data: Category) {
    if (this.isSelectedCategory(data)) this.fundService.selectedItem = this.fundService.selectedItem.filter(val => val.CategoryID !== data.CategoryID)
    else this.fundService.selectedItem.push(data);
  }

  removeCategory(data: Category) {
    this.fundService.selectedItem = this.fundService.selectedItem.filter(val => val.CategoryID !== data.CategoryID)
  }

  getHotKeyword(Keyword: string, id: number) {
    if (this.isHotKeywordSelected(id)) {
      // 如果該分類已被選中，則取消選中
      this.fundService.KeywordId = 0;
      this.fundService.Keyword = '';
    } else {
      this.fundService.KeywordId = id;
      this.fundService.Keyword = Keyword;
      this.fundService.selectedItem = [];
    }
    this.submit(2);
  }

  isHotKeywordSelected(categoryId: number): boolean {
    return this.fundService.KeywordId === categoryId;
  }

  clearAll() {
    this.fundService.Keyword = '';
    this.fundService.KeywordId = 0;
    this.fundService.selectedItem = [];
    this.submit(1);
  }

  submit(type = 1) {
    if (type == 1) {
      this.fundService.KeywordId = 0;
    }
    if (!this.baseService.Web$.value) this.fundService.searchOpen = true;
    this.result.emit({
      Keyword: this.fundService.Keyword,
      data: this.fundService.selectedItem
    });

    //  抓ID滾動到3/4的範圍
    const elementToScroll = document.getElementById('searchResults');
    if (elementToScroll) {
      const elementHeight = elementToScroll.getBoundingClientRect().height;
      const scrollToPosition = elementHeight * 3 / 4;
      window.scrollBy(0, scrollToPosition);
    }
  }
}
