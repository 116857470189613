<div class="products">
  <owl-carousel-o [options]="customOptions" (changed)="getData($event)" #owlCar1>
    <ng-container *ngFor="let slide of data">
      <ng-template carouselSlide>
        <div class="wrapper">
          <a class="name"  (click)="gobuy(slide)">
            <div class="name-ellipsis">{{slide.FundName}}<span *ngIf="slide.FundWarning" class="warning">{{slide.FundWarning}}</span></div>
          </a>
          <div class="">淨值走勢</div>
          <!-- hightChart -->
            <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts"
            [options]="slide.chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
            [runOutsideAngular]="runOutsideAngular"
            class="highcharts"></highcharts-chart>

          <div class="database">
            <div>{{slide.LatestDataDt}}</div>
            <div class="item">
              最新淨值
              <p>{{slide.LatestNetValueStr}}</p>
            </div>
            <div>
              漲跌幅
              <p
                [ngClass]="{'nValue': getNegativeValue(slide.LatestChangeRatio),'pValue': getPositiveValue(slide.LatestChangeRatio)}">
                {{slide.LatestChangeRatioStr}}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <!-- <div class="icon prev" (click)="owlCar1.prev()">
    <img src="./assets/banner-arrow_left.svg" />
  </div>
  <div class="icon next" (click)="owlCar1.next()">
    <img src="./assets/banner-arrow.svg" />
  </div> -->
</div>
