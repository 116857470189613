<app-bread-crumb></app-bread-crumb>
<div class="wrapper">
  <main>
    <h2 class="main-title">{{ActivityFormInfo.Name}}</h2>
    <div class="main-subtitle" [innerHTML]="ActivityFormInfo.Describe | html"></div>
  </main>
  <main class="form-box">
    <form>
      <ng-container *ngFor="let item of FormData;" [formGroup]="ActivityFormData">
        <div class="input-box" *ngIf="item.Type == 1">
          <div class="title"><span [innerHTML]="item.Name | html"></span><span class="require"
              *ngIf="item.Must">＊必填</span></div>
          <input [placeholder]="'請輸入您的' + item.Name" name="{{item.ID}}" formControlName="{{'Control'+ item.ID}}">
        </div>
        <div class="input-box" *ngIf="item.Type == 2">
          <div class="title"><span [innerHTML]="item.Name | html"></span><span class="require"
              *ngIf="item.Must">＊必填</span></div>
          <div class="radio">
            <div class="radio-text" *ngFor="let data of item.Answer;">
              <p-radioButton name="{{'Control'+ item.ID}}" formControlName="{{'Control'+ item.ID}}" [value]="data.Text"
                inputId="{{'radio' + item.ID}}"></p-radioButton>
              <label for="{{'radio' + item.ID}}" class="ml-2" [innerHTML]="data.Text | html"></label>
            </div>
          </div>
        </div>
        <div class="input-box" *ngIf="item.Type == 3">
          <div class="title"><span [innerHTML]="item.Name | html"></span><span class="require"
              *ngIf="item.Must">＊必填</span></div>
          <div class="check">
            <div class="flex" *ngFor="let data of item.Answer;">
              <p-checkbox class="checkboxAll" [binary]="true" [name]="'group'"
                (onChange)="toggleSelection(data.Text,'Control'+ item.ID)" [value]="data.Text" [id]="data.Text"
                inputId="{{data.Text}}">
              </p-checkbox>
              <label for="{{data.Text}}" [innerHTML]="data.Text | html"></label>
            </div>
          </div>
        </div>
        <div class="input-box" *ngIf="item.Type == 4">
          <div class="title"><span [innerHTML]="item.Name | html"></span><span class="require"
              *ngIf="item.Must">＊必填</span></div>
          <p-dropdown [options]="item.Answer" name="{{item.ID}}" formControlName="{{'Control'+ item.ID}}"
            optionLabel="Text" optionValue="Text"></p-dropdown>
        </div>
      </ng-container>
      <div class="input-box">
        <div class="title">驗證碼<span class="require">＊必填</span></div>
        <div class="refreshbox">
          <input class="refreshinput" placeholder="請輸入圖形驗證碼" name="Code" [(ngModel)]="request.Code">
          <img [src]="resimg.Base64" *ngIf="resimg.Base64">
          <img class="refresh" src="/assets/images/refresh.svg" alt="refresh" (click)="refresh()">
        </div>
      </div>

      <div class="confirm-btn-box">
        <button type="submit" class="confirm-btn" (click)="ContantUs()">確認送出</button>
      </div>
    </form>
  </main>
</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData"
  (visibleChange)="visibleChange($event)"></app-message-dialog>