<main>
    <h2 class="sub-title sub-title-first">申購買回清單</h2>
    <div class="search-wrap">
        <div class="calendar-item">
            <label>選擇查詢日期</label>
            <div class="calendar-wrap">
                <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
                <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="SDate" placeholder="年/月/日"></p-calendar>
            </div>
        </div>
        <button (click)="GetSearch()">查詢</button>
    </div>

    <div *ngIf="data" class="table-title">{{data.CPreDate2}} 申購買回清單公告</div>
    <div *ngIf="data" class="detail-table-wrap">
        <div class="detail-table detail-table2 detail-table3">
            <div class="items">
                <div class="item">
                    <div class="label">預收申購總價金(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CPreAllotAmt | number}}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">基金淨資產價值(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CFundSize | number}}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">已發行受益權單位總數</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CFundUnit | number}}
                    </div>
                </div>
            </div>

            <div class="items">
                <div class="item">
                    <div class="label">與前日已發行單位差異數</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CFundUnitOld | number}}
                    </div>
                </div>
            </div>

            <div class="items">
                <div class="item">
                    <div class="label">{{data.CSettleDate2}} 預估發行受益權單位數</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CPreSize | number}}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">每受益權單位淨資產價值(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{ addThousandsSeparator(data.CFundNavStr) }}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">每現金申購/買回基數之受益權單位數</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CBasicUnit | number}}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">每現金申購/買回基數約當市值(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CBasicPrice | number}}
                    </div>
                </div>
            </div>
            <div class="items">
              <div class="item">
                  <div class="label">{{data.CNavDate}} 每基數實際申購總價金(元)</div>
              </div>
              <div class="item">
                  <div class="desc">
                      {{data.CBasicCash | number}}
                  </div>
              </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">{{data.CNavDate}} 每基數申購總價金差異額(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CBasicCashNew | number}}
                    </div>
                </div>
            </div>

            <div class="items">
                <div class="item">
                    <div class="label">{{data.CNavDate}} 每申購基數之申購交易費(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CAllotTradeFee | number}}
                    </div>
                </div>
            </div>

            <div class="items">
                <div class="item">
                    <div class="label">{{data.CNavDate}} 每買回基數之買回交易費(元)</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{data.CRedemTradeFee | number}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="no-data" *ngIf="!data">查無資料</p>

    <div *ngIf="data" class="note-wrap">
        <div style="color: #DC3545;">註1：每一基金營業日將依據當下各基金標的商品之流動性、基金淨值、商品價格及申購作業流程等各項因素，決定次一營業日預計釋出之總組數，惟總組數可能因前述變動因素而更新，以本公司最新公告為準。</div>
        <div style="color: #DC3545;" *ngIf="data.CReleaseDate">註2：{{data.CReleaseDate}}預計釋出總組數 {{data.CReleaseTotal}} 組，其中釋出予受託客戶（不含流動量提供者）共 {{data.CReleaseClient}} 組。
          <ng-container *ngIf="data.CReleaseNavDate">
            (計算淨值日：{{data.CReleaseNavDate}})
          </ng-container>
        </div>
        <ng-container *ngIf="data.FundsSplit">
          <div [innerHTML]="data.Alert|html"></div>
         </ng-container>
        <div style="margin-top: 24px;">
            參考匯率：<br>
            1 USD = TWD {{data.CCurrRate}}<br>
            (資料日期：{{data.CCurrDate}})
        </div>
        <div style="margin-top: 24px;">
            註1：預估發行受益權單位數，係以前一日已發行受益權單位總數，加計當日初級市場申購單位減去贖回單位之淨申贖單位而得，並非最終確定數據，僅提供投資人參考。
        </div>
        <div>
            註2：每現金申購/買回基數之實際現金差額以基金經理公司通知給付之金額為準。每現金申購/買回基數總價金與實際執行結果有關，詳請參閱公開說明書。
        </div>

    </div>

</main>
