import { Meta, Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ArticledataID, ResponseArticleID } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { DialogData } from 'src/app/shared/models/base';

@Component({
  selector: 'app-unit-detail',
  templateUrl: './unit-detail.component.html',
  styleUrls: ['./unit-detail.component.scss']
})
export class UnitDetailComponent {
  currPath = ''; //當前的路由
  request = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;
  url = '';
  Type = ''; //Type1列表;Type2圖文
  FId = 0;
  CId = 0;
  isPreview = false; //是否為預覽
  showError = false; //是否真無資料
  noComeback = false;//Type5關閉回列表
  footertitle = ''//footer文章使用的標題
  footer = false;//判斷是否為footer文章
  router1 = '';
  Article =false; //判斷是否為meta文章
  visible =false;
  dialogData: DialogData = {} as DialogData;
  constructor(
    // private location: Location,
    private route: ActivatedRoute,
    public aritcleService: AritcleService,
    private router: Router,
    private titleService: Title,
    private metaTagService: Meta,
  ) {
    this.router1 = this.router.url;
  }

  ngOnInit(): void {

    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      this.Type = params['Type'];
      this.FId = Number(params['FId']);
      this.CId = Number(params['CId']);
      this.isPreview = params['isPreview']
      this.request.FunctionID = this.FId;
      this.request.ID = this.CId;
      if (this.isPreview) this.request.IsPreview = true;
      if (this.Type == 'type5' || this.Type == 'Type5' || this.FId == 638) this.noComeback = true;
    });
    this.GetArticleByID();
    //修改路徑使用router不會自動更新的問題
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.CId = event.id;
        this.GetArticleByID();
      }
    });
  }


  back() {
    this.router.navigate(['unit', this.Type, this.FId]);
  }

  GetArticleByID() {
    this.aritcleService.GetArticleByID(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.showError = false;
        this.list = res.Entries;
        if (this.FId == 638) {
          this.footertitle = this.list.CName;
          this.footer = true;
        }
        if (this.router1 == '/unit/Type5/7051/1') {
          this.footertitle = this.list.CName;
          this.footer = true;
        }
        if (res.Entries.MetaDataInfo) {
          if (res.Entries.MetaDataInfo.CTitle || res.Entries.MetaDataInfo.CContent || res.Entries.MetaDataInfo.CKeyword) {
            this.Article = true;
          }
          this.titleService.setTitle(res.Entries.MetaDataInfo.CTitle);
          this.metaTagService.updateTag({
            name: 'title',
            content: res.Entries.MetaDataInfo.CTitle
          });
          this.metaTagService.updateTag({
            name: 'description',
            content: res.Entries.MetaDataInfo.CContent,
          });
          this.metaTagService.updateTag({
            name: 'keyword',
            content: res.Entries.MetaDataInfo.CKeyword,
          });
        }
      }
      // else this.showError = true;
      else{
        this.visible = true;
        this.dialogData.title = '提醒'
        this.dialogData.message = '連結已失效。點[確認]後回到首頁。'
    }
    });
  }

  visibleChange(e: any) {
    this.visible = false;
    this.router.navigate(['/']);
  }
}
