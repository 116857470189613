import { Page } from "./page";

export class ResponseArticle extends Page {
  FunctionID: number = 0;
  IsPagination: boolean = false;
}

export interface Articledata {
  CID: number;
  CName:number;
  CParentID:number;
  ParentName: string;
  Data:Data[];
}

export interface Data{
  CId: number;
  CFunctionId: number;
  CCategoryId: number;
  CName: string;
  CContent: string;
  CFileNameSys: string;
  CFileNameOld: string;
  CFileDescription: string;
  CShowStartDt: string;
  CShowEndDt: string;
  CMetadataMainId: number;
  CPageAdMainId: number;
  CViewCompetenceMainId: number;
  CFooterId: number;
  CPhotoFileName: string;
  CEdmparameter: string;
  CLinkType: number;
  CLinkUrl: string;
  CLinkFile: string;
  CAnother: number;
  CIsSticky: boolean;
  CVideoDescription: string;
  CPhotoName: string;
  CIsNotice: boolean;
  CSummary: boolean;
  CCreateDt: string;
  Tags: string;
  CPopularSdate: string;
  CPopularEdate: string;
}

export class ResponseArticleID extends Page {
  FunctionID: number = 0;
  ID: number = 0;
  IsPreview: boolean = false;
}

export interface ArticledataID {
  CId: number,
CNextId: number,
CPrevId: number,
CFunctionId: number,
CFunctionName: string;
ParentID: number,
ParentName: string;
CCategoryId: number,
CName: string;
CContent: string;
CFileNameSys: string;
CFileNameOld: string;
CFileDescription: string;
CShowStartDt: string;
CShowEndDt: string;
CCreateDt: string;
CMetadataMainId: number,
CPageAdMainId: number,
CViewCompetenceMainId: number,
CVideoDescription: string;
CVideoFileName: string;
CYoutubeEmbedCode: string;
CVideoType: number,
CFooterId: number,
CPhotoFileName: string;
CEdmparameter: string;
CLinkType: number,
CLinkUrl: string;
CLinkFile: string;
CAnother: number,
CIsSticky: boolean
CIsNotice :boolean
Tags: string;
MetaDataInfo: MetaDataInfo;
CPopularSdate: string;
CPopularEdate: string;
}

export interface MetaDataInfo {
  CContent: string;
  CKeyword: string;
  CTitle: string;
}
export interface QAReq  {
  Keyword: string;
  CCategoryMainId: number;
  IsAccessible: boolean;
}

export interface QAList {
  CCategoryMainId: number;
  CCategoryName: string;
  FaqList:FaqList[];
}

export interface FaqList {
  CId: number;
  CQuestion: string;
  CAnswer: string;
  CIsAccessible:boolean;
}


