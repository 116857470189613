<div class="top-wrap">
  <main>
    <ol vocab="https://schema.org/" typeof="BreadcrumbList">
      <li property="itemListElement" typeof="ListItem">
        <a property="item" typeof="WebPage" [href]="domain">
          <span property="name">Home</span></a>
        <meta property="position" content="1">
      </li>
      <li property="itemListElement" typeof="ListItem">
        <a property="item" typeof="WebPage" routerLink="/product/etf-list">
          <span property="name">ETF總覽</span></a>
        <meta property="position" content="2">
      </li>
      <li property="itemListElement" typeof="ListItem">
        <a property="item" typeof="WebPage" (click)="tabActiveChange(1)">
          <span property="name">基金介紹</span></a>
        <meta property="position" content="3">
      </li>
    </ol>

    <div class="search-label">查詢其他基金</div>
    <div class="dropdown-wrap">
      <div class="dropdown">
        <p-dropdown [options]="fundTypeList" (onChange)="selectedTypeChange($event)" [(ngModel)]="selectedType"
          optionLabel="Name"></p-dropdown>
      </div>
      <div class="dropdown" *ngIf="fundSelectList.length">
        <p-dropdown [options]="fundSelectList" (onChange)="selectListChange($event)" [(ngModel)]="selectedFund"
          optionLabel="FundShortName"></p-dropdown>
      </div>
    </div>
    <h1 class="fundName" *ngIf="!baseService.Web$.value">
      {{FundDetail?.BasicInfo?.FundShortName}}
      <span class="warning-text">{{FundDetail?.BasicInfo?.FundWarning}}</span>
    </h1>
    <a class="aLink" [routerLink]="'/etf/register/' + FundDetailTopData.FundNo" *ngIf="FundDetailTopData.IsCanTrade"> <button *ngIf="!baseService.Web$.value && FundDetailTopData.IsCanTrade">線上申購</button></a>
    <h1 class="fundName" *ngIf="baseService.Web$.value">
      {{FundDetail?.BasicInfo?.FundShortName}}
      <span class="warning-text">{{FundDetail?.BasicInfo?.FundWarning}}</span>
    </h1>

    <div class="fund-info">
      <div class="fund-item">
        <div class="name">最新淨值 ({{FundDetailTopData.LatestDataDt}})</div>
        <div style="display: flex;align-items: center;"><span class="value">{{FundDetailTopData.LatestNetValueStr}}</span><span *ngIf="FundDetail.BasicInfo?.FundCurrencyTypeStr">{{FundDetail.BasicInfo?.FundCurrencyTypeStr}}</span></div>
      </div>
      <div class="fund-item">
        <div class="name">漲跌 (漲跌幅)</div>
        <div class="value-wrap">
          <span class="value"
            [ngClass]="{'pValue': FundDetailTopData.LatestChange > 0,'nValue': 0 > FundDetailTopData.LatestChange}">
            <span class="arrow"
              [ngClass]="{'nArrow': 0 > FundDetailTopData.LatestChange,none: FundDetailTopData.LatestChange == 0}"></span>
            {{FundDetailTopData.LatestChangeStr}}
          </span>
          <span class="ratio"
            [ngClass]="{'pValue': FundDetailTopData.LatestChangeRatio > 0,'nValue': 0 > FundDetailTopData.LatestChangeRatio}">
            (<span class="arrow"
              [ngClass]="{'nArrow': 0 > FundDetailTopData.LatestChangeRatio,none: FundDetailTopData.LatestChangeRatio == 0}"></span>{{FundDetailTopData.LatestChangeRatioStr}})
          </span>
        </div>
      </div>
      <div class="fund-item">
        <div class="name">歷史淨值最低 ({{FundDetailTopData.LowestNAVDate}})</div>
        <div><span class="value">{{FundDetailTopData.LowestNAVStr}}</span></div>
      </div>
      <div class="fund-item">
        <div class="name">歷史淨值最高 ({{FundDetailTopData.HighestNAVDate}})</div>
        <div><span class="value">{{FundDetailTopData.HighestNAVStr}}</span></div>
      </div>
      <a class="aLink" [routerLink]="'/etf/register/' + FundDetailTopData.FundNo" *ngIf="FundDetailTopData.IsCanTrade">
        <button *ngIf="baseService.Web$.value && FundDetailTopData.IsCanTrade">
          線上申購
        </button>
      </a>
    </div>
  </main>
</div>

<div class="detail-wrap">
  <div class="tab-menu">
    <main class="tab-inner-menu">
      <div class="tab-item" *ngFor="let item of tabList" [ngClass]="{'tab-item-active': tabActive == item.id}"
        (click)="tabActiveChange(item.id)">{{item.name}}</div>
    </main>
  </div>

  <ng-container *ngIf="tabActive == 1">
    <main>
      <h2 class="sub-title sub-title-first">基金特色</h2>
      <ol>
        <li *ngFor="let data of FundFeatureList">{{data}}</li>
      </ol>

      <h2 class="sub-title">基本資料</h2>
      <div class="detail-table-wrap" *ngIf="FundDetail.BasicInfo">
        <div class="detail-table">
          <div class="itembox">
            <div class="itemas">
              <div class="label">淨值日期</div>
              <div class="desc">{{FundDetail.BasicInfo.FundNavDate}}</div>
            <!-- <div class="item">
              <div class="label">前一營業日淨值</div>
              <div class="desc">
                {{FundDetail.BasicInfo.PreviousNav}}
              </div>
            </div> -->
          </div>
          <div class="itemas">
            <div class="label">前一營業日淨值</div>
            <div class="desc">
              {{FundDetail.BasicInfo.PreviousNav}}
            </div>
          </div>
          <div class="itemas" *ngIf="FundDetail.BasicInfo.Description">
            <div class="label">基金描述</div>
            <div class="desc">
              {{FundDetail.BasicInfo.Description}}
            </div>
          </div>
          <div class="itemas" *ngIf="FundDetail.BasicInfo.FundSize">
            <div class="label">基金規模</div>
            <div class="desc">
              {{FundDetail.BasicInfo.FundSize}}
            </div>
          </div>
          <div class="itemas" *ngIf="FundDetail.BasicInfo.Remark">
            <div class="label">基金備註</div>
            <div class="desc">
              {{FundDetail.BasicInfo.Remark}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">基金經理人</div>
            <div class="desc">
              {{FundDetail.BasicInfo.Manager}}
            </div>
          </div>
          <div class="itemas" *ngIf="FundDetail.BasicInfo.CoManager">
            <div class="label">協管經理人</div>
            <div class="desc">
              {{FundDetail.BasicInfo.CoManager}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">經理費</div>
            <div class="desc">
              {{FundDetail.BasicInfo.ManagerFee}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">保管費</div>
            <div class="desc">
              {{FundDetail.BasicInfo.CustodyFee}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">成立日期</div>
            <div class="desc">
              {{FundDetail.BasicInfo.SetUpDt}}
            </div>
          </div>
          <div class="itemas" *ngIf="FundDetail.BasicInfo.ISINCode">
            <div class="label">ISIN代碼</div>
            <div class="desc">
              {{FundDetail.BasicInfo.ISINCode}}
            </div>
          </div>
          <div class="itemas" *ngIf="FundDetail.BasicInfo.BloomberCode">
            <div class="label">彭博代碼</div>
            <div class="desc">
              {{FundDetail.BasicInfo.BloomberCode}}
            </div>
          </div>
          <div class="itemas"  *ngIf="FundDetail.BasicInfo.LipperCode">
            <div class="label">Lipper代碼</div>
            <div class="desc">
              {{FundDetail.BasicInfo.LipperCode}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">投資地區</div>
            <div class="desc">
              {{FundDetail.BasicInfo.FundAreaStr}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">計價幣別</div>
            <div class="desc">
              {{FundDetail.BasicInfo.FundCurrencyTypeStr}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">配息頻率</div>
            <div class="desc">
              {{FundDetail.BasicInfo.FundDividendStr}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">漲跌幅度</div>
            <div class="desc">
              {{FundDetail.BasicInfo.ChangeRatio}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">風險等級</div>
            <div class="desc">
              {{FundDetail.BasicInfo.FundRiskLevelStr}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">股票代碼</div>
            <div class="desc">
              {{FundDetail.BasicInfo.StockNo}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">上市日期</div>
            <div class="desc">
              {{FundDetail.BasicInfo.ListingDT}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">標的指數</div>
            <div class="desc"  [innerHTML]="FundDetail.BasicInfo.StockIndex|html">
              <!-- {{FundDetail.BasicInfo.StockIndex}} -->
            </div>
          </div>
          <div class="itemas">
            <div class="label">指數授權費</div>
            <div class="desc" [innerHTML]="FundDetail.BasicInfo.IndexLicensingFee|html">
              <!-- {{FundDetail.BasicInfo.IndexLicensingFee}} -->
            </div>
          </div>
          <div class="itemas">
            <div class="label">信用交易</div>
            <div class="desc" [innerHTML]="FundDetail.BasicInfo.MarginTrading|html">
              <!-- {{FundDetail.BasicInfo.MarginTrading}} -->
            </div>
          </div>
          <div class="itemas">
            <div class="label">次級市場交易<br>手續費(‰)</div>
            <div class="desc" [innerHTML]="FundDetail.BasicInfo.SecMarketTranFee|html">
               <!-- {{FundDetail.BasicInfo.SecMarketTranFee}} -->
            </div>
          </div>
          <div class="itemas">
            <div class="label">現金申購/買回基數</div>
            <div class="desc" [innerHTML]="FundDetail.BasicInfo.CashNAV|html">
                <!-- {{FundDetail.BasicInfo.CashNAV}} -->
            </div>
          </div>
          <div class="itemas">
            <div class="label">交易單位</div>
            <div class="desc">
              {{FundDetail.BasicInfo.TradingUnit}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">升降單位</div>
            <div class="desc">
              {{FundDetail.BasicInfo.ElevationUnit}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">證券交易稅(‰)</div>
            <div class="desc">
              {{FundDetail.BasicInfo.SecuritiesTransactionTax}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">初級市場申購、買回方式</div>
            <div class="desc">
              {{FundDetail.BasicInfo.PMSubRedMethod}}
            </div>
          </div>
          <div class="itemas">
            <div class="label">申購/買回手續費<br>申購/買回交易費用</div>
            <div class="desc">
              {{FundDetail.BasicInfo.SubRedemptionFee}}
            </div>
          </div>
          <!-- <div class="items">
            <div class="item">
              <div class="label">成立日期</div>
              <div class="desc">
                {{FundDetail.BasicInfo.SetUpDt}}
              </div>
            </div>
            <div class="item">
              <div class="label">上市日期</div>
              <div class="desc">
                {{FundDetail.BasicInfo.ListingDT}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">股票代碼</div>
              <div class="desc">
                {{FundDetail.BasicInfo.StockNo}}
              </div>
            </div>
            <div class="item">
              <div class="label">基金類型</div>
              <div class="desc">
                {{FundDetail.BasicInfo.FundTypeStr}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">標的指數</div>
              <div class="desc">
                {{FundDetail.BasicInfo.StockIndex}}
              </div>
            </div>
            <div class="item">
              <div class="label">交易單位</div>
              <div class="desc">
                {{FundDetail.BasicInfo.TradingUnit}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">計價幣別</div>
              <div class="desc">
                {{FundDetail.BasicInfo.FundCurrencyTypeStr}}
              </div>
            </div>
            <div class="item">
              <div class="label">風險等級</div>
              <div class="desc">
                {{FundDetail.BasicInfo.FundRiskLevelStr}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">經理人</div>
              <div class="desc">
                {{FundDetail.BasicInfo.Manager}}
              </div>
            </div>
            <div class="item">
              <div class="label">經理費</div>
              <div class="desc">
                {{FundDetail.BasicInfo.ManagerFee}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">保管費</div>
              <div class="desc">
                {{FundDetail.BasicInfo.CustodyFee}}
              </div>
            </div>
            <div class="item">
              <div class="label">指數授權費</div>
              <div class="desc">
                {{FundDetail.BasicInfo.IndexLicensingFee}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">配息頻率</div>
              <div class="desc">
                {{FundDetail.BasicInfo.FundDividendStr}}
              </div>
            </div>
            <div class="item">
              <div class="label">漲跌幅度</div>
              <div class="desc">
                {{FundDetail.BasicInfo.ChangeRatio}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">升降單位</div>
              <div class="desc">
                {{FundDetail.BasicInfo.ElevationUnit}}
              </div>
            </div>
            <div class="item">
              <div class="label">信用交易</div>
              <div class="desc">
                {{FundDetail.BasicInfo.MarginTrading}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">證券交易稅(‰)</div>
              <div class="desc">
                {{FundDetail.BasicInfo.SecuritiesTransactionTax}}
              </div>
            </div>
            <div class="item">
              <div class="label">次級市場交易手續費<br>(‰)</div>
              <div class="desc">
                {{FundDetail.BasicInfo.SecMarketTranFee}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">初級市場申購、買回方式</div>
              <div class="desc">
                {{FundDetail.BasicInfo.PMSubRedMethod}}
              </div>
            </div>
            <div class="item">
              <div class="label">現金申購/買回基數</div>
              <div class="desc">
                {{FundDetail.BasicInfo.CashNAV}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">申購/買回手續費<br>申購/買回交易費用</div>
              <div class="desc">
                {{FundDetail.BasicInfo.SubRedemptionFee}}
              </div>
            </div>
          </div> -->
        </div>
      </div>
      </div>

      <h2 class="sub-title">檔案下載</h2>
    </main>

    <div class="download-wrap">
      <main class="download-main">
        <div class="download-item" *ngIf="FundLinkData.ProspectusUrl">
          <a [href]="FundLinkData.ProspectusUrl" target="_blank">
            <img class="download-icon" src="/assets/images/download_icon.svg" alt="下載公開說明書" />公開說明書
          </a>
        </div>
        <div class="download-item" *ngIf="FundLinkData.SimpleProspectusUrl">
          <a [href]="FundLinkData.SimpleProspectusUrl" target="_blank">
            <img class="download-icon" src="/assets/images/download_icon.svg" alt="下載簡式公開說明書" />簡式公開說明書
          </a>
        </div>
        <div class="download-item" *ngIf="FundLinkData.MonthUrl">
          <a [href]="FundLinkData.MonthUrl" target="_blank">
            <img class="download-icon" src="/assets/images/download_icon.svg" alt="下載月報" />月報
          </a>
        </div>
        <div class="download-item no-data" *ngIf="!FundLinkData.ProspectusUrl&&!FundLinkData.SimpleProspectusUrl&&!FundLinkData.MonthUrl">無相關資料</div>
      </main>
    </div>
    <main>
      <h2 class="sub-title">交易須知</h2>
      <!-- <div class="table-title">初級市場</div> -->
      <div class="detail-table-wrap">
        <div class="detail-table detail-table2">
          <div class="items" *ngFor="let data of FundDetail.CETFTranNotice">
            <div class="item"
              [ngClass]="{'table-title': data.IsTitle,'table-title-border': !data.Val2,'table3-title':!data.Val2}">
              <div class="label">{{data.Name}}</div>
            </div>
            <div class="item" *ngIf="!data.IsTitle" [ngClass]="{'table3-item': !data.Val2}">
              <div class="desc" [ngClass]="{'desc-border': !data.Val2}">
               <div  [innerHTML]="data.Val1|html"></div>
              </div>
            </div>
            <div class="item" *ngIf="!data.IsTitle && data.Val2">
              <div class="desc">
                <div  [innerHTML]="data.Val2|html"></div>
                <!-- {{data.Val2}} -->
              </div>
            </div>
          </div>

          <!-- <div class="items">
            <div class="item">
              <div class="label">參與券商申請截止時間</div>
            </div>
            <div class="item">
              <div class="desc">
                02:00 PM
              </div>
            </div>
            <div class="item">
              <div class="desc">
                02:00 PM
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <!-- <div class="table-title table-title2">次級市場</div>
      <div class="detail-table-wrap">
        <div class="detail-table detail-table2">
          <div class="items">
            <div class="item">
              <div class="label">交易類別</div>
            </div>
            <div class="item">
              <div class="desc">
                現金申購
              </div>
            </div>
            <div class="item">
              <div class="desc">
                現金買回
              </div>
            </div>
          </div>

          <div class="items">
            <div class="item">
              <div class="label">參與券商申請截止時間</div>
            </div>
            <div class="item">
              <div class="desc">
                02:00 PM
              </div>
            </div>
            <div class="item">
              <div class="desc">
                02:00 PM
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-title table-title2">費用說明</div>
      <div class="detail-table-wrap">
        <div class="detail-table detail-table2 detail-table3">
          <div class="items">
            <div class="item">
              <div class="label">成立日(不含當日)前之申購手續費</div>
            </div>
            <div class="item">
              <div class="desc">
                最高不超過發行價額之百分之一。
              </div>
            </div>
          </div>

          <div class="items">
            <div class="item">
              <div class="label">上市日(含當日)後申購手續費及參與證券商處理費</div>
            </div>
            <div class="item">
              <div class="desc">
                合計最高不超過本基金每受益權單位淨資產價值百分之一。
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </main>

  </ng-container>

  <!-- 指數介紹 -->
  <ng-container *ngIf="tabActive == 2">
    <app-etf-indices [FundShortName]="FundDetail.BasicInfo?.FundShortName || ''"
      [FundWarning]="FundDetail.BasicInfo?.FundWarning || ''" [data]="FundDetail.CIndexDescription || []"
      [navData]="FundDetail.IndexMarketData" [FundNo]="FundDetail.BasicInfo?.FundNo|| ''"></app-etf-indices>
  </ng-container>

  <!-- 預估淨值vs即時市價 -->
  <ng-container *ngIf="tabActive == 3">
    <app-nav-realtime [fundId]="FundNo"></app-nav-realtime>
  </ng-container>

  <!-- 淨值VS收盤價 -->
  <ng-container *ngIf="tabActive == 4">
    <app-etf-nav [fundId]="FundNo" (submit)="getNavSubmit($event)" [data]="FundNAVData" [FundsSplitRemark]="FundsSplitRemark" [dataup]="FundNAVDataup" [Creatdate]="Createdate"></app-etf-nav>
  </ng-container>

  <!-- 基金成分股 -->
  <ng-container *ngIf="tabActive == 5">
    <app-etf-constituent [data]="FundConstituent.FundConstituentData"></app-etf-constituent>
  </ng-container>

  <!-- 申購買回清單 -->
  <ng-container *ngIf="tabActive == 6">
    <app-etf-pcf (dateChange)="dateChange($event)" [data]="FundTradeInfo.TradeInfoData"></app-etf-pcf>
  </ng-container>

  <!-- 流動量提供與參與券商 -->
  <ng-container *ngIf="tabActive == 7">
    <app-etf-provider [data]="ParticipatingDealerList"></app-etf-provider>
  </ng-container>
</div>


<ng-container *ngFor="let data of dialogData | slice:0:3">
  <app-confirm-dialog *ngIf="visible" [visible]="visible" [data]="data" (visibleChange)="visibleChange($event)" (unCheck)="unCheck($event)"></app-confirm-dialog>
</ng-container>

<ng-container *ngIf="visible && Disclaimer && tabActive == 3 || visible && Disclaimer && tabActive == 4">
  <app-disclaimer-dialog *ngIf="visible && Disclaimer" [visible]="visible" [data]="dialogDataDisclaimer" (visibleChange)="visibleChangeDisclaimer($event)"  (unCheck)="etfunCheck($event)"></app-disclaimer-dialog>
</ng-container>
