<main *ngIf="data">
  <div class="datebox">
    <h2 class="sub-title sub-title-first">基金資產</h2>
    <div class="date">資料日期：{{data.Date}}</div>
  </div>
    <div class="table-title">{{data.Item1Title}}</div>
    <div class="detail-table-wrap">
        <div class="detail-table detail-table2 detail-table3">
            <div class="items">
                <div class="item">
                    <div class="label">基金淨資產價值</div>
                </div>
                <div class="item">
                    <div class="desc">
                      {{ addThousandsSeparator(data.Item1[0].Fund_Size) }}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">基金在外流通單位數</div>
                </div>
                <div class="item">
                    <div class="desc">
                      {{ addThousandsSeparator(data.Item1[0].Fund_Unit) }}
                    </div>
                </div>
            </div>
            <div class="items">
              <div class="item">
                  <div class="label">基金每單位淨值</div>
              </div>
              <div class="item">
                  <div class="desc">
                      {{ addThousandsSeparator(data.Item1[0].Fund_NAV) }}
                  </div>
              </div>
          </div>
        </div>
    </div>
    <div class="table-title table-title2">{{data.Item2Title}}</div>
    <div class="detail-table-wrap">
        <div class="detail-table detail-table2 detail-table3">
            <div class="items">
                <div class="item">
                    <div class="label">股票</div>
                </div>
                <div class="item">
                    <div class="desc">
                      {{ addThousandsSeparator(data.Item2[0].Stock_Size) }}
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <div class="label">期貨(名目本金)</div>
                </div>
                <div class="item">
                    <div class="desc">
                      {{ addThousandsSeparator(data.Item2[0].Fut_Size) }}
                    </div>
                </div>
            </div>
            <div class="items">
              <div class="item">
                  <div class="label">ETF</div>
              </div>
              <div class="item">
                  <div class="desc">
                    {{ addThousandsSeparator(data.Item2[0].ETF_Size)}}
                  </div>
              </div>
          </div>
        </div>
    </div>
    <div class="table-title table-title2">{{ addThousandsSeparator(data.Item3Title) }}</div>
    <div class="detail-table-wrap">
        <div class="detail-table detail-table2 detail-table3">
            <div class="items" *ngFor="let item of data.Item3">
                <div class="item">
                    <div class="label">{{item.Item}}</div>
                </div>
                <div class="item">
                    <div class="desc">
                        {{ addThousandsSeparator(item.Amt) }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h2 class="sub-title">股票投資比例</h2>
    <table class="table-web">
        <thead>
          <tr>
            <th>股票代號</th>
            <th>股票名稱</th>
            <th>股數</th>
            <th>投資比例</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr>
            <th>S_202311</th>
            <td>aaa</td>
            <td>aaa</td>
            <td>aaa</td>
          </tr> -->
        </tbody>
    </table>
    <!-- <table class="table-mob">
        <thead>
          <tr>
            <th>股票代號
                <span class="code">aaa</span>
            </th>
            <th class="name">
                202311黃豆期貨
            </th>
            <th>股數
                <span>1000</span>
            </th>
            <th>投資比例
                <span>10</span>
            </th>
          </tr>
          <tr>
            <th>股票代號
                <span class="code">aaa</span>
            </th>
            <th class="name">
                202311黃豆期貨
            </th>
            <th>股數
                <span>1000</span>
            </th>
            <th>投資比例
                <span>10</span>
            </th>
          </tr>
        </thead>
    </table> -->
    <p class="no-data">沒有任何資料</p>

    <h2 class="sub-title">期貨投資比例</h2>
    <table class="table-web">
        <thead>
          <tr>
            <th>期貨代號</th>
            <th>期貨名稱</th>
            <th>口數</th>
            <th>投資比例</th>
            <th>契約年月</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data.Future">
            <th>{{item.Future_Code}}</th>
            <td>{{item.Future_Name}}</td>
            <td>{{item.Future_Unit}}</td>
            <td>{{item.Future_Percentage}}</td>
            <td>{{item.Future_Y_M}}</td>
          </tr>
        </tbody>
    </table>
    <table class="table-mob">
        <thead>
          <tr *ngFor="let item of data.Future">
            <th>期貨代號
                <span class="code">{{item.Future_Code}}</span>
            </th>
            <th class="name">
              {{item.Future_Name}}
            </th>
            <th>口數
                <span>{{item.Future_Unit}}</span>
            </th>
            <th>投資比例
                <span>{{item.Future_Percentage}}</span>
            </th>
            <th>契約年月
                <span>{{item.Future_Y_M}}</span>
            </th>
          </tr>
        </thead>
    </table>
    <!-- <p class="no-data">沒有任何資料</p> -->

    <h2 class="sub-title">ETF投資比例</h2>
    <table class="table-web">
        <thead>
          <tr>
            <th>ETF代碼</th>
            <th>ETF名稱</th>
            <th>股數</th>
            <th>投資比例</th>
          </tr>
        </thead>
        <!-- <tbody>
          <tr>
            <th>S_202311</th>
            <td>aaa</td>
            <td>aaa</td>
            <td>aaa</td>
          </tr>
        </tbody> -->
    </table>
    <!-- <table class="table-mob">
        <thead>
          <tr>
            <th>ETF代碼
                <span class="code">aaa</span>
            </th>
            <th class="name">
                202311黃豆期貨
            </th>
            <th>股數
                <span>1000</span>
            </th>
            <th>投資比例
                <span>10</span>
            </th>
          </tr>
        </thead>
    </table> -->
    <p class="no-data">沒有任何資料</p>

    <h2 class="sub-title">附買回債券投資金額</h2>
    <table class="table-web table2">
        <thead>
          <tr>
            <th>名稱</th>
            <th>金額</th>
          </tr>
        </thead>
        <!-- <tbody>
          <tr>
            <th>S_202311</th>
            <td>100</td>
          </tr>
        </tbody> -->
    </table>
    <!-- <table class="table-mob">
        <thead>
          <tr>
            <th>名稱
                <span class="code">aaa</span>
            </th>

            <th>金額
                <span>10</span>
            </th>
          </tr>
        </thead>
    </table> -->
    <p class="no-data">沒有任何資料</p>
</main>
