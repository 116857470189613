import { Component, Inject, Input, PLATFORM_ID, OnInit } from '@angular/core';
import { MenuService } from './../../../shared/services/Menu.service';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { BreadCrumbInfo, GetMenu, BreadCrumbReq } from '../../../shared/models/menu';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { mainData } from '../../../shared/interface/main-data';
import { isPlatformBrowser } from '@angular/common';
import { MetaData } from 'src/app/shared/models/home';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent {
  breadCrumb: BreadCrumbInfo = {} as BreadCrumbInfo;
  domain = environment.domain;
  breadCrumbId = 0;
  router1 = '';
  router2 = '';
  router3 = '';
  canonicalUrl = '';
  mainUrl = '';
  isMenu = {} as GetMenu;
  SubTitle = '';
  Title = '';
  isReady = false;
  request: BreadCrumbReq = {} as BreadCrumbReq;
  breadCrumbShow = false;
  isBrowser: boolean;
  @Input() footer: boolean = false;
  @Input() footertitle = '';
  @Input() Article: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public menuService: MenuService,
    private route: ActivatedRoute,
    private router: Router,
    public mainData$: mainData,
    private metaTagService: Meta,
    private titleService: Title,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.router1 = this.router.url.split('/')[1];
    this.router2 = this.router.url.split('/')[2];
    this.router3 = this.router.url;
    this.menuService.Headers$.subscribe((value) => {
      if (value.length && !this.isReady) {
        this.isReady = true;
        this.GetCId(value);
      }
    });

    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );

    urlParams.subscribe(params => {
      if (params['CId']) this.request.UnitAfterID = Number(params['CId']);
      if (params['id']) this.request.UnitAfterID = Number(params['id'].replace('news', ''));
      if (params['FId']) this.request.FunctionID = Number(params['FId']);
      if (params['CId'] || params['id'] || params['FId']) this.isReady = false;
    });

    this.route.url.subscribe(params => {
      if (this.menuService.Headers$.value.length && !this.isReady) {
        this.isReady = true;
        this.GetCId(this.menuService.Headers$.value);
        this.isReady = false;
      }
    })

  }


  GetCId(value: GetMenu[]) {
    let id = 0;
    if (value.length) {
      value.map(val => val.ChildUnits.map(x => {
        if (x.CPageUrl == this.router.url) {
          this.isMenu = val;
          id = x.CId
        }
      }))
      if (this.router2) {
        if (id) this.request.UnitAfterID = id;
        this.canonicalUrl = '/' + this.router1 + '/' + this.router2
      } else {
        id = this.isMenu.ChildUnits[0].CId
        if (id) this.request.UnitAfterID = id;
        this.canonicalUrl = '/' + this.router1 + '/' + this.isMenu.ChildUnits[0].CPageUrl;
      }
      if (this.isMenu.ChildUnits) this.mainUrl = '/' + this.isMenu.ChildUnits[0].CPageUrl;
      //不再menu裡的內頁
      if (this.router2.includes('bulletin-detail')) {
        //  this.mainUrl='/notice/bulletin'
        this.request.FunctionID = 7101
        this.request.UnitAfterID = 15
      } else if (this.router2.includes('yield-detail')) {
        // this.mainUrl='/notice/bulletin'
        this.request.FunctionID = 7107
        this.request.UnitAfterID = 16
      } else if (this.router2.includes('anti-money-detail')) {
        // this.mainUrl='/notice/bulletin'
        this.request.FunctionID = 7020
        this.request.UnitAfterID = 17
      } else if (this.router2.includes('world-detail')) {
        //  this.mainUrl='/point/news'
        this.request.FunctionID = 7001
        this.request.UnitAfterID = 12
      } else if (this.router2.includes('weekly-report-detail')) {
        // this.mainUrl='/point/news'
        this.request.FunctionID = 7007
        this.request.UnitAfterID = 13
      } else if (this.router2.includes('quarterly-report-detail')) {
        // this.mainUrl='/point/news'
        this.request.FunctionID = 7013
        this.request.UnitAfterID = 14
      } else if (this.router2.includes('classroom-detail')) {
        //  this.mainUrl='/etf/classroom'
        this.request.UnitAfterID = 36
      } else if (this.router2.includes('video-detail')) {
        //  this.mainUrl='/etf/classroom'
        this.request.UnitAfterID = 37
      }
      else if (this.router2.includes('intro-detail')) {
        //  this.mainUrl='/etf/classroom'
        this.request.UnitAfterID = 38
      }
      else if (this.router2.includes('dividend-element')) {
        this.request.UnitAfterID = 8
      } else if (this.router2.includes('reference-date')) {
        this.request.UnitAfterID = 8
      } else if (this.router2.includes('trace')) {
        this.request.UnitAfterID = 27
        // this.mainUrl='/product/etf-list'
      } else if (this.router2.includes('bodong')) {
        this.request.UnitAfterID = 27
        // this.mainUrl='/product/etf-list'
      } else if (this.router2.includes('rate')) {
        this.request.UnitAfterID = 27
        //  this.mainUrl='/product/etf-list'
      }
      //懶人包ETF小教室列表
      else if (this.router2.includes('classroom')) {
        this.request.UnitAfterID = 36
        this.SubTitle = 'ETF';
        this.Title = 'ETF懶人包';
        //  this.mainUrl='/etf/classroom'
      }
      //懶人包ETF影音列表
      else if (this.router2.includes('video')) {
        this.request.UnitAfterID = 37
        this.SubTitle = 'ETF';
        this.Title = 'ETF懶人包';
        //  this.mainUrl='/etf/classroom'
      }
      else if (this.router2.includes('fund-lecture')) {
        //需在確認理財講座內頁
        this.request.UnitAfterID = 35
      }
      //懶人包下載列表
      else if (this.router2.includes('intro')) {
        this.request.UnitAfterID = 38
        this.SubTitle = 'ETF';
        this.Title = 'ETF懶人包';
        // this.mainUrl='/etf/classroom'
      }
      else if (this.router2.includes('esg-detail')) {
        this.request.UnitAfterID = 23;
        //  this.mainUrl='/about-us/esg'
      } else if (this.router2 == 'register') {
        this.SubTitle = 'ETF';
        this.Title = 'ETF申購登記';
      }


      if (this.isBrowser) this.menuService.generateCanonicalUrl(this.canonicalUrl);
      if (this.request.UnitAfterID || this.request.FunctionID) this.GetBreadCrumbInfo();
    }
  }

  GetBreadCrumbInfo() {
    this.menuService.GetBreadCrumbInfo(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.breadCrumb = res.Entries;
        if(!this.Article){
          if (this.breadCrumb.CTitle) this.titleService.setTitle(this.breadCrumb.CTitle)
            if (this.breadCrumb.CDescription) this.metaTagService.updateTag({ name: 'description', content: this.breadCrumb.CDescription });
            if (this.breadCrumb.CKeyWord) this.metaTagService.updateTag({ name: 'keyword', content: this.breadCrumb.CKeyWord });
        }
        this.breadCrumbShow = true;
      }
      this.mainData$.loading$.next(false);
    });
  }
}
