import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { mainData } from './../interface/main-data';

@Injectable({
  providedIn: 'root'
})
export class MainDataService implements mainData {
  loading$: BehaviorSubject<boolean>;
  IsApplyTemplete$: BehaviorSubject<boolean>;
  lang$: BehaviorSubject<number>;


  constructor() { 
    this.loading$ = new BehaviorSubject<boolean>(false);
    this.IsApplyTemplete$ = new BehaviorSubject<boolean>(true);
    this.lang$ = new BehaviorSubject<number>(1);
  }
}
