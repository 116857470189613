
<app-bread-crumb></app-bread-crumb>
<main>
  <app-fund-search  id="searchResults" [hotKeyword]="categoryData" [fundCategory]="categoryService.fundYieldCategoryList" (result)="submit($event)"
    [TotalItems]="TotalItems"></app-fund-search>

  <div class="tab-menu">
    <div class="tab-item" *ngFor="let item of tabList" [ngClass]="{'tab-item-active': tabActive == item.id}"
      (click)="tabActiveChange(item.id)">{{item.name}}</div>
  </div>
  <!-- 配息資訊 -->
  <div *ngIf="tabActive == 1" class="dividend-list dividend-original">
    <table>
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>基金類型</th>
          <th>幣別</th>
          <th>配息頻率</th>
          <th>除息日前<br>一日淨值</th>
          <th>基準日</th>
          <th>除息日</th>
          <th>發放日</th>
          <th>每單位<br> 配息金額</th>
          <th>配息紀錄</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fundYieldList">
          <th (click)="goBuy(data.CNo)"><a>{{data.CShortName}} <span class="warning-text">{{data.CWarning}}</span></a></th>
          <td>{{data.CCgFundTypeStr}}</td>
          <td>{{data.CCgCurrencyTypeStr}}</td>
           <td>{{data.CCgDividendTypeStr}}</td>
          <td>{{data.CExDateBeforeOneDayNav}}</td>
          <td>{{data.CBaseDate}}</td>
          <td>{{data.CExDate}}</td>
          <td>{{data.CPayableDate}}</td>
          <td>{{data.CPerShare}}</td>
          <td><a [routerLink]="'/fund-info/' + data.CNo" [queryParams]="{tab: 4}"><img class="icon" src="/assets/images/dividend.svg" /></a></td>
        </tr>
      </tbody>
    </table>
    <p-panel class="p-panel"
    [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
    [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of fundYieldList;let i = index">
    <ng-template pTemplate="header">
      <div (click)="goBuy(data.CNo)">
        {{data.CShortName}}
        <span class="warning-text">{{data.CWarning}}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="headericons">
      <img class="black-arrow" src="/assets/images/black-arrow.svg" />
    </ng-template>
    <div class="panel-item">
      <div class="name">基金類型</div>
      <div class="desc">{{data.CCgFundTypeStr}}</div>
    </div>
    <div class="panel-item">
      <div class="name">幣別</div>
      <div class="desc">{{data.CCgCurrencyTypeStr}}</div>
    </div>
    <div class="panel-item">
      <div class="name">配息頻率</div>
      <div class="desc">{{data.CCgDividendTypeStr}}</div>
    </div>
    <div class="panel-item">
      <div class="name">除息日前一日淨值</div>
      <div class="desc">{{data.CExDateBeforeOneDayNav}}</div>
    </div>
    <div class="panel-item">
      <div class="name">基準日</div>
      <div class="desc">{{data.CBaseDate}}</div>
    </div>
    <div class="panel-item">
      <div class="name">除息日</div>
      <div class="desc">{{data.CExDate}}</div>
    </div>
    <div class="panel-item">
      <div class="name">發放日</div>
      <div class="desc">{{data.CPayableDate}}</div>
    </div>
    <div class="panel-item">
      <div class="name">每單位配息金額</div>
      <div class="desc">{{data.CPerShare}}</div>
    </div>
    <div class="panel-item">
      <div class="name">配息紀錄</div>
      <div class="desc"><a [routerLink]="'/fund-info/' + data.CNo" [queryParams]="{tab: 4}"><img class="icon" src="/assets/images/dividend.svg" /></a></div>
    </div>
  </p-panel>
  </div>

  <!-- 配息組成 -->
  <div *ngIf="tabActive == 2" class="dividend-list dividend-element">
    <table>
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>年/月</th>
          <th>每單位配息</th>
          <th>可分配淨利益÷配息</th>
          <th>本金÷配息</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of FundComposition">
          <th (click)="goBuy(data.FundNo)"><a>{{data.FundShortName}}
            <span class="warning-text">{{data.FundWarning}}</span></a>
          </th>
          <td>{{data.Year}}年{{data.Month}}</td>
          <td>{{data.YieldStr}}</td>
          <td>{{data.DistributeStr}}</td>
          <td>{{data.PrincipalStr}}</td>
        </tr>
      </tbody>
    </table>
    <p-panel class="p-panel"
    [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
    [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of FundComposition;let i = index">
    <ng-template pTemplate="header">
      <div (click)="goBuy(data.FundNo)">
        {{data.FundShortName}}
        <span class="warning-text">{{data.FundWarning}}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="headericons">
      <img class="black-arrow" src="/assets/images/black-arrow.svg" />
    </ng-template>
    <div class="panel-item">
      <div class="name">年/月</div>
      <div class="desc">{{data.Year}}年{{data.Month}}</div>
    </div>
    <div class="panel-item">
      <div class="name">每單位配息</div>
      <div class="desc">{{data.YieldStr}}</div>
    </div>
    <div class="panel-item">
      <div class="name">可分配淨利益÷配息</div>
      <div class="desc">{{data.DistributeStr}}</div>
    </div>
    <div class="panel-item">
      <div class="name">本金÷配息</div>
      <div class="desc">{{data.PrincipalStr}}</div>
    </div>
  </p-panel>
  </div>

   <!-- 配息基準日 -->
   <div *ngIf="tabActive == 3 && FundRecordDate.length" class="dividend-list">
    <table>
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>月份</th>
          <th>收益分配<br>基準日</th>
          <th>除息日</th>
          <th>發放日</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of FundRecordDate">
          <th (click)="goBuy(data.FundNo)"><a>{{data.FundShortName}}
            <span class="warning-text">{{data.FundWarning}}</span></a>
          </th>
          <td>{{data.Month}}</td>
          <td>{{data.RecordDate}}</td>
          <td>{{data.DividendDate}}</td>
          <td>{{data.PaymentDate}}</td>
        </tr>
      </tbody>
    </table>
    <p-panel class="p-panel"
    [collapsed]="baseService.Web$.value || (!baseService.Web$.value && i == 0) ? false : true"
    [toggleable]="baseService.Web$.value ? false : true" *ngFor="let data of FundRecordDate;let i = index">
    <ng-template pTemplate="header">
      <div (click)="goBuy(data.FundNo)">
        {{data.FundShortName}}
        <span class="warning-text">{{data.FundWarning}}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="headericons">
      <img class="black-arrow" src="/assets/images/black-arrow.svg" />
    </ng-template>
    <div class="panel-item">
      <div class="name">月份</div>
      <div class="desc">{{data.Month}}</div>
    </div>
    <div class="panel-item">
      <div class="name">收益分配基準日</div>
      <div class="desc">{{data.RecordDate}}</div>
    </div>
    <div class="panel-item">
      <div class="name">除息日</div>
      <div class="desc">{{data.DividendDate}}</div>
    </div>
    <div class="panel-item">
      <div class="name">發放日</div>
      <div class="desc">{{data.PaymentDate}}</div>
    </div>
  </p-panel>
  </div>
  <button *ngIf="TotalItems > getLength()" class="arrow-btn" (click)="more()">
    顯示更多
    <div class="top-arrow"></div>
  </button>
  <p class="no-data" *ngIf="getLength() >= TotalItems && isSubmit">無更多資料</p>
<div class="note-wrap">
  注意事項：<br>
  1.基金配息率不代表基金報酬率，且過去配息率不代表未來配息率；基金淨值可能因市場因素而上下波動。且投資人可能有因市場波動無法獲得配息之風險。<br>
  2.經理公司得依孳息收入之情況，決定每月應分配之收益金額。<br>
  3.境外所得之已實現資本利得扣除資本損失（包括已實現、未實現之資本損失及月配息單位應負擔之各項成本費用），經理公司得於每年度結束後視該年度之收益情況，決定應分配之收益金額。<br>
  4.上述資料僅供參考，各基金相關配息時間，依本公司公告之實際配息日期為準，實際配息金額與時間將視狀況而可能調整；各基金配息原則，請詳閱基金公開說明書。
</div>
</main>
