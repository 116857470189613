import { Component, EventEmitter, Input, Output } from '@angular/core';
export interface DialogData {
  title:string;
  message:string;
  data: any;
 }

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.scss']
})
export class DisclaimerDialogComponent {
  @Input() data:DialogData = {} as DialogData;
  @Input() visible:boolean = false;
  @Input() IsPreview:boolean = false;
  @Output() visibleChange = new EventEmitter<any>();
  @Output() unCheck = new EventEmitter();
  @Input() noagree:boolean = false;
  constructor(
  ) {
    // this.visible = this.data.visible;
  }

  _visibleChange(visible:boolean) {
    this.visibleChange.emit(visible)
    this.visible = false;
  }

  unCheckClick() {
    this.unCheck.emit();
    this.noagree = true;
  }
}
