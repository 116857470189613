import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { SeminarForm, lectureID } from 'src/app/shared/models/activity';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-lecture-apply',
  templateUrl: './lecture-apply.component.html',
  styleUrls: ['./lecture-apply.component.scss']
})
export class LectureApplyComponent {
  lectureID = {} as lectureID;
  SeminarForm= {} as SeminarForm;
  constructor(
    public baseService: BaseService,
    private route: ActivatedRoute,
    public activityService: ActivityService,
  ) {
    const urlParams: Observable<any> = combineLatest(
      this.route.params
    ).pipe(
      map(([params]) => ({ ...params }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      this.lectureID.CId = Number(id);
    });
    this.GetSeminarForm()
  }

  GetSeminarForm() {
    this.activityService.GetSeminarForm(this.lectureID).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
       this.SeminarForm=res.Entries;
      }
    });
  }
}
