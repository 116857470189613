import { Component } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { QAList ,QAReq} from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-qalist',
  templateUrl: './qalist.component.html',
  styleUrls: ['./qalist.component.scss']
})
export class QAlistComponent {
  QA : QAReq= {} as QAReq;
  qalist: QAList [] = [];
  selectedCategoryId=34;
  constructor(
    public homeService: HomeService,
    // private router: Router,
    public baseService: BaseService,
    public aritcleService: AritcleService
  ) {
    this.GetQAlist()
  }

  GetQAlist() {
    this.aritcleService.GetFaqList(this.QA).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.qalist = res.Entries;
      }
    });
  }

  selectChange(e: any){
    this.selectedCategoryId = e.value.CCategoryMainId;
  }
}
