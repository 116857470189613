<main>
 <div class="sub-title-first" *ngIf="StockSplit.FundsSplit =='1' && StockSplit.Alert"><div *ngIf="StockSplit.Alert" [innerHTML]="StockSplit.Alert|html" ></div></div>
    <h2 class="sub-title sub-title-first">盤中預估淨值與市價</h2>
    <!-- hightChart -->
    <div class="highcharts">
        <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
            [options]="chartOptions" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
          </div>

    <h2 class="sub-title sub-title-first">折溢價</h2>
    <div class="highcharts">
        <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
            [options]="chart2Options" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>

    <div class="note-wrap">
        ※本網站提供之指數盤中價格資訊，約每15秒即時更新一次。 資料時間：{{this.lastDatatime| date:'yyyy/MM/dd HH:mm:ss'}}
    </div>
</main>
