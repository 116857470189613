<div class="qa">
      <div class="wrapper">
        <main>
          <ng-container  *ngFor="let item of data">
            <p-panel class="p-panel" [header]="item.CQuestion" [collapsed]="true" [toggleable]="true">
              <ng-template pTemplate="headericons">
                <img class="black-arrow" src="/assets/images/open.svg" />
              </ng-template>
              <div class="panel-item">
                <div class="desc" [innerHtml]="item.CAnswer|html"></div>
              </div>
            </p-panel>
          </ng-container>
        </main>
      </div>

</div>
