import { Component, HostListener, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { ETFService } from '../../../../shared/services/etf.service';
import {
  ETPNAVPrice,
  ETPNAVPrices
} from '../../../../shared/models/etf';
import { mainData } from 'src/app/shared/interface/main-data';
import { finalize } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';

@Component({
  selector: 'app-nav-realtime',
  templateUrl: './nav-realtime.component.html',
  styleUrls: ['./nav-realtime.component.scss']
})
export class NavRealtimeComponent {
  @Input() fundId = '';

  now = new Date()
  isHighcharts = typeof Highcharts === 'object';
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      data: [],
      type: 'line',
      dataLabels: {
        enabled: true
      },
    }, {
      data: [],
      type: 'line',
      dataLabels: {
        enabled: true
      },
    }],
    legend: {
      enabled: true
    },
    colors: ['#E3961C', '#29A1D8'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
        rotation: 45
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        turboThreshold: 0
      }
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return '收盤價：' + this.y + '<br>' + '時間：' + this.x;
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  };
  chart2Options: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      data: [],
      type: 'line',
      dataLabels: {
        enabled: false
      },
    }],
    legend: {
      enabled: false
    },
    colors: ['#C9191D'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
        rotation: 45
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        turboThreshold: 0
      },
      areaspline: {
        fillOpacity: 0.5
      }
    },
    tooltip: {
      enabled: false,
      formatter: function () {
        return this.y + ' ';
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  };
  Data: ETPNAVPrice[] = [];
  StockSplit: ETPNAVPrices = {} as ETPNAVPrices;
  lastDatatime = new Date();
  NetWorthtime = new Date();
  NowtimeNew = new Date();
  series1Data: any[] = []; //預估淨值
  series2Data: any[] = []; //市價
  series3Data: any[] = []; //折溢價
  dateType: number = 20;
  timeoutID: any;
  totalItem = 1081; //及時預估淨值總比數
  todaylasttime = new Date();
  constructor(
    public ETFService: ETFService,
    public mainData$: mainData,
    private route: ActivatedRoute,
  ) {
    //每15秒更新
    this.timeoutID = setInterval(() => {
      this.GetPredict();
    }, 15000);
    this.todaylasttime.setHours(17, 0, 0, 0);
    this.route.url.subscribe(url => {
      this.ClearnData();
    });
  }


  ngOnChanges() {
    if (this.fundId) {
      this.StockSplit.Alert = ''
      this.StockSplit.FundsSplit = ''
      this.GetPredict();

    }
  }

  GetPredict() {
    this.ETFService.GetFundIntradayNAVPrice(this.fundId).pipe(
      finalize(() => {
        this.mainData$.loading$.next(false);
        this.clearPredictInterval();
      })
    ).subscribe((res) => {
      if (res.returnCode == '200') {
        this.now = new Date();
        if (res.returnData) {
          this.Data = res.returnData[0].Data;
        }
        else if (res.FundsSplit == '1') {
          this.Data = [];
          this.StockSplit.Alert = res.Alert;
          this.StockSplit.FundsSplit = res.FundsSplit;
          let previousTime = new Date().setHours(17, 0, 0, 0);
          let currentTime = new Date();
          if (currentTime.getTime() >= previousTime) {
          } else {
            this.lastDatatime = new Date();
          }
        }
        else {
          this.Data = [];
        }
        if (this.Data.length) {
          let unenoughitem = this.totalItem - this.Data.length;
          let timeLeast = this.Data[this.Data.length - 1].Nav_Time;
          let timeNew = new Date(timeLeast);
          let timeNewNow = new Date(timeLeast);
          this.lastDatatime = timeNew; //最後時間
          this.NowtimeNew = timeNewNow; //最後時間+15秒 用來新增到圖表的時間
          let previousTime = new Date().setHours(17, 0, 0, 0);
          let NetWorthtime = new Date().setHours(13, 30, 0, 0);
          for (var i = 0; i < unenoughitem; i++) {
            timeNewNow = new Date(timeNewNow.setSeconds(timeNewNow.getSeconds() + 15));
            if (new Date(timeNewNow).getTime() <= new Date(NetWorthtime).getTime()) {
              this.Data.push(
                {
                  Nav_Time: timeNewNow.toISOString(),
                  Fund_ID: "test",
                  Fund_Nav_New: null,
                  Stock_Nav_New: null,
                  Discount_Premium: null
                }
              );
            }
          }
        }
        this.GetNAVRealtime();
        this.GetDiscountPremium();
        this.clearPredictInterval();
      }
    });
  }

  ClearnData() {
    this.series1Data = [];
    this.series2Data = [];
    this.series3Data = [];
    this.chartOptions = {
      ...this.chartOptions,
      series: [{
        name: '預估淨值',
        data: this.series1Data,
        type: 'line',
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
      }, {
        name: '市價',
        data: this.series2Data,
        type: 'line',
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        }
      }
      ],
    }

    this.chart2Options = {
      ...this.chart2Options,
      series: [{
        name: '折溢價',
        data: this.series3Data,
        type: 'column',
        dataLabels: {
          enabled: false,
        },
      }
      ],
    }
  }

  GetNAVRealtime() {
    let _xAxis: any[] = [];
    this.series1Data = [];
    this.series2Data = [];
    this.series3Data = [];

    this.Data.map(val => {
      _xAxis.push(moment(val.Nav_Time).format("HH:mm:ss"));
      this.series1Data.push(val.Fund_Nav_New)
      this.series2Data.push(val.Stock_Nav_New)
      this.series3Data.push(val.Discount_Premium) //折溢價
    })

    this.chartOptions = {
      ...this.chartOptions,
      series: [{
        name: '預估淨值',
        data: this.series1Data,
        type: 'line',
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
      }, {
        name: '市價',
        data: this.series2Data,
        type: 'line',
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        }
      }
      ],
      xAxis: {
        type: 'datetime',
        tickInterval: this.dateType,
        categories: _xAxis,
        labels: {
          format: "{value}",
          style: {
            fontSize: '0.8em',
            fontWeight: 'bolder',
          },
          rotation: -60
        },
        lineWidth: 2,
      },
      // yAxis:{
      //   tickInterval: 0.05,
      // },
      tooltip: {
        shared: true, // 允許工具提示框共享顯示多個數據系列
        formatter: function () {
          let s = '<b>' + this.x + '</b>'; // 顯示時間
          this.points?.forEach((point) => {
            s += '<br/>' + point.series.name + ': ' + point.y;
          });
          return s;
        }
      }
    }
  }

  //取得折溢價
  GetDiscountPremium() {
    let _xAxis2: any[] = [];
    this.series3Data = []
      ; this.Data.map(val => {
        _xAxis2.push(moment(val.Nav_Time).format("HH:mm:ss"));
        this.series3Data.push(val.Discount_Premium) //折溢價
      })
    this.chart2Options = {
      ...this.chart2Options,
      series: [{
        name: '折溢價',
        data: this.series3Data,
        type: 'column',
        dataLabels: {
          enabled: false,
        },
      }
      ],
      xAxis: {
        type: 'datetime',
        tickInterval: this.dateType,
        categories: _xAxis2,
        labels: {
          format: "{value}",
          style: {
            fontSize: '0.8em',
            fontWeight: 'bolder',
          },
          rotation: -60
        },
        lineWidth: 2,
      },
      //   yAxis:{
      //   tickInterval: 0.05,
      // },
      tooltip: {
        enabled: true,
        formatter: function () {
          let s = '<b>' + this.x + '</b>'; // 顯示時間
          s += '<br/>' + this.series.name + ': ' + this.y;
          return s;
        }
      }
    }
  }

  ngOnDestroy() {
    window.clearInterval(this.timeoutID);
  }

  clearPredictInterval() {
    if (this.timeoutID && this.now > this.todaylasttime) {
      window.clearInterval(this.timeoutID);
      this.timeoutID = null;
    }
  }
}
