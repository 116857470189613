<div class="wrapper">
  <main>
    <div>
      <ng-container *ngFor="let item of newslist.Data">
        <a class="list" [href]="getLink(item)" [attr.target]="getTarget(item)">
          <span class="date">{{item.CShowStartDt | date:'yyyy/MM/dd' }}
            <span class="listcontent">
              {{item.CName}}
            </span>
          </span>
        </a>
      </ng-container>
    <!-- <ng-container *ngFor="let item of newslist.Data" >
      <a class="list"  [href]="item.CLinkUrl" [target]="item.CAnother == 1 ? '_blank' : '_parent'"   *ngIf="item.CLinkType==1">
        <span class="date">{{item.CShowStartDt | date:'yyyy/MM/dd' }}
          <span class="listcontent">
            {{item.CName}}
          </span>
        </span>
      </a>
    </ng-container>
    <ng-container *ngFor="let item of newslist.Data" >
      <a class="list"   [href]="item.CLinkFile" target="_blank"   *ngIf="item.CLinkType==2">
        <span class="date">{{item.CShowStartDt | date:'yyyy/MM/dd' }}
          <span class="listcontent">
            {{item.CName}}
          </span>
        </span>
      </a>
    </ng-container>
    <ng-container *ngFor="let item of newslist.Data" >
    <a class="list" href="unit/Type1/{{this.newsres.FunctionID}}/{{item.CId}}" [target]="item.CAnother == 1 ? '_blank' : '_parent'"  *ngIf="item.CLinkType==3">
      <span class="date">{{item.CShowStartDt | date:'yyyy/MM/dd' }}
        <span class="listcontent">
          {{item.CName}}
        </span>
      </span>
    </a>
  </ng-container> -->
      <!-- <button class="arrow-btn" *ngIf="TotalItems > (newslist.Data && newslist.Data.length)" (click)="more()">
        查看更多
        <div class="top-arrow"></div>
      </button>
      <p class="no-data" *ngIf="(newslist.Data && newslist.Data.length) >= TotalItems" >無更多資料</p> -->
      <ng-container>
        <p-paginator (onPageChange)="paginate($event)"
        [first]="first"
        [rows]="10"
        [totalRecords]="TotalItems" />
      </ng-container>
    </div>
  </main>
</div>
