<app-bread-crumb></app-bread-crumb>
<div class="qa">
  <p-tabView [(activeIndex)]="activeIndex" (onChange)="selectChange($event)">
    <p-tabPanel [header]="'ETF小教室'">
      <!-- <app-announcement-info  [data]="newslist.Data"></app-announcement-info> -->
      <app-announcement-info [FunctionID]="this.newsres.FunctionID"></app-announcement-info>
    </p-tabPanel>
    <p-tabPanel [header]="'ETF影音'" (click)="navigateToRoute('/etf/video')">
      <!-- <app-announcement-info [data]="vediolist.Data"></app-announcement-info> -->
      <app-announcement-info [FunctionID]="this.vediosres.FunctionID"></app-announcement-info>
    </p-tabPanel>
    <p-tabPanel [header]="'懶人包下載'" (click)="navigateToRoute('/etf/intro')">
     <!-- <app-form-download [data]="doclist"  [subData]="doctype.length && doctype[0].ChildTypes || []" (onChange)="getCategorySubID($event)" [isActiveSubId]="isActiveSubId"></app-form-download> -->
     <app-announcement-info [FunctionID]="this.docDownloads.FunctionID"></app-announcement-info>
    </p-tabPanel>
    <!-- <p-tabView class="web" [(activeIndex)]="activeIndex" (activeIndexChange)="activeIndexChange($event)">
      <p-tabPanel [header]="item.CName" *ngFor="let item of doctype">
          <app-form-download [data]="doclist" [subData]="item.ChildTypes" (onChange)="getCategorySubID($event)" [isActiveSubId]="isActiveSubId"></app-form-download>
      </p-tabPanel>
    </p-tabView> -->
  </p-tabView>
</div>

<ng-container *ngFor="let data of dialogData | slice:0:3">
  <app-confirm-dialog *ngIf="visible" [visible]="visible" [data]="data" (visibleChange)="visibleChange($event)"></app-confirm-dialog>
</ng-container>
