import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AritcleService } from 'src/app/shared/services/article.service';
import { ArticledataID, ResponseArticleID } from '../../../../shared/models/article';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { DialogData } from '../../../../shared/models/base';
import { MenuService } from '../../../../shared/services/Menu.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent {
  request = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;

  dialogData: DialogData = {} as DialogData;
  visible = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public aritcleService: AritcleService,
    public menuService: MenuService,
  ) {

    this.menuService.Headers$.subscribe((value) => {
      let id = 0;
      if(value.length) {
        value.map(val => val.ChildUnits.map(x => {
          if(x.CPageUrl == this.router.url) {
            this.request.FunctionID = x.CFunctionID;
            this.request.ID = 7;
            this.GetArticleByID();
          }
        }))
      }

    })
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );

    urlParams.subscribe(params => {
      // this.request.FunctionID = params['FunctionID'];
      // this.request.ID = params['ID'];
      this.request.IsPreview = params['isPreview'] || false;
    });
  }

  GetArticleByID() {
    this.aritcleService.GetArticleByID(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.list = res.Entries;
      } else {
        this.dialogOpen(res.Message)
      }
    });
  }

  dialogOpen(message:string) {
    this.visible = true;
    this.dialogData.title = '提醒'
    this.dialogData.message = message
  }

  visibleChange(e: any) {
    this.visible = false;
  }
}
