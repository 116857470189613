import { SeminarDetail, lectureID } from './../../../shared/models/activity';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { PopupReq } from '../../../shared/models/base';

@Component({
  selector: 'app-lecture-detail',
  templateUrl: './lecture-detail.component.html',
  styleUrls: ['./lecture-detail.component.scss']
})
export class LectureDetailComponent {
  customers: any[] = [{}];
  isLeft = false;
  // lectureID = {} as lectureID;
  SeminarDetail= {} as SeminarDetail;
  url='/service/fund-lecture/apply';
  templateString = `
  <table>
    <tr>
      <td style="width:100px" pFrozenColumn>時間</td>
      <td style="width:100px">14:00-14:30</td>
      <td style="width:100px">14:00-14:30</td>
      <td style="width:130px">14:40-15:00</td>
      <td style="width:100px">15:00-15:20</td>
      <td style="width:100px">14:40-15:00</td>
    </tr>
    <tr>
      <td style="width:100px" pFrozenColumn>議程</td>
      <td style="width:100px">14:00-14:30</td>
      <td style="width:100px">開幕致詞</td>
      <td style="width:130px">2023/08/17</td>
      <td style="width:100px">27.98</td>
      <td style="width:100px">27.98</td>
    </tr>
    <tr>
      <td style="width:100px" pFrozenColumn>主講人</td>
      <td style="width:100px">14:40-15:00</td>
      <td style="width:100px">OO信投信直銷主管</td>
      <td style="width:130px">2023/08/17</td>
      <td style="width:100px">27.98</td>
      <td style="width:100px">27.98</td>
    </tr>
    </table>
  `;
  PopupReq= new PopupReq;
  constructor(
    public baseService: BaseService,
    private route: ActivatedRoute,
    public activityService: ActivityService,
  ) {
    const urlParams: Observable<any> = combineLatest(
      this.route.params,
      this.route.queryParams
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      const isPreview = params['isPreview'];
      this.PopupReq.CId = Number(id);
      if(isPreview) this.PopupReq.IsPreview = true;
    });
    this.lecturelist()
  }

  getScroll() {
    let element = document.getElementsByClassName('p-datatable-wrapper');
    let maxScrollLeft = element[0].scrollWidth - element[0].clientWidth;
    if (!this.isLeft) {
      element[0].scrollTo({
        left: maxScrollLeft,
        behavior: 'smooth'
      });
      this.isLeft = true;
    } else {
      element[0].scrollTo({
        left: 0,
        behavior: 'smooth'
      });
      this.isLeft = false;
    }

  }

  lecturelist() {
    this.activityService.GetSeminarDetail(this.PopupReq).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
       this.SeminarDetail=res.Entries;
      }
    });
  }
}
