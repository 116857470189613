<!-- <app-bread-crumb></app-bread-crumb> -->
<div class="wrapper">
  <main>
      <ol  vocab="https://schema.org/" typeof="BreadcrumbList">
          <li property="itemListElement" typeof="ListItem">
              <a property="item" typeof="WebPage" routerLink="/">
                  <span property="name">Home</span></a>
              <meta property="position" content="1">
          </li>
          <li property="itemListElement" typeof="ListItem">
              <a property="item" typeof="WebPage" routerLink="/product/etf-list">
                  <span property="name">ETF總覽</span></a>
              <meta property="position" content="2">
          </li>
          <li property="itemListElement" typeof="ListItem">
              <a property="item" typeof="WebPage" routerLink="/etf/index-market/{{request.FundNo}}">
                  <span property="name">指數行情</span></a>
              <meta property="position" content="3">
          </li>
      </ol>
      <h1 class="title">
          指數行情
      </h1>
  </main>
</div>
<div class="detail-wrap">
  <main>
    <div class="search-wrap">
      <div class="calendar-item">
        <label>開始時間</label>
        <div class="calendar-wrap">
          <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
          <p-calendar class="calendar" dateFormat="yy/mm/dd" placeholder="年/月/日" [(ngModel)]="request.StartDate"></p-calendar>
        </div>
      </div>
      <div class="calendar-item">
        <label>結束時間</label>
        <div class="calendar-wrap">
          <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
          <p-calendar class="calendar" dateFormat="yy/mm/dd" placeholder="年/月/日" [(ngModel)]="request.EndDate"></p-calendar>
        </div>
      </div>
      <button (click)="search()">查詢</button>
    </div>
    <div class="button-wrap">
      <button *ngFor="let data of enumDateList" [ngClass]="{'btn-active': DateName == data.Name}"
        (click)="getDateName(data)">{{data.Name}}</button>
    </div>

    <!-- hightChart -->
    <div class="highcharts" *ngIf="fundIndexDetail">
      <div>
        <h2 class="sub-title sub-title-first">{{fundIndexDetail.FundStockName}}<span *ngIf="DateName">{{DateName}}</span><span *ngIf="this.request.StartDate && request.EndDate&&!DateName">{{this.request.StartDate| date:'yyyy/MM/dd'}} ~ {{this.request.EndDate| date:'yyyy/MM/dd'}}</span>指數行情</h2>
        <div class="nav-item">
          <span>期間最高：{{fundIndexDetail.QHighestNAVStr}}</span>
          <span>期間最低：{{fundIndexDetail.QLowestNAVStr}}</span>
        </div>
      </div>
      <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
        [options]="chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
        style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>
    <div class="detail-table-wrap detail-table-wrap2">
      <div class="detail-table">
        <div class="item">
          <div class="label">日期</div>
          <div class="desc">收盤價</div>
        </div>
        <div class="item" *ngFor="let item of fundIndexDetail.StockNav | slice: section1Start : section1End">
          <div class="label">{{item?.DateStr}}</div>
          <div class="desc">{{item?.NavStr}}</div>
        </div>
      </div>
      <ng-container *ngIf="fundIndexDetail?.StockNav?.length">
        <div class="detail-table" *ngIf="fundIndexDetail.StockNav.length > section1End">
          <div class="item">
            <div class="label">日期</div>
            <div class="desc">收盤價</div>
          </div>
          <div class="item" *ngFor="let item of fundIndexDetail.StockNav | slice: section2Start : section2End">
            <div class="label">{{item.DateStr}}</div>
            <div class="desc">{{item.NavStr}}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="fundIndexDetail?.StockNav?.length">
      <div class="detail-table" *ngIf="fundIndexDetail.StockNav.length > section2End">
        <div class="item">
          <div class="label">日期</div>
          <div class="desc">收盤價</div>
        </div>
        <div class="item" *ngFor="let item of fundIndexDetail.StockNav | slice: section3Start : section3End">
          <div class="label">{{item.DateStr}}</div>
          <div class="desc">{{item.NavStr}}</div>
        </div>
      </div>
    </ng-container>
    </div>
    <div class="tip">
      本網站所提供之任何指數數值僅供投資人參考使用，不作為任何投資邀約或建議；且投資人個別之網路頻寬、連線速度、傳輸品質各異，將可能產生更新頻率上之差異。再次提醒您，以上資料僅供參考，本公司不保證資料來源之完整正確性。如有任何遺漏或疏忽，請即通知本公司修正，本公司有權隨時修改或刪除，如遇通訊斷線、斷電、網路壅塞、股價報價延遲、系統延宕、或發生天然災害等不可抗力事由，或許將造成您使用上的不便、資料喪失、錯誤、電腦系統受損或其他經濟上損失等情形。您於使用本服務時宜自行採取防護措施。本公司對於您因使用本服務所衍生之損害或損失，不負任何賠償及法律責任。
    </div>
  </main>
</div>
