<app-banner-carousel [data]="banners"></app-banner-carousel>
<div class="wrapper">
    <main>
        <h2 class="sub-title">產品資訊</h2>

        <div class="product-carousel">
            <app-product-carousel [data]="products" [chartOptions]="chartOptions"></app-product-carousel>
        </div>

        <h2 class="sub-title">最新消息</h2>

        <div class="news">
          <ng-container *ngFor="let data of  newslist.Data">
            <a class="item"  [href]="getLink(data)" [attr.target]="getTarget(data)" >
              <span class="date">{{data.CShowStartDt| date:'yyyy/MM/dd' }} <span class="detail">{{data.CName}}</span></span>
          </a>
          </ng-container>
            <div class="more-btn" routerLink="/notice/bulletin">
                MORE
                <div class="more" routerLink="/notice/bulletin"></div>
            </div>
        </div>
    </main>
</div>

<ng-container *ngFor="let data of dialogData | slice:0:3">
    <app-confirm-dialog *ngIf="visible" [IsPreview]="IsPreview" [visible]="visible" [data]="data" (visibleChange)="visibleChange(data)" (unCheck)="unCheck($event)"></app-confirm-dialog>
</ng-container>

<ng-container *ngIf="visible && Disclaimer">
  <app-disclaimer-dialog *ngIf="visible && Disclaimer" [IsPreview]="IsPreview" [visible]="visible" [data]="dialogDataDisclaimer"></app-disclaimer-dialog>
</ng-container>
