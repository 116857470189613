import { Data } from './../../models/article';
import { Component, Input } from '@angular/core';
import { CreateSeminar, ResponseContact, Responsefeeback } from '../../models/activity';
import { ImgToken } from '../../models/user';
import { DialogData } from '../confirm-dialog/confirm-dialog.component';
import { ArticledataID, ResponseArticleID } from '../../models/article';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService } from '../../services/activity.service';
import { UserService } from '../../services/user.service';
import { AritcleService } from '../../services/article.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { mainData } from '../../../shared/interface/main-data';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'app-lecture-info',
  templateUrl: './lecture-info.component.html',
  styleUrls: ['./lecture-info.component.scss']
})
export class LectureInfoComponent {
  request = {} as CreateSeminar;
  res: Responsefeeback = {} as Responsefeeback;
  resimg: ImgToken = {} as ImgToken;
  Token = '';
  visible = false;
  dialogData: DialogData = {} as DialogData;
  checkbox = false;
  radio = false;
  requestNew = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;
  errorTest: string[] = [];
  errorFormat: string[] = [];
  errorcheckTest: string[] = [];
  lecture = false;
  @Input() data = {} as any;
  incheckother = false;
  otherthing = '';
  alreadyExecuted = false;
  visibleType = 0;
  disableagreeChecked = true;
  constructor(
    private route: ActivatedRoute,
    public activityService: ActivityService,
    public userService: UserService,
    public aritcleService: AritcleService,
    public mainData$: mainData,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.request.CSource = [];
    this.GetCaptchaImg()
    this.requestNew.FunctionID = 7343,
      this.requestNew.ID = 4,
      this.GetArticleByID();
    const urlParams: Observable<any> = combineLatest(
      this.route.params
    ).pipe(
      map(([params]) => ({ ...params }))
    );
    urlParams.subscribe(params => {
      const id = params['id'];
      this.request.CSeminarAfterId = Number(id);
    });
  }

  ContantUs() {
    this.errorTest = [];
    this.errorFormat = [];
    this.errorcheckTest = [];
    this.dialogData.message = ''
    if (!this.request.CName || !/^\s*\S/.test(this.request.CName)) {
      this.errorTest.push('姓名');
    }
    if (!this.request.CGender) {
      this.errorTest.push('性別');
    }

    if (!this.request.CEmail || !/^\s*\S/.test(this.request.CEmail)) {
      this.errorTest.push('E-mail');
    }

    if (this.request.CEmail && !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.request.CEmail)) {
      this.errorFormat.push('E-mail');
    }
    if (!this.request.CPhone || !/^\s*\S/.test(this.request.CPhone)) {
      this.errorTest.push('聯絡電話');
    }
    if (this.request.CPhone && !/^(09\d{8}|(?!00|01|09)(\d{2,4})\d{7,8})$/.test(this.request.CPhone)) {
      this.errorFormat.push('聯絡電話');
    }

    if (!this.request.CSource && !this.incheckother) {
      this.errorTest.push('如何得知此次活動');
    }

    if (this.incheckother) {
      if (!this.otherthing) {
        this.errorcheckTest.push('若勾選活動其他欄位，');
      }
    }

    if (!this.request.CIsMember) {
      this.errorTest.push('是否為投信客戶');
    }

    if (!this.request.Code) {
      this.errorTest.push('驗證碼');
    }

    if (!this.checkbox) {
      this.errorTest.push('個人資料蒐集、處理及利用告知事項');
    }

    if (this.errorTest.length || this.errorFormat.length || this.errorcheckTest.length) {
      this.visible = true;
      this.visibleType = 0;
      this.dialogData.title = '提醒'
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '為必填欄位。<br>'
      if (this.errorcheckTest.length) this.dialogData.message = this.dialogData.message + this.errorcheckTest.toString() + '則其他輸入框為必填。<br>'
      if (this.errorFormat.length) this.dialogData.message = this.dialogData.message + this.errorFormat.toString() + '欄位格式錯誤。'

    } else this.submit();
  }

  submit() {
    if (this.otherthing) {
      this.otherthing = "其他：" + this.otherthing;
      this.request.CSource.push(this.otherthing);
    }
    this.request.CodeToken = this.Token;
    this.activityService.CreateSeminarSignupData(this.request).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.res.Message = res.Message;
        this.OpenDialog(true);
      } else if (res.StatusCode == EnumStatusCode.Fail) {
        this.res.Message = res.Message;
        this.OpenDialog(false);
      }
      this.mainData$.loading$.next(false);
    });
  }

  otherthingchange(e: any) {
    if (e) {
      this.incheckother = true;
    }
    else {
      this.incheckother = false;
    }
  }

  GetCaptchaImg() {
    this.userService.GetCaptchaImg().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.resimg = res.Entries;
        this.Token = this.resimg.Token;
      }
    });
    this.mainData$.loading$.next(false);
  }

  refresh() {
    this.GetCaptchaImg()
  }
  OpenDialogremark() {
    this.visible = true;
    this.dialogData.title = '提醒'
    this.visibleType = 0;
  }

  OpenDialog(success: any) {
    this.visible = true;
    this.visibleType = 0;
    this.dialogData.title = '提醒'
    this.dialogData.message = this.res?.Message;
    if (success) {
      this.lecture = true;
      this.visible
    } else {
      this.lecture = false;
    }
  }

  policyDialog(type: any) {
    if (type == 1) {
      this.visibleType = type;
      this.visible = true;
      this.dialogData.title = '個人資料蒐集、處理及利用告知事項'
      this.dialogData.message = this.list.CContent
    }
  }

  visibleChange(e: any) {
    this.visible = false;
    if(this.visibleType ==1){
      this.checkbox = true;
      this.disableagreeChecked = false;
    }
  }


  visiblelecture(e: any) {
    this.visible = false;
    this.router.navigate(['/service/fund-lecture']);
  }

  //個資單篇文章
  GetArticleByID() {

    this.aritcleService.GetArticleByID(this.requestNew).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.list = res.Entries;
      }
    });
  }
}
