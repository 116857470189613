import { Component, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import {
  FundNAV, FundNAVvsPrice
} from '../../../../shared/models/etf';
import { enumETFDateList } from 'src/app/enum/enumDateList';
import { BaseService } from '../../../../shared/services/base.service';
import { NavigationEnd, Router } from '@angular/router';
import { DialogData } from 'src/app/shared/models/base';

@Component({
  selector: 'app-etf-nav',
  templateUrl: './etf-nav.component.html',
  styleUrls: ['./etf-nav.component.scss']
})
export class EtfNavComponent {
  @Output() submit = new EventEmitter<any>();
  @Input() fundId = '';
  @Input() data: FundNAVvsPrice = {} as FundNAVvsPrice;
  @Input() dataup: FundNAVvsPrice = {} as FundNAVvsPrice;
  @Input() Creatdate = '';
  @Input() FundsSplitRemark = '';
  visible = false;
  dialogData: DialogData = {} as DialogData; //訊息彈窗
  dialogConfirmData: DialogData = {} as DialogData; //確認彈窗
  isHighcharts = typeof Highcharts === 'object';
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  dateType: number = 2;
  series1Data: any[] = []; //預估淨值
  series2Data: any[] = []; //市價
  series3Data: any[] = []; //折溢價
  errorTest: string[] = [];
  chartOptions: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      name: '淨值',
      data: this.series1Data,
      type: 'area',
      dataLabels: {
        enabled: false,
      },
    }, {
      name: '市價',
      data: this.series2Data,
      type: 'area',
      dataLabels: {
        enabled: false,
      },
    }],
    legend: {
      labelFormatter: function () {
        return `<span>${this.name}</span>`
      },
      enabled: true,
    },
    lang: {
      noData: '無資料可顯示' // 設置無資料時的文本訊息
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    },
    colors: ['#E3961C', '#29A1D8'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        turboThreshold: 0
      },
      areaspline: {
        fillOpacity: 0.3
      }
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return '數據：' + this.y + '<br>' + '日期：' + this.x;
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  };
  chart2Options: Highcharts.Options = {
    title: {
      text: ``,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#414141',
      },
      verticalAlign: 'middle'
    },
    series: [{
      name: '折溢價',
      data: this.series1Data,
      type: 'column',
      dataLabels: {
        enabled: false,
      },
    }],
    legend: {
      enabled: false
    },
    colors: ['#C9191D'],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '0.8em',
          textAlign: 'left'
        },
      },
      lineWidth: 0,
      gridLineWidth: 1,
    },
    xAxis: {
      categories: ['2023/07/12', '2023/08/12'],
      labels: {
        style: {
          fontSize: '0.8em',
        },
      },
      lineWidth: 1,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        turboThreshold: 0
      },
      areaspline: {
        fillOpacity: 0.5
      }
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return '折溢價：' + this.y + '<br>' + '日期：' + this.x;
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1200
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    credits: {
      enabled: false
    },
  };
  FundNAVReq: FundNAV = {} as FundNAV;
  enumDateList = enumETFDateList;
  Date30day = this.enumDateList[0];
  DateName = this.enumDateList[0].Name;
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  constructor(
    public baseService: BaseService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.DateName = ''; //路徑改變清空active
      }
    });
  }

  ngAfterViewInit() {
    //預設一開始就觸發30天淨值收盤價
    this.getDateName(this.Date30day);
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    if (this.data.DataList && this.data.DataList.length) {
      this.getNavChart();
      this.GetDiscountPremium();
    }
    else{
      this.ClearnData();
    }
  }
  //取得淨值走勢圖
  GetFundNAV(active: boolean) {
    if (active) {
      this.DateName = '';
    }
    this.FundNAVReq = {
      ...this.FundNAVReq,
      FundNo: this.fundId,
      IsPreview: false,
    }
    this.submit.emit(this.FundNAVReq)
  }

  getDateName(data: any) {
    this.DateName = data.Name;
    if (data.Name !== '成立至今') {
      this.FundNAVReq.SDate = new Date(data.SDate);
      this.FundNAVReq.EDate = new Date(data.EDate);
      // if (this.baseService.Web$.value) this.dateType = data.tickInterval;
      // else this.dateType = data.tickIntervalM;
    }
    else {
      this.FundNAVReq.SDate = new Date(this.Creatdate);
      this.FundNAVReq.SDate.setUTCHours(16, 0, 0, 0);
      this.FundNAVReq.EDate = new Date(data.EDate);
    }
    // if (this.baseService.Web$.value) this.dateType = data.tickInterval;
    // else this.dateType = data.tickIntervalM;
    this.GetFundNAV(false);
  }

  getNavChart() {
    let _xAxis: any[] = [];
    this.series1Data = [];
    this.series2Data = [];
    this.data.DataList.forEach((x) => {
      _xAxis.push(x.CDataDt);
      this.series1Data.push(x.CNetValue);
      this.series2Data.push(x.CPrice);
    });

    let minValue = Math.min(...this.series1Data);
    let maxValue = Math.max(...this.series1Data);
    let minValue2 = Math.min(...this.series2Data);
    let maxValue2 = Math.max(...this.series2Data);
    let realmin: any = Math.min(minValue, minValue2);
    let realmax: any = Math.max(maxValue, maxValue2);
    let totalDataPoints = this.data.DataList.length;
    let desiredTicks = window.innerWidth < 768 ? 20 : 40;

    this.chartOptions = {
      ...this.chartOptions,
      series: [{
        name: '淨值',
        // data: this.series1Data,
        data: this.data.DataList.map(x => {
          return {
            y: x.CNetValue,
            _xYxis: x.CNetValueStr,
          }
        }),
        type: 'area',
        dataLabels: {
          enabled: false,
        },
      }, {
        name: '市價',
        // data: this.series2Data,
        data: this.data.DataList.map(x => {
          return {
            y: x.CPrice,
            _xYxis: x.CPriceStr,
          }
        }),
        type: 'area',
        dataLabels: {
          enabled: false,
        },
      }],
      yAxis: {
        min: realmin,
        max: realmax,
        title: {
          text: ''
        },
      },
      xAxis: {
        type: 'datetime',
        categories: _xAxis,
        labels: {
          rotation: -60,
          format: "{value}",
          style: {
            fontSize: '0.8em',
            fontWeight: 'bolder',
          },
        },
        lineWidth: 2,
        tickPositioner: function () {
          var positions = [],
            step = Math.ceil(totalDataPoints / desiredTicks);
          for (let i = 0; i < totalDataPoints; i += step) {
            positions.push(i);
          }
          return positions.filter(pos => typeof pos === 'number');  // 確保只返回 number 類型
        },
      },
      tooltip: {
        enabled: true,
        formatter: function (): string { // 這裡指定返回類型為 string
          return this.series.name + '：' + (this as any).point._xYxis + '<br>' + '日期：' + this.x;
        }
      },
    }
  }
  //取得折溢價
  GetDiscountPremium() {
    let _xAxis2: any[] = [];
    this.series3Data = [];
    this.data.DataList.forEach((val, index) => {
      _xAxis2.push(val.CDataDt);
      this.series3Data.push(val.CPremDiscount); // 折溢價
    });

    // this.data.DataList.map(val => {
    //   _xAxis2.push(val.CDataDt);
    //   this.series3Data.push(val.CPremDiscount) //折溢價
    // })

    let totalDataPoints = this.data.DataList.length;
    let desiredTicks = window.innerWidth < 768 ? 20 : 40;

    this.chart2Options = {
      ...this.chart2Options,
      series: [{
        name: '折溢價',
        // data: this.series3Data,
        data: this.data.DataList.map(x => {
          return {
            y: x.CPremDiscount,
            _xYxis: x.CPremDiscountStr,
          }
        }),
        type: 'column',
        dataLabels: {
          enabled: false,
        },
      }],
      xAxis: {
        type: 'datetime',
        categories: _xAxis2,
        labels: {
          rotation: -60,
          format: "{value}",
          style: {
            fontSize: '0.8em',
            fontWeight: 'bolder',
          },
        },
        lineWidth: 2,
        tickPositioner: function () {
          var positions = [],
            step = Math.ceil(totalDataPoints / desiredTicks);
          for (let i = 0; i < totalDataPoints; i += step) {
            positions.push(i);
          }
          return positions.filter(pos => typeof pos === 'number');  // 確保只返回 number 類型
        }
      },
      tooltip: {
        enabled: true,
        formatter: function (): string {
          return '折溢價：' + (this as any).point._xYxis + '<br>' + '日期：' + this.x;
        }
      }
    }
  }
  //清空
  ClearnData() {
    let _xAxis2: any[] = [];
    this.series1Data = [];
    this.series2Data = [];
    this.series3Data = [];
    this.chartOptions = {
      ...this.chartOptions,
      series: [{
        name: '淨值',
        data: this.series1Data,
        type: 'area',
        dataLabels: {
          enabled: false,
        },
      }, {
        name: '市價',
        data: this.series1Data,
        type: 'area',
        dataLabels: {
          enabled: false,
        },

      }],
      xAxis: {
        categories: _xAxis2,
      }
    }
    this.chart2Options = {
      ...this.chart2Options,
      series: [{
        name: '折溢價',
        data: this.series3Data,
        type: 'column',
        dataLabels: {
          enabled: false,
        },
      }
      ],
    }
  }

  visibleChange(e: any) {
    this.visible = false;
  }

  submitCheck() {
    this.errorTest = [];
    this.dialogData.message = '';

    if (this.FundNAVReq.EDate<this.FundNAVReq.SDate) {
      this.errorTest.push('結束時間不可小於開始時間');
    }
    if (this.errorTest.length) {
      this.visible = true;
      this.dialogData.title = '提醒'
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '。<br>'
    } else this.GetFundNAV(true);
  }
}
