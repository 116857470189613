import { Component } from '@angular/core';

@Component({
  selector: 'app-fund-record',
  templateUrl: './fund-record.component.html',
  styleUrls: ['./fund-record.component.scss']
})
export class FundRecordComponent {

}
