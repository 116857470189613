import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { mainData } from 'src/app/shared/interface/main-data';
import { ResponseArticle, Articledata } from 'src/app/shared/models/article';
import { AritcleService } from 'src/app/shared/services/article.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-unit-news',
  templateUrl: './unit-news.component.html',
  styleUrls: ['./unit-news.component.scss']
})
export class UnitNewsComponent {
  @Input() data: any[] = [];
  // @Input() TotalItems: number = 0;
  @Input() FunctionID: number = 0;
  @Output() clicked = new EventEmitter<any>();
  searchOpen= false;
  TotalItems=0;
  url='';
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  first: number = 1;
  rows: number = 10;
  constructor(
    public homeService: HomeService,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ){

  }

  ngOnInit(): void {
    this.Getnews();
  }


  close() {
    this.searchOpen = !this.searchOpen;
  }
  Getnews() {
    this.newsres.FunctionID= this.FunctionID
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.newslist = res.Entries;
        this.TotalItems= res.TotalItems;
      }
      this.mainData$.loading$.next(false);
    });
  }

  more() {
    this.newsres.PageSize=this.newsres.PageSize + 10;
    this.Getnews()
  }

  getLink(item: any): string {
    switch (item?.CLinkType) {
      case 1:
        return item?.CLinkUrl;
      case 2:
        return item?.CLinkFile;
      case 3:
        return `unit/Type1/${this.newsres.FunctionID}/${item.CId}`;
      case null:
        return `unit/Type1/${this.newsres.FunctionID}/${item.CId}`;
      default:
        return `unit/Type1/${this.newsres.FunctionID}/${item.CId}`;
    }
  }

  getTarget(item: any): string {
    switch (item?.CLinkType) {
      case 1:
      case 3:
      case null:
        return item?.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }

  paginate(event: any) {
    this.newsres.PageIndex = event.page + 1;
     this.first = event.first;
     this.Getnews();
  }
}
