export class FundDetailPriceReq {
  fundID: string = '';
  priceENUM: string = 'PerformanceHIS'; //PriceENUM:歷史淨值NAVHIS 歷史績效PerformanceHIS 歷史績效(台幣)PerformanceTWDHIS 歷史配息DividendHIS
  strDate: string = '';
  endDate: string = '';
  reload: boolean = false;
  chartFomatUse: boolean = true;
}

export class CartLocalStorageModel {
  FundID: string = '';
  Type: string[] = [];
}

export class CartModel extends CartLocalStorageModel {
  checkBox_A = false;
  checkBox_R = false;
}

export class SearchDetailCondition {
  groupID: string = '';
  parameterValue: string = '';
}

export class SearchDetailReq {
  fundIDs: string = '';
  pageSize: string = '20';
  pageIndex: string = '1';
  sortColumnName: string = '';
  desc: boolean = true;
  keyword: string = '';
  condition: SearchDetailCondition[] = [];
}

export interface ActivityFormData {
  Describe: string;
  Name: string;
  Question:ActivityForm[]
}

export interface ActivityForm {
  ID: number;
  Name: string;
  Type: number;
  Must: boolean;
  Answer:Answer[];
}

export interface Answer {
  ID: number;
  Text: string;
}


export interface SignActionForm {
  Source: string,
  ActionCode: string,
  List: SignActionFormList[],
  Code: string,
  CodeToken: string
}

export interface SignActionFormList {
  Name: string,
  Answer: string
}
